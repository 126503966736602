import React from "react";
import Moment from "react-moment";
import Styles from "../Users/UserToUser/_UserToUser.module.css";
import styles from "../Users/Chat/Modal.module.css";
import Linkify from "react-linkify";
import linkifyHtml from "linkify-html";
import { GiWideArrowDunk } from "react-icons/gi";
import { useState } from "react";
import useDownloader from "react-use-downloader";
import { Modal, IconButton, Dropdown } from "rsuite";
import { IoIosShareAlt } from "react-icons/io";
import Select from "react-select";
import { useEffect } from "react";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../api/AxiosInstance";
import { socket } from "../../ioContext/socketIo";
import { useRef } from "react";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import handleError from "../Users/ErrorHandler";
import {
  Modal as MuiModal,
  Box,
  IconButton as MuiIconButton,
} from "@mui/material";

const UserMessage = ({
  messages,
  own,
  particularStudent,
  selectedmsg,
  forwardmsg,
  index,
  imageUrls,
  imageUrlsReply,
  onClick,
  setReply,
  reply,
  selectedMessages,
  setSelectedMessages,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState("");
  const [open, setOpen] = React.useState(false);
  const [openmodal, setOpenmodal] = React.useState(false);
  const [openmodal2, setOpenmodal2] = React.useState(false);

  let [batchData, setBatchData] = useState([]);
  let [groupData, setGroupData] = useState([]);
  let [studentData, setStudentData] = useState([]);
  let [student, setStudent] = useState([]);

  let [batchselected, setBatchSelected] = useState(false);
  let [groupselected, setGroupSelected] = useState(false);
  let [studentselected, setStudentSelected] = useState(false);
  let [userselected, setuserSelected] = useState(false);

  const [backgroundColor1, setBackgroundColor1] = useState("");
  let [searchStudent, setSearchStudent] = useState("");

  let [forwardUser, setForwardUser] = useState([]);

  const [SelectedBatches, setSelectedBatches] = useState([]);
  const [SelectedUsers, setSelectedUsers] = useState([]);

  const [SelectedGroups, setSelectedGroups] = useState([]);
  const [SelectedStudents, setSelectedStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { download } = useDownloader();
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const imgRef = useRef(null);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  // let CurrentDate = dateFormat(Current, "yyyy-MM-dd HH:mm");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen2 = () => setOpenmodal2(true);
  const handleClose2 = () => {
    setOpenmodal2(false);
  };

  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  //reply
  useEffect(() => {
    const isMessageSelected = selectedMessages.some(
      msg =>
        msg?.message?.text === messages?.message?.text && msg?.index === index
    );

    setBackgroundColor1(isMessageSelected ? "lightblue" : "");
  }, [selectedMessages, messages, index]);

  const handleClick = () => {
    const isMessageSelected = selectedMessages.some(
      msg => msg.message.text === messages.message.text && msg.index === index
    );

    if (selectedMessages?.length < 1) {
      setBackgroundColor1(backgroundColor1 === "" ? "lightblue" : "");
      const modifiedMessage = { ...messages, index, isMessageSelected };
      onClick(modifiedMessage);
    } else if (reply === false && selectedMessages?.length === 1) {
      setBackgroundColor1(backgroundColor1 === "");
      setSelectedMessages([]);
    }
  };
  useEffect(() => {
    setBackgroundColor1("");
  }, [particularStudent || reply]);

  const item123 = [
    <Dropdown.Item onClick={() => HandleReply()}>Reply</Dropdown.Item>,
  ];
  let HandleReply = () => {
    setReply(true);
  };

  const renderIconButton = (props, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<IoIosShareAlt />}
        circle
        color="white"
        size="10px"
      />
    );
  };

  const handleCloseModal = () => {
    setOpenmodal(false);
    setSelectedBatches([]);

    setSelectedGroups([]);
    setSelectedStudents([]);
    setBatchData([]);
    setGroupData([]);
    setStudentData([]);
    setSelectedUsers([]);
  };

  let FetchBatch = async () => {
    setBatchSelected(true);
    setOpenmodal(true);

    async function fetchData() {
      await AxiosInstance.get(`users/batches`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data?.BatchsDetails;
          const uniqueArray = data.filter((obj, index, arr) => {
            return index === arr.findIndex(o => o.batchCode === obj.batchCode);
          });

          let eee = uniqueArray.map(function (ele) {
            //(ele)
            return {
              value: ele.batchCode,
              label: ele.batchCode,
            };
          });
          // setAllStudents(eee);
          setBatchData(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }
    fetchData();
  };
  let FetchGroup = () => {
    setOpenmodal(true);
    setGroupSelected(true);
    async function fetchData() {
      await AxiosInstance.get(`users/groups`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data?.GroupDetails;
          const uniqueArray = data.filter((obj, index, arr) => {
            return index === arr.findIndex(o => o.groupCode === obj.groupCode);
          });
          let eee = uniqueArray.map(function (ele) {
            //(ele)
            return {
              value: ele.groupCode,
              label: ele.groupCode,
            };
          });

          setGroupData(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }
    fetchData();
  };

  let FetchStudents = () => {
    async function fetchData() {
      let StudentData = await AxiosInstance.get(
        `users/getstudents/?search=${searchStudent}`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      let Studentdataaa = StudentData?.data?.students;

      let eee = Studentdataaa.map(function (ele) {
        //(ele)
        return {
          value: ele._id,
          label: ele.username,
        };
      });

      setStudentData(eee);
      setStudent(eee);
    }
    fetchData();
  };

  useEffect(() => {
    //("trigered api");
    if (searchQuery.length > 2) {
      async function fetchData() {
        let StudentData = await AxiosInstance.get(
          `users/getstudents/?search=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        let Studentdataaa = StudentData?.data?.students;

        let eee = Studentdataaa.map(function (ele) {
          //(ele)
          return {
            value: ele._id,
            label: ele.username,
          };
        });
        // //(Studentdataaa);
        setStudentData(eee);
      }
      fetchData();
    } else {
      setStudentData(student);
    }
  }, [searchQuery]);

  const items = [
    <Dropdown.Item onClick={() => handleOpen2()}>Forward To</Dropdown.Item>,
  ];

  const handleChangeStudent = e => {
    if (e.length <= 5) {
      setSelectedBatches(
        Array.isArray(e)
          ? e.map(
              x =>
                // //(x)
                x.value
              // x.value
            )
          : []
      );
    } else {
      alert("you can only send 5 Batches at a time");
    }
  };

  let handleChangeStudents = e => {
    setSelectedStudents(Array.isArray(e) ? e.map(x => x.value) : []);
  };
  useEffect(() => {
    setBackgroundColor1("");
  }, [particularStudent]);
  useEffect(() => {
    //("trigered api");
    if (searchQuery.length > 2) {
      async function fetchData() {
        let StudentData = await AxiosInstance.get(
          `users/getstudents/?search=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        let Studentdataaa = StudentData?.data?.students;

        let eee = Studentdataaa.map(function (ele) {
          //(ele)
          return {
            value: ele._id,
            label: ele.username,
          };
        });
        // //(Studentdataaa);
        setStudentData(eee);
      }
      fetchData();
    } else {
      setStudentData(student);
    }
  }, [searchQuery]);
  let FetchUsers = () => {
    async function fetchData() {
      let UserData = await AxiosInstance.get(
        `/employee/users_coneverstions_list`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );

      let converName = UserData?.data?.findUserslist;
      let data = converName.map((ele, ind) => {
        let newid = ele.conversation.map(x => {
          return {
            ...x,
            userid: x.id ? x.id : x.id1,
            // name: name,
          };
        });

        let id = newid.filter(y => {
          return y.userid !== user._id;
        });

        return {
          ...ele,
          Conver: true,
          // conve_id: ele._id,
          user_id: ele.group === false ? id[0].userid : ele._id,
          name: ele.group === false ? id[0].username : "",
        };
      });

      let UserList = await AxiosInstance.get(`/employee/users_list`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });

      let NotConvo = UserList?.data?.UsersList;
      let NoCon = NotConvo.map(x => {
        return {
          ...x,
          user_id: x._id,
          Conver: false,
        };
      });
      let ConbinedList = [...data, ...NoCon];

      let finalList = ConbinedList.filter((ele, ind, self) => {
        return ind === self.findIndex(obj => obj.user_id === ele.user_id);
      });

      let eee = finalList.map(function (ele) {
        return {
          value: ele,
          // label: ele.username ? ele.username : ele.groupName,
          label:
            ele.Conver === true && ele.group === false
              ? ele.name
              : ele.group === true && ele.Conver === true
              ? ele.groupName
              : ele.username,
        };
      });

      setForwardUser(eee);
      // setStudentData(eee);
      // setStudent(eee);
    }
    fetchData();
  };
  // //(messages);
  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
    // setShowImagePreview(true);
  };

  // //(messages.messages.text);
  function stopPropagation(e) {
    e.stopPropagation(); // Stop the event from propagating to the outer div
  }
  let handleImage = e => {
    //(e);
    handleOpen(true);
    openImagePreview(e);
  };

  const handleInputChange = value => {
    setSearchQuery(value);
  };

  const handleChangeUser = e => {
    if (e.length <= 5) {
      setSelectedUsers(
        Array.isArray(e)
          ? e.map(
              x =>
                // //(x)
                x.value
              // x.value
            )
          : []
      );
    } else {
      alert("you can only send 5 Batches at a time");
    }
  };
  let handleChangeGroup = e => {
    if (e.length <= 5) {
      setSelectedGroups(
        Array.isArray(e)
          ? e.map(
              x =>
                // //(x)
                x.value
              // x.value
            )
          : []
      );
    } else {
      alert("you can only send 5 Groups at a time");
    }
  };
  const selectedOptions = SelectedGroups.map(groupValue => ({
    value: groupValue,
    label: groupValue,
  }));
  // //(selectedOptions);
  const selectedOptionsBatch = SelectedBatches.map(groupValue => ({
    value: groupValue,
    label: groupValue,
  }));

  let HandleForwordMessage = async e => {
    // setOpenmodal(false);
    setBackgroundColor1("");
    e.preventDefault();
    let username = user.username;
    let senderId = user._id;
    let role = user.role;

    let mmm = forwardmsg.map(x => {
      return x.message.text
        ? {
            text: x.message.text,
            code: x.message.code ? x.message.code : "",
          }
        : {
            files: x.message.files,
            code: x.message.code ? x.message.code : "",
          };
    });

    let codeName = SelectedBatches.map(x => x);
    let groupName = SelectedGroups.map(x => x);
    let StudentName = SelectedStudents.map(x => x);

    if (batchselected === true) {
      let payload = {
        forwardTo: "batch_group",
        username: username,
        senderId: senderId,
        codelist: codeName,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
      };
      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setSelectedBatches([]);
            // setSelectedItemIndexes([]);
            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }

    if (groupselected === true) {
      let payload = {
        forwardTo: "batch_group",
        username: username,
        senderId: senderId,
        codelist: groupName,
        role: role,
        index: "0",
        type: "group",
        forward_msgs: mmm,
      };
      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
          }
          setOpenmodal(false);
          setSelectedBatches([]);
          // setSelectedItemIndexes([]);
          setSelectedGroups([]);
          setSelectedStudents([]);
          setBatchData([]);
          setGroupData([]);
          setStudentData([]);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }

    if (studentselected === true) {
      let payload = {
        forwardTo: "students",
        receiverId: StudentName,
        senderId: senderId,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
      };

      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setSelectedBatches([]);
            // setSelectedItemIndexes([]);
            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }

    if (userselected === true) {
      let codeName = SelectedUsers.map(x =>
        x.Conver === true ? x._id : ""
      ).filter(x => {
        return x !== "";
      });
      let NonRecive = SelectedUsers.map(x =>
        x.Conver === false ? x.user_id : ""
      ).filter(x => {
        return x !== "";
      });

      let payload = {
        // forwardTo: "batch_group",
        sendername: username,
        senderId: senderId,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
        nonreceiverId: codeName,
        receiverIds: NonRecive,
      };

      await AxiosInstance.post("/employee/users_forwardmsg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setSelectedBatches([]);
            // setSelectedItemIndexes([]);
            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
            setSelectedUsers([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(y);
        });
    }
  };

  function linkify(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    function openInNewTab(url) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    }

    return (
      <Linkify componentDecorator={openInNewTab}>
        <p className={Styles.messageText}>
          {messages?.message?.text} <br />
          {messages.forwarded ? (
            <span className={Styles.broadcast}>
              <GiWideArrowDunk />
              {/* {message.broadcastchat} */}
            </span>
          ) : (
            ""
          )}
        </p>
      </Linkify>
    );
  }
  return (
    <div className={Styles.chat}>
      {/* {messages.map((message, index) => ( */}
      <div
        className={
          own
            ? `${Styles.sendermsg}`
            : messages.Conversation !== user._id
            ? `${Styles.recivermsg}`
            : `${Styles.displaynone}`
        }
      >
        {/* <Modal onClose={handleClose} open={open} size="full">
          <Modal.Body>
            <div
              style={{
                overflow: "hidden",
                cursor: zoomLevel > 1 ? "grab" : "auto",
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
            >
              <img
                ref={imgRef}
                src={previewImage}
                style={{
                  transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                  transition: "transform 0.3s ease",
                  maxWidth: "700px",
                  maxHeight: "80%",
                  width: "80%",
                  margin: "auto",
                  display: "block",

               
                }}
                alt="Preview"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleZoomOut}>
              <FaSearchMinus />
            </Button>
            <Button variant="secondary" onClick={handleZoomIn}>
              <FaSearchPlus />
            </Button>
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal> */}
        <MuiModal
          open={open}
          onClose={handleClose}
          aria-labelledby="image-modal"
          aria-describedby="image-modal-description"
        >
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              maxWidth: "100%",
              maxHeight: "100%",
              bgcolor: "background.paper",
              boxShadow: 24,
              outline: "none",
              overflow: "hidden",
              cursor: zoomLevel > 1 ? "grab" : "auto",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <MuiIconButton
              sx={{
                position: "absolute",
                top: 0,
                right: 0,
                zIndex: "2222",
              }}
              onClick={handleClose}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={22}
                height={22}
                viewBox="0 0 24 24"
              >
                <path
                  fill="crimson"
                  d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
                ></path>
              </svg>
            </MuiIconButton>
            <img
              ref={imgRef}
              src={previewImage}
              alt="Modal Content"
              style={{
                maxWidth: "90%",
                maxHeight: "90%",
                objectFit: "contain",
                display: "block",
                margin: "0 auto",
                transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                transition: "transform 0.3s ease",
              }}
            />
            <Box
              sx={{
                position: "absolute",
                display: "flex",
                flexDirection: "column",
                top: "35px",
                right: "-3px",
              }}
            >
              <MuiIconButton variant="secondary" onClick={handleZoomOut}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#555"
                    d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M5 8v2h8V8z"
                  ></path>
                </svg>
              </MuiIconButton>
              <MuiIconButton variant="secondary" onClick={handleZoomIn}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={20}
                  height={20}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#555"
                    d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M8 5v3H5v2h3v3h2v-3h3V8h-3V5z"
                  ></path>
                </svg>
              </MuiIconButton>
            </Box>
          </Box>
        </MuiModal>
        <Modal onClose={handleClose2} open={openmodal2} size="xs">
          <Modal.Header>
            <Modal.Title>
              <h4>Forward To </h4>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              className={Styles.ListForward}
              onClick={() => {
                FetchBatch();
                handleClose2();
              }}
            >
              Batches
            </div>
            <div
              className={Styles.ListForward}
              onClick={() => {
                FetchGroup();
                handleClose2();
              }}
            >
              Groups
            </div>
            <div
              className={Styles.ListForward}
              onClick={() => {
                FetchStudents();
                setOpenmodal(true);
                setStudentSelected(true);
                handleClose2();
              }}
            >
              Students
            </div>
            <div
              className={Styles.ListForward}
              onClick={() => {
                FetchUsers();
                setOpenmodal(true);
                setuserSelected(true);
                handleClose2();
              }}
            >
              {" "}
              Users
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          open={openmodal}
          onClose={handleCloseModal}
          size="lg"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "500px",
            // width: "58%",
          }}
        >
          <Modal.Header>
            <Modal.Title>{/* <pre> Forward Messages To </pre> */}</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              overflow: "unset",
              maxheight: "none",
            }}
          >
            {
              <div className={Styles.chatMenuWrapper1}>
                <div className={Styles.ConversationBlock1}>
                  <>
                    {batchData && batchData?.length > 0 ? (
                      <>
                        <h4>.Forward Messages To Batches. </h4>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          // inputValue={searchQuery}
                          // onInputChange={handleInputChange}
                          name="addStudentstogroup"
                          options={batchData}
                          onChange={handleChangeStudent}
                          onMenuScrollToBottom
                          value={selectedOptionsBatch}
                        />
                      </>
                    ) : groupData && groupData.length > 0 ? (
                      <>
                        <>
                          <h4>.Forward Messages To Groups. </h4>
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            // inputValue={searchQuery}
                            // onInputChange={handleInputChange}
                            name="addStudentstogroup"
                            options={groupData}
                            onChange={handleChangeGroup}
                            onMenuScrollToBottom
                            value={selectedOptions}
                          />
                        </>
                      </>
                    ) : studentData && studentData.length > 0 ? (
                      <>
                        <>
                          <h4>Forward Messages To Students</h4>
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            // inputValue={searchQuery}
                            onInputChange={handleInputChange}
                            name="addStudentstogroup"
                            options={studentData}
                            onChange={handleChangeStudents}
                            onMenuScrollToBottom
                          />
                        </>
                      </>
                    ) : forwardUser && forwardUser.length > 0 ? (
                      <>
                        <>
                          <h4>.Forward Messages To Users. </h4>
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            // inputValue={searchQuery}
                            onInputChange={handleInputChange}
                            name="addStudentstogroup"
                            options={forwardUser}
                            onChange={handleChangeUser}
                            onMenuScrollToBottom
                          />
                        </>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                </div>

                <div className={Styles.loadmoreBlock}></div>
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <div className={styles.sendbtnfile}>
              <button id={styles.filesubmit}>
                <FiSend onClick={HandleForwordMessage} />
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <main
          style={{ backgroundColor: backgroundColor1 }}
          onClick={handleClick}
        >
          <div>
            {messages?.replayback &&
            Object.keys(messages?.replayback).length > 0 ? (
              <div className={Styles.StatusText}>
                Reply-{" "}
                {messages?.replayback?.message &&
                messages?.replayback?.message !== "" ? (
                  messages?.replayback?.message?.length > 85 ? (
                    messages?.replayback?.message.slice(0, 85)
                  ) : (
                    messages?.replayback?.message
                  )
                ) : messages?.replayback?.file?.length > 0 ? (
                  <>
                    {(() => {
                      switch (messages?.replayback?.file[0]?.mimetype) {
                        case "image/jpg":
                        case "image/jpeg":
                        case "image/avif":
                        case "image/png":
                        case "image/webp":
                        case "image/tiff":
                        case "image/bmp":
                          return (
                            <>
                              <img
                                src={
                                  imageUrlsReply[
                                    messages?.replayback?.file[0]?.path
                                  ]?.url
                                }
                                alt=""
                                style={{
                                  width: "100%",
                                  height: "100px",
                                  borderRadius: "5px",
                                  display: "flex",
                                }}
                                onClick={e => {
                                  stopPropagation(e);
                                  handleImage(
                                    imageUrlsReply[
                                      messages?.replayback?.file[0]?.path
                                    ]?.url
                                  );
                                }}
                              />{" "}
                            </>
                          );
                        default:
                          return (
                            <button className={Styles.btndownload1}>
                              {" "}
                              {messages?.replayback?.file[0]?.originalname}
                            </button>
                          );
                      }
                    })()}
                  </>
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
          </div>
          <div>
            <p className={Styles.textmsg}>
              {messages?.message?.text ? (
                messages?.message?.text && messages?.html ? (
                  // <pre
                  //   dangerouslySetInnerHTML={{
                  //     __html: messages?.message?.text,
                  //   }}
                  //   className={Styles.textEditStyle}
                  // />
                  <Linkify
                    componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a
                        href={decoratedHref}
                        target="_blank"
                        rel="noopener noreferrer"
                        key={key}
                      >
                        {decoratedText}
                      </a>
                    )}
                  >
                    <pre
                      dangerouslySetInnerHTML={{
                        __html: linkifyHtmlContent(
                          messages?.message?.text || ""
                        ),
                      }}
                      className={Styles.textEditStyle}
                    />
                  </Linkify>
                ) : (
                  linkify(messages?.message?.text)
                )
              ) : messages?.message?.files?.length > 0 ? (
                <>
                  {(() => {
                    switch (messages?.message?.files[0]?.mimetype) {
                      case "image/jpg":
                      case "image/jpeg":
                      case "image/avif":
                      case "image/png":
                      case "image/webp":
                      case "image/tiff":
                      case "image/bmp":
                        return (
                          <>
                            <img
                              src={
                                imageUrls[messages?.message?.files[0]?.path]
                                  ?.url
                              }
                              alt=""
                              style={{
                                width: "200px",
                                height: "130px",
                                borderRadius: "5px",
                                display: "flex",
                              }}
                              onClick={e => {
                                stopPropagation(e);
                                handleImage(
                                  imageUrls[messages?.message?.files[0]?.path]
                                    ?.url
                                );
                              }}
                            />{" "}
                            <button className={Styles.btndownload1}>
                              {messages?.message?.files[0]?.originalname}
                              <span className={Styles.Download}></span>
                              <span
                                className={Styles.linkBlock}
                                onClick={() =>
                                  download(
                                    imageUrls[messages?.message?.files[0]?.path]
                                      ?.url,
                                    messages?.message?.files[0]?.originalname,
                                    messages?.message?.files[0]?.filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                            {/* {messages.broadcastchat ? ( */}
                            <>
                              <span>
                                {messages?.forwarded ? (
                                  <span className={Styles.broadcast}>
                                    <GiWideArrowDunk />
                                    {/* {message.broadcastchat} */}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </>
                          </>
                        );
                      case "audio/mpeg":
                      case "audio/mp3":
                      case "audio/webm":
                      case "application/wav":
                      case "video/webm":
                      case "audio/wav":
                        return (
                          <>
                            <audio
                              src={
                                imageUrls[messages?.message?.files[0]?.path]
                                  ?.url
                              }
                              controls
                              style={{ width: "250px", height: "40px" }}
                            ></audio>

                            <>
                              <span>
                                {messages.forwarded ? (
                                  <span className={Styles.broadcast}>
                                    <GiWideArrowDunk />
                                    {/* {message.broadcastchat} */}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </>
                          </>
                        );
                      case "video/mp4":
                      case "video/x-matroska":
                      case "application/docx":
                      case "application/doc":
                      case "application/msword":
                      case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                      case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                      case "application/vnd.ms-word.document.macroEnabled.12":
                      case "application/vnd.ms-excel":
                      case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                      case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                      case "application/vnd.ms-excel.sheet.macroEnabled.12":
                      case "application/vnd.ms-powerpoint":
                      case "application/xlsx":
                      case "application/pptx":
                      case "application/octet-stream":
                      case "text/plain":
                      case "text/html":
                      case "application/apk":
                      case "application/pdf":
                      case "application/x-pdf":
                      case "application/x-gzpdf":
                      case "applications/vnd.pdf":
                      case "application/acrobat":
                      case "application/x-google-chrome-pdf":
                      case "text/pdf":
                      case "text/x-pdf":
                      case "text/csv":
                      case "application/zip":
                      case "application/x-zip":
                      case "application/x-zip-compressed":
                      case "application/json":
                        return (
                          <>
                            <button className={Styles.btndownload1}>
                              {messages?.message?.files[0]?.originalname}
                              <span className={Styles.Download}></span>
                              <span
                                className={Styles.linkBlock}
                                onClick={() =>
                                  download(
                                    imageUrls[messages?.message?.files[0]?.path]
                                      ?.url,
                                    messages?.message?.files[0]?.originalname,
                                    messages?.message?.files[0]?.filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>

                            <>
                              <span>
                                {messages?.forwarded ? (
                                  <span className={Styles.broadcast}>
                                    <GiWideArrowDunk />
                                    {/* {message.broadcastchat} */}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </span>
                            </>
                          </>
                        );
                      default:
                        return (
                          <button
                            className={Styles.btndownload1}
                            onClick={() =>
                              download(
                                imageUrls[messages?.message?.files[0]?.path]
                                  ?.url,
                                messages?.message?.files[0]?.originalname,
                                messages?.message?.files[0]?.filename
                              )
                            }
                          >
                            {messages?.message?.files[0]?.originalname}
                            <span className={Styles.Download}></span>
                            <span className={Styles.linkBlock}>
                              Click to download
                            </span>
                          </button>
                        );
                    }
                  })()}
                </>
              ) : (
                ""
              )}
            </p>
          </div>
          <div id={Styles.FooterBlock}>
            <span></span>
            {user?.username !== messages?.senderName && (
              <p
                className={Styles.namesender}
                style={{
                  fontSize: "x-small",
                  textTransform: "capitalize",
                }}
              >
                {messages?.senderName}
              </p>
            )}
            <div className={Styles.messageBottom} style={{ display: "flex" }}>
              {Date.now ? (
                <Moment format="MMM DD - h:mm a">{messages?.createdAt}</Moment>
              ) : (
                <Moment format="MMM DD YYYY h A">{messages?.createdAt}</Moment>
              )}

              {backgroundColor1 === "lightblue" ? (
                <span
                  className={Styles.ForwardMsgBlock}
                  onClick={e => stopPropagation(e)}
                >
                  {" "}
                  <Dropdown
                    renderToggle={renderIconButton}
                    placement="leftEnd"
                    style={{ zIndex: 2222222 }}
                  >
                    {item123}
                  </Dropdown>
                </span>
              ) : (
                ""
              )}
            </div>
          </div>
        </main>
      </div>
      {/* ))} */}
    </div>
  );
};

export default UserMessage;
