import React, { useContext, useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import profileImg from "../../assets/profile.webp";

import {
  BddtAxios,
  BddtFile,
  uploadResumeNewUrl,
} from "../../api/AxiosInstance";
import jwt_decode from "jwt-decode";

import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { RotatingLines } from "react-loader-spinner";
import Styles from "./_user.module.css";
import { SocketContext } from "../../ioContext/socketIo";
import { Modal } from "rsuite";
import classes from "./profile.module.css";
import darkBack from "../../assets/background.webp";
import { Box, Divider, Modal as MUIModal } from "@mui/material";
import handleError from "../Users/ErrorHandler";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  height: "auto",
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const Profile1 = () => {
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [showEmail, setShowEmail] = useState(false);

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");

  let [profile, setProfile] = useState([]);

  let navigate = useNavigate();
  const [file, setFile] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [number, setNumber] = useState([]);
  const [selectedUsers1, setSelectedUsers1] = useState([]);
  const [email, setEmail] = useState([]);

  // modal
  const [open, setOpen] = React.useState(false);
  const [open1, setOpen1] = React.useState(false);
  const handleOpen1 = () => setOpen1(true);
  const handleClose1 = () => setOpen1(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const socket = useContext(SocketContext);

  // upload resume for new student part starts here
  const [openNewResume, setOpenNewResume] = useState(false);
  const [selectedResume, setSelectedResume] = useState(null);
  const [loadUpload, setLoadUpload] = useState(false);
  const [isChanged, setIsChanged] = useState(false);

  const uploadNewFile = e => {
    const allowedTypes = ["application/pdf", "application/msword"];
    const selectedFiles = e.target.files[0];
    setSelectedResume(selectedFiles);
  };

  const handleUploadResumeNew = async () => {
    if (selectedResume === null) {
      toast.warn("Please select resume");
    } else {
      try {
        setLoadUpload(true);
        const formData = new FormData();
        formData.append("upload", selectedResume);
        const { data } = await uploadResumeNewUrl.post(
          `resume-upload`,
          formData,
          {
            headers: {
              Authorization: `${user?.q_q_users}`,
              "Content-Type": "multipart/form-data",
            },
          }
        );
        toast.success(data?.message || "Resume Uploaded");
        setLoadUpload(false);
        setSelectedResume(null);
        setOpenNewResume(false);
        setIsChanged(!isChanged);
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
        setLoadUpload(false);
        setSelectedResume(null);
        toast.warn("Something went wrong.");
      }
    }
  };

  // upload resume for new student part ends here

  let dddd = userToken;
  var decoded = jwt_decode(dddd);

  // ========== Browser Notification ======= //
  useEffect(() => {
    const handleNotification = data => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, [socket]);
  // ========== Browser Notification  end ======= //

  let handleClickLogout = e => {
    handleOpen();
  };
  let handleClickLogout1 = e => {
    handleOpen1();
  };
  let handleFile = event => {
    const file = event.target.files[0];

    setFile(file);
    // You can perform additional operations like uploading the file to a server
  };
  let handleSubmit = e => {
    let id = e;
    // const number = id.match(/\d+/)[0]; // Extract the first sequence of digits

    const formData = new FormData();
    formData.append("resume", file);
    formData.append("stu_id", id);
    async function students() {
      const options = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer",
        },
      };

      if (file?.length <= 0 || file === null) {
        toast.error("Choose file first", { position: "top-right" });
      } else {
        await BddtFile.post("hr/add_old_st_resume/", formData, options)
          .then(x => {
            toast.success(x.data.message, { position: "top-right" });
            setFile(null);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
          });
      }
    }
    students();
  };
  useEffect(() => {
    setLoading(true);
    async function fetchlist() {
      await BddtAxios.get(`/hr/student-personaldetails/?token=${userToken}`, {})
        .then(x => {
          setLoading(false);
          let data = x?.data?.results;
          let img = data[0].image;

          setProfile(data);

          setNumber(data[0]?.number);
          setEmail(data[0]?.email);
          let eee = data[0].number.map(x => {
            return x.show_to_hr === true ? x.number : "";
          });
          setSelectedUsers(eee);
          let emailclone = data[0].email.map(x => {
            return x.show_to_hr === true ? x.email : "";
          });
          setSelectedUsers1(emailclone);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
          setLoading(false);
        });
    }
    fetchlist();
  }, [isChanged]);

  let HandleClickLink = () => {
    const newTab = window.open(
      "https://student.qspiders.com/profile",
      "_blank"
    );
    newTab.focus();
  };

  const HandleSelectUser = user => {
    setSelectedUsers(prevUsers => {
      const userIndex = prevUsers.findIndex(u => u === user);
      if (userIndex !== -1) {
        // If user is already selected, remove it from selectedUsers
        return prevUsers.filter((_, index) => index !== userIndex);
      } else {
        // If user is not selected, add it to selectedUsers
        return [...prevUsers, user];
      }
    });
  };

  let HandleUpdateNumber = () => {
    let num = number.map(x => {
      return x.number;
    });

    let ttt = num.map(x => {
      const showToHR = selectedUsers.includes(x);
      return {
        mob: x,
        show_to_hr: showToHR,
        token: userToken,
      };
    });

    async function students() {
      if (selectedUsers.length <= 0) {
        toast.error("Select atlast One Number", { position: "top-right" });
      } else {
        let payload = {
          studid: profile[0].id,
          mob_nums: ttt,
          token: userToken,
        };

        await BddtAxios.post("/hr/update_number/", payload, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
          .then(x => {
            setOpen(false);
            setLoading(true);
            async function fetchlist() {
              await BddtAxios.get(
                `/hr/student-personaldetails/?token=${userToken}`,
                {}
              )
                .then(x => {
                  setLoading(false);

                  let data = x?.data?.results;

                  let img = data[0].image;

                  setNumber(data[0]?.number);
                  setEmail(data[0]?.email);

                  setProfile(data);
                })
                .catch(y => {
                  if (y?.response?.status === 403) {
                    handleError(navigate, socket);
                  }
                  setLoading(false);
                });
            }
            fetchlist();
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
            setOpen(false);

            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
          });
      }
    }
    students();
  };
  const HandleSelectUserEmail = user => {
    setSelectedUsers1(prevUsers => {
      const userIndex = prevUsers.findIndex(u => u === user);
      if (userIndex !== -1) {
        // If user is already selected, remove it from selectedUsers
        return prevUsers.filter((_, index) => index !== userIndex);
      } else {
        // If user is not selected, add it to selectedUsers
        return [...prevUsers, user];
      }
    });
  };

  let HandleUpdateEmail = () => {
    let num = email?.map(x => {
      return x.email;
    });

    let ttt = num.map(x => {
      const showToHR = selectedUsers1.includes(x);
      return {
        email: x,
        show_to_hr: showToHR,
      };
    });

    async function students() {
      if (ttt.length <= 0) {
        toast.error("Select atlast One Number", { position: "top-right" });
      } else {
        let payload = {
          studid: profile[0].id,
          emails: ttt,
          token: userToken,
        };
        await BddtAxios.post("/hr/update_email/", payload, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
          .then(x => {
            setOpen1(false);
            setLoading(true);
            async function fetchlist() {
              await BddtAxios.get(
                `/hr/student-personaldetails/?token=${userToken}`,
                {}
              )
                .then(x => {
                  setLoading(false);

                  let data = x?.data?.results;

                  setProfile(data);
                  setNumber(data[0]?.number);
                  setEmail(data[0]?.email);
                })
                .catch(y => {
                  if (y?.response?.status === 403) {
                    handleError(navigate, socket);
                  }
                  setLoading(false);
                });
            }
            fetchlist();
          })
          .catch(y => {
            setOpen1(false);

            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
          });
      }
    }
    students();
  };
  // ----- toggle number and email---------//
  const handlePhoneNumberClick = () => {
    setShowPhoneNumber(prevShowPhoneNumber => !prevShowPhoneNumber);
    setShowEmail(false);
  };
  const handleEmailClick = () => {
    setShowEmail(prevShowEmail => !prevShowEmail);
    setShowPhoneNumber(false);
  };

  return (
    <>
      <MUIModal
        open={openNewResume}
        onClose={() => {
          setOpenNewResume(false);
        }}
      >
        <Box sx={style} position="relative">
          <button
            style={{
              background: "transparent",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={() => {
              setOpenNewResume(false);
            }}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
            Upload Resume
          </h4>
          <Divider />
          <br />
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              size="small"
              variant="contained"
              component="label"
              className={Styles.fileUpload}
              title="Add File"
              color="success"
            >
              <input
                type="file"
                name="image"
                onChange={e => uploadNewFile(e)}
                hidden
                style={{ display: "none" }}
                accept=".pdf,.doc,.docx"
              />
              Choose File
            </Button>
          </div>
          <br />
          {selectedResume === null ? null : (
            <span
              style={{
                display: "inline-block",
                width: "100%",
                textAlign: "center",
              }}
            >
              {selectedResume?.name}
            </span>
          )}
          <br />
          <div>
            <Button
              disabled={loadUpload}
              variant="contained"
              color="warning"
              size="small"
              style={{ float: "right" }}
              onClick={handleUploadResumeNew}
            >
              Submit
            </Button>
          </div>
        </Box>
      </MUIModal>
      {loading === true ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : profile?.length == 0 ? (
        <section className={Styles.MainProfileBlock}>
          <div className={Styles.MessageError1}>
            <img src={profileImg} alt="" />
            <h3>You have not filled profile detail's please </h3>
            <span style={{ color: "black" }}>contact- 7338471266</span>
            {/* <Button>
              <div>
                <Link to={"/student-dashboard/deleteaccount"}>
                  Delete Account
                </Link>
              </div>
            </Button> */}
          </div>
        </section>
      ) : (
        profile?.map((ele, ind) => {
          return (
            <main className={classes.profileRoot} key={ind}>
              <div
                className={classes.rootProfileContent}
                key={ind}
                style={{
                  backgroundImage: `url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw0QDQ0NDg0NDQ0NDQ0NDQ0NDQ8NDg0NFREWFhURFRUYHSggGBolGxUVITEhJSkrLi4uFx8zODM4NygtLisBCgoKDQ0NDg0NDisZFRk3Kys3LS0tLSs3KysrKzcrKysrKys3KysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAKQBMwMBIgACEQEDEQH/xAAbAAEBAQEBAQEBAAAAAAAAAAAAAQIDBAYFB//EACUQAQEAAgEEAgIDAQEAAAAAAAABAhEDEiExUUFxYYETkbHxof/EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAMAwEAAhEDEQA/AP7iAAAAAAAAAAAAAAAAAAAAAACWgqbS1m0HTabcrknWDrWcmf5DqijnaxatZoiWs5LWKqMZ4PNyS/Mem1mqjyddny1Ob3P6Xk4442Kjv/JPY4BCvpgHN0AAAAAAAAAAAAAAAAAAAAGbUzrPUBkxa1tjKelQtZtZtS0FtZtNs2qiXOp/L7jGflkHbql+Wa42nXfajdrNqfye4ls9/wBiJXLPH06ZMWqOI6oI+iAc3QAAAAAAAAAAAAAAAAABm1Ntuec/IOdrPUmUrO1RrqTqZ2m1Gr3YyNmxGds2rlPTnaCZ1i1c2LVRdptLWdqLWbTaWiL1JaiUF3PYzsUfSgOToAAAAAAAAAAAAAACbS0Gk2ztNg1tzzq3JyuSotrNsLWLQLj6YsrWzao57Nt3VYuHqgbZyLLGdgxyOVrvl4ccsVRi1FrNVDaWjILs2gqAAPpgHJ1AAAAAAAAAAAAAS0FYsW1Ngzaza3WLFRjkyc9rne7ALs2ztNqi2pstYoNbOpz2bB16ksjnsmQLlh6cbLHfrZ2qOGUc7HpuM/4xlx/so81RvkwsYaQAEAAfTAOTqAACbTYNCSqAAAAADNAtQQC1LUtZ2qLtLkMcl+AZrNxNqqM7ZsdKxcQc7tNtpcYDnU23cb9udihs2zsEXadSbZtB0mbW3DZMgdcq55ccv4+kmbcoOOXFWLHpLFqR5h26IFI+gNpaza5ujW0tZ2mwa2m2bW8J8qNYxQQBKmwUtIUDaVmpsGqzlV6nPLIBENqiuOV7t5Xs5gGxi1Ua2bZXEGrGbi0AwWS+W7GbiDjnxeu7jXrZyxlVHl2za658Xr/1yyxvpUTaWr0plIDMrWO1xalBrH89nWYxxlblRXTpnqDPWA/WtS1m1NstNbTbO1xm6DWGO/p2SQ2iqlqbQATagG0QGqybS1UZzy1HHa55bv4+GQXqWVkVDOssdXynWDVokqglaxI6dIMi9JoEUALizcP23s2DlY5ZO+c7OOc/YOOWM+nHkldrXHky7tYzqY1prDGWT3pLhQXHy254tbFaGdgP1rUtZtRhpqbtejDHU0xxYa+/8bNFtQRFVNolqoqpABBNgu2M6tccsgZyhK0xliqNM53ssc+TLd+gYtRVUSRuVIIOuGnWOPHHSUG2csfSzItRXIdMo55SxUNm2QE5K57OW+HO5KLyaeSx1zy7Vy2uM66Y3tHTHJbh2n1HO42IrtMZWcuP01x3s2Dz6vqj0BR6rXThw+b+meLDd/H+u7LSoIAbRAVEWAu0EABLQZ5MnMyrO1RpdsylBnKuNb5fDjM/i/2o6StRmRdINBG8cQakVdGgIjTAL1LtlQTLD05122bB4uW93HK7ejn83X7cdqjlmxqunJe7Mqo9clS4VtcfMRUvEsxvy61i1FToGbVUfpSamkBloqUBEQARoAQAEY5KgDmgKgsoA5cnlz5J2BRjHKzw9EA1FbwBFdIoAzmyAEUAKmV7UAeO+b9s5KKjz5eaTzAaZe1rj8gw261jknaUAYAB/9k=')`,
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  border: "0.3px ridge #999",
                }}
              >
                <div className={classes.leftSide}>
                  <main className={classes.imageBlock}>
                    {ele?.thumbnail100 === null ? (
                      <img
                        src={darkBack}
                        className={classes.profileimage}
                        alt=""
                      />
                    ) : (
                      <img
                        src={`https://bdbackend.qspiders.com/${ele.thumbnail100}`}
                        className={classes.profileimage}
                        alt=""
                      />
                    )}
                  </main>
                  <Typography variant="h6" component="h2" align="center">
                    {ele.name}
                  </Typography>

                  <div className={classes.mobileEmailView}>
                    <h5 className={classes.NumberBlock1}>
                      <span
                        onClick={handlePhoneNumberClick}
                        className={classes.iconHolderProfile}
                      >
                        <span>
                          <svg width="22" height="22" viewBox="0 0 24 24">
                            <path
                              fill="green"
                              d="M19.95 21q-3.125 0-6.175-1.362t-5.55-3.863q-2.5-2.5-3.862-5.55T3 4.05q0-.45.3-.75t.75-.3H8.1q.35 0 .625.238t.325.562l.65 3.5q.05.4-.025.675T9.4 8.45L6.975 10.9q.5.925 1.187 1.787t1.513 1.663q.775.775 1.625 1.438T13.1 17l2.35-2.35q.225-.225.588-.337t.712-.063l3.45.7q.35.1.575.363T21 15.9v4.05q0 .45-.3.75t-.75.3M6.025 9l1.65-1.65L7.25 5H5.025q.125 1.025.35 2.025T6.025 9m8.95 8.95q.975.425 1.988.675T19 18.95v-2.2l-2.35-.475zm0 0"
                            />
                          </svg>
                        </span>
                        &nbsp;
                      </span>{" "}
                      {showPhoneNumber && (
                        <>
                          <span
                            className={`${Styles.Valuenumber} ${Styles.active}`}
                          >
                            {ele.number.map((x, i) => {
                              return x.show_to_hr === true ? (
                                <div
                                  key={i}
                                  className={classes.titleEmailMobile}
                                >
                                  {x.number} -{" "}
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "x-small",
                                    }}
                                  >
                                    Primary
                                  </span>
                                </div>
                              ) : (
                                <div className={classes.titleEmailMobile}>
                                  {x.number} -{" "}
                                  <span
                                    style={{
                                      color: "#2EC2F8",
                                      fontSize: "x-small",
                                    }}
                                  >
                                    Secondary
                                  </span>
                                </div>
                              );
                            })}
                          </span>

                          {ele.number.length > 1 && (
                            <Button
                              color="primary"
                              onClick={() => {
                                handleClickLogout();
                              }}
                            >
                              Update
                            </Button>
                          )}
                        </>
                      )}
                      <Modal
                        backdrop="static"
                        role="alertdialog"
                        open={open}
                        onClose={handleClose}
                        size="xs"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Modal.Body style={{ fontSize: "16px" }}>
                          <h5> Update Your Number to Hr</h5>
                          <aside>
                            {ele.number.map((x, index) => (
                              <div className={Styles.ModalNumber} key={index}>
                                {x.number}

                                <span>
                                  <input
                                    type="checkbox"
                                    defaultChecked={x.show_to_hr === true}
                                    // checked={x.show_to_hr === true}
                                    onChange={() => HandleSelectUser(x.number)}
                                  />
                                </span>
                              </div>
                            ))}
                          </aside>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            appearance="primary"
                            onClick={() => {
                              HandleUpdateNumber(ele);
                            }}
                          >
                            Update Number
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </h5>
                    <h5 className={classes.NumberBlock1}>
                      <span
                        onClick={handleEmailClick}
                        className={classes.iconHolderProfile}
                      >
                        <span>
                          <svg width="22" height="22" viewBox="0 0 24 24">
                            <path
                              fill="crimson"
                              d="M4 20q-.825 0-1.412-.587T2 18V6q0-.825.588-1.412T4 4h16q.825 0 1.413.588T22 6v12q0 .825-.587 1.413T20 20zm8-7L4 8v10h16V8zm0-2l8-5H4zM4 8V6v12z"
                            />
                          </svg>
                        </span>
                        &nbsp;
                      </span>
                      {showEmail && (
                        <>
                          <span className={Styles.Value}>
                            {ele.email.map((x, i) => {
                              return x.show_to_hr === true ? (
                                <div
                                  key={i}
                                  className={classes.titleEmailMobile}
                                >
                                  {x.email} -{" "}
                                  <span
                                    style={{
                                      color: "green",
                                      fontSize: "x-small",
                                    }}
                                  >
                                    Primary
                                  </span>
                                </div>
                              ) : (
                                <div
                                  key={i}
                                  className={classes.titleEmailMobile}
                                >
                                  {x.email} -{" "}
                                  <span
                                    style={{
                                      color: "#2EC2F8",
                                      fontSize: "x-small",
                                    }}
                                  >
                                    Secondary
                                  </span>
                                </div>
                              );
                            })}
                          </span>
                          {ele.email.length > 1 && (
                            <Button
                              appearance="primary"
                              onClick={() => {
                                handleClickLogout1();
                              }}
                            >
                              Update
                            </Button>
                          )}
                        </>
                      )}
                      <Modal
                        backdrop="static"
                        role="alertdialog"
                        open={open1}
                        onClose={handleClose1}
                        size="xs"
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Modal.Body style={{ fontSize: "16px" }}>
                          <h5> Update Your Email to Hr</h5>
                          <aside>
                            {ele.email.map((x, index) => (
                              <div className={Styles.ModalNumber} key={index}>
                                {x.email}

                                <span>
                                  <input
                                    type="checkbox"
                                    defaultChecked={x.show_to_hr === true}
                                    // checked={x.show_to_hr === true}
                                    onChange={() =>
                                      HandleSelectUserEmail(x.email)
                                    }
                                  />
                                </span>
                              </div>
                            ))}
                          </aside>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            appearance="primary"
                            onClick={() => {
                              HandleUpdateEmail(ele);
                            }}
                          >
                            Update email
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </h5>
                  </div>

                  {decoded?.aid !== null && ele?.resume !== null ? (
                    <>
                      <a
                        href={`${ele.resume}`}
                        download
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        className={classes.downloadResumeButton}
                      >
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path
                            fill="green"
                            d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                          />
                        </svg>{" "}
                        Download Resume
                      </a>
                      {!ele.old_student && (
                        <button
                          style={{
                            padding: "2px 5px",
                            borderRadius: "6px",
                            textTransform: "uppercase",
                            // fontWeight: "bold",
                            fontSize: "x-small",
                            background: "transparent",
                            border: "1px solid gray",
                            color: "#f16f1d",
                          }}
                          onClick={() => {
                            setOpenNewResume(true);
                          }}
                        >
                          upload/update resume
                        </button>
                      )}
                    </>
                  ) : decoded?.aid === null && ele?.resume !== null ? (
                    <>
                      <a
                        href={`${ele.resume}`}
                        download
                        target="_blank"
                        className={classes.downloadResumeButton}
                      >
                        <svg width="20" height="20" viewBox="0 0 24 24">
                          <path
                            fill="green"
                            d="m12 16l-5-5l1.4-1.45l2.6 2.6V4h2v8.15l2.6-2.6L17 11zm-6 4q-.825 0-1.412-.587T4 18v-3h2v3h12v-3h2v3q0 .825-.587 1.413T18 20z"
                          />
                        </svg>{" "}
                        Download Resume
                      </a>
                      <div className={Styles.fileuploadcontainer}>
                        <h4
                          className={Styles.fileuploadheading}
                          style={{ color: "#f57c00" }}
                        >
                          Update Resume
                        </h4>

                        <div className={Styles.fileupload}>
                          <Button appearance="primary">
                            <input type="file" onChange={handleFile} />
                          </Button>
                        </div>

                        <div className={Styles.buttoncontainer}>
                          <button
                            id={Styles.submitbutton}
                            onClick={() => {
                              handleSubmit(ele?.id);
                            }}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </>
                  ) : decoded?.aid === null && ele?.resume === null ? (
                    <>
                      <div className={Styles.fileuploadcontainer}>
                        <h4 className={Styles.fileuploadheading}>
                          Upload Resume
                        </h4>

                        <div className={Styles.fileupload}>
                          <Button appearance="primary">
                            <input type="file" onChange={handleFile} />
                          </Button>
                        </div>

                        <div className={Styles.buttoncontainer}>
                          <button
                            id={Styles.submitbutton}
                            onClick={() => {
                              handleSubmit(ele?.id);
                            }}
                          >
                            Upload
                          </button>
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}
                </div>
                <div className={classes.rightSide}>
                  {ele?.percentage_10 <= 0 &&
                  ele?.percentage_iti <= 0 &&
                  ele?.percentage_12 <= 0 &&
                  ele?.percentage_deg <= 0 ? (
                    <aside>
                      <>
                        <h4 style={{ color: "red" }}>
                          * you have not filled the Profile Details, please
                          click here to fill the Details -
                          {/* <span style={{ color: "black" }}>
                            contact- 9900075511
                          </span> */}
                          <Button
                            appearance="primary"
                            size="md"
                            onClick={HandleClickLink}
                          >
                            Click
                          </Button>
                          <h6 style={{ color: "black" }}>
                            {" "}
                            - Once done with filling details refresh the page to
                            get data
                          </h6>
                        </h4>
                        {/* <Button>
                          <div>
                            <Link to={"/student-dashboard/deleteaccount"}>
                              Delete Account
                            </Link>
                          </div>
                        </Button> */}
                      </>
                    </aside>
                  ) : (
                    <>
                      <div className={classes.headContentDisplay}>
                        <Typography
                          variant="h6"
                          component="h2"
                          className={classes.heading}
                        >
                          Educational Details
                        </Typography>
                      </div>
                      <div className={classes.detailsContainerBlock}>
                        <>
                          {ele?.percentage_10 > 0 && (
                            <div className={classes.educationItem}>
                              <h5>SSLC</h5>
                              <main className={classes.educationItemContent}>
                                <div>
                                  Percentage :{" "}
                                  <span>{ele?.percentage_10}%</span>
                                </div>
                                {ele.cgpa_10 > 0 && (
                                  <div>
                                    Cgpa : <span>{ele?.cgpa_10}</span>
                                  </div>
                                )}
                                <div>
                                  YOP : <span>{ele?.yop_10}</span>
                                </div>
                              </main>
                            </div>
                          )}
                          {ele?.percentage_iti > 0 && (
                            <div className={classes.educationItem}>
                              <h5>ITI</h5>
                              <main className={classes.educationItemContent}>
                                <div>
                                  Percentage :{" "}
                                  <span>{ele?.percentage_iti}%</span>
                                </div>
                                {ele.cgpa_iti > 0 && (
                                  <div>
                                    Cgpa : <span>{ele?.cgpa_iti}</span>
                                  </div>
                                )}
                                <div>
                                  YOP : <span>{ele?.yg_iti}</span>
                                </div>
                              </main>
                            </div>
                          )}

                          {ele?.percentage_12 > 0 ? (
                            <div className={classes.educationItem}>
                              <h5>PUC</h5>
                              <main className={classes.educationItemContent}>
                                <div>
                                  Percentage :{" "}
                                  <span>{ele?.percentage_12}%</span>
                                </div>
                                {ele.cgpa_12 > 0 && (
                                  <div>
                                    Cgpa : <span>{ele?.cgpa_12}</span>
                                  </div>
                                )}
                                <div>
                                  YOP : <span>{ele?.yop_12}</span>
                                </div>
                              </main>
                            </div>
                          ) : ele?.percentage_dip > 0 ? (
                            <div className={classes.educationItem}>
                              <h5>Diploma</h5>
                              <main className={classes.educationItemContent}>
                                <div>
                                  Percentage :{" "}
                                  <span>{ele?.percentage_dip}%</span>
                                </div>
                                {ele.cgpa_dip > 0 && (
                                  <div>
                                    Cgpa : <span>{ele?.cgpa_dip}</span>
                                  </div>
                                )}
                                <div>
                                  YOP : <span>{ele?.yop_dip}</span>
                                </div>
                              </main>
                            </div>
                          ) : null}
                          {/* degree part */}
                          <div>
                            {ele.otherdegree === true ? (
                              <>
                                <div className={classes.educationItem}>
                                  <h5>
                                    Degree - <span> {ele?.other_degree}</span>
                                  </h5>
                                  {ele.other_stream_deg === true ? (
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Degree Stream -{" "}
                                        <span> {ele?.stream_other_deg}</span>
                                      </h5>
                                    </div>
                                  ) : ele?.d_stream === null ||
                                    ele?.d_stream?.name === "" ? null : (
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Degree Stream -{" "}
                                        <span> {ele?.d_stream?.name}</span>
                                      </h5>
                                    </div>
                                  )}
                                  <main
                                    className={classes.educationItemContent}
                                  >
                                    <div>
                                      Percentage :{" "}
                                      <span>{ele?.percentage_deg}%</span>
                                    </div>
                                    {ele.cgpa_deg > 0 && (
                                      <div>
                                        Cgpa : <span>{ele?.cgpa_deg}</span>
                                      </div>
                                    )}
                                    <div>
                                      YOP : <span>{ele?.yop_deg}</span>
                                    </div>
                                  </main>
                                </div>
                              </>
                            ) : ele?.degree === null ? null : (
                              <>
                                <div className={classes.educationItem}>
                                  <h5>
                                    Degree - <span> {ele?.degree?.name}</span>
                                  </h5>
                                  {ele.other_stream_deg === true ? (
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Degree Stream -{" "}
                                        <span> {ele?.stream_other_deg}</span>
                                      </h5>
                                    </div>
                                  ) : ele?.d_stream === null ||
                                    ele?.d_stream?.name === "" ? null : (
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Degree Stream -{" "}
                                        <span> {ele?.d_stream?.name}</span>
                                      </h5>
                                    </div>
                                  )}
                                  <main
                                    className={classes.educationItemContent}
                                  >
                                    <div>
                                      Percentage :{" "}
                                      <span>{ele?.percentage_deg}%</span>
                                    </div>
                                    {ele.cgpa_deg > 0 && (
                                      <div>
                                        Cgpa : <span>{ele?.cgpa_deg}</span>
                                      </div>
                                    )}
                                    <div>
                                      YOP : <span>{ele?.yop_deg}</span>
                                    </div>
                                  </main>
                                </div>
                              </>
                            )}
                          </div>
                          <div>
                            {ele.othermaster === true ? (
                              <>
                                <div className={classes.educationItem}>
                                  <h5>
                                    Masters - <span> {ele?.other_master}</span>
                                  </h5>
                                  {ele.other_stream_mas === true ? (
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Masters Stream -{" "}
                                        <span> {ele?.stream_other_mas}</span>
                                      </h5>
                                    </div>
                                  ) : ele?.m_stream === null ||
                                    ele?.m_stream?.name === "" ? null : (
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Masters Stream -{" "}
                                        <span> {ele?.m_stream?.name}</span>
                                      </h5>
                                    </div>
                                  )}
                                  <main
                                    className={classes.educationItemContent}
                                  >
                                    <div>
                                      Percentage :{" "}
                                      <span>{ele?.percentage_mas}%</span>
                                    </div>
                                    {ele.cgpa_mas > 0 && (
                                      <div>
                                        Cgpa : <span>{ele?.cgpa_mas}</span>
                                      </div>
                                    )}
                                    <div>
                                      YOP : <span>{ele?.yop_mas}</span>
                                    </div>
                                  </main>
                                </div>
                              </>
                            ) : ele?.masters === null ? null : (
                              <>
                                <div className={classes.educationItem}>
                                  <h5>
                                    Masters - <span> {ele?.masters?.name}</span>
                                  </h5>
                                  {ele.other_stream_mas === true ? (
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Masters Stream -{" "}
                                        <span> {ele?.stream_other_mas}</span>
                                      </h5>
                                    </div>
                                  ) : ele?.m_stream === null ||
                                    ele?.m_stream?.name === "" ? null : (
                                    <div className={classes.educationItem}>
                                      <h5>
                                        Masters Stream -{" "}
                                        <span> {ele?.m_stream?.name}</span>
                                      </h5>
                                    </div>
                                  )}
                                  <main
                                    className={classes.educationItemContent}
                                  >
                                    <div>
                                      Percentage :{" "}
                                      <span>{ele?.percentage_mas}%</span>
                                    </div>
                                    {ele.cgpa_mas > 0 && (
                                      <div>
                                        Cgpa : <span>{ele?.cgpa_mas}</span>
                                      </div>
                                    )}
                                    <div>
                                      YOP : <span>{ele?.yop_mas}</span>
                                    </div>
                                  </main>
                                </div>
                              </>
                            )}
                          </div>
                        </>
                      </div>
                      {/* <div
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          margin: "10px 0",
                          borderTop: "1px solid gray",
                          paddingTop: "10px",
                          height: "5vh",
                        }}
                      >
                        <Button
                          variant="outlined"
                          size="small"
                          color="warning"
                          // className={classes.button}
                          onClick={() => {}}
                        >
                          Delete Account
                        </Button>
                      </div> */}
                    </>
                  )}
                </div>
              </div>
            </main>
          );
        })
      )}
    </>
  );
};

export default Profile1;
