
const Validation = (password, confirmPassword) => {
  let errors = {};

  if (!confirmPassword) {
    errors.confirmPassword = "confirm Password is required";
  }
  if (!password) {
    errors.password = "Password is required";
  } else if (password?.length < 8) {
    errors.password = "Password must be 8 characters";
  } else if (password !== confirmPassword) {
    errors.confirmPassword = "Passwords do not match";
  }
  return errors;
};

export default Validation;
