

const ValidationCreateBatch = (batchCode,subject,course,branch,startDate,endDate,trainer,tracker,addStudents) => {
    let errors ={};

    if(!batchCode){
        errors.batchCode="batchCode is required"
    }
    if(!subject){
        errors.subject="subject is required"
    }
    if(!course){
        errors.course="course is required"
    }
    if(!branch){
        errors.branch="branch is required"
    }
    if(!startDate){
        errors.startDate="startDate is required"
    }
    if(!endDate){
        errors.endDate="endDate is required"
    }
    if(!trainer){
        errors.trainer="trainer is required"
    }
    // if(juniorTrainer){
    //     errors.trainer="trainer is required"
    // }
    if(!tracker){
        errors.tracker="tracker is required"
    }
    if(!addStudents){
        errors.addStudents="addStudents is required"
    }



  return errors
}

export default ValidationCreateBatch