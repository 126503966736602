import { Autocomplete, Box, Button, Tab, Tabs } from "@mui/material";
import { CircularProgress, FormControl, MenuItem, Select } from "@mui/material";
import { Container, Divider, Grid } from "@mui/material";
import { Modal, Slider, TextField, InputLabel } from "@mui/material";
import React, { Suspense, lazy, useContext, useEffect, useState } from "react";
import Style from "./brand.module.css";
import styles from "../Student/Chat/Modal.module.css";
import { BddtAxios, fileAxios } from "../../api/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import handleError from "../Users/ErrorHandler";
import { socket } from "../../ioContext/socketIo";
import { styled } from "@mui/material/styles";
import { PostContext } from "./BrandContext";
const StudentDetailsFilter = lazy(() => import("./StudentDetailsFilter"));
const EducationDetailFilter = lazy(() => import("./EducationDetailFilter"));

const ItemHeight = 44;
const itemPaddingTop = 8;
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ItemHeight * 4.5 + itemPaddingTop, width: "250" },
  },
};

function valueText(value) {
  return `${value}`;
}

const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "58%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const styleModalFilter = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  height: "auto",
  minHeight: "50vh",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const StyledTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 40,
    width: "100%",
    backgroundColor: "#f57c00",
  },
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: "inherit",
    "&.Mui-selected": {
      color: "#f57c00",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#f57c00",
    },
  })
);

const CreatePost = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  const navigate = useNavigate();

  let {
    studentFiltersData,
    educationDetailsFilter,
    selectedId4,
    selectedId5,
    selectedId6,
    selectedId7,
    selectedId8,
    selectedId9,
  } = useContext(PostContext);

  // state to store api response data
  const [qualificationData, setQualificationData] = useState([]);
  const [collegeData, setCollegeData] = useState([]);
  const [streamData, setStreamData] = useState([]);
  const [branchData, setBranchData] = useState([]);
  const [qualTypeData, setQualTypeData] = useState([]);

  // state to store value
  const [percentageRange, setPercentageRange] = useState([0, 0]);
  const [yearOfPass, setYearOfPass] = useState([]);
  // Generate years from 1980 to current year
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array(currentYear - 1980 + 1),
    (val, index) => 1980 + index
  );

  const handleChangePercentageRange = (event, newValue) => {
    setPercentageRange(newValue);
  };

  const [highestDegree, setHighestDegree] = useState("");

  useEffect(() => {
    const fetchHighestDegree = async () => {
      try {
        const responseHighestDegree = await BddtAxios.get(
          `/commons/highest-degree/`,
          {
            headers: {
              Authorization: `Token ${user?.hr_Token}`,
            },
          }
        );
        let fetchedDegree = responseHighestDegree.data.results;
        setQualificationData(fetchedDegree);
      } catch (error) {
        toast.error("Something went wrong");
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
      }
    };
    fetchHighestDegree();
  }, []);

  useEffect(() => {
    const fetchHighestDegreeType = async () => {
      try {
        const responseHighestDegree = await BddtAxios.get(
          `/education/degree/?type=${highestDegree}`,
          {
            headers: {
              Authorization: `Token ${user?.hr_Token}`,
            },
          }
        );
        let fetchedType = responseHighestDegree.data.results;

        setQualTypeData(fetchedType);
      } catch (error) {
        toast.error("Something went wrong");
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
      }
    };
    if (highestDegree !== "") fetchHighestDegreeType();
  }, [highestDegree]);

  //? autocomplete for qualification type starts here
  const [openQualificationModal, setOpenQualificationModal] = useState(false);
  const [loadingQualifications, setLoadingQualifications] = useState(false);
  const [qualificationInput, setQualificationInput] = useState("");
  const [selectedQualification, setSelectedQualification] = useState([]);

  const fetchQualificationTypes = async searchTerm => {
    try {
      setLoadingQualifications(true);
      const response = await BddtAxios.get(
        `/education/degree/?type=${highestDegree}&name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      if (Array.isArray(data)) {
        setQualTypeData(data);
      } else {
        setQualTypeData([]);
      }

      setLoadingQualifications(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoadingQualifications(false);
    }
  };

  const handleQualificationInputChange = (event, newInputValue) => {
    setQualificationInput(newInputValue);

    if (newInputValue.length >= 3) {
      fetchQualificationTypes(newInputValue);
    }
  };

  const handleQualificationSelect = (_, newValue) => {
    setSelectedQualification(newValue);
  };

  useEffect(() => {
    const fetchInitialQualificationTypes = async () => {
      if (selectedQualification.length < 1 && qualificationInput === "") {
        const response = await BddtAxios.get(
          `/education/degree/?type=${highestDegree}`,
          {
            headers: {
              Authorization: `Token ${user?.hr_Token}`,
            },
          }
        );
        const data = response.data.results;
        setQualTypeData(data);
      }
    };
    if (highestDegree !== "") {
      fetchInitialQualificationTypes();
    }
  }, [selectedQualification, qualificationInput, highestDegree]);
  // ?autocomplete for qualification type ends here

  // autocomplete for college
  const [open1, setOpen1] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [inputValue1, setInputValue1] = useState("");
  const [selectedId1, setSelectedId1] = useState(null);

  const fetchOptions1 = async searchTerm => {
    try {
      setLoading1(true);
      const response = await BddtAxios.get(
        `/education/collegemini/?name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setCollegeData(data);
      } else {
        setCollegeData([]);
      }

      setLoading1(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange1 = (event, newInputValue) => {
    setInputValue1(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions1(newInputValue);
    }
  };

  const handleOptionSelect1 = (_, newValue) => {
    setSelectedId1(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId1 === null && inputValue1 === "") {
        // subject
        let subjectData = await BddtAxios.get(`/education/collegemini/`, {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        });
        let fetchSubject = subjectData.data.results;
        setCollegeData(fetchSubject);
      }
    };
    fetchSubject();
  }, [selectedId1, inputValue1]);

  // autocomplete for stream
  const [open2, setOpen2] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [inputValue2, setInputValue2] = useState("");
  const [selectedId2, setSelectedId2] = useState(null);

  const fetchOptions2 = async searchTerm => {
    try {
      setLoading2(true);
      const response = await BddtAxios.get(
        `/education/streams/?type=${highestDegree}&name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setStreamData(data);
      } else {
        setStreamData([]);
      }

      setLoading2(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange2 = (event, newInputValue) => {
    setInputValue2(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions2(newInputValue);
    }
  };

  const handleOptionSelect2 = (_, newValue) => {
    setSelectedId2(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId2 === null && inputValue2 === "") {
        // subject
        let subjectData = await BddtAxios.get(
          `/education/streams/?type=${highestDegree}`,
          {
            headers: {
              Authorization: `Token ${user?.hr_Token}`,
            },
          }
        );
        let fetchSubject = subjectData.data.results;
        setStreamData(fetchSubject);
      }
    };
    if (highestDegree !== "") {
      fetchSubject();
    }
  }, [selectedId2, inputValue2, highestDegree]);

  // autocomplete for branch
  const [open3, setOpen3] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [inputValue3, setInputValue3] = useState("");
  const [selectedId3, setSelectedId3] = useState([]);

  const fetchOptions3 = async searchTerm => {
    try {
      setLoading3(true);
      const response = await BddtAxios.get(
        `/branches/branchmini/?name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setBranchData(data);
      } else {
        setBranchData([]);
      }

      setLoading3(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange3 = (event, newInputValue) => {
    setInputValue3(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions3(newInputValue);
    }
  };

  const handleOptionSelect3 = (_, newValue) => {
    setSelectedId3(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId3.length < 1 && inputValue3 === "") {
        // subject
        let subjectData = await BddtAxios.get(`/branches/branchmini/`, {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        });
        let fetchSubject = subjectData.data.results;
        setBranchData(fetchSubject);
      }
    };

    fetchSubject();
  }, [selectedId3, inputValue3]);

  // modal part to type message and send file or image
  const [openModal, setOpenModal] = useState(false);
  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const [newMessage, setNewMessage] = useState("");
  const [contact, setContact] = useState([]);
  const roles = [
    "trainer",
    "counselor",
    "human resource",
    "feecollector",
    "feetracker",
    "admin",
  ];
  const [file, setFile] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);

  const handlePreview = e => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile && selectedFile.type.startsWith("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImagePreview(null);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setImagePreview(null);
  };

  //? more filter for message part starts here
  const [openModalFilter, setOpenModalFilter] = useState(false);
  const handleOpenModalFilter = () => setOpenModalFilter(true);
  const handleCloseModalFilter = () => setOpenModalFilter(false);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  //? more filter for message part ends here

  const handleSubmitSendMEssage = async () => {
    if (newMessage.trim() === "" && file === null) {
      toast.warn("Please type the message or select the file");
    } else {
      const degree = qualificationData.find(
        item => item.name.toLowerCase() === highestDegree.toLowerCase()
      );
      const qualFil =
        highestDegree === "" ? "" : `highest_degree_id=${degree?.id}`;
      const passoutYearsString = yearOfPass.join(",");

      let yop = "";
      if (yearOfPass.length < 1 || highestDegree === "") {
        yop = "";
      } else if (highestDegree === "Degree") {
        yop = `&yop_deg=${passoutYearsString}`;
      } else if (highestDegree === "Masters") {
        yop = `&yop_mas=${passoutYearsString}`;
      } else if (highestDegree === "12th/Puc") {
        yop = `&yop_12=${passoutYearsString}`;
      } else if (highestDegree === "iti") {
        yop = `&yop_iti=${passoutYearsString}`;
      } else if (highestDegree === "Diploma") {
        yop = `&yop_dip=${passoutYearsString}`;
      }
      let college = "";
      if (selectedId1 === null || highestDegree === "") {
        college = "";
      } else if (highestDegree === "Degree") {
        college = `&college_deg_id=${selectedId1?.id}`;
      } else if (highestDegree === "Masters") {
        college = `&college_mas_id=${selectedId1?.id}`;
      } else if (highestDegree === "12th/Puc") {
        college = "";
      } else if (highestDegree === "iti") {
        college = `&college_id=${selectedId1?.id}`;
      } else if (highestDegree === "Diploma") {
        college = `&college_dip_id=${selectedId1?.id}`;
      }

      let startPercRange = "";
      if (percentageRange[0] === 0 || highestDegree === "") {
        startPercRange = "";
      } else if (highestDegree === "Degree") {
        startPercRange = `&percentage_deg_min=${percentageRange[0]}`;
      } else if (highestDegree === "Masters") {
        startPercRange = `&percentage_mas_min=${percentageRange[0]}`;
      } else if (highestDegree === "12th/Puc") {
        startPercRange = `&percentage_12_min=${percentageRange[0]}`;
      } else if (highestDegree === "iti") {
        startPercRange = `&percentage_iti_min=${percentageRange[0]}`;
      } else if (highestDegree === "Diploma") {
        startPercRange = `&percentage_dip_min=${percentageRange[0]}`;
      }

      let endPercRange = "";
      if (percentageRange[1] === 0 || highestDegree === "") {
        endPercRange = "";
      } else if (highestDegree === "Degree") {
        endPercRange = `&percentage_deg_max=${percentageRange[1]}`;
      } else if (highestDegree === "Masters") {
        endPercRange = `&percentage_mas_max=${percentageRange[1]}`;
      } else if (highestDegree === "12th/Puc") {
        endPercRange = `&percentage_12_max=${percentageRange[1]}`;
      } else if (highestDegree === "iti") {
        endPercRange = `&percentage_iti_max=${percentageRange[1]}`;
      } else if (highestDegree === "Diploma") {
        endPercRange = `&percentage_dip_max=${percentageRange[1]}`;
      }

      let streamVal = "";
      if (selectedId2 === null || highestDegree === "") {
        streamVal = "";
      } else if (highestDegree === "Degree") {
        streamVal = `&d_stream_id=${selectedId2?.id}`;
      } else if (highestDegree === "Masters") {
        streamVal = `&m_stream_id=${selectedId2?.id}`;
      } else if (highestDegree === "12th/Puc") {
        streamVal = "";
      } else if (highestDegree === "iti") {
        streamVal = `&stream_iti_id=${selectedId2?.id}`;
      } else if (highestDegree === "Diploma") {
        streamVal = `&stream_dip_id=${selectedId2?.id}`;
      }

      const degTypeString = selectedQualification
        .map(item => item.id)
        .join(",");

      let degType = "";
      if (selectedQualification.length < 1 || highestDegree === "") {
        degType = "";
      } else if (highestDegree === "Degree") {
        degType = `&degree_id=${degTypeString}`;
      } else if (highestDegree === "Masters") {
        degType = `&masters_id=${degTypeString}`;
      } else if (highestDegree === "12th/Puc") {
        degType = "";
      } else if (highestDegree === "iti") {
        degType = "";
      } else if (highestDegree === "Diploma") {
        degType = "";
      }

      const contactString = contact.length > 0 ? `${contact.join(",")}` : "";

      const branchIds = selectedId3.map(item => item.id).join(",");

      const branch = selectedId3.length < 1 ? "" : `&branch_id=${branchIds}`;

      const batchCode = selectedId4.map(item => item.id).join(",");

      const batch = selectedId4.length < 1 ? "" : `&batchcode_id=${batchCode}`;

      const currentLoc =
        selectedId5 === null ? "" : `&temp_district_id=${selectedId5.id}`;

      const prefLoc = selectedId6.map(item => item.id).join(",");

      const preferred =
        selectedId6.length < 1 ? "" : `&preferred_location_id=${prefLoc}`;

      const subject =
        selectedId7 === null ? "" : `&mock_subject=${selectedId7.id}`;

      const courseVal = selectedId8.map(item => item.id).join(",");

      const course = selectedId8.length < 1 ? "" : `&course_id=${courseVal}`;

      const stuName =
        studentFiltersData.studentName.trim() === ""
          ? ""
          : `&student_name=${studentFiltersData.studentName}`;

      const stuEmail =
        studentFiltersData.studentEmail.trim() === ""
          ? ""
          : `&email=${studentFiltersData.studentEmail}`;

      const stuMobile =
        studentFiltersData.studentNumber.trim() === ""
          ? ""
          : `&number=${studentFiltersData.studentNumber}`;

      const stuGender =
        studentFiltersData.gender === ""
          ? ""
          : `&gender_id=${studentFiltersData.gender}`;

      const oldBatch =
        studentFiltersData.oldBatch.trim() === ""
          ? ""
          : `&old_batch=${studentFiltersData.oldBatch}`;

      const feeStu =
        studentFiltersData.fee.trim() === ""
          ? ""
          : `&paidper=${studentFiltersData.fee}`;

      const techRating =
        studentFiltersData.technicalRating === ""
          ? ""
          : `&technical_id=${studentFiltersData.technicalRating}`;

      const commRating =
        studentFiltersData.communicationRating === ""
          ? ""
          : `&communication_id=${studentFiltersData.communicationRating}`;

      const reqCount =
        studentFiltersData.requirementCount.trim() === ""
          ? ""
          : `&req_count=${studentFiltersData.requirementCount}`;

      const reqCountRange =
        studentFiltersData.requirementCountRange === "2"
          ? `&req_count_gte=${studentFiltersData.requirementCount}`
          : "";

      const readyToRelocate = studentFiltersData.readyToRelocate
        ? `&ready_to_relocate=true`
        : "";

      const preMockStudents = studentFiltersData.preMockStudents
        ? `&pre_mock=true`
        : "";

      const finalMockStudents = studentFiltersData.finalMockStudents
        ? `&final_mock=true`
        : "";

      const TenthMin =
        educationDetailsFilter.min10thPercentage.trim() === ""
          ? ""
          : `&percentage_10_min=${educationDetailsFilter.min10thPercentage}`;

      const TenthMax =
        educationDetailsFilter.max10thPercentage.trim() === ""
          ? ""
          : `&percentage_10_max=${educationDetailsFilter.max10thPercentage}`;

      const TwelveMin =
        highestDegree !== ""
          ? ""
          : educationDetailsFilter.min12thPercentage.trim() === ""
          ? ""
          : `&percentage_12_min=${educationDetailsFilter.min12thPercentage}`;

      const TwelveMax =
        highestDegree !== ""
          ? ""
          : educationDetailsFilter.max12thPercentage.trim() === ""
          ? ""
          : `&percentage_12_max=${educationDetailsFilter.max12thPercentage}`;

      const passoutYearsStringDip = educationDetailsFilter.diplomaYop.join(",");
      const DipYop =
        highestDegree !== ""
          ? ""
          : educationDetailsFilter.diplomaYop.length === 0
          ? ""
          : `&yop_dip=${passoutYearsStringDip}`;

      const DipMin =
        highestDegree !== ""
          ? ""
          : educationDetailsFilter.minDiplomaPercentage.trim() === ""
          ? ""
          : `&percentage_dip_min=${educationDetailsFilter.minDiplomaPercentage}`;

      const DipMax =
        highestDegree !== ""
          ? ""
          : educationDetailsFilter.maxDiplomaPercentage.trim() === ""
          ? ""
          : `&percentage_dip_max=${educationDetailsFilter.maxDiplomaPercentage}`;

      const dipStream =
        highestDegree !== ""
          ? ""
          : selectedId9.length === 0
          ? ""
          : `&stream_dip_id=${selectedId9.map(item => item.id).join(",")}`;

      let formData = new FormData();
      formData.append("text", newMessage);
      formData.append("files", file);
      formData.append("role", "branding");
      formData.append("senderId", user?._id);
      formData.append("senderName", user?.username);
      formData.append("contactPerson", contactString);
      formData.append("from", "branding");

      try {
        const options = {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await fileAxios.post(
          `/brand/brandStudents?${qualFil}${yop}${college}${startPercRange}${endPercRange}${streamVal}${batch}${branch}${stuName}${stuEmail}${stuMobile}${stuGender}${oldBatch}${feeStu}${techRating}${commRating}${reqCount}${reqCountRange}${readyToRelocate}${preMockStudents}${finalMockStudents}${currentLoc}${preferred}${subject}${TenthMin}${TenthMax}${course}${degType}${TwelveMin}${TwelveMax}${DipYop}${DipMin}${DipMax}${dipStream}`,
          formData,
          options
        );
        toast.success(response?.data?.message || "message sent successfully");
        setOpenModal(false);
        setTimeout(() => {
          navigate("/spideyhome");
        }, 1100);
      } catch (error) {
        toast.error(error?.response?.data.error);
        console.log("error", error);
      }
    }
  };

  const handleSubmitFilter = () => {
    setOpenModalFilter(false);
    toast.info("selected filters will be applied while sending message");
    setTimeout(() => {
      setOpenModal(true);
    }, 50);
  };

  return (
    <Container
      className={Style.updateStudentBlockContainer}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "90vh",
        width: "50vw",
        paddingTop: window.innerHeight < 750 ? "5%" : "8%",
      }}
    >
      {/* modal for more filters starts here*/}
      <Modal open={openModalFilter}>
        <Box sx={styleModalFilter} position="relative">
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
              zIndex: "999",
            }}
            onClick={() => {
              handleCloseModalFilter();
            }}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <StyledTabs value={value} onChange={handleChange}>
            <StyledTab label="Student" />
            <StyledTab label="Education" />
          </StyledTabs>
          <br />
          {value === 0 ? (
            <Suspense fallback={"Loading..."}>
              {/* <PostContextProvider> */}
              <StudentDetailsFilter handleSubmitFilter={handleSubmitFilter} />
              {/* </PostContextProvider> */}
            </Suspense>
          ) : value === 1 ? (
            <Suspense fallback={"Loading..."}>
              {/* <PostContextProvider> */}
              <EducationDetailFilter
                handleSubmitFilter={handleSubmitFilter}
                highestDegree={highestDegree}
              />
              {/* </PostContextProvider> */}
            </Suspense>
          ) : null}
        </Box>
      </Modal>
      {/* modal for more filters ends here */}

      {/* modal for more brand messages starts here */}
      <Modal open={openModal}>
        <Box sx={styleModal1} position="relative">
          <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
            Branding Message
          </h4>
          <Divider />
          <br />
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={() => {
              handleCloseModal();
            }}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Grid container spacing={2}>
            <Grid item sm={12}>
              <FormControl fullWidth>
                <InputLabel size="small" color="warning">
                  Select Contact Person
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  size="small"
                  label="Select Contact Person "
                  color="warning"
                  name="subject"
                  value={contact}
                  onChange={e => {
                    setContact(e.target.value);
                  }}
                  multiple
                >
                  {roles.map((role, key) => {
                    return (
                      <MenuItem key={key} value={role}>
                        {role}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                color="warning"
                label="Message"
                variant="outlined"
                placeholder="Type your Message here...."
                multiline
                minRows={3}
                value={newMessage}
                onChange={e => {
                  setNewMessage(e.target.value);
                }}
              />
            </Grid>
            <Grid item sm={12}>
              <Button
                size="small"
                variant="outlined"
                component="label"
                className={styles.fileUpload}
                color="success"
              >
                Choose File
                <input
                  type="file"
                  name="file"
                  onChange={handlePreview}
                  hidden
                />
              </Button>
              <br />
              {file && (
                <div className={styles.previewContainer}>
                  {imagePreview ? (
                    <div className={styles.imagePreviewContainer}>
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className={styles.imagePreview}
                        width={100}
                        height={100}
                      />
                      <span
                        className={styles.deleteButton}
                        onClick={handleDelete}
                        style={{ color: "crimson", fontSize: "medium" }}
                      >
                        &#10006;
                      </span>
                    </div>
                  ) : (
                    <div
                      className={styles.filePreview}
                      style={{ position: "relative" }}
                    >
                      <div className={styles.fileIcon}>📄</div>
                      <div className={styles.fileName}>{file.name}</div>
                      <span
                        className={styles.deleteButton}
                        onClick={handleDelete}
                        style={{ color: "crimson", fontSize: "medium" }}
                      >
                        &#10006;
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Grid>
            <Grid
              item
              sm={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                type="button"
                size="small"
                variant="contained"
                color="info"
                onClick={() => {
                  handleCloseModal();
                  setFile(null);
                  setNewMessage("");
                  setContact([]);
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="contained"
                color="warning"
                size="small"
                onClick={() => {
                  handleSubmitSendMEssage();
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
      <Box
        sx={{
          height: "auto",
          padding: "17px",
          width: "100%",
          borderRadius: "10px",
          border: "0.3px ridge gray",
          boxShadow: "1px 0px 8px #f16f1d",
          background: "#fff",
        }}
      >
        <form style={{ width: "100%" }}>
          <h3 style={{ color: "#f16f1d", textAlign: "center" }}>
            Create Branding Post
          </h3>
          <Divider />
          <br />

          <Grid container spacing={2} className={Style.formContainerFields}>
            <Grid item sm={6}>
              <FormControl fullWidth>
                <InputLabel size="small" color="warning">
                  Select Qualification
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  size="small"
                  label="Select Qualification"
                  color="warning"
                  value={highestDegree}
                  onChange={e => {
                    setHighestDegree(e.target.value);
                    setSelectedId1(null);
                    setSelectedId2(null);
                  }}
                >
                  {qualificationData.map((opt, index) => (
                    <MenuItem key={index} value={opt.name}>
                      {opt.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={6}>
              <Autocomplete
                size="small"
                open={openQualificationModal}
                onOpen={() => setOpenQualificationModal(true)}
                onClose={() => setOpenQualificationModal(false)}
                options={qualTypeData}
                loading={loadingQualifications}
                inputValue={qualificationInput}
                value={selectedQualification}
                multiple
                getOptionLabel={option => option.label}
                onChange={handleQualificationSelect}
                onInputChange={handleQualificationInputChange}
                renderInput={params => (
                  <TextField
                    {...params}
                    label="Qualification Type"
                    variant="outlined"
                    color="warning"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loadingQualifications ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            {highestDegree !== "" && highestDegree !== "12th/Puc" && (
              <Grid item sm={6}>
                <Autocomplete
                  size="small"
                  open={open2}
                  onOpen={() => {
                    setOpen2(true);
                  }}
                  onClose={() => {
                    setOpen2(false);
                  }}
                  // isOptionEqualToValue={(option, value) => option.id === value.id}
                  color="warning"
                  options={streamData}
                  loading={loading2}
                  inputValue={inputValue2}
                  value={selectedId2}
                  getOptionLabel={option => option.label}
                  onChange={handleOptionSelect2}
                  onInputChange={handleInputChange2}
                  renderInput={params => (
                    <TextField
                      {...params}
                      color="warning"
                      label="Select Stream"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading2 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item sm={6} display="flex">
              <FormControl fullWidth>
                <InputLabel size="small" color="warning">
                  Year of passout
                </InputLabel>
                <Select
                  MenuProps={MenuProps}
                  size="small"
                  label="Year of passout"
                  color="warning"
                  value={yearOfPass}
                  onChange={e => {
                    if (highestDegree === "") {
                      toast.info("Please select qualification first");
                    } else {
                      setYearOfPass(e.target.value);
                    }
                  }}
                  multiple
                >
                  {years.map((year, index) => (
                    <MenuItem key={index} value={year}>
                      {year}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {yearOfPass.length > 0 && (
                <button
                  style={{ background: "transparent" }}
                  onClick={() => {
                    setYearOfPass([]);
                  }}
                >
                  <svg width={20} height={22} viewBox="0 0 20 20">
                    <path
                      fill="crimson"
                      d="M8.5 4h3a1.5 1.5 0 0 0-3 0m-1 0a2.5 2.5 0 0 1 5 0h5a.5.5 0 0 1 0 1h-1.054l-1.194 10.344A3 3 0 0 1 12.272 18H7.728a3 3 0 0 1-2.98-2.656L3.554 5H2.5a.5.5 0 0 1 0-1zM9 8a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0zm2.5-.5a.5.5 0 0 0-.5.5v6a.5.5 0 0 0 1 0V8a.5.5 0 0 0-.5-.5"
                    ></path>
                  </svg>
                </button>
              )}
            </Grid>
            {highestDegree !== "" && highestDegree !== "12th/Puc" && (
              <Grid item sm={12}>
                <Autocomplete
                  size="small"
                  open={open1}
                  onOpen={() => {
                    setOpen1(true);
                  }}
                  onClose={() => {
                    setOpen1(false);
                  }}
                  limitTags={4}
                  // isOptionEqualToValue={(option, value) => option.id === value.id}
                  color="warning"
                  options={collegeData}
                  loading={loading1}
                  inputValue={inputValue1}
                  value={selectedId1}
                  getOptionLabel={option => option.name}
                  onChange={handleOptionSelect1}
                  onInputChange={handleInputChange1}
                  renderInput={params => (
                    <TextField
                      {...params}
                      color="warning"
                      label="Select College"
                      variant="outlined"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            {loading1 ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item sm={12}>
              <InputLabel
                size="small"
                color="warning"
                style={{ marginBottom: "12px" }}
              >
                Percentage Range "{percentageRange[0]} to {percentageRange[1]}"
                <small style={{ fontSize: "x-small" }}>(Default is none)</small>
              </InputLabel>
              <Slider
                color="warning"
                getAriaLabel={() => "Percentage Range"}
                value={percentageRange}
                onChange={handleChangePercentageRange}
                valueLabelDisplay="auto"
                getAriaValueText={valueText}
              />
            </Grid>
            {/* <Grid item sm={6}>
              <FormControl fullWidth size="small">
                <InputLabel color="warning">Select Education</InputLabel>
                <Select
                  MenuProps={MenuProps}
                  label="Select Education"
                  color="warning"
                  size="small"
                  value={selectedDegree}
                  onChange={e => {
                    setSelectedDegree(e.target.value);
                  }}
                >
                  <MenuItem value="Degree">Degree</MenuItem>
                  <MenuItem value="Masters">Masters</MenuItem>
                </Select>
              </FormControl>
            </Grid> */}

            <Grid item sm={12}>
              <Autocomplete
                size="small"
                open={open3}
                onOpen={() => {
                  setOpen3(true);
                }}
                onClose={() => {
                  setOpen3(false);
                }}
                limitTags={4}
                color="warning"
                options={branchData}
                multiple
                loading={loading3}
                inputValue={inputValue3}
                value={selectedId3}
                getOptionLabel={option => option.name + " - " + option?.city}
                onChange={handleOptionSelect3}
                onInputChange={handleInputChange3}
                renderInput={params => (
                  <TextField
                    {...params}
                    color="warning"
                    label="Select Branch"
                    variant="outlined"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {loading3 ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <button
                className={Style.moreFilterBtn}
                type="button"
                onClick={handleOpenModalFilter}
              >
                More Filters
              </button>
            </Grid>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                variant="contained"
                type="reset"
                color="info"
                size="small"
                onClick={() => {
                  setSelectedId1(null);
                  setSelectedId2(null);
                  setSelectedId3([]);
                  setPercentageRange([0, 0]);
                  setHighestDegree("");
                }}
              >
                Clear
              </Button>
              <Button
                variant="contained"
                type="button"
                color="warning"
                size="small"
                onClick={() => {
                  handleOpenModal();
                }}
              >
                Add Message
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default CreatePost;
