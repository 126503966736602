import Cookies from "js-cookie";
import AxioInstance from "../../api/AxiosInstance";

const register = async (userData, token) => {
  //(token);
  AxioInstance.interceptors.request.use(res => {
    res.headers = {
      Authorization: `Bearer ${token}`,
    };
    return res;
  });
  await AxioInstance.post("/auth/register", userData);
};

const adminLogin = async userData => {
  const { data } = await AxioInstance.post("/auth/login", userData);
  //(data);
  if (data) {
    sessionStorage.setItem("user", JSON.stringify(data));
  }
  return data;
};

//student loin
const stdlogin = async userData => {
  const hoursToExpire = 8;
  const expirationDate = new Date();
  expirationDate.setTime(
    expirationDate.getTime() + hoursToExpire * 60 * 60 * 1000
  );
  const { data } = await AxioInstance.post(
    "/employee/students/login",
    userData
  );

  if (data) {
    if (data?.role?.includes("student")) {
      let data1 = {
        ...data,
        role1: "student",
      };

      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
    }

    // Cookies.set("student", data?.TOKEN, { domain: ".qspiders.com" });
    Cookies.set(
      "visitedHomePage",
      true,
      { expires: 1 },
      { domain: ".qspiders.com" }
    );
    Cookies.set("user1", data.TOKEN, {
      expires: expirationDate,
      domain: "qspiders.com",
    });
  }
  return data;
};
//student loin with Q and Q
const stdloginQandQ = async userData => {
  const { data } = await AxioInstance.post("/users/tokenlogin", userData);

  if (data) {
    if (data?.role?.includes("student")) {
      let data1 = {
        ...data,
        role1: "student",
      };

      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("isCookie", "1");

      sessionStorage.setItem("token", data.TOKEN);
    } else if (data?.role?.includes("trainer")) {
      let data1 = {
        ...data,
        role1: "trainer",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("isCookie", "1");

      sessionStorage.setItem("token", data.TOKEN);
      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("hr")) {
      let data1 = {
        ...data,
        role1: "human resource",
      };

      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("isCookie", "1");

      sessionStorage.setItem("token", data.TOKEN);
    } else if (data?.role?.includes("human resource")) {
      let data1 = {
        ...data,
        role1: "human resource",
      };

      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      sessionStorage.setItem("isCookie", "1");
    } else if (data?.role?.includes("counselor")) {
      let data1 = {
        ...data,
        role1: "counselor",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
      sessionStorage.setItem("isCookie", "1");
    } else if (data?.role?.includes("tracker")) {
      let data1 = {
        ...data,
        role1: "tracker",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("isCookie", "1");

      sessionStorage.setItem("token", data.TOKEN);
      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("feetracker")) {
      let data1 = {
        ...data,
        role1: "feetracker",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("isCookie", "1");

      sessionStorage.setItem("token", data.TOKEN);
      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("qtalkHelp")) {
      let data1 = {
        ...data,
        role1: "qtalkHelp",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      sessionStorage.setItem("isCookie", "1");

      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("branding")) {
      let data1 = {
        ...data,
        role1: "branding",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      sessionStorage.setItem("isCookie", "1");

      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("user")) {
      let data1 = {
        ...data,
        role1: "user",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      sessionStorage.setItem("isCookie", "1");

      // Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    }
    setTimeout(() => {
      window.location.reload();
    }, 10);
    // Cookies.set("student", data?.TOKEN, { domain: ".qspiders.com" });
    Cookies.set(
      "visitedHomePage",
      true,
      { expires: 1 },
      { domain: ".qspiders.com" }
    );
  }
  return data;
};

//User loin
const Userlogin = async userData => {
  const { data } = await AxioInstance.post("/employee/login", userData);
  if (data) {
    if (data?.role?.includes("trainer")) {
      let data1 = {
        ...data,
        role1: "trainer",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("hr")) {
      let data1 = {
        ...data,
        role1: "human resource",
      };

      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
    } else if (data?.role?.includes("human resource")) {
      let data1 = {
        ...data,
        role1: "human resource",
      };

      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
    } else if (data?.role?.includes("counselor")) {
      let data1 = {
        ...data,
        role1: "counselor",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("tracker")) {
      let data1 = {
        ...data,
        role1: "tracker",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("feetracker")) {
      let data1 = {
        ...data,
        role1: "feetracker",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("qtalkHelp")) {
      let data1 = {
        ...data,
        role1: "qtalkHelp",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("branding")) {
      let data1 = {
        ...data,
        role1: "branding",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    } else if (data?.role?.includes("user")) {
      let data1 = {
        ...data,
        role1: "user",
      };
      sessionStorage.setItem("user", JSON.stringify(data1));
      sessionStorage.setItem("token", data.TOKEN);
      Cookies.set("name", JSON.stringify(data1), { domain: ".qspiders.com" });
    }

    Cookies.set(
      "visitedHomePage",
      true,
      { expires: 1 },
      { domain: ".qspiders.com" }
    );
  }
  return data;
};

const logout = async token => {
  //(token)
  //("home")
  let config = AxioInstance.interceptors.request.use(res => {
    res.headers = {
      Authorization: `Bearer ${token}`,
    };
    return res;
  });
  const { data } = await AxioInstance.post("/auth/logoutuser", config);
  //(data, "data logout")
  if (data) {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("device");
    sessionStorage.removeItem("isCookie");

    Cookies.remove("student", { path: "", domain: ".qspiders.com" });
    Cookies.remove("visitedHomePage", { path: "", domain: ".qspiders.com" });
    // Cookies.remove("commonuser", { path: "", domain: ".qspiders.com" });
    Cookies.remove("QtoQ", { domain: "qspiders.com" });
    Cookies.remove("name", { path: "", domain: ".qspiders.com" });
    Cookies.remove("device", { domain: ".qspiders.com" });
    window.location.reload();
  }
  return data;
};

const StudentLogout = async (logoutdata, token) => {
  let config = AxioInstance.interceptors.request.use(res => {
    res.headers = {
      Authorization: `Bearer ${token}`,
    };
    return res;
  });
  let device = Cookies.get("device", { domain: ".qspiders.com" });
  const { data } = await AxioInstance.post(
    "/users/logoutusers",
    { deviceId: device },

    config
  );
  if (data) {
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("device");
    sessionStorage.removeItem("isCookie");

    Cookies.remove("student", { path: "", domain: ".qspiders.com" });
    Cookies.remove("visitedHomePage", { path: "", domain: ".qspiders.com" });
    // Cookies.remove("commonuser", { path: "", domain: ".qspiders.com" });
    Cookies.remove("QtoQ", { domain: "qspiders.com" });
    Cookies.remove("name", { path: "", domain: ".qspiders.com" });
    Cookies.remove("user1", { domain: "qspiders.com" });

    Cookies.remove("device", { domain: ".qspiders.com" }); // fail!
    window.location.reload();
    return data;
  }
};

const authService = {
  register,
  logout,
  stdlogin,
  stdloginQandQ,
  adminLogin,
  StudentLogout,
  Userlogin,
};

export default authService;
