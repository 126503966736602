import React from "react";

const UserNameData = ({ batchName, groupname }) => {
  return (
    <main>
      <div>
        <h2 style={{ color: "#f16f1d" }}>
          {batchName ? batchName : groupname}
        </h2>
        <span>These chats are monitored by admin</span>
        <p>This is BroadCast Chat</p>
      </div>
    </main>
  );
};

export default UserNameData;
