import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import Styles from "../auth/_auth.module.css";
import {
  Box,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  TextField,
  Select as MuiSelect,
  Button as MUIButton,
} from "@mui/material";

const ItemHeight = 48;
const itemPaddingTop = 8;
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ItemHeight * 4.5 + itemPaddingTop, width: "250" },
  },
};

const AddRoles = () => {
  let navigate = useNavigate();

  let [branches, setBranches] = useState([]);
  let [subjects, setSubjects] = useState([]);
  let [sections, setSections] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  const [role, setRole] = useState("");
  const [selectedSubject, setSelectedSubject] = useState("");
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [userName, setUserName] = useState("");

  let { userAdmin } = useSelector(state => state.auth);

  // email and mobile dynamic input field
  let [emails, setEmails] = useState([""]);
  let [mobiles, setMobiles] = useState([""]);

  const handleChanger = (value, index) => {
    const newEmail = emails.map((Item, Index) => {
      return Index === index ? value : Item;
    });
    setEmails(newEmail);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...emails];
    list.splice(index, 1);
    setEmails(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setEmails([...emails, ""]);
  };

  const handleMobChange = (value, index) => {
    const newMobile = mobiles.map((Item, Index) => {
      return Index === index ? value : Item;
    });
    setMobiles(newMobile);
  };

  const handleRemoveMob = index => {
    const list = [...mobiles];
    list.splice(index, 1);
    setMobiles(list);
  };
  const handleAddMob = () => {
    setMobiles([...mobiles, ""]);
  };

  // to prevent Alphabets
  const preventMinus = e => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  // email and mobile dynamic input field

  let LoadBranch = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data[0]?.branch;
          let ccc = Branchdataaa?.map(function (ele) {
            return { value: ele, label: ele };
          });
          setBranches(ccc);
        })
        .catch(y => {})
        .finally(() => {
          setIsLoadingBranch(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenBranch = () => {
    if (branches.length === 0 && !isLoadingBranch) {
      setIsLoadingBranch(true);
      LoadBranch();
    }
  };

  useEffect(() => {
    async function fetchData() {
      // get Subjects
      let Subjectdata = await AxiosInstance.get(`auth/getdata?data=subject`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      });

      let Subjectdataaa = Subjectdata?.data[0].subject;

      Subjectdataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setSubjects(Subjectdataaa);
    }
    fetchData();
  }, [userAdmin._id]);

  let LoadSections = () => {
    async function fetchData() {
      // get Subjects
      await AxiosInstance.get(`auth/getdata?data=section`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Subjectdataaa = x?.data[0].section;

          let ddd = Subjectdataaa.map(function (ele) {
            return { value: ele, label: ele };
          });

          setSections(ddd);
        })
        .catch(y => {
          //(y);
        })
        .finally(z => {
          setIsLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpen = () => {
    if (sections?.length === 0 && !isLoading) {
      setIsLoading(true);
      LoadSections();
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    try {
      let payload = {
        username: userName,
        email: emails,
        number: mobiles,
        role: role,
        branch: selectedBranch,
      };
      if (role === "trainer" || role === "qtalkHelp") {
        payload.subject = selectedSubject;
      } else if (role === "qtalkHelp") {
        payload.section = selectedSection;
      }
      AxiosInstance.post(`/auth/register`, payload, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      });
      toast.success("user added successfully");
      setTimeout(() => {
        navigate("/admin-dashboard/admin/allroles");
      }, 900);
    } catch (error) {
      toast.error(error?.message || "something went wrong");
    }
  };

  return (
    <>
      <Container
        className={Styles.updateStudentBlockContainer}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "42vw",
        }}
      >
        <Box
          sx={{
            height: "auto",
            padding: "17px",
            width: "100%",
            borderRadius: "10px",
            border: "0.3px ridge gray",
            boxShadow: "1px 0px 8px #f16f4d",
          }}
        >
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <h3 style={{ color: "#f16f4d", textAlign: "center" }}>Add User</h3>
            <Divider />
            <br />
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <TextField
                  color="warning"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={userName}
                  placeholder="Enter Username"
                  label="Username"
                  name="username"
                  required
                  onChange={e => setUserName(e.target.value)}
                />
              </Grid>
              {emails.map((item, index) => {
                return (
                  <Grid
                    item
                    sm={6}
                    position="relative"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                    key={index + 1}
                  >
                    <TextField
                      style={{ marginBottom: "5px" }}
                      type="email"
                      label="Email"
                      color="warning"
                      variant="outlined"
                      className="email"
                      size="small"
                      fullWidth
                      value={item}
                      required
                      onChange={e => handleChanger(e.target.value, index)}
                    />
                    <div className="btn-box">
                      {emails.length !== 1 && index !== 0 && (
                        <button
                          title="Delete Email"
                          className={Styles.btns}
                          type="button"
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "-8px",
                          }}
                          onClick={() => handleRemoveClick(index)}
                        >
                          <svg width={15} height={15} viewBox="0 0 20 20">
                            <path
                              fill="crimson"
                              d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z"
                            ></path>
                          </svg>
                        </button>
                      )}
                      {emails.length - 1 === index && (
                        <button
                          title="Add more Email"
                          className={Styles.btns}
                          type="button"
                          style={
                            emails.length < 2
                              ? {
                                  display: "inline-block",
                                  position: "absolute",
                                  right: "-8px",
                                  bottom: "-8px",
                                }
                              : { display: "none" }
                          }
                          onClick={handleAddClick}
                        >
                          <svg width={18} height={18} viewBox="0 0 48 48">
                            <circle
                              cx={24}
                              cy={24}
                              r={21}
                              fill="#4caf50"
                            ></circle>
                            <g fill="#fff">
                              <path d="M21 14h6v20h-6z"></path>
                              <path d="M14 21h20v6H14z"></path>
                            </g>
                          </svg>{" "}
                        </button>
                      )}
                    </div>
                  </Grid>
                );
              })}

              {mobiles.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      position: "relative",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                    key={index + 1}
                  >
                    <TextField
                      style={{ marginBottom: "5px" }}
                      type="tel"
                      label="Number"
                      color="warning"
                      variant="outlined"
                      onKeyPress={preventMinus}
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      size="small"
                      fullWidth
                      required
                      value={item}
                      onChange={e => handleMobChange(e.target.value, index)}
                    />
                    <div className="btn-box">
                      {mobiles.length !== 1 && index !== 0 && (
                        <button
                          title="Delete Mobile Number"
                          className={Styles.btns}
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "-8px",
                          }}
                          type="button"
                          onClick={() => handleRemoveMob(index)}
                        >
                          <svg width={15} height={15} viewBox="0 0 20 20">
                            <path
                              fill="crimson"
                              d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z"
                            ></path>
                          </svg>
                        </button>
                      )}
                      {mobiles.length - 1 === index && (
                        <button
                          title="Add More Mobile Number"
                          className={Styles.btns}
                          type="button"
                          style={
                            mobiles.length < 2
                              ? {
                                  display: "inline-block",
                                  position: "absolute",
                                  right: "-10px",
                                  bottom: "-3px",
                                }
                              : { display: "none" }
                          }
                          onClick={handleAddMob}
                        >
                          <svg width={17} height={17} viewBox="0 0 48 48">
                            <circle
                              cx={24}
                              cy={24}
                              r={21}
                              fill="#4caf50"
                            ></circle>
                            <g fill="#fff">
                              <path d="M21 14h6v20h-6z"></path>
                              <path d="M14 21h20v6H14z"></path>
                            </g>
                          </svg>
                        </button>
                      )}
                    </div>
                  </Grid>
                );
              })}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size="small" color="warning">
                    Role *
                  </InputLabel>
                  <MuiSelect
                    label="Role"
                    size="small"
                    color="warning"
                    required
                    value={role}
                    onChange={e => {
                      setRole(e.target.value);
                      setSelectedSubject("");
                      setSelectedSection("");
                    }}
                  >
                    <MenuItem value="trainer">Trainer</MenuItem>
                    <MenuItem value="human resource">Hr</MenuItem>
                    <MenuItem value="counselor">Counselor</MenuItem>
                    <MenuItem value="tracker">Tracker</MenuItem>
                    <MenuItem value="qtalkHelp">QTalkHelp</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                    <MenuItem value="branding">Branding</MenuItem>
                  </MuiSelect>
                </FormControl>
              </Grid>

              {role === "qtalkHelp" && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel size="small" color="warning">
                      Section *
                    </InputLabel>
                    <MuiSelect
                      onOpen={handleMenuOpen}
                      label="Section"
                      size="small"
                      color="warning"
                      required
                      value={selectedSection}
                      onChange={e => {
                        setSelectedSection(e.target.value);
                      }}
                    >
                      {sections?.map((ele, ind) => {
                        return (
                          <MenuItem
                            value={ele.value}
                            key={ind}
                            style={{ textTransform: "capitalize" }}
                          >
                            {ele.label}
                          </MenuItem>
                        );
                      })}
                    </MuiSelect>
                  </FormControl>
                </Grid>
              )}
              {(role === "trainer" ||
                (role === "qtalkHelp" &&
                  selectedSection === "course related queries")) && (
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <InputLabel size="small" color="warning">
                      Subject *
                    </InputLabel>
                    <MuiSelect
                      MenuProps={MenuProps}
                      label="Subject"
                      size="small"
                      color="warning"
                      required
                      value={selectedSubject}
                      onChange={e => {
                        setSelectedSubject(e.target.value);
                      }}
                    >
                      {subjects?.map((ele, ind) => {
                        return (
                          <MenuItem
                            value={ele}
                            key={ind}
                            style={{ textTransform: "capitalize" }}
                          >
                            {ele}
                          </MenuItem>
                        );
                      })}
                    </MuiSelect>
                  </FormControl>
                </Grid>
              )}
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel size="small" color="warning">
                    Branch *
                  </InputLabel>
                  <MuiSelect
                    MenuProps={MenuProps}
                    onOpen={handleMenuOpenBranch}
                    label="Branch"
                    size="small"
                    color="warning"
                    required={role === "qtalkHelp" ? false : true}
                    value={selectedBranch}
                    onChange={e => {
                      setSelectedBranch(e.target.value);
                    }}
                  >
                    {branches.map((ele, ind) => {
                      return (
                        <MenuItem
                          key={ind}
                          value={ele.value}
                          style={{ textTransform: "capitalize" }}
                        >
                          {ele.label}
                        </MenuItem>
                      );
                    })}
                  </MuiSelect>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <MUIButton
                  variant="contained"
                  type="submit"
                  color="warning"
                  fullWidth
                >
                  Submit
                </MUIButton>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AddRoles;
