import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import AxiosInstance, { BddtAxios } from "../../api/AxiosInstance";
import "./Chat/styles.css";
import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";

const styleModal2 = {
  position: "absolute",
  top: "45%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const NewRequirementDetail = props => {
  let {
    reqSelected,
    disableAcceptBtn,
    handleDisplayAcceptBtn,
    setOpenReqModal,
  } = props;

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  const [details, setDetails] = useState(null);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    const fetchReqDetail = async () => {
      setLoad(true);
      try {
        const data = await BddtAxios.get(
          `/hr/req-detail-list-qtalk/?reqid=${reqSelected?.req_id}&token=${user?.TOKEN}&stb_id=${reqSelected.stb_id}`
        );
        setDetails(data.data);

        setLoad(false);
      } catch (error) {
        toast.error(error?.response?.data?.message || "something went wrong");
        setLoad(false);
      }
    };
    fetchReqDetail();
  }, []);

  const [openModal, setOpenModal] = useState(false);
  const [selectedBtn, setSelectedBtn] = useState("");
  const [comment, setComment] = useState("");
  const [disableButton, setDisableButton] = useState(false);

  const handleAcceptBtnSubmit = async () => {
    setDisableButton(true);
    try {
      const response = await BddtAxios.post(`/hr/addstudentreq/`, {
        split: reqSelected.stb_id,
        comment: comment,
        token: user?.TOKEN,
      });

      toast.success(response?.data?.qtalk);

      const data = await AxiosInstance.post(
        "/employee/student/requirement_accept_reject",
        {
          req_id: reqSelected.req_id,
          stb_id: reqSelected.stb_id,
          req_status: response?.data?.qtalk,
          req_accept_status: true,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      );
      setDisableButton(false);
      setOpenModal(false);
      setComment("");
      setSelectedBtn("");
      setOpenReqModal(false);

      // handleDisplayAcceptBtn(reqSelected?._id);
    } catch (error) {
      toast.info(error.response.data?.qtalk);
      console.log(error);
      setDisableButton(false);
      setOpenModal(false);
      setComment("");
      setSelectedBtn("");
    }
  };
  const handleRejectBtnSubmit = async () => {
    setDisableButton(true);
    try {
      const response = await BddtAxios.post(`/hr/req-reject-qtalk/`, {
        split: reqSelected.stb_id,
        action: "reject",
        token: user?.TOKEN,
      });

      toast.info(response.data.message);

      const data = await AxiosInstance.post(
        "/employee/student/requirement_accept_reject",
        {
          req_id: reqSelected.req_id,
          stb_id: reqSelected.stb_id,
          req_status: response.data.message,
          req_accept_status: false,
        },
        {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        }
      );
      setDisableButton(false);
      setOpenModal(false);
      setComment("");
      setSelectedBtn("");
      handleDisplayAcceptBtn(reqSelected?._id);
      setOpenReqModal(false);
    } catch (error) {
      toast.error(error.response.data?.message);
      setDisableButton(false);
      setOpenModal(false);
      setComment("");
      setSelectedBtn("");
    }
  };

  const handleCancelBtn = () => {
    setOpenModal(false);
    setComment("");
    setSelectedBtn("");
  };



  const getReqStatusMsg = () => {
    if (
      details.student_streq_status_for_this_req === null ||
      details.student_streq_status_for_this_req === "None"
    ) {
      return "You are added to the requirement";
    } else if (
      details.student_streq_status_for_this_req === 0 ||
      details.student_streq_status_for_this_req === "0"
    ) {
      return details.st_count < details.no_of_position
        ? "You will be added for the requirement after HR's approval"
        : "Positions are filled, please try for other requirements";
    } else if (
      details.student_streq_status_for_this_req === 1 ||
      details.student_streq_status_for_this_req === "1"
    ) {
      return "Your request has been rejected";
    } else if (
      details.student_streq_status_for_this_req === 2 ||
      details.student_streq_status_for_this_req === "2"
    ) {
      return "You are added to the requirement";
    } else {
      return ""; // Default case: No action
    }
  };

  return (
    <section className="details-container">
      <Modal open={openModal}>
        <Box sx={styleModal2} position="relative">
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
              zIndex: "999",
            }}
            onClick={handleCancelBtn}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Typography
            variant="h6"
            component="h2"
            gutterBottom
            style={{ color: "#f57c00", textAlign: "center" }}
          >
            Are you sure you want to{" "}
            {selectedBtn === "accept" ? (
              <b style={{ color: "green" }}>Accept</b>
            ) : (
              <b style={{ color: "crimson" }}>Reject</b>
            )}{" "}
            the requirement?
          </Typography>
          {selectedBtn === "accept" ? (
            <p style={{ color: "crimson", textAlign: "center" }}>
              Note :{" "}
              <small style={{ color: "#333" }}>
                Once the requirement is accepted and you missed to attend the
                interview, you will be permanently blocked for the future
                requirements.
              </small>
            </p>
          ) : null}
          <br />
          {selectedBtn === "accept" ? (
            <TextField
              multiline
              minRows={2}
              maxRows={3}
              fullWidth
              label="comment"
              color="warning"
              value={comment}
              onChange={e => {
                setComment(e.target.value);
              }}
            />
          ) : null}

          <br />
          <br />
          <div
            style={{
              display: "flex",
              width: "80%",
              justifyContent: "space-evenly",
              margin: "0 auto",
            }}
          >
            <Button
              variant="contained"
              color="info"
              size="small"
              onClick={handleCancelBtn}
            >
              Cancel
            </Button>
            {selectedBtn === "accept" ? (
              <Button
                variant="contained"
                color="success"
                size="small"
                onClick={() => {
                  handleAcceptBtnSubmit();
                  // handleCloseReqModal();
                }}
              >
                accept
              </Button>
            ) : (
              <Button
                variant="contained"
                color="error"
                size="small"
                onClick={() => {
                  handleRejectBtnSubmit();
                }}
              >
                reject
              </Button>
            )}
          </div>
        </Box>
      </Modal>


      {load ? (
        <h5 className="noDataText">Loading...</h5>
      ) : details === null ? (
        <h5 className="noDataText">No Matching Data Found</h5>
      ) : (
    
        <div className="details-content">
          <h2>Requirements Details</h2>
          <Divider />

          <br />

          {details.st_count < details.no_of_position ||
          details.student_streq_status_for_this_req === null ||
          details.student_streq_status_for_this_req === "None" ||
          details.student_streq_status_for_this_req === 2 ||
          details.student_streq_status_for_this_req === "2" ? (
            <>
              {/* Normal Details */}
              <section className="details-section">
                <div style={{ display: "flex", gap: "1%", flexWrap: "wrap" }}>
                  {details.req_code && (
                    <p style={{ fontWeight: "bold", color: "green" }}>
                      <strong>Req Code:</strong>
                      {details.req_code}
                    </p>
                  )}
                  {details.organisation && (
                    <p>
                      <strong>Organisation:</strong>
                      {details.organisation}
                    </p>
                  )}

                  {details.gender.length > 0 && (
                    <p>
                      <strong>Gender:</strong>
                      {details.gender.join(", ")}
                    </p>
                  )}
                </div>
              </section>
              {/* Job Details */}
              <section className="details-section">
                <h3>Job Description</h3>
                <Divider />
                <div style={{ display: "flex", gap: "1%", flexWrap: "wrap" }}>
                  {details.designation.length > 0 && (
                    <p>
                      <strong>Designation:</strong>
                      {details.designation.join(", ")}
                    </p>
                  )}
                  {details.subject.length > 0 && (
                    <p style={{ display: "flex" }}>
                      <strong>Subject:</strong>
                      <span
                        style={{
                          display: "inline-block",
                          width: "59%",
                          textTransform: "capitalize",
                        }}
                      >
                        {details.subject.join(", ")}
                      </span>
                    </p>
                  )}
                  <p>
                    <strong>Bond:</strong>
                    {details.bond ? "Yes" : "No"}
                  </p>
                  {details.years !== null && (
                    <p>
                      <strong>Years:</strong>
                      {details.years}
                    </p>
                  )}
                  {details.shift.length > 0 && (
                    <p>
                      <strong>Shift:</strong>
                      {details.shift.join(", ")}
                    </p>
                  )}
                  {details.ctc && (
                    <p>
                      <strong>CTC:</strong>
                      {details.ctc}
                    </p>
                  )}
                  {details.btype && (
                    <p>
                      <strong>Type:</strong>
                      {details.btype}
                    </p>
                  )}
                  {details.location.length > 0 && (
                    <p style={{ display: "flex" }}>
                      <strong>Location:</strong>{" "}
                      <span
                        style={{
                          display: "inline-block",
                          width: "59%",
                          textTransform: "capitalize",
                        }}
                      >
                        {details.location.join(", ")}
                      </span>
                    </p>
                  )}
                </div>
              </section>
              {/* Education Details */}
              <section className="details-section">
                <h3>Educational Criteria</h3>
                <Divider />
                <div style={{ display: "flex", gap: "1%", flexWrap: "wrap" }}>
                  {details.tenth_percent && (
                    <p>
                      <strong>Tenth Percent:</strong>
                      {details.tenth_percent}
                    </p>
                  )}
                  {details.twelfth_percent && (
                    <p>
                      <strong>Twelfth Percent:</strong>
                      {details.twelfth_percent}
                    </p>
                  )}
                  {details.diploma_percent && (
                    <p>
                      <strong>Diploma Percent:</strong>
                      {details.diploma_percent}
                    </p>
                  )}
                  {details.yop && (
                    <p style={{ display: "flex" }}>
                      <strong>Year of Passing:</strong>{" "}
                      <span style={{ display: "inline-block", width: "59%" }}>
                        {details.yop.split(",").join(", ")}
                      </span>
                    </p>
                  )}
                  {details.any_degree && (
                    <p>
                      <strong>Any Degree:</strong>Yes
                    </p>
                  )}
                  {details.degree.length > 0 && (
                    <p style={{ display: "flex" }}>
                      <strong>Degree:</strong>{" "}
                      <span
                        style={{
                          display: "inline-block",
                          width: "59%",
                          textTransform: "capitalize",
                        }}
                      >
                        {details.degree.join(", ")}
                      </span>
                    </p>
                  )}
                  {details.any_degree_stream && (
                    <p>
                      <strong>Any Degree Stream:</strong>Yes
                    </p>
                  )}
                  {details.degree_stream.length > 0 && (
                    <p style={{ display: "flex" }}>
                      <strong>Degree Stream:</strong>{" "}
                      <span
                        style={{
                          display: "inline-block",
                          width: "59%",
                          textTransform: "capitalize",
                        }}
                      >
                        {details.degree_stream.join(", ")}
                      </span>
                    </p>
                  )}
                  {details.degree_percent && (
                    <p>
                      <strong>Degree Percent:</strong>
                      {details.degree_percent}
                    </p>
                  )}
                  {details.any_master_degree && (
                    <p>
                      <strong>Any Master Degree:</strong>Yes
                    </p>
                  )}
                  {details.master_degree.length > 0 && (
                    <p style={{ display: "flex" }}>
                      <strong>Master Degree:</strong>{" "}
                      <span
                        style={{
                          display: "inline-block",
                          width: "59%",
                          textTransform: "capitalize",
                        }}
                      >
                        {details.master_degree.join(", ")}
                      </span>
                    </p>
                  )}
                  {details.master_stream.length > 0 && (
                    <p style={{ display: "flex" }}>
                      <strong>Master Stream:</strong>{" "}
                      <span
                        style={{
                          display: "inline-block",
                          width: "59%",
                          textTransform: "capitalize",
                        }}
                      >
                        {details.master_stream.join(", ")}
                      </span>
                    </p>
                  )}
                  {details.masters_percent !== null && (
                    <p>
                      <strong>Masters Percent:</strong>
                      {details.masters_percent}
                    </p>
                  )}
                  {details.any_master_stream && (
                    <p>
                      <strong>Any Master Stream:</strong>Yes
                    </p>
                  )}
                  {details.master_and_degree && (
                    <p>
                      <strong>Master and Degree:</strong> Yes
                    </p>
                  )}
                </div>
              </section>
            </>
          ) : null}
          <Divider />
          {reqSelected.req_status === null &&
          !disableAcceptBtn.includes(reqSelected._id) ? (
            <div className="button-container">
              <button
                className="reject-button"
                disabled={disableButton}
                onClick={() => {
                  setSelectedBtn("reject");
                  setTimeout(() => {
                    setOpenModal(true);
                  }, 100);
                }}
              >
                Reject
              </button>

              <button
                className="accept-button"
                disabled={disableButton}
                onClick={() => {
                  setSelectedBtn("accept");
                  setTimeout(() => {
                    setOpenModal(true);
                  }, 100);
                }}
              >
                Accept
              </button>
            </div>
          ) : reqSelected.req_status.includes("reject") ? (
            <h4 style={{ textAlign: "center", color: "crimson" }}>
              You have rejected this requirement
            </h4>
          ) : (
            <h4 style={{ textAlign: "center", color: "green" }}>
              {getReqStatusMsg()}
            </h4>
          )}
        </div>
      )}
    </section>
  );
};

export default NewRequirementDetail;
