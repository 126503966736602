import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import useDownloader from "react-use-downloader";
import AxiosInstance from "../../api/AxiosInstance";
import Styles from "./_UserToUser.module.css";
import { GiWideArrowDunk } from "react-icons/gi";
import Linkify from "react-linkify";
import linkifyHtml from "linkify-html";
import { RotatingLines } from "react-loader-spinner";
import { AiFillDelete } from "react-icons/ai";
import Moment from "react-moment";
import wavEncoder from "wav-encoder";

import {
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import {
  Tooltip,
  Whisper,
  Modal,
  ButtonToolbar,
  Dropdown,
  IconButton,
} from "rsuite";
import { ImAttachment } from "react-icons/im";
import { IoIosShareAlt } from "react-icons/io";
import { socket } from "../../ioContext/socketIo";
import { useNavigate } from "react-router-dom";
import FileUpload from "./FileUpload";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import handleError from "../Users/ErrorHandler";
import fetchImageUrl from "../functions/FetchFileWithToken";
import {
  Modal as MuiModal,
  Box,
  IconButton as MuiIconButton,
} from "@mui/material";
const StudentHelpChatOld = ({ selectedTitle }) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");

  let scrollRef = useRef();
  let navigate = useNavigate();

  let [msgScroll, setMsgScroll] = useState(0);
  let [messageCount, setMessageCount] = useState(0);
  let [lengthmsg, setLengthMsg] = useState(0);
  let HandleLoadMessages = () => {
    setMsgScroll(msgScroll + lengthmsg);
  };
  const [newMessage, setNewMessage] = useState("");
  const [previewImage, setPreviewImage] = useState("");
  let [message, setMessage] = useState([]);
  const [arrivalMessage, setArrivalMessage] = useState([]);
  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isIconDisabled, setIsIconDisabled] = useState(false);
  let [loading, setLoading] = useState(false);

  const [openImg, setOpenImg] = React.useState(false);

  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const imgRef = useRef(null);

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };

  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  const { download } = useDownloader();
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  // const handleOpen2 = () => setOpenmodal2(true);

  function stopPropagation(e) {
    e.stopPropagation(); // Stop the event from propagating to the outer div
  }
  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
    // setShowImagePreview(true);
  };
  let handleImage = e => {
    setOpenImg(true);
    openImagePreview(e);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [message]);

  useEffect(() => {
    socket.on("getHelpMsg", data => {
      setArrivalMessage(data);
    });
  }, [selectedTitle]);

  useEffect(() => {
    socket.emit("notification", {
      userId: user?._id,
      role: user?.role1,
      requestId: selectedTitle.requestId,
      seen: true,
    });
  }, [selectedTitle]);

  useEffect(() => {
    if (arrivalMessage) {
      setMessage(prev => [...prev, arrivalMessage]);
    }
  }, [arrivalMessage]);
  useEffect(() => {
    if (arrivalMessageFile.length > 0) {
      setArrivalMessageFile([]);
    }
    setMessage(prevMessages => prevMessages.concat(arrivalMessageFile));
  }, [arrivalMessageFile]);

  // reply
  const [reply, setReply] = useState(false);
  const [selectedMessages, setSelectedMessages] = useState([]);

  const [activeIndex, setActiveIndex] = useState(null);
  let handleClick = (msg, ind) => {
    setSelectedMessages(msg.message);
    setActiveIndex(ind === activeIndex ? null : ind);
  };
  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
      } catch (error) {
        alert("Error accessing microphone");

        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
      }
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      const audioData = event.data;
      setRecordedAudio(audioData);
      setRecordedChunks(prevChunks => [...prevChunks, event.data]);
    }
  };

  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);

    if (recordedAudio) {
      // storeAudioLocally(recordedAudio);
    }
  };

  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive(prevPlaybackState => !prevPlaybackState);
    setIsIconDisabled(true); // Disable the icon when clicked
  };
  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      console.error("No audio recorded");
      return null;
    }
    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });

    return URL.createObjectURL(audioBlob);
  };

  const audioPlayer = isPlaybackActive ? (
    <audio
      controls
      autoPlay
      key={isPlaybackActive ? "audio-playback" : "audio-empty"}
    >
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;

  let handelSubmitButton = e => {
    handelSubmit();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };
  const renderIconButton = (props, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<IoIosShareAlt />}
        circle
        color="white"
        size="10px"
      />
    );
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    // setIsAudioVisible(false); // Hide the audio tag
    setRecordedChunks([]); // Clear the recorded audio chunks
    setIsPlaying(false);
  };

  let HandleAudio = async e => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = arrayBuffer => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };
    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );
      if (wavFile !== "") {
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };
          const options = {
            headers: {
              Authorization: `Bearer ${userToken}`,
              "Content-Type": "multipart/form-data",
            },
          };
          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );
          let filesData = res?.data?.files;

          socket.emit(
            "helpGroup",
            {
              files: filesData,
              requestId: selectedTitle.requestId,
              senderId: user._id,
              senderName: user.username,
              role: user?.role1,
              section: selectedTitle.section,
              title: selectedTitle.title,
            },
            function callback(msg) {
              setMessage([...message, msg]);
              setIsPlaybackActive(false);
              setRecordedChunks([]);
              setIsPlaying(false);
            }
          );
          setRecordedChunks([]);
          setIsPlaying(false);
        } catch (error) {
          //(error);
        }
      } else {
        //("type msg")
      }
      // return file;
    }
    return null;
  };

  const items = [
    <Dropdown.Item onClick={() => HandleReply()}>Reply</Dropdown.Item>,
  ];
  let HandleReply = () => {
    setReply(true);
  };
  let CancelReply = () => {
    setReply(false);
    setSelectedMessages([]);
    setActiveIndex(null);
  };
  useEffect(() => {
    async function fetchlist() {
      try {
        socket.emit("chatwith", {
          senderId: user?._id,
          role: "student",
          receiverId: selectedTitle?.requestId,
        });
        let payload = {
          title: selectedTitle.title,
          requestId: selectedTitle.requestId,
          scroll: msgScroll,
        };
        let x = await AxiosInstance.post(`/help/getChatTitle`, payload, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        });

        x?.data?.chat?.reverse();
        let data = x?.data?.chat;

        setLengthMsg(data.length);

        setMessageCount(x?.data?.msgcount);
        if (x?.data?.chat?.length !== 0 && msgScroll > 0) {
          setMessage([...data, ...message]);
        } else if (x?.data?.chat?.length !== 0 && msgScroll <= 0) {
          setMessage(data);
        }
        //  setLoadingMsg(false);
      } catch (error) {}
    }
    fetchlist();
  }, [msgScroll]);

  let handelSubmit = async e => {
    e?.preventDefault();
    if (newMessage.trim() !== "") {
      {
        reply
          ? socket?.emit(
              "helpGroup",
              {
                senderId: user?._id,
                senderName: user?.username,
                requestId: selectedTitle.requestId,
                text: newMessage,
                role: user?.role1,
                section: selectedTitle.section,
                title: selectedTitle.title,
                replayback: {
                  message: selectedMessages.text ? selectedMessages.text : "",
                  file: selectedMessages.files ? selectedMessages.files : "",
                },
              },
              function callback(msg) {
                if (msg.read === false) {
                  msg.tick = false;
                  setMessage([...message, msg]);
                } else {
                  setMessage([...message, msg]);
                }
              }
            )
          : socket?.emit(
              "helpGroup",
              {
                senderId: user?._id,
                senderName: user?.username,
                requestId: selectedTitle.requestId,
                text: newMessage,
                role: user?.role1,
                section: selectedTitle.section,
                title: selectedTitle.title,
              },
              function callback(msg) {
                if (msg.read === false) {
                  msg.tick = false;
                  setMessage([...message, msg]);
                } else {
                  setMessage([...message, msg]);
                }
              }
            );
      }
    }

    try {
      setNewMessage("");
      setReply(false);
      setSelectedMessages([]);
      setActiveIndex(null);
    } catch (error) {
      if (error?.response?.status === 403) {
        handleError(navigate, socket);
      }
      //(error);
    }
  };

  function openInNewTab(url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  function stopPropagation(e) {
    e.stopPropagation(); // Stop the event from propagating to the outer div
  }

  function openInNewTab(url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  const [imageUrls, setImageUrls] = useState({});
  const [imageUrlsReply, setImageUrlsReply] = useState({});
  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrlsReply = {};

      await Promise.all(
        message.map(async message => {
          if (
            message?.replayback &&
            Object.keys(message?.replayback).length > 0 &&
            message?.replayback?.file !== "" &&
            message?.replayback?.file != undefined
          ) {
            const fileData = await fetchImageUrl(
              message?.replayback?.file[0]?.path,
              user?.TOKEN
            );

            if (fileData) {
              newFileUrlsReply[message?.replayback?.file[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrlsReply(prevUrls => ({ ...prevUrls, ...newFileUrlsReply }));
    };

    fetchUrls();
  }, [message]);
  const [pastedImage, setPastedImage] = useState(null);
  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard
    if (pastedText) {
      setNewMessage(pastedText);
    }

    // Handle image paste
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        setOpen(true);
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);
        console.log(imageUrl);
        setPastedImage(imageUrl); // Store pasted image
        break;
      }
    }
  };

  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        message.map(async message => {
          if (message?.message?.files !== undefined) {
            const fileData = await fetchImageUrl(
              message?.message?.files[0]?.path,
              user?.TOKEN
            );

            if (fileData) {
              newFileUrls[message?.message?.files[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [message]);

  return (
    <>
      {/* <Modal
        onClose={() => setOpenImg(false)}
   
        open={openImg}
        size="full"
      >
        <Modal.Body>
          <div
            style={{
              overflow: "hidden",
              cursor: zoomLevel > 1 ? "grab" : "auto",
            }}
            onMouseDown={handleMouseDown}
            onMouseMove={handleMouseMove}
            onMouseUp={handleMouseUp}
            onMouseLeave={handleMouseLeave}
          >
            <img
              src={previewImage}
              style={{
                transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                transition: "transform 0.3s ease",
                maxWidth: "700px",
                maxHeight: "80%",
                width: "80%",
                margin: "auto",
                display: "block",
            
              }}
              alt=""
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleZoomOut}>
            <FaSearchMinus />
          </Button>
          <Button variant="secondary" onClick={handleZoomIn}>
            <FaSearchPlus />
          </Button>
          <Button
            variant="primary"
            onClick={() => {
              setOpenImg(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal> */}
      <MuiModal
        open={openImg}
        onClick={() => setOpenImg(false)}
        // aria-labelledby="image-modal"
        // aria-describedby="image-modal-description"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "100%",
            maxHeight: "100%",
            bgcolor: "background.paper",
            boxShadow: 24,
            outline: "none",
            overflow: "hidden",
            cursor: zoomLevel > 1 ? "grab" : "auto",
          }}
          onMouseDown={handleMouseDown}
          onMouseMove={handleMouseMove}
          onMouseUp={handleMouseUp}
          onMouseLeave={handleMouseLeave}
        >
          <MuiIconButton
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: "2222",
            }}
            onClick={() => {
              setOpenImg(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={22}
              height={22}
              viewBox="0 0 24 24"
            >
              <path
                fill="crimson"
                d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
              ></path>
            </svg>
          </MuiIconButton>
          <img
            ref={imgRef}
            src={previewImage}
            alt="Modal Content"
            style={{
              maxWidth: "90%",
              maxHeight: "90%",
              objectFit: "contain",
              display: "block",
              margin: "0 auto",
              transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
              transition: "transform 0.3s ease",
            }}
          />
          <Box
            sx={{
              position: "absolute",
              display: "flex",
              flexDirection: "column",
              top: "35px",
              right: "-3px",
            }}
          >
            <MuiIconButton variant="secondary" onClick={handleZoomOut}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 24 24"
              >
                <path
                  fill="#555"
                  d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M5 8v2h8V8z"
                ></path>
              </svg>
            </MuiIconButton>
            <MuiIconButton variant="secondary" onClick={handleZoomIn}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={20}
                height={20}
                viewBox="0 0 24 24"
              >
                <path
                  fill="#555"
                  d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M8 5v3H5v2h3v3h2v-3h3V8h-3V5z"
                ></path>
              </svg>
            </MuiIconButton>
          </Box>
        </Box>
      </MuiModal>
      <aside className={Styles.ChatBlock} style={{ background: "#D7DBDD" }}>
        {messageCount != msgScroll + lengthmsg && (
          <div
            onClick={HandleLoadMessages}
            // className={Styles.Spinner}
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width={25}
              height={25}
              viewBox="0 0 20 20"
            >
              <g fill="#555">
                <path d="M13.937 4.211a1 1 0 0 1-1.126 1.653A5 5 0 1 0 15 10a1 1 0 1 1 2 0a7 7 0 1 1-3.063-5.789"></path>
                <path d="M13.539 12.506a1 1 0 1 1-1.078-1.685l3.482-2.227a1 1 0 0 1 1.077 1.685z"></path>
                <path d="M18.903 12.41a1 1 0 0 1-1.826.815l-1.508-3.38a1 1 0 1 1 1.826-.815z"></path>
              </g>
            </svg>
          </div>
        )}

        {message?.length > 0 &&
          message.map((messages, ind) => {
            const isActive = ind === activeIndex; // Check if this message is active
            const backgroundColor = isActive ? "lightblue" : ""; // Change to desired colors

            return (
              <div className={Styles.chat} key={ind}>
                <div
                  className={
                    messages?.senderName === user?.username
                      ? `${Styles.sendermsg}`
                      : messages?.senderName !== user?.username
                      ? `${Styles.recivermsg}`
                      : `${Styles.displaynone}`
                  }
                >
                  <main
                    style={{ backgroundColor }}
                    onClick={() => handleClick(messages, ind)}
                  >
                    <div>
                      {messages?.replayback &&
                      Object.keys(messages?.replayback).length > 0 ? (
                        <div className={Styles.StatusText}>
                          Reply-{" "}
                          {messages?.replayback?.message &&
                          messages?.replayback?.message !== "" ? (
                            messages?.replayback?.message?.length > 85 ? (
                              messages?.replayback?.message.slice(0, 85)
                            ) : (
                              messages?.replayback?.message
                            )
                          ) : messages?.replayback?.file?.length > 0 ? (
                            <>
                              {(() => {
                                switch (
                                  messages?.replayback?.file[0]?.mimetype
                                ) {
                                  case "image/jpg":
                                  case "image/jpeg":
                                  case "image/avif":
                                  case "image/png":
                                  case "image/webp":
                                  case "image/tiff":
                                  case "image/bmp":
                                    return (
                                      <>
                                        <img
                                          src={
                                            imageUrlsReply[
                                              messages?.replayback?.file[0]
                                                ?.path
                                            ]?.url
                                          }
                                          alt=""
                                          style={{
                                            width: "100%",
                                            height: "100px",
                                            borderRadius: "5px",
                                            display: "flex",
                                          }}
                                          onClick={e => {
                                            stopPropagation(e);
                                            handleImage(
                                              imageUrlsReply[
                                                messages?.replayback?.file[0]
                                                  ?.path
                                              ]?.url
                                            );
                                          }}
                                        />{" "}
                                      </>
                                    );
                                  default:
                                    return (
                                      <button className={Styles.btndownload1}>
                                        {" "}
                                        {
                                          messages?.replayback?.file[0]
                                            ?.originalname
                                        }
                                      </button>
                                    );
                                }
                              })()}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div>
                      <Linkify componentDecorator={openInNewTab}>
                        <p className={Styles.textmsg}>
                          {messages?.message?.text ? (
                            messages?.message?.text && messages?.html ? (
                              // <pre
                              //   dangerouslySetInnerHTML={{
                              //     __html: messages?.message?.text,
                              //   }}
                              //   className={Styles.textEditStyle}
                              // />
                              <Linkify
                                componentDecorator={(
                                  decoratedHref,
                                  decoratedText,
                                  key
                                ) => (
                                  <a
                                    href={decoratedHref}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    key={key}
                                  >
                                    {decoratedText}
                                  </a>
                                )}
                              >
                                <pre
                                  dangerouslySetInnerHTML={{
                                    __html: linkifyHtmlContent(
                                      messages?.message?.text || ""
                                    ),
                                  }}
                                  className={Styles.textEditStyle}
                                />
                              </Linkify>
                            ) : (
                              messages?.message?.text
                            )
                          ) : messages?.message?.files?.length > 0 ? (
                            <>
                              {(() => {
                                switch (messages?.message?.files[0]?.mimetype) {
                                  case "image/jpg":
                                  case "image/jpeg":
                                  case "image/avif":
                                  case "image/png":
                                  case "image/webp":
                                  case "image/tiff":
                                  case "image/bmp":
                                    return (
                                      <>
                                        <img
                                          src={
                                            imageUrls[
                                              messages?.message?.files[0]?.path
                                            ]?.url
                                          }
                                          alt=""
                                          style={{
                                            width: "200px",
                                            height: "130px",
                                            borderRadius: "5px",
                                            display: "flex",
                                          }}
                                          onClick={e => {
                                            // stopPropagation(e);
                                            handleImage(
                                              imageUrls[
                                                messages?.message?.files[0]
                                                  ?.path
                                              ]?.url
                                            );
                                          }}
                                        />{" "}
                                        <button
                                          className={Styles.btndownload}
                                          onClick={e =>
                                            download(
                                              imageUrls[
                                                messages?.message?.files[0]
                                                  ?.path
                                              ]?.url,
                                              messages?.message?.files[0]
                                                ?.originalname,
                                              messages?.message?.files[0]
                                                .filename
                                            )
                                          }
                                        >
                                          download
                                        </button>
                                      </>
                                    );
                                  case "audio/mpeg":
                                  case "audio/mp3":
                                  case "audio/webm":
                                  case "application/wav":
                                  case "video/webm":
                                  case "audio/wav":
                                    return (
                                      <>
                                        <audio
                                          src={
                                            imageUrls[
                                              messages?.message?.files[0]?.path
                                            ]?.url
                                          }
                                          controls
                                          style={{
                                            width: "250px",
                                            height: "40px",
                                          }}
                                        ></audio>

                                        <>
                                          <span>
                                            {messages.forwarded ? (
                                              <span
                                                className={Styles.broadcast}
                                              >
                                                <GiWideArrowDunk />
                                                {/* {message.broadcastchat} */}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </>
                                      </>
                                    );
                                  case "video/mp4":
                                  case "video/x-matroska":
                                  case "application/docx":
                                  case "application/doc":
                                  case "application/msword":
                                  case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                                  case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                                  case "application/vnd.ms-word.document.macroEnabled.12":
                                  case "application/vnd.ms-excel":
                                  case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                                  case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                                  case "application/vnd.ms-excel.sheet.macroEnabled.12":
                                  case "application/vnd.ms-powerpoint":
                                  case "application/xlsx":
                                  case "application/pptx":
                                  case "application/octet-stream":
                                  case "text/plain":
                                  case "text/html":
                                  case "application/apk":
                                  case "application/pdf":
                                  case "application/x-pdf":
                                  case "application/x-gzpdf":
                                  case "applications/vnd.pdf":
                                  case "application/acrobat":
                                  case "application/x-google-chrome-pdf":
                                  case "text/pdf":
                                  case "text/x-pdf":
                                  case "text/csv":
                                  case "application/zip":
                                  case "application/x-zip":
                                  case "application/octet-stream":
                                  case "application/x-zip-compressed":
                                  case "application/json":
                                    return (
                                      <>
                                        <button
                                          className={Styles.btndownload1}
                                          onClick={() =>
                                            download(
                                              imageUrls[
                                                messages?.message?.files[0]
                                                  ?.path
                                              ]?.url,
                                              messages?.message?.files[0]
                                                ?.originalname,
                                              messages?.message?.files[0]
                                                ?.filename
                                            )
                                          }
                                        >
                                          {
                                            messages?.message?.files[0]
                                              ?.originalname
                                          }
                                          <span
                                            className={Styles.Download}
                                          ></span>
                                          <span className={Styles.linkBlock}>
                                            Click to download
                                          </span>
                                        </button>

                                        <>
                                          <span>
                                            {messages?.forwarded ? (
                                              <span
                                                className={Styles.broadcast}
                                              >
                                                <GiWideArrowDunk />
                                                {/* {message.broadcastchat} */}
                                              </span>
                                            ) : (
                                              ""
                                            )}
                                          </span>
                                        </>
                                      </>
                                    );
                                  default:
                                    return (
                                      <button
                                        className={Styles.btndownload1}
                                        onClick={() =>
                                          download(
                                            imageUrls[
                                              messages?.message?.files[0]?.path
                                            ]?.url,
                                            messages?.message?.files[0]
                                              ?.originalname,
                                            messages?.message?.files[0]
                                              ?.filename
                                          )
                                        }
                                      >
                                        {
                                          messages?.message?.files[0]
                                            ?.originalname
                                        }
                                        <span
                                          className={Styles.Download}
                                        ></span>
                                        <span className={Styles.linkBlock}>
                                          Click to download
                                        </span>
                                      </button>
                                    );
                                }
                              })()}
                            </>
                          ) : (
                            ""
                          )}
                        </p>
                      </Linkify>
                    </div>
                    {user?.username !== messages?.senderName && (
                      <div
                        className={Styles.HelpName}
                        style={{
                          fontSize: "x-small",
                          textTransform: "capitalize",
                        }}
                      >
                        {messages?.senderName}
                      </div>
                    )}{" "}
                    <div className={Styles.messageBottom}>
                      {Date.now ? (
                        <Moment format="MMM DD - h:mm a">
                          {messages?.createdAt}
                        </Moment>
                      ) : (
                        <Moment format="MMM DD YYYY h A">
                          {messages?.createdAt}
                        </Moment>
                      )}

                      {backgroundColor === "lightblue" ? (
                        <span
                          className={Styles.ForwardMsgBlock}
                          onClick={e => stopPropagation(e)}
                        >
                          {" "}
                          <Dropdown
                            renderToggle={renderIconButton}
                            placement="leftEnd"
                            style={{ zIndex: 2222222 }}
                          >
                            {items}
                          </Dropdown>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </main>
                </div>
              </div>
            );
          })}
        <div ref={scrollRef} style={{ visibility: "hidden" }}>
          top
        </div>
      </aside>
      <form onSubmit={handelSubmit} className={Styles.FormMessage}>
        {reply && (
          <div className={Styles.replyBlock}>
            <div className={Styles.StatusText1}>
              {" "}
              {selectedMessages && (
                <>
                  <div className={Styles.copiedText}>
                    {selectedMessages?.text
                      ? selectedMessages?.text?.slice(0, 180)
                      : selectedMessages?.files[0]?.filename}
                  </div>
                  <div
                    className={Styles.CrossMark}
                    onClick={() => CancelReply()}
                  >
                    X
                  </div>
                </>
              )}
            </div>
          </div>
        )}
        <div className={Styles.messageinput}>
          <input
            type="text"
            placeholder="Type your message"
            value={newMessage}
            onChange={e => {
              setNewMessage(e.target.value);
            }}
            onKeyPress={handleKeyPress}
            autoFocus
          />
          <aside
            style={{ position: "relative" }}
            className={Styles.UploadfileContainer}
          >
            <>
              {isRecording ? (
                ""
              ) : stop ? (
                ""
              ) : (
                <>
                  <ButtonToolbar>
                    <ImAttachment onClick={handleOpen}></ImAttachment>
                  </ButtonToolbar>
                </>
              )}

              <Modal
                // backdrop={backdrop}
                keyboard={false}
                open={open}
                onClose={handleClose}
              >
                <Modal.Header>
                  <Modal.Title>Send File</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <FileUpload
                    setMessages={setMessage}
                    messages={message}
                    setOpen={setOpen}
                    setArrivalMessage={setArrivalMessage}
                    setArrivalMessageFile={setArrivalMessageFile}
                    selectedTitle={selectedTitle}
                    selectedMessages={selectedMessages}
                    reply={reply}
                    setReply={setReply}
                    setSelectedMessages={setSelectedMessages}
                    setActiveIndex={setActiveIndex}
                    pastedImage={pastedImage}
                    setPastedImage={setPastedImage}
                  />
                </Modal.Body>
                <Modal.Footer></Modal.Footer>
              </Modal>
            </>
          </aside>
          <aside className={Styles.AudioBlock}>
            <div>
              {!stop && !isRecording && !isPlaying && (
                <Whisper followCursor speaker={<Tooltip>{"Start"}</Tooltip>}>
                  <main
                    className={Styles.StartAudio}
                    onClick={handleStartRecording}
                    disabled={isRecording}
                  >
                    <span>
                      {/* start */}
                      <BsFillMicFill />
                    </span>
                  </main>
                </Whisper>
              )}
              <div
                className={`${Styles.recordingAnimation} ${
                  isRecording ? Styles.active : ""
                }`}
              ></div>
              {isRecording && (
                <main
                  className={Styles.StopAudio}
                  onClick={handleStopRecording}
                  disabled={!isRecording}
                >
                  <span>
                    {/* stop */}
                    <BsFillMicMuteFill />
                  </span>
                </main>
              )}
              {stop && (
                <Whisper followCursor speaker={<Tooltip>{"Play"}</Tooltip>}>
                  <main
                    className={Styles.PlayAudio}
                    onClick={handlePlayButtonClick}
                    disabled={isRecording}
                  >
                    <span>
                      {/* play */}
                      <BsFillPlayCircleFill />
                    </span>
                  </main>
                </Whisper>
              )}
              {/* {stop &&<p>{recordingStatus}</p>} */}
              {audioPlayer}
            </div>
          </aside>
          {stop && (
            <div className={Styles.SendAudioBlock}>
              <Whisper followCursor speaker={<Tooltip>{"Delete"}</Tooltip>}>
                <span
                  onClick={handleDeleteButtonClick}
                  className={Styles.DeleteAudio}
                >
                  <AiFillDelete />
                </span>
              </Whisper>
              <span onClick={HandleAudio} className={Styles.SendAudio}>
                send
                {/* <AiOutlineSend /> */}
              </span>
            </div>
          )}
          {!stop && (
            <div>
              {loading || isRecording ? (
                <button
                  className="chatSubmitButton"
                  disabled
                  onClick={handelSubmitButton}
                >
                  <div className={Styles.loader}>
                    <RotatingLines
                      strokeColor="#f16f1d"
                      strokeWidth="5"
                      animationDuration="0.75"
                      width="20"
                      visible={true}
                    />
                  </div>
                </button>
              ) : (
                <>
                  <button
                    type="button"
                    className={Styles.sendbutton}
                    onClick={handelSubmitButton}
                  >
                    Send
                  </button>
                  <button
                    type="submit"
                    style={{ display: "none" }}
                    className={Styles.sendbutton}
                    onClick={handelSubmitButton}
                  >
                    Send
                  </button>
                </>
              )}
            </div>
          )}
        </div>
      </form>
    </>
  );
};

export default StudentHelpChatOld;
