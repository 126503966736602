import React, { useContext, useEffect, useState } from "react";
import { BddtAxios } from "../../api/AxiosInstance";
import jwt_decode from "jwt-decode";
import Styles from "./_user.module.css";
import { RotatingLines } from "react-loader-spinner";
import { Fragment } from "react";
import { Button, Modal } from "rsuite";
import Moment from "react-moment";
import { SocketContext } from "../../ioContext/socketIo";
import mockImg from "../../assets/mock.webp";
import handleError from "../Users/ErrorHandler";
import { useNavigate } from "react-router-dom";

const Mock = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");
  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);
  const [openAttended, setOpenAttended] = useState(false);

  let [mock, setMock] = useState([]);
  let [selectedData, setSelectedData] = useState([]);
  let dddd = userToken;
  var decoded = jwt_decode(dddd);
  const socket = useContext(SocketContext);

  function closeAttended() {
    setOpenAttended(false);
  }

  //---------modal open and close end-----------//

  // ========== Browser Notification ======= //
  useEffect(() => {
    const handleNotification = data => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, [socket]);
  // ========== Browser Notification  end ======= //
  useEffect(() => {
    setLoading(true);
    async function fetchlist() {
      //(decoded.aid);
      if (decoded.aid != null) {
        BddtAxios.get(`/hr/student-personaldetails/?token=${userToken}`)
          .then(x => {
            setLoading(false);

            let data = x?.data?.results[0]?.mock;

            setMock(data);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
            setLoading(false);

            //(y)
          });
      } else if (user?.number) {
        BddtAxios.get(`/hr/student-personaldetails/?token=${userToken}`)
          .then(x => {
            setLoading(false);

            let data = x?.data?.results[0]?.mock;

            setMock(data);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
            setLoading(false);
          });
      } else if (user.email) {
        BddtAxios.get(`/hr/student-personaldetails/?token=${userToken}`)
          .then(x => {
            setLoading(false);
            let data = x?.data?.results[0]?.mock;
            setMock(data);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
            setLoading(false);
          });
      }
    }
    fetchlist();
  }, []);

  return (
    <section id={Styles.RequirementBlock}>
      {loading === true ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        <>
          {mock?.length <= 0 || mock === undefined ? (
            <div className={Styles.MessageError}>
              <img src={mockImg} alt="" />
              <h3> You have not taken any mock yet</h3>
            </div>
          ) : (
            <Fragment>
              {mock
                ?.slice()
                ?.sort((a, b) => new Date(b.created) - new Date(a.created))
                ?.map((ele, ind) => {
                  return (
                    <section key={ind + 1}>
                      <div
                        className={Styles.card1}
                        key={ind + 1}
                        style={{
                   
                          border: "0.3px ridge #1A5276",
                        }}
                      >
                        <div className={Styles.CardHeading}>
                          {" "}
                          <h4 className={Styles.cardTitle}>
                            Mock Details
                          </h4>{" "}
                        </div>
                        <div className={Styles.cardContent}>
                          <h4 className={Styles.cardTitle}>Batchname</h4>{" "}
                          <span className={Styles.Colan}>:</span>
                          <p className={Styles.cardDescription}>
                            {ele?.batch_code?.name
                              ? ele?.batch_code?.name
                              : ele?.batch_text}
                          </p>
                        </div>
                        <div className={Styles.cardContent}>
                          <h4 className={Styles.cardTitle}>Mock ratings</h4>{" "}
                          <span className={Styles.Colan}>:</span>
                          <p className={Styles.cardDescription}>
                            <span
                              className={
                                ele?.communication_name === "1*"
                                  ? `${Styles.ratinggreen}`
                                  : ele.communication_name === "1"
                                  ? `${Styles.ratingyellow}`
                                  : `${Styles.ratingred}`
                              }
                            >
                              communication - {ele.communication_name}
                            </span>

                            <span
                              className={
                                ele?.technical_name === "1*"
                                  ? `${Styles.ratinggreen}`
                                  : ele.technical_name === "1"
                                  ? `${Styles.ratingyellow}`
                                  : `${Styles.ratingred}`
                              }
                            >
                              technical -{ele.technical_name}
                            </span>
                          </p>
                        </div>
                        <div className={Styles.cardContent}>
                          <h4 className={Styles.cardTitle}>Final mock</h4>{" "}
                          <span className={Styles.Colan}>:</span>
                          <p className={Styles.cardDescription}>
                            {ele.final_mock === true ? (
                              <span style={{ fontWeight: "bold" }}>Yes</span>
                            ) : (
                              <span style={{ fontWeight: "bold" }}>No</span>
                            )}{" "}
                          </p>
                        </div>
                        <div className={Styles.cardContent}>
                          <h4 className={Styles.cardTitle}>Mock taken by</h4>{" "}
                          <span className={Styles.Colan}>:</span>
                          <p className={Styles.cardDescription}>
                            {ele?.final_mock === true ? (
                              <span>{ele?.trainer}</span>
                            ) : ele.taken_mentor === true ? (
                              <span>{ele?.mentor_name}</span>
                            ) : (
                              <span>{ele?.trainer}</span>
                            )}
                          </p>
                        </div>
                        <div className={Styles.cardContent}>
                          <h4 className={Styles.cardTitle}>Date</h4>{" "}
                          <span className={Styles.Colan}>:</span>
                          <p className={Styles.cardDescription}>
                            {
                              <Moment format="MMM DD YYYY">
                                {ele?.updated}
                              </Moment>
                            }
                          </p>
                        </div>
                        {ele.comment && (
                          <div className={Styles.cardContent}>
                            <h4 className={Styles.cardTitle}>Details</h4>{" "}
                            <span className={Styles.Colan}>:</span>
                            <p className={Styles.cardDescription}>
                              <Button
                                appearance="primary"
                                size="xs"
                                onClick={e => {
                                  setOpenAttended(true);
                                  setSelectedData(ele);
                                }}
                              >
                                Comment
                              </Button>
                            </p>
                          </div>
                        )}
                      </div>
                      <Modal
                        size="sm"
                        keyboard={false}
                        open={openAttended}
                        onClose={closeAttended}
                      >
                        <Modal.Header>
                          <Modal.Title className={Styles.HeadingAdd}>
                            Comment
                          </Modal.Title>
                        </Modal.Header>

                        <Modal.Body
                          style={{
                            overflow: "unset",
                            maxheight: "none",
                          }}
                        >
                          <h6>{selectedData.comment}</h6>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button appearance="ghost" onClick={closeAttended}>
                            Cancel
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </section>
                  );
                })}
            </Fragment>
          )}
        </>
      )}
    </section>
  );
};

export default Mock;

// export default Mock
