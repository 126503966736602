import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import {
  createBatch,
  reset,
} from "../../redux/CreateBatchandGroup/CreateBatchGroup";
import { IoMdAddCircle } from "react-icons/io";
import Styles from "./_admin-login.module.css";
import Select from "react-select";
import Creatable from "react-select/creatable";
import Modal from "react-modal";
import AddRolesCreateTrainer from "./AddRolesCreateTrainer";
import {
  CreateBranch,
  CreateCourse,
  CreateSubject,
} from "../../redux/Batch/BatchSlice";

import { AiOutlineClose } from "react-icons/ai";
import ValidationCreateBatch from "./ValidationCreateBatch";
const CreateBatch = () => {
  const [modalisOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openCourse, setopenCourse] = useState(false);
  const [openSubject, setopenSubject] = useState(false);

  let [trainers, setTrainers] = useState([]);
  // let [Jtrainers, setJtrainers] = useState([]);

  let [courses, setCourses] = useState([]);
  let [subjects, setSubjects] = useState([]);
  let [branches, setBranches] = useState([]);

  let [inputCourse, setinputCourse] = useState("");
  let [inputSubject, setinputSubject] = useState("");
  let [inputBranch, setinputBranch] = useState("");
  let [batchCode, setBatchCode] = useState("");
  let [subject, setSubject] = useState("");
  let [course, setCourse] = useState("");
  let [branch, setBranch] = useState("");
  let [startDate, setStartDate] = useState("");

  let [trainer, setTrainer] = useState("");
  // let [juniorTrainer, setJuniorTrainer] = useState("");
  // let [batchCode, setBatchCode] = useState("");
  // let [batchcode, setBatchcode] = useState([]);

  // const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCourse, setIsLoadingCourse] = useState(false);
  const [isLoadingTrainer, setIsLoadingrainer] = useState(false);
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);

  let [addStudents, setAddStudents] = useState(Array);

  let [errors, setErrors] = useState({});
  Modal.setAppElement("#root");
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
  }

  function CloseMod() {
    setOpen(false);
  }
  function OpenCourse() {
    setopenCourse(true);
  }
  function closeCourse() {
    setopenCourse(false);
  }
  function OpenSubject() {
    setopenSubject(true);
  }
  function closeSubject() {
    setopenSubject(false);
  }

  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  let dispatch = useDispatch();
  let { isError, isSuccess, message } = useSelector(
    state => state.createBatchgroup
  );
  let navigate = useNavigate();
  let { userAdmin } = useSelector(state => state.auth);
  let SubjectSubmit = e => {
    let payload = { subject: inputSubject };
    if (inputSubject === "") {
      toast.error("Add subject", { position: "top-right" });
    } else {
      dispatch(CreateSubject(payload));

      toast.success("Subject Added", { position: "top-right" });
      setinputSubject("");
    }
  };
  let CourseSubmit = e => {
    let payload = { course: inputCourse };
    if (inputCourse === "") {
      toast.error("Add Course", { position: "top-right" });
      // //("enter data")
    } else {
      dispatch(CreateCourse(payload));
      toast.success("Course Added", { position: "top-right" });
      setinputCourse("");
    }
  };
  let BranchSubmit = () => {
    let payload = { branch: inputBranch };

    if (inputBranch === "") {
      toast.error("Add Branch", { position: "top-right" });
      // //("enter data")
    } else {
      dispatch(CreateBranch(payload));
      toast.success("Branch Added", { position: "top-right" });
      setinputBranch("");
    }
  };
  let LoadSubject = () => {
    async function fetchData() {
      // get Subjects
      await AxiosInstance.get(`auth/getdata?data=subject`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Subjectdataaa = x?.data[0].subject;

          let ddd = Subjectdataaa.map(function (ele) {
            return { value: ele, label: ele };
          });
          setSubjects(ddd);
        })
        .catch(y => {
          //(y);
        })
        .finally(z => {
          setIsLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpen = () => {
    if (subjects.length === 0 && !isLoading) {
      setIsLoading(true);
      LoadSubject();
    }
  };

  let LoadCourse = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=course`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Coursedataaa = x?.data[0].course;
          let eee = Coursedataaa.map(function (ele) {
            return { value: ele, label: ele };
          });
          setCourses(eee);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingCourse(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenCourse = () => {
    if (courses.length === 0 && !isLoadingCourse) {
      setIsLoadingCourse(true);
      LoadCourse();
    }
  };

  let LoadBranch = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data[0]?.branch;
          let ccc = Branchdataaa?.map(function (ele) {
            return { value: ele, label: ele };
          });
          setBranches(ccc);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingBranch(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenBranch = () => {
    if (branches.length === 0 && !isLoadingBranch) {
      setIsLoadingBranch(true);
      LoadBranch();
    }
  };

  let LoadTrainer = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getusersrole?role=trainer`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let trainersss = x?.data.usersDetails;

          let eee = trainersss.map(function (ele) {
            return { value: ele.username, label: ele.username };
          });
          setTrainers(eee);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingrainer(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenTrainer = () => {
    if (trainers.length === 0 && !isLoadingTrainer) {
      setIsLoadingrainer(true);
      LoadTrainer();
    }
  };
  useEffect(() => {
    if (isError) {
      toast.error(message?.message, {
        position: "top-right",
      });
      dispatch(reset());
    }
    if (isSuccess) {
      toast.success("Batch added successfully", { position: "top-right" });
      dispatch(reset());
      navigate("/admin-dashboard");
    }
    // //(isSuccess);
  }, [isSuccess, isError]);

  let handleSubmit = async e => {
    e.preventDefault();

    if (
      batchCode === "" ||
      subject === "" ||
      course === "" ||
      branch === "" ||
      startDate === "" ||
      trainer === "" ||
      addStudents?.length === 0
    ) {
      setErrors(
        ValidationCreateBatch(
          batchCode,
          subject,
          course,
          branch,
          startDate,
          trainer,
          // juniorTrainer,
          addStudents
        )
      );
    } else {
      try {
        // //(juniorTrainer);
        let form = new FormData();
        form.append("file", addStudents);
        form.append("batchCode", batchCode);
        form.append("subject", subject);
        form.append("course", course);
        form.append("branch", branch);
        form.append("startDate", startDate);
        form.append("trainer", trainer);

        dispatch(createBatch(form));
        dispatch(reset());
      } catch (error) {}
    }
  };

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  };

  let onChangeselect = e => {
    if (e?.value) {
      setSubject(e.value);
    } else {
      setSubject("");
    }
  };
  let ChangeCourse = e => {
    if (e?.value) {
      setCourse(e.value);
    } else {
      setCourse("");
    }
  };
  const handleChange = e => {
    if (e?.value) {
      setTrainer(e.value);
    } else {
      setTrainer("");
    }
  };

  const handleChangeBranch = e => {
    if (e?.value) {
      setBranch(e.value);
    } else {
      setBranch("");
    }
  };

  return (
    <section id={Styles.createBatch}>
      <article>
        <div className={Styles.container}>
          <h1>Create Batch</h1>
          <form onSubmit={handleSubmit} className={Styles.createBatchForm}>
            <div>
              <div className={Styles.formgroup}>
                <label htmlFor="batchCode">BatchCode</label>
                <input
                  className="form-control"
                  type="text"
                  name="batchCode"
                  // required
                  value={batchCode}
                  placeholder="Enter Batchcode"
                  onChange={e => setBatchCode(e.target.value)}
                />
                <p
                  className={
                    errors.subject ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>
                {errors.batchCode && (
                  <p className={Styles.errors}>{errors.batchCode}</p>
                )}
              </div>
              <div className={Styles.trainer1}>
                <label htmlFor="subject">subject</label>
                <span className={Styles.AddIcon}>
                  {/* <IoMdAddCircle onClick={OpenSubject}></IoMdAddCircle> */}
                </span>
                <Modal
                  isOpen={openSubject}
                  // onAfterOpen={afterOpenModal}
                  onRequestClose={closeSubject}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className={Styles.inputforBranch}>
                    <aside>
                      <button onClick={() => closeSubject()}>
                        <AiOutlineClose />
                      </button>
                    </aside>
                    <h3>Add Subject</h3>
                    <div>
                      <form onSubmit={SubjectSubmit}>
                        <input
                          value={inputSubject}
                          type="text"
                          placeholder="Subject"
                          className={Styles.inputforBranch}
                          onChange={e => setinputSubject(e.target.value)}
                        />
                        <button>Add</button>
                      </form>
                    </div>
                  </div>
                </Modal>
                <Select
                  onMenuOpen={handleMenuOpen}
                  isLoading={isLoading}
                  isClearable
                  isSearchable
                  escapeClearsValue={true}
                  labelKey="name"
                  className={Styles.option}
                  options={subjects}
                  onChange={onChangeselect}
                />
                <p
                  className={
                    errors.subject ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>

                {errors.subject && (
                  <p className={Styles.errors}>{errors.subject}</p>
                )}
              </div>
              <div className={Styles.trainer1}>
                <label htmlFor="course">course</label>
                <span className={Styles.AddIcon}>
                  <IoMdAddCircle onClick={OpenCourse}></IoMdAddCircle>
                </span>
                <Modal
                  isOpen={openCourse}
                  onRequestClose={closeCourse}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className={Styles.inputforBranch}>
                    <aside>
                      <button onClick={() => closeCourse()}>
                        <AiOutlineClose />
                      </button>
                    </aside>
                    <h3>Add Course</h3>
                    <div>
                      <form onSubmit={CourseSubmit}>
                        <input
                          value={inputCourse}
                          type="text"
                          placeholder="Course"
                          className={Styles.inputforBranch}
                          onChange={e => setinputCourse(e.target.value)}
                        />
                        <button>Add</button>
                      </form>
                    </div>
                  </div>
                </Modal>
                <Creatable
                  onMenuOpen={handleMenuOpenCourse}
                  isLoading={isLoadingCourse}
                  isClearable
                  className={Styles.option}
                  options={courses}
                  onChange={ChangeCourse}
                />
                <p
                  className={
                    errors.course ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>
                {errors.course && (
                  <p className={Styles.errors}>{errors.course}</p>
                )}
              </div>
              <div className={Styles.trainer1}>
                <label htmlFor="branch">branch</label>
                <Modal
                  isOpen={open}
                  onRequestClose={CloseMod}
                  style={customStyles}
                  contentLabel="Example Modal"
                >
                  <div className={Styles.inputforBranch}>
                    <aside>
                      <button onClick={() => CloseMod()}>
                        <AiOutlineClose />
                      </button>
                    </aside>
                    <h3>Add Branch</h3>
                    <div>
                      <form onSubmit={BranchSubmit}>
                        <input
                          value={inputBranch}
                          type="text"
                          placeholder="Branch"
                          className={Styles.inputforBranch}
                          onChange={e => setinputBranch(e.target.value)}
                          // setinputBranch
                        />
                        <button>Add</button>
                      </form>
                    </div>
                  </div>
                </Modal>
                <Creatable
                  onMenuOpen={handleMenuOpenBranch}
                  isLoading={isLoadingBranch}
                  // handleMenuOpenBranch
                  isClearable
                  className={Styles.option}
                  options={branches}
                  onChange={handleChangeBranch}
                />
                <p
                  className={
                    errors.branch ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>

                {errors.branch && (
                  <p className={Styles.errors}>{errors.branch}</p>
                )}
              </div>
              <div className={Styles.formgroup}>
                <label htmlFor="startDate">StartDate</label>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  min={disablePastDate()}
                  // required
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                />
                <p
                  className={
                    errors.startDate ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>
                {errors.startDate && (
                  <p className={Styles.errors}>{errors.startDate}</p>
                )}
              </div>

              {/* -----------------------------------trainer ----------------------------------------*/}
              <div className={Styles.trainer}>
                <label htmlFor="trainer">
                  Trainer{" "}
                  <span className={Styles.AddIcon}>
                    <IoMdAddCircle onClick={openModal}></IoMdAddCircle>
                  </span>
                  <Modal
                    isOpen={modalisOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <AddRolesCreateTrainer closeModal={closeModal} />
                  </Modal>
                </label>

                <Creatable
                  onMenuOpen={handleMenuOpenTrainer}
                  isLoading={isLoadingTrainer}
                  isClearable
                  options={trainers}
                  onChange={handleChange}
                  labelKey="name"
                />
                <p
                  className={
                    errors.trainer ? `${Styles.errrr}` : `${Styles.gapbtw}`
                  }
                ></p>
                {errors.trainer && (
                  <p className={Styles.errors}>{errors.trainer}</p>
                )}
              </div>

              {/* --------------------------juniour trainer -------------------------*/}

              {/* <div className={Styles.trainer}>
                <label htmlFor="juniorTrainer">
                  Junior Trainer{" "}
                  <Modal
                    isOpen={modalisOpen}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <AddRolesCreateTrainer closeModal={closeModal} />
                  </Modal>
                </label>
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  name="students"
                  options={Jtrainers}
                  onChange={(e) => {
                    setJuniorTrainer(
                      Array.isArray(e)
                        ? e.map((x) => ({
                            _id: x.value._id,
                            username: x.value.username,
                          }))
                        : []
                    );
                  }}
                />
              </div> */}

              {/* --------------------------Upload Your File -------------------------*/}
              <div className={Styles.formgroup}>
                <label>Upload Your File </label>
                <input
                  type="file"
                  id="addStudents"
                  name="addStudents"
                  required
                  onChange={e => setAddStudents(e.target.files[0])}
                />
                {errors.addStudents && (
                  <p className={Styles.errors}>{errors.addStudents}</p>
                )}

                {/* </button> */}
              </div>
            </div>
            <aside className={Styles.createButton}>
              <button>Add Batch</button>
            </aside>
          </form>
        </div>
      </article>
    </section>
  );
};

export default CreateBatch;
