const UserNameData = ({ name, batchName, groupName }) => {
  return (
    <main>
      <div>
        <h2>{batchName ? batchName : groupName}</h2>
        <span>This Is Broadcast Chat</span>
      </div>
    </main>
  );
};

export default UserNameData;
