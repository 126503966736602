import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { VscOutput } from "react-icons/vsc";
import { FcGraduationCap } from "react-icons/fc";
import { BsChatDotsFill } from "react-icons/bs";
import Styles from "./_user.module.css";
import { SocketContext } from "../../ioContext/socketIo";
import { toast } from "react-toastify";

const UserGroupListDetails = ({ purpose, course, groupCode }) => {
  let navigate = useNavigate();

  const socket = useContext(SocketContext);
  let [permenentBlock, setPermanentBlock] = useState(false);

  useEffect(() => {
    socket.on("blocked", data => {
      setPermanentBlock(true);
    });
  }, [socket]);
  if (permenentBlock === true) {
    toast.error("Contact Administrator", { position: "top-right" });
    function greeting() {
      socket.disconnect();
      navigate("/");
    }
    setTimeout(greeting, 3000);
  }
  return (
    <div
      className={Styles.container}
      style={{
        // backgroundImage: `url('https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRGzAJznGUJCegtKaMXwIvYMmcuBt14EwrlXXjudhc3DIfPnFFa')`,
        // backgroundSize: "cover",
        // backgroundRepeat: "no-repeat",
        border: "0.3px ridge #1A5276",
      }}
    >
      <h1 style={{ textAlign: "center", width: "100%" }}>{groupCode}</h1>
      <p>
        <span>
          <strong>
            <VscOutput />
          </strong>
          <strong>purpose </strong>
        </span>
        <span> {purpose}</span>
      </p>
      <p>
        <span>
          <strong>
            <FcGraduationCap />
          </strong>
          <strong>Course </strong>
        </span>
        <span> {course}</span>
      </p>
      <Link to={`${encodeURIComponent(groupCode)}`}>
        <span>
          <BsChatDotsFill />
        </span>
        Chat with Trainer/Hr
      </Link>
    </div>
  );
};

export default UserGroupListDetails;
