import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import AxiosInstance from "../../api/AxiosInstance";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Styles from "./table.module.css";
import { Tooltip, Whisper, Button } from "rsuite";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import { RotatingLines } from "react-loader-spinner";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#D35400",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const ActiveInactive = () => {
  let { branch } = useParams();

  // const [loading, setLoading] = useState(false);
  let [data, setData] = useState([]);
  let [loading, setLoading] = useState(false);

  let { userAdmin } = useSelector(state => state?.auth);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await AxiosInstance.get(`/qtalk/barnch_users?branch=${branch}`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data;
          // let data1 = x?.data?.inactive_usersArchive;
          setData(data);
          // setDataInActive(data1);
          setLoading(false);
        })
        .catch(y => {})
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, []);
  return (
    <section className={Styles.StudentListBlock1}>
      {loading ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        <Paper
          elevation={12}
          sx={{
            width: "98%",
            overflow: "hidden",
            margin: "0 auto",
            position: "relative",
            marginTop: "20px",
          }}
          className={Styles.studentListTable}
        >
          <Grid
            container
            spacing={1}
            className={Styles.filterBlock}
            alignItems="center"
          >
            <Grid item md={12}>
              <h4 style={{ color: "#fff", textAlign: "center" }}>Users List</h4>
            </Grid>
          </Grid>
          <TableContainer
            component={Paper}
            sx={{ height: "78vh", borderRadius: "6px" }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ borderCollapse: "collapse" }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell size="small" align="center">
                    Name
                  </StyledTableCell>
                  <StyledTableCell size="small" align="center">
                    Branch
                  </StyledTableCell>
                  <StyledTableCell size="small" align="center">
                    Role
                  </StyledTableCell>
                  <StyledTableCell size="small" align="center">
                    Number
                  </StyledTableCell>
                  <StyledTableCell size="small">Notification</StyledTableCell>
                  <StyledTableCell size="small">sendmsgcount</StyledTableCell>
                  <StyledTableCell size="small">Active</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {" "}
                {data?.length > 0 &&
                  data.map((ele, ind) => {
                    return (
                      <StyledTableRow key={ind}>
                        <td>{ele.username}</td>
                        {ele?.branch?.length > 25 ? (
                          <Whisper
                            followCursor
                            speaker={<Tooltip>{ele?.branch}</Tooltip>}
                          >
                            <StyledTableCell align="center" size="small">
                              {ele?.branch?.toString().substring(0, 25) +
                                " " +
                                ". . ."}
                            </StyledTableCell>
                          </Whisper>
                        ) : (
                          <StyledTableCell align="center" size="small">
                            {ele?.branch}
                          </StyledTableCell>
                        )}
                        {ele?.role?.length > 20 ? (
                          <Whisper
                            followCursor
                            speaker={<Tooltip>{ele?.role}</Tooltip>}
                          >
                            <StyledTableCell align="center" size="small">
                              {ele?.role?.toString().substring(0, 20) +
                                " " +
                                ". . ."}
                            </StyledTableCell>
                          </Whisper>
                        ) : (
                          <StyledTableCell align="center" size="small">
                            {ele?.role}
                          </StyledTableCell>
                        )}
                        <StyledTableCell>
                          {ele.number.map(x => x)}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {ele.notification}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {ele.sendmsgcount}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {ele.active_users === true ? (
                            <Button className={Styles.Active}>Active</Button>
                          ) : (
                            <Button className={Styles.InActive}>
                              Inactive
                            </Button>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
    </section>
  );
};

export default ActiveInactive;
