import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { testing } from "../../api/AxiosInstance";
import Styles from "./_delete.module.css";
import Cookies from "js-cookie";
import { useContext } from "react";
import { SocketContext } from "../../ioContext/socketIo";

const RequestDelete = () => {
  const socket = useContext(SocketContext);

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");

  let navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [reason, setReason] = useState("");

  useEffect(() => {
    setUsername(user?.username);
    setEmail(user?.email);

    setNumber(user?.number);
  }, []);

  const handleSubmit = e => {
    e.preventDefault();
    let payload = {
      studentid: user?._id,

      reasons: reason,
    };

    async function students() {
      await testing
        .post(`/archive/profiledeletereq`, payload, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
        .then(x => {
          toast.success("profile delete request accepted", {
            position: "top-right",
          });
          sessionStorage.removeItem("device");
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("token");
          Cookies.remove("name", { path: "", domain: ".qspiders.com" });
          navigate("/");
          Cookies.remove("student", {
            path: "",
            domain: ".qspiders.com",
          });
          Cookies.remove("visitedHomePage", {
            path: "",
            domain: ".qspiders.com",
          });
          socket.disconnect();
        })
        .catch(y => {
          toast.success("profile delete request accepted", {
            position: "top-right",
          });
          sessionStorage.removeItem("device");
          sessionStorage.removeItem("user");
          sessionStorage.removeItem("token");
          Cookies.remove("name", { path: "", domain: ".qspiders.com" });
          navigate("/");
          Cookies.remove("student", {
            path: "",
            domain: ".qspiders.com",
          });
          Cookies.remove("visitedHomePage", {
            path: "",
            domain: ".qspiders.com",
          });
          socket.disconnect();
        });
    }

    students();
  };

  return (
    <section className={Styles.MainBlockDelete}>
      <form className={Styles.myForm} onSubmit={handleSubmit}>
        <div>
          <h4>Account Delete</h4>
        </div>
        <div className={Styles.formgroup}>
          <label htmlFor="username">Username:</label>
          <input
            className={Styles.inputBlock}
            type="text"
            id="username"
            name="username"
            value={username}
            readOnly
          />
        </div>
        <div className={Styles.formgroup}>
          <label htmlFor="number">Number:</label>
          <input
            className={Styles.inputBlock}
            type="number"
            id="number"
            name="number"
            value={number}
            readOnly
          />
        </div>
        <div className={Styles.formgroup}>
          <label htmlFor="email">Email:</label>
          <input
            className={Styles.inputBlock}
            type="email"
            id="email"
            name="email"
            value={email}
            readOnly
          />
        </div>
        <div className={Styles.formgroup}>
          <label htmlFor="reason">Reason:</label>
          <textarea
            className={Styles.inputBlock}
            id="reason"
            name="reason"
            value={reason}
            onChange={e => setReason(e.target.value)}
          ></textarea>
        </div>
        <button className={Styles.DeleteButton} type="submit">
          Submit
        </button>
      </form>
    </section>
  );
};

export default RequestDelete;
