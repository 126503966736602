import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Styles from "./_user.module.css";
import { AllUsersGroupDetails } from "../../redux/usersBatches/UserBatchesListSlice";
import StudentGroupListDetails from "./StudentGroupListDetails";

import { useNavigate } from "react-router-dom";
import { SocketContext } from "../../ioContext/socketIo";

import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { FiSearch } from "react-icons/fi";

const StudentGroupData = () => {
  let navigate = useNavigate();
  let dispatch = useDispatch();
  let {message } = useSelector(state => state.usersBatches);
  let { usergroupsdetails } = useSelector(state => state.usersBatches);
  const socket = useContext(SocketContext);
  let [search, setSearch] = useState("");
  let [batchBlock, setBatchBlock] = useState(false);

  let [data, setSData] = useState("");

  useEffect(() => {
    socket.on("batchclose_open", data => {
      setBatchBlock(true);
      setSData(data.result.res);
    });
  }, []);
  if (batchBlock === true) {
    toast.error(data, { position: "top-right" });
    function greeting() {}
    setTimeout(greeting, 3000);
    setBatchBlock(false);
    setSData("");
  }

  useEffect(() => {
    if (message?.status === 403) {
      sessionStorage.removeItem("device");
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      Cookies.remove("name", { path: "", domain: ".qspiders.com" });
      Cookies.remove("student", { path: "", domain: ".qspiders.com" });
      Cookies.remove("visitedHomePage", { path: "", domain: ".qspiders.com" });
      socket.disconnect();
      navigate("/");
    } else {
      dispatch(AllUsersGroupDetails());
      let data = usergroupsdetails?.data?.BatchsDetails;
    }
  }, []);

  useEffect(() => {
    const handleNotification = data => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, [socket]);
  let handleBatchname = e => {
    setSearch(e.target.value?.toLowerCase());
  };
  return (
    <>
      {usergroupsdetails?.GroupDetails?.length > 0 ? (
        <div className={Styles.Search}>
          <span>
            <FiSearch />
          </span>
          <input
            name="search"
            id="search"
            value={search}
            type="search"
            placeholder="Search GroupCode"
            onChange={e => handleBatchname(e)}
          />
        </div>
      ) : (
        ""
      )}
      <section id={Styles.BatchGridLayout}>
        <article>
          {usergroupsdetails?.GroupDetails?.length === 0 ? (
            <div className={Styles.MessageError}>
              <h3>You are not assigned to any group</h3>
            </div>
          ) : (
            usergroupsdetails?.GroupDetails?.filter(val =>
              val?.groupCode?.toLowerCase().includes(search)
            )?.map((val, index) => {
              return (
                <Fragment key={index + 1}>
                  <StudentGroupListDetails {...val} />
                </Fragment>
              );
            })
          )}
        </article>
      </section>
    </>
  );
};

export default StudentGroupData;
