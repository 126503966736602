import { useContext } from "react";
import { useSelector } from "react-redux";
import { globalContext } from "../Users/UsersContext";
import AdminUserBroadGroup from "./broadcast/AdminUserBroadGroup";
import AdminGroupChatComp from "./Chat/AdminGroupChatComp";


const AdminSelectedGroupList = () => {
  let {switchComp}= useContext(globalContext)
  let { user ,userAdmin } = useSelector(state => state?.auth);
  return(
    <div>
    {userAdmin.TOKEN ? (
     switchComp? (
        <AdminGroupChatComp  />
      ) : (
        <AdminUserBroadGroup  />
      )
    ) : (
      "loading111..."
    )}
 </div>
  )
  //  <div>{user.TOKEN ? <AdminGroupChatComp /> : "loading..."}</div>;
};

export default AdminSelectedGroupList;
