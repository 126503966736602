import CryptoJS from "crypto-js";
import pako from "pako";

const key = CryptoJS.enc.Hex.parse(
  "c2dc556dd73e9326c988136ddfd62107ebac97a10e88816d0c31d6893ca36eac"
);

const decryptFunction = (encryptedData, iv) => {
  const cipherParams = CryptoJS.lib.CipherParams.create({
    ciphertext: CryptoJS.enc.Hex.parse(encryptedData),
  });

  const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
    iv: CryptoJS.enc.Hex.parse(iv),
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  return decrypted.toString(CryptoJS.enc.Utf8);
};

// If DeCompressionFunction is meant to be different, implement its specific logic here
const deCompressionFunction = compressedData => {
  const binaryString = atob(compressedData);
  const binaryData = new Uint8Array(binaryString.length);

  for (let i = 0; i < binaryString.length; i++) {
    binaryData[i] = binaryString.charCodeAt(i);
  }

  const decompressedData = pako.inflate(binaryData, { to: "string" });
  return decompressedData;
};

const decryptAndDecompress = (encryptedData, iv) => {
  const decryptedData = decryptFunction(encryptedData, iv);

  const decompressedData = deCompressionFunction(decryptedData);
  let finalData = JSON.parse(decompressedData);

  return finalData;
};

export { deCompressionFunction, decryptAndDecompress };
export default decryptFunction;
