import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import {
  createGroup,
  reset,
} from "../../redux/CreateBatchandGroup/CreateBatchGroup";
import Styles from "./_admin-login.module.css";
import Creatable from "react-select/creatable";
import Modal from "react-modal";
import { IoMdAddCircle } from "react-icons/io";
import AddRolesCreateTrainer from "./AddRolesCreateTrainer";
// import AddRoleTracker from "./AddRoleTracker";
import { AiOutlineClose } from "react-icons/ai";
import QRCode from "qrcode.react";
import {
  CreateBranch,
  CreateCourse,
  CreatePurpose,
} from "../../redux/Batch/BatchSlice";
import Select from "react-select";
import ValidationCreateGroup from "./ValidationCreateGroup";
// import AddRoleHr from "./AddRoleHr";
const CreateGroup = () => {
  let [trainers, setTrainers] = useState([]);
  let [purposes, setPurposes] = useState([]);
  let [courses, setCourses] = useState([]);
  let [branchs, setBranchs] = useState([]);
  // let [adminbranchs, setAdminBranchs] = useState([]);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [openCourse, setopenCourse] = useState(false);
  const [openSubject, setopenSubject] = useState(false);
  let [inputCourse, setinputCourse] = useState("");
  let [inputpurpose, setinputpurpose] = useState("");
  let [inputBranch, setinputBranch] = useState("");

  let [groupCode, setGroupCode] = useState("");
  let [purpose, setPurpose] = useState("");
  let [course, setCourse] = useState("");
  let [branch, setBranch] = useState("");
  let [startDate, setStartDate] = useState("");
  // let [endDate, setEndDate] = useState();
  let [trainer, setTrainer] = useState("");

  let [addStudentstogroup, setAddStudentstogroup] = useState(Array);
  let [errors, setErrors] = useState({});

  ///--------- branch admin -------------/////
  let [addStudentstogroupbranch, setAddStudentsToGroupbranch] = useState({});

  let [allstudents, setAllStudents] = useState([]);
  let [qrCode, setQrCode] = useState(false);
  let [valueid, setsValueId] = useState("");
  // let [juniorTrainer, setJuniorTrainer] = useState([]);
  let { userAdmin } = useSelector(state => state.auth);
  let [author, setAuthor] = useState({});
  const [searchQuery, setSearchQuery] = useState("");
  // let [searchQ, SetSearchQ] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingCourse, setIsLoadingCourse] = useState(false);
  const [isLoadingTrainer, setIsLoadingrainer] = useState(false);
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);

  Modal.setAppElement("#root");

  const handleInputChange = value => {
    // SetSearchQ(value)
    // if(value.length>=4){
    setSearchQuery(value);

    // }
    //(value)
  };
  //(userAdmin.branch);
  useEffect(() => {
    async function fetchData() {
      //   // get Students
      let StudentData = await AxiosInstance.get(
        `admin/getstudents?&search=${searchQuery}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );
      let data = StudentData?.data?.result?.results;
      let eee = data.map(function (ele) {
        //(ele)
        return {
          value: ele._id,
          label: ele.username + "-" + ele.number.toString().substring(6, 10),
        };
      });
      setAllStudents(eee);
    }
    fetchData();
  }, [userAdmin._id, searchQuery]);

  let AddStudentsData = e => {
    //("first");
    //(e);
    setAddStudentsToGroupbranch(
      Array.isArray(e)
        ? e.map(
            x =>
              // //(x)
              ({ _id: x.value })
            // x.value
          )
        : []
    );
  };
  function openModal() {
    setIsOpen(true);
  }
  function closeModal() {
    setIsOpen(false);
    // window.location.reload()
  }

  function CloseMod() {
    setOpen(false);
    // window.location.reload()
  }
  function OpenCourse() {
    setopenCourse(true);
  }
  function closeCourse() {
    setopenCourse(false);
    // window.location.reload()
  }
  function OpenSubject() {
    setopenSubject(true);
  }
  function closeSubject() {
    setopenSubject(false);
    // window.location.reload()
  }

  // function openModalHr(){
  //   setIsOpenHr(true);
  // }
  // function closeModaHr(){
  //   setIsOpenHr(false);
  //   // window.location.reload()
  // }
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
    },
  };

  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { isSuccess, isError, message } = useSelector(
    state => state.createBatchgroup
  );

  let LoadSubject = () => {
    async function fetchData() {
      // get Subjects
      await AxiosInstance.get(`auth/getdata?data=purpose`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Subjectdataaa = x?.data[0].purpose;

          let ddd = Subjectdataaa.map(function (ele) {
            return { value: ele, label: ele };
          });
          setPurposes(ddd);
        })
        .catch(y => {
          //(y);
        })
        .finally(z => {
          setIsLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpen = () => {
    if (purposes.length === 0 && !isLoading) {
      setIsLoading(true);
      LoadSubject();
    }
  };

  let LoadCourse = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=course`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Coursedataaa = x?.data[0].course;
          let eee = Coursedataaa.map(function (ele) {
            return { value: ele, label: ele };
          });
          setCourses(eee);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingCourse(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenCourse = () => {
    if (courses.length === 0 && !isLoadingCourse) {
      setIsLoadingCourse(true);
      LoadCourse();
    }
  };

  let LoadBranch = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data[0]?.branch;
          let ccc = Branchdataaa?.map(function (ele) {
            return { value: ele, label: ele };
          });
          setBranchs(ccc);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingBranch(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenBranch = () => {
    if (branchs.length === 0 && !isLoadingBranch) {
      setIsLoadingBranch(true);
      LoadBranch();
    }
  };

  let LoadTrainer = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getusersrole?role=trainer`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let trainersss = x?.data.usersDetails;

          let eee = trainersss.map(function (ele) {
            return { value: ele.username, label: ele.username };
          });
          setTrainers(eee);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingrainer(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenTrainer = () => {
    if (trainers.length === 0 && !isLoadingTrainer) {
      setIsLoadingrainer(true);
      LoadTrainer();
    }
  };

  useEffect(() => {
    if (isError) {
      toast.error(message.message, {
        position: "top-right",
      });

      dispatch(reset());
    }
    if (isSuccess) {
      toast.success("group added successfully", { position: "top-right" });
      dispatch(reset());
      navigate("/admin-dashboard/admin/groupList");
    }
    dispatch(reset());
  }, [isError, isSuccess]);

  let handleSubmit = async e => {
    e.preventDefault();
    if (
      groupCode === "" ||
      purpose === "" ||
      course === "" ||
      branch === "" ||
      startDate === "" ||
      // endDate === "" ||
      branch === "" ||
      trainer === "" ||
      addStudentstogroup.length === 0
    ) {
      setErrors(
        ValidationCreateGroup(
          groupCode,
          purpose,
          course,
          branch,
          startDate,
          // endDate,
          trainer,

          addStudentstogroup
        )
      );
      // toast.error("Please fill all the batch details", {
      //   position: "top-right",
      // });
    } else {
      try {
        let groupName = groupCode.trim();
        if (userAdmin.role[0] === "corporate") {
          let form = new FormData();
          form.append("file", addStudentstogroup);
          form.append("groupCode", groupName);
          form.append("purpose", purpose);
          form.append("course", course);
          form.append("branch", branch);
          form.append("startDate", startDate);
          // form.append("endDate", endDate);
          form.append("trainer", trainer);

          dispatch(createGroup(form));
          dispatch(reset());
        } else {
          //(addStudentstogroupbranch);
          let payload = {
            addStudentstogroupbranch,
            groupCode,
            purpose,
            course,
            branch,
            startDate,
            trainer,
            author,
            // juniorTrainer
          };
          //(payload);

          await AxiosInstance.post("admin/branch/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${userAdmin.TOKEN}`,
            },
          })
            .then(x => {
              //(x);
              let value = {
                Group_Id: x.data.groupData._id,
                Group_Name: x.data.groupData.groupCode,
                User_Id: userAdmin._id,
                User_Name: userAdmin.username,
                Role: userAdmin.role,
                Branch: "",
                Purpose: "",
              };
              setsValueId(JSON.stringify(value));
              setQrCode(true);
              //(x)
              //(x);
              //(value);
              toast.success("Group added successfully", {
                position: "top-right",
              });
              // navigate("/admin-dashboard/admin/groupList");
            })
            .catch(x => {
              //(x);
              //(x?.response?.data?.message);
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
        }
        // }
      } catch (error) {
        //(error);
      }
    }
  };
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  const handleChange = e => {
    if (e?.value) {
      setTrainer(e.value);
    } else {
      setTrainer("");
    }
  };

  let CourseSubmit = e => {
    let payload = { course: inputCourse };
    if (inputCourse === "") {
      toast.error("Add Course", { position: "top-right" });
      // //("enter data")
    } else {
      dispatch(CreateCourse(payload));
      toast.success("Course Added", { position: "top-right" });
      setinputCourse("");
    }
  };
  let SubjectSubmit = e => {
    let payload = { purpose: inputpurpose };
    if (inputpurpose === "") {
      toast.error("Add Purpose", { position: "top-right" });
      // //("enter data")
    } else {
      dispatch(CreatePurpose(payload));
      toast.success("Purpose Added", { position: "top-right" });
      setinputpurpose("");
    }
  };
  const handleChangeBranch = e => {
    if (e?.value) {
      setBranch(e.value);
    } else {
      setBranch("");
    }
  };
  const handleChangePurpose = e => {
    if (e?.value) {
      setPurpose(e.value);
    } else {
      setPurpose("");
    }
  };
  const handleChangeCourse = e => {
    setAuthor({
      id: userAdmin?._id,
      name: userAdmin?.username,
    });
    if (e?.value) {
      setCourse(e.value);
    } else {
      setCourse("");
    }
  };
  // const handleChangeHr = (e) => {
  //   if (e?.value) {
  //     setHr(e.value);
  //   } else {
  //     setHr("");
  //   }
  // };
  let BranchSubmit = () => {
    let payload = { branch: inputBranch };
    if (inputBranch === "") {
      toast.error("Add Branch", { position: "top-right" });
      // //("enter data")
    } else {
      dispatch(CreateBranch(payload));
      toast.success("Branch Added", { position: "top-right" });
      setinputBranch("");
    }
  };
  // //(errors);
  return (
    <section id={Styles.createBatch}>
      <article>
        {qrCode === true ? (
          <>
            <div className={Styles.Qrcode}>
              <QRCode
                value={valueid}
                size={256}
                bgColor="#FFFFFF"
                fgColor="#000000"
                level="H"
                includeMargin={false}
                renderAs="svg"
              />
              <div>
                {" "}
                Scan the QR code to join Group{" "}
                <span style={{ color: "blue" }}>{valueid?.groupCode}</span>
              </div>
            </div>
            {/* <button>Download QRCOde</button> */}
          </>
        ) : (
          <div className={Styles.container}>
            <h1>Create Group</h1>
            <form onSubmit={handleSubmit} className={Styles.createBatchForm}>
              <div>
                <div className={Styles.formgroup}>
                  <label htmlFor="groupCode">GroupCode</label>
                  <input
                    className="form-control"
                    type="text"
                    name="groupCode"
                    // required
                    value={groupCode}
                    placeholder="Enter groupCode"
                    onChange={e => setGroupCode(e.target.value)}
                  />
                  <p
                    className={
                      errors.groupCode ? `${Styles.errrr}` : `${Styles.gapbtw}`
                    }
                  ></p>
                  {errors.groupCode && (
                    <p className={Styles.errors}>{errors.groupCode}</p>
                  )}
                </div>
                <div className={Styles.trainer1}>
                  <label htmlFor="purpose">purpose</label>
                  <span className={Styles.AddIcon}>
                    <IoMdAddCircle onClick={OpenSubject}></IoMdAddCircle>
                  </span>
                  <Modal
                    isOpen={openSubject}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeSubject}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className={Styles.inputforBranch}>
                      <aside>
                        <button onClick={() => closeSubject()}>
                          <AiOutlineClose />
                        </button>
                      </aside>
                      <h3>Add Purpose</h3>
                      <div>
                        <form onSubmit={SubjectSubmit}>
                          <input
                            value={inputpurpose}
                            type="text"
                            placeholder="purpose"
                            className={Styles.inputforBranch}
                            onChange={e => setinputpurpose(e.target.value)}
                          />
                          <button>Add</button>
                        </form>
                      </div>
                    </div>
                  </Modal>
                  <Creatable
                    isClearable
                    onMenuOpen={handleMenuOpen}
                    isLoading={isLoading}
                    labelKey="name"
                    className={Styles.option}
                    options={purposes}
                    onChange={handleChangePurpose}
                  />
                  <p
                    className={
                      errors.purpose ? `${Styles.errrr}` : `${Styles.gapbtw}`
                    }
                  ></p>

                  {errors.purpose && (
                    <p className={Styles.errors}>{errors.purpose}</p>
                  )}
                </div>
                <div className={Styles.trainer1}>
                  <label htmlFor="course">course</label>
                  <span className={Styles.AddIcon}>
                    <IoMdAddCircle onClick={OpenCourse}></IoMdAddCircle>
                  </span>
                  <Modal
                    isOpen={openCourse}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={closeCourse}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className={Styles.inputforBranch}>
                      <aside>
                        <button onClick={() => closeCourse()}>
                          <AiOutlineClose />
                        </button>
                      </aside>
                      <h3>Add Course</h3>
                      <div>
                        <form onSubmit={CourseSubmit}>
                          <input
                            value={inputCourse}
                            type="text"
                            placeholder="Course"
                            className={Styles.inputforBranch}
                            onChange={e => setinputCourse(e.target.value)}
                            // (e)=> setinputCourse(e.target.value)
                          />
                          <button>Add</button>
                        </form>
                      </div>
                    </div>
                  </Modal>
                  <Select
                    isClearable
                    onMenuOpen={handleMenuOpenCourse}
                    isLoading={isLoadingCourse}
                    className={Styles.option}
                    options={courses}
                    onChange={handleChangeCourse}
                  />
                  <p
                    className={
                      errors.course ? `${Styles.errrr}` : `${Styles.gapbtw}`
                    }
                  ></p>

                  {errors.course && (
                    <p className={Styles.errors}>{errors.course}</p>
                  )}
                </div>
                <div className={Styles.trainer1}>
                  <label htmlFor="branch">branch</label>

                  <Modal
                    isOpen={open}
                    // onAfterOpen={afterOpenModal}
                    onRequestClose={CloseMod}
                    style={customStyles}
                    contentLabel="Example Modal"
                  >
                    <div className={Styles.inputforBranch}>
                      <aside>
                        <button onClick={() => CloseMod()}>
                          <AiOutlineClose />
                        </button>
                      </aside>
                      <h3>Add Branch</h3>
                      <div>
                        <form onSubmit={BranchSubmit}>
                          <input
                            value={inputBranch}
                            type="text"
                            placeholder="Branch"
                            className={Styles.inputforBranch}
                            onChange={e => setinputBranch(e.target.value)}
                            // setinputBranch
                          />
                          <button>Add</button>
                        </form>
                      </div>
                    </div>
                  </Modal>
                  {userAdmin.role[0] === "corporate" ? (
                    <Select
                      onMenuOpen={handleMenuOpenBranch}
                      isLoading={isLoadingBranch}
                      isClearable
                      className={Styles.option}
                      options={branchs}
                      onChange={handleChangeBranch}
                    />
                  ) : (
                    <Select
                      onMenuOpen={handleMenuOpenBranch}
                      isLoading={isLoadingBranch}
                      isClearable
                      className={Styles.option}
                      // options={adminbranchs}
                      onChange={handleChangeBranch}
                    />
                  )}
                  <p
                    className={
                      errors.branch ? `${Styles.errrr}` : `${Styles.gapbtw}`
                    }
                  ></p>

                  {errors.branch && (
                    <p className={Styles.errors}>{errors.branch}</p>
                  )}
                </div>
                <div className={Styles.formgroup}>
                  <label htmlFor="startDate">Start Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="startDate"
                    min={disablePastDate()}
                    // required
                    value={startDate}
                    onChange={e => setStartDate(e.target.value)}
                  />
                  <p
                    className={
                      errors.startDate ? `${Styles.errrr}` : `${Styles.gapbtw}`
                    }
                  ></p>

                  {errors.startDate && (
                    <p className={Styles.errors}>{errors.startDate}</p>
                  )}
                </div>

                <div className={Styles.trainer}>
                  <label htmlFor="trainer">
                    trainer{" "}
                    <span className={Styles.AddIcon}>
                      <IoMdAddCircle onClick={openModal}></IoMdAddCircle>
                    </span>
                    <Modal
                      isOpen={modalIsOpen}
                      // onAfterOpen={afterOpenModal}
                      onRequestClose={closeModal}
                      style={customStyles}
                      contentLabel="Example Modal"
                    >
                      {<AddRolesCreateTrainer closeModal={closeModal} />}
                    </Modal>
                  </label>
                  <Select
                    onMenuOpen={handleMenuOpenTrainer}
                    isLoading={isLoadingTrainer}
                    isClearable
                    options={trainers}
                    onChange={handleChange}
                    labelKey="name"
                  />
                  <p
                    className={
                      errors.trainer ? `${Styles.errrr}` : `${Styles.gapbtw}`
                    }
                  ></p>

                  {errors.trainer && (
                    <p className={Styles.errors}>{errors.trainer}</p>
                  )}
                </div>
                {userAdmin.role[0] === "corporate" ? (
                  <>
                    <div className={Styles.formgroup}>
                      <label>Add Students </label>
                      <input
                        type="file"
                        id="addStudents"
                        name="addStudentstogroup"
                        required
                        onChange={e => setAddStudentstogroup(e.target.files[0])}
                      ></input>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className={Styles.AddStudents}>
                      <label>Add Students </label>
                      <Select
                        closeMenuOnSelect={false}
                        isMulti
                        id="addStudentstogroupbranch"
                        name="addStudentstogroupbranch"
                        inputValue={searchQuery}
                        onInputChange={handleInputChange}
                        options={allstudents}
                        // onInputChange={handleChangeStudent}
                        onChange={AddStudentsData}
                        onMenuScrollToBottom
                      />
                    </div>
                  </>
                )}
              </div>

              <aside className={Styles.createButton}>
                <button>Add Groups</button>
              </aside>
            </form>
          </div>
        )}
      </article>
    </section>
  );
};

export default CreateGroup;

// export default CreateGroup
