import React, { useEffect, useState } from "react";
import Styles from "./help.module.css";
import { Box, Button, Divider, FormControl } from "@mui/material";
import { Grid, InputLabel, MenuItem, Modal } from "@mui/material";
import { Select } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import ChatComp from "./ChatComp";
import { toast } from "react-toastify";

const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const ItemHeight = 46;
const itemPaddingTop = 8;
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ItemHeight * 4.5 + itemPaddingTop, width: "250" },
  },
};

const HelpLanding = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const { state } = useLocation();
  const navigate = useNavigate();

  // Api data storing states
  const [helpUsersList, setHelpUserList] = useState([]);
  const [studentsList, setStudentsList] = useState([]);
  const [titleList, setTitleList] = useState([]);

  // modal and selected values data states
  const [openHelp, setOpenHelp] = useState(state.modal);
  const [viewChatComp, setViewChatComp] = useState(state.chat);
  const [selectedSection, setSelectedSection] = useState("");
  const [selectedHelpUser, setSelectedHelpUser] = useState("");
  const [selectedStudent, setSelectedStudent] = useState("");
  const [selectedTitle, setSelectedTitle] = useState("");
  const [propsData, setPropsData] = useState(null);
  const [propsActualData, setPropsActualData] = useState({
    section: "",
    helpUser: null,
    student: null,
    title: null,
  });

  // use effect for modal and chat when help icon is clicked
  useEffect(() => {
    setOpenHelp(state.modal);
    setViewChatComp(state.chat);
  }, [state]);

  // use effect to fetch help user data
  useEffect(() => {
    const fetchHelpUser = async () => {
      try {
        const payload = { section: selectedSection };
        const { data } = await AxiosInstance.post(
          `/help/getDataAdmin`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        setHelpUserList(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedSection !== "") fetchHelpUser();
  }, [selectedSection]);

  // use effect to fetch student data
  useEffect(() => {
    const fetchStudent = async () => {
      try {
        const payload = { userId: selectedHelpUser };
        const { data } = await AxiosInstance.post(
          `/help/getStudentAdmin`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        setStudentsList(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedHelpUser !== "") fetchStudent();
  }, [selectedHelpUser]);

  // use effect to fetch title data
  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const payload = {
          userId: selectedHelpUser,
          studentId: selectedStudent,
        };
        const { data } = await AxiosInstance.post(
          `/help/getTitleUsers`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        setTitleList(data);
      } catch (error) {
        console.log(error);
      }
    };

    if (selectedStudent !== "") fetchTitle();
  }, [selectedStudent]);

  // submit function to view chat and close modal
  const submitFunction = (
    selectedSection,
    selectedHelpUser,
    selectedStudent,
    selectedTitle
  ) => {
    if (selectedSection === "") {
      toast.warn("Please select section");
    } else if (selectedHelpUser === "") {
      toast.warn("Please select help user");
    } else if (selectedStudent === "") {
      toast.warn("Please select student");
    } else if (selectedTitle === "") {
      toast.warn("Please select title");
    } else {
      setViewChatComp(true);
      setOpenHelp(false);
      const helpUser = helpUsersList.find(user => user.id === selectedHelpUser);
   
      const student = studentsList.find(
        std => std.studentId === selectedStudent
      );
      const title = titleList.find(std => std.requestId === selectedTitle);
      setPropsActualData({
        ...propsActualData,
        helpUser: helpUser,
        student: student,
        title: title,
      });
      setPropsData({
        selectedSection,
        selectedHelpUser,
        selectedStudent,
        selectedTitle,
      });
      setSelectedSection("");
      setSelectedHelpUser("");
      setSelectedStudent("");
      setSelectedTitle("");
    }
  };

  //* component function render part starts here
  return (
    <section id={Styles.adminDashboardBlock}>
      <article className={Styles.helpChatContainer}>
        <Modal open={openHelp}>
          <Box sx={styleModal1}>
            <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
              View "Help" Chat
            </h4>
            <Divider />
            <br />
            <Grid container spacing={2}>
              <Grid item sm={12}>
                <FormControl fullWidth>
                  <InputLabel size="small" color="warning">
                    Select Section *
                  </InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    required
                    size="small"
                    label="Select Section"
                    color="warning"
                    name="subject"
                    value={selectedSection}
                    onChange={e => {
                      setSelectedSection(e.target.value);
                      setSelectedHelpUser("");
                      setSelectedStudent("");
                      setSelectedTitle("");
                      setPropsActualData({
                        section: e.target.value,
                        helpUser: null,
                        student: null,
                        title: null,
                      });
                    }}
                  >
                    <MenuItem value="general queries">General Queries</MenuItem>
                    <MenuItem value="request profile update">
                      Request Profile Update
                    </MenuItem>
                    <MenuItem value="course related queries">
                      Course Related Queries
                    </MenuItem>
                    <MenuItem value="hr queries">HR Queries</MenuItem>
                    <MenuItem value="technical doubt clearance">
                      Technical Doubt Clearance
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid item sm={12}>
                <FormControl fullWidth>
                  <InputLabel size="small" color="warning">
                    Select Help User *
                  </InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    required
                    size="small"
                    label="Select Help User"
                    color="warning"
                    name="subject"
                    value={selectedHelpUser}
                    onChange={e => {
                      setSelectedHelpUser(e.target.value);
                      setSelectedStudent("");
                      setSelectedTitle("");
                    }}
                  >
                    {selectedSection === "" ? (
                      <MenuItem disabled>Select Section</MenuItem>
                    ) : (
                      helpUsersList.map((ele, index) => {
                        return (
                          <MenuItem
                            value={ele.id}
                            style={{ textTransform: "capitalize" }}
                            key={index}
                          >
                            {ele.username}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl fullWidth>
                  <InputLabel size="small" color="warning">
                    Select Student *
                  </InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    required
                    size="small"
                    label="Select Student"
                    color="warning"
                    name="subject"
                    value={selectedStudent}
                    onChange={e => {
                      setSelectedStudent(e.target.value);
                      setSelectedTitle("");
                    }}
                  >
                    {selectedHelpUser === "" ? (
                      <MenuItem disabled>Select Help User</MenuItem>
                    ) : (
                      studentsList.map((ele, index) => {
                        return (
                          <MenuItem
                            value={ele.studentId}
                            style={{ textTransform: "capitalize" }}
                            key={index}
                          >
                            {ele.username}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item sm={12}>
                <FormControl fullWidth>
                  <InputLabel size="small" color="warning">
                    Select Title *
                  </InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    required
                    size="small"
                    label="Select Title"
                    color="warning"
                    name="subject"
                    value={selectedTitle}
                    onChange={e => {
                      setSelectedTitle(e.target.value);
                    }}
                  >
                    {selectedStudent === "" ? (
                      <MenuItem disabled>Select Student</MenuItem>
                    ) : (
                      titleList.map((ele, index) => {
                        return (
                          <MenuItem
                            value={ele.requestId}
                            style={{ textTransform: "capitalize" }}
                            key={index}
                          >
                            {ele.title}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid
                item
                sm={12}
                style={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <Button
                  variant="outlined"
                  color="info"
                  size="small"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  color="warning"
                  size="small"
                  onClick={() =>
                    submitFunction(
                      selectedSection,
                      selectedHelpUser,
                      selectedStudent,
                      selectedTitle
                    )
                  }
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Modal>
        {viewChatComp && (
          <ChatComp
            propsData={{ ...propsData }}
            propsActualData={propsActualData}
          />
        )}
      </article>
    </section>
  );
};

export default HelpLanding;
