import React, {
  Fragment,
  useEffect,
  useRef,
  useState,
} from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import Styles from "./_chat.module.css";
import Conversation from "./Conversation";
import Message from "./Message";
import UserNameData from "./UserNameData";

const AdminUserBroadcastComp = () => {
  let { batchCode } = useParams();
  let { userAdmin } = useSelector(state => state?.auth);

  let scrollRef = useRef();
  // slected batch data
  let [selctBatchStud, setSelctBatchStud] = useState([]);
  let [batchStdentData, setBatchStdentData] = useState([]);
  let [particularStudent, setParticularStudent] = useState("");
  let [batchTrainerData, setBatchTrainerData] = useState([]);
  let [batchName, setBatchName] = useState("");
  let [allUsers, setAllUsers] = useState([]);
  let [defaultvalueuser, setDefaultvalueuser] = useState("");

  let [messages, setMessages] = useState([]);

  // api
  //(batchStdentData)
  useEffect(() => {
    async function fetchlist() {
      try {
        AxiosInstance.get(`admin/batchList/${batchCode}`, {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }).then(data => {
          let payload = data?.data?.batchData;
          let studentData = data?.data?.studentData;
          let trainer = data?.data?.trainertrackerData;
          let trainersData = data?.data?.trainertrackerData?.filter(x => {
            if (x.role == "trainer") {
              return x;
            } else if (x.role == "counselor") {
              return x;
            } else if (x.role == "human resource" || x.role == "hr") {
              return x;
            }
          });
          let trainername =
            trainersData[0]?.username + "-" + trainersData[0]?.role;
          let trainerId = trainersData[0]?._id;
          let batchname = data?.data?.batchData?.batchCode;
          setDefaultvalueuser(trainername);
          setBatchName(batchname);
          setSelctBatchStud(payload);
          setBatchStdentData(studentData);
          setBatchTrainerData(trainerId);
          setAllUsers(trainer);
        });
        // }
      } catch (error) {
        console.log(error);
      }
    }
    fetchlist();
  }, []);

  useEffect(() => {
    async function demo() {
      try {


        if (particularStudent !== "") {

          const res = await AxiosInstance.post(
            "/chat/admin/msg/new/allbrcamsg",
            {
              userId: particularStudent,
              receiversId: selctBatchStud?._id,
            },
            {
              headers: {
                Authorization: `Bearer ${userAdmin.TOKEN}`,
              },
            }
          );

          let mess = res?.data?.reverse();
          setMessages(mess);
        }
      } catch (error) {
        console.log(error);
      }
    }
    demo();
  }, [batchTrainerData]);
  //(messages)
  // scroll for msg
  // useEffect(() => {
  //   scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  // }, [messages]);
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [messages]);

  //(messages);
  // jsx start
  return (
    <>
      <section id={Styles.chatBoxSection}>
        <article>
          <aside className={Styles.chatMenu}>
            <Conversation
              batchStdent={batchStdentData}
              trainername={selctBatchStud}
              setParticularStudent={setParticularStudent}
              allUsers={allUsers}
              batchname={batchName}
              setBatchTrainerData={setBatchTrainerData}
            />
          </aside>
          <aside className={Styles.chatBox}>
            <div className={Styles.chatBoxWrapper}>
              {userAdmin._id ? (
                <>
                  <div className={Styles.chatBoxTop}>
                    <UserNameData
                      name={batchStdentData}
                      batchname={batchName}
                    />
                    <div className={Styles.chatBoxStore}>
                      {messages.length > 0 ? (
                        messages.map((m, ind) => (
                          <Fragment key={ind}>
                            <Message
                              message={m}
                              own={m.sender === batchTrainerData}
                            />
                          </Fragment>
                        ))
                      ) : (
                        <span className={Styles.noConversation}>
                          <p>No Messages</p>
                        </span>
                      )}
                    </div>
                  </div>
                  <div ref={scrollRef} style={{ visibility: "hidden" }}>
                    top
                  </div>
                </>
              ) : (
                <span className={Styles.noConversationText}>
                  <p>Open a conversation to start a chat.</p>
                </span>
              )}
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};

export default AdminUserBroadcastComp;
