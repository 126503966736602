import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
const AdminRoute = ({ children }) => {
  let { userAdmin } = useSelector(state => state.auth);

  //   //(userAdmin, "userAdmin")
  if (
    (userAdmin?.TOKEN && userAdmin?.role[0] === "admin") ||
    (userAdmin?.TOKEN && userAdmin?.role[0] === "corporate")
  ) {
    return <>{children}</>;
  } else {
    return <Navigate to="/auth/admin/login" />;
  }
};

export default AdminRoute;
