import Cookies from "js-cookie";

const handleError = (navigate, socket) => {
  sessionStorage.removeItem("user");
  sessionStorage.removeItem("token");
  sessionStorage.removeItem("device");
  Cookies.remove("name", { path: "", domain: ".qspiders.com" });
  Cookies.remove("student", { path: "", domain: ".qspiders.com" });
  Cookies.remove("visitedHomePage", { path: "", domain: ".qspiders.com" });
  if (socket) {
    socket.disconnect();
  }
  navigate("/");
};

export default handleError;
