import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import batchService from "../../services/Batch/BatchService";
let initialState = {
  batch: null,
  group: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: [],
  role: "",
  isLogout: false,
  update: null,
  updategroup: null,
  student: null,
};

//Add student
export const addStudent = createAsyncThunk(
  "admin/addStudents",
  async (studentsAdd, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.AddStudents(studentsAdd, token);
    } catch (error) {
      //(error);
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//
export const createBatch = createAsyncThunk(
  "batch/createbatch",
  async (batchData, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    //(token);
    try {
      let data = await batchService.CreateBatch(batchData, token);
      //(data);
      return data;
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      //(message);
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const createGroup = createAsyncThunk(
  "group/creategroup",
  async (groupData, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.CreateGroup(groupData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//updated batch
export const updateBatch = createAsyncThunk(
  "admin/updateBatch",
  async (Createbachdata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.UpdateBatch(Createbachdata, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//update Group
export const updateGroup = createAsyncThunk(
  "admin/updateGroup",
  async (Creategroupdata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.UpdateGroup(Creategroupdata, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);




export let batchgroupcreate = createSlice({
  name: "createbatch",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.batch = null;
      state.group = null;
    },
  },
  extraReducers: (builder) => {
    // create batch start here
    builder.addCase(createBatch.fulfilled, (state, { payload }) => {
      //(payload);
      state.isLoading = false;
      state.isSuccess = true;
      state.batch = payload;
      //(state.isSuccess);
    });
    builder.addCase(createBatch.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      //(payload);
      state.batch = null;
      state.isSuccess = false;
      //(state.isError);
    });
    //update batch
    builder.addCase(updateBatch.fulfilled, (state, { payload }) => {
      //(payload);
      state.isLoading = false;
      state.isSuccess = true;
      state.update = payload;
      //(state.isSuccess);
    });
    builder.addCase(updateBatch.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      //(payload);
      state.update = null;
      state.isSuccess = false;
      //(state.isError);
    });

    //update group
    builder.addCase(updateGroup.fulfilled, (state, { payload }) => {
      //(payload);
      state.isLoading = false;
      state.isSuccess = true;
      state.updategroup = payload;
      //(state.isSuccess);
    });
    builder.addCase(updateGroup.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      //(payload);
      state.updategroup = null;
      state.isSuccess = false;
      //(state.isError);
    });



    // create Group
    builder.addCase(createGroup.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.group = payload;
      //(state.isSuccess);
    });
    builder.addCase(createGroup.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      //(payload);
      state.group = null;
      //(state.isError);
    });
    //Student add
    builder.addCase(addStudent.fulfilled, (state, { payload }) => {
      //(payload);
      state.isLoading = false;
      state.isSuccess = true;
      state.student = payload;
      //(state.isSuccess);
    });
    builder.addCase(addStudent.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      //(payload);
      state.student = null;
      state.isSuccess = false;
      //(state.isError);
    });
  },
});
export let { reset } = batchgroupcreate.actions;
export default batchgroupcreate.reducer;
