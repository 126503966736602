import React from "react";
import classes from "../Student/profile.module.css";
import Styles from "../Student/_user.module.css";
import { Typography } from "@mui/material";
import darkBack from "../../assets/background.webp";
const UserProfile = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  return (
    <div style={{ width: "100%" }}>
      <main className={classes.profileRoot}>
        <div
          className={classes.rootProfileContent}
          //   key={ind}
          style={{
            // backgroundImage: `url('data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBw0QDQ0NDg0NDQ0NDQ0NDQ0NDQ8NDg0NFREWFhURFRUYHSggGBolGxUVITEhJSkrLi4uFx8zODM4NygtLisBCgoKDQ0NDg0NDisZFRk3Kys3LS0tLSs3KysrKzcrKysrKys3KysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAKQBMwMBIgACEQEDEQH/xAAbAAEBAQEBAQEBAAAAAAAAAAAAAQIDBAYFB//EACUQAQEAAgEEAgIDAQEAAAAAAAABAhEDEiExUUFxYYETkbHxof/EABcBAQEBAQAAAAAAAAAAAAAAAAABAgP/xAAWEQEBAQAAAAAAAAAAAAAAAAAAEQH/2gAMAwEAAhEDEQA/AP7iAAAAAAAAAAAAAAAAAAAAAACWgqbS1m0HTabcrknWDrWcmf5DqijnaxatZoiWs5LWKqMZ4PNyS/Mem1mqjyddny1Ob3P6Xk4442Kjv/JPY4BCvpgHN0AAAAAAAAAAAAAAAAAAAAGbUzrPUBkxa1tjKelQtZtZtS0FtZtNs2qiXOp/L7jGflkHbql+Wa42nXfajdrNqfye4ls9/wBiJXLPH06ZMWqOI6oI+iAc3QAAAAAAAAAAAAAAAAABm1Ntuec/IOdrPUmUrO1RrqTqZ2m1Gr3YyNmxGds2rlPTnaCZ1i1c2LVRdptLWdqLWbTaWiL1JaiUF3PYzsUfSgOToAAAAAAAAAAAAAACbS0Gk2ztNg1tzzq3JyuSotrNsLWLQLj6YsrWzao57Nt3VYuHqgbZyLLGdgxyOVrvl4ccsVRi1FrNVDaWjILs2gqAAPpgHJ1AAAAAAAAAAAAAS0FYsW1Ngzaza3WLFRjkyc9rne7ALs2ztNqi2pstYoNbOpz2bB16ksjnsmQLlh6cbLHfrZ2qOGUc7HpuM/4xlx/so81RvkwsYaQAEAAfTAOTqAACbTYNCSqAAAAADNAtQQC1LUtZ2qLtLkMcl+AZrNxNqqM7ZsdKxcQc7tNtpcYDnU23cb9udihs2zsEXadSbZtB0mbW3DZMgdcq55ccv4+kmbcoOOXFWLHpLFqR5h26IFI+gNpaza5ujW0tZ2mwa2m2bW8J8qNYxQQBKmwUtIUDaVmpsGqzlV6nPLIBENqiuOV7t5Xs5gGxi1Ua2bZXEGrGbi0AwWS+W7GbiDjnxeu7jXrZyxlVHl2za658Xr/1yyxvpUTaWr0plIDMrWO1xalBrH89nWYxxlblRXTpnqDPWA/WtS1m1NstNbTbO1xm6DWGO/p2SQ2iqlqbQATagG0QGqybS1UZzy1HHa55bv4+GQXqWVkVDOssdXynWDVokqglaxI6dIMi9JoEUALizcP23s2DlY5ZO+c7OOc/YOOWM+nHkldrXHky7tYzqY1prDGWT3pLhQXHy254tbFaGdgP1rUtZtRhpqbtejDHU0xxYa+/8bNFtQRFVNolqoqpABBNgu2M6tccsgZyhK0xliqNM53ssc+TLd+gYtRVUSRuVIIOuGnWOPHHSUG2csfSzItRXIdMo55SxUNm2QE5K57OW+HO5KLyaeSx1zy7Vy2uM66Y3tHTHJbh2n1HO42IrtMZWcuP01x3s2Dz6vqj0BR6rXThw+b+meLDd/H+u7LSoIAbRAVEWAu0EABLQZ5MnMyrO1RpdsylBnKuNb5fDjM/i/2o6StRmRdINBG8cQakVdGgIjTAL1LtlQTLD05122bB4uW93HK7ejn83X7cdqjlmxqunJe7Mqo9clS4VtcfMRUvEsxvy61i1FToGbVUfpSamkBloqUBEQARoAQAEY5KgDmgKgsoA5cnlz5J2BRjHKzw9EA1FbwBFdIoAzmyAEUAKmV7UAeO+b9s5KKjz5eaTzAaZe1rj8gw261jknaUAYAB/9k=')`,
            // backgroundSize: "cover",
            // backgroundRepeat: "no-repeat",
            border: "0.3px ridge #999",
            overflow: "auto",
          }}
        >
          <div className={classes.leftSide}>
            <main className={Styles.imageBlock}>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={200}
                height={200}
                viewBox="0 0 24 24"
              >
                <path
                  fill="#555"
                  d="M12 4a4 4 0 0 1 4 4a4 4 0 0 1-4 4a4 4 0 0 1-4-4a4 4 0 0 1 4-4m0 10c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4"
                ></path>
              </svg>
              {/* <img src={darkBack} className={Styles.profileimage} alt="" /> */}
            </main>
            <Typography variant="h6" component="h2">
              {user.username}
            </Typography>
          </div>
          <div className={classes.rightSide}>
            <div className={classes.detailsContainerBlock}>
              {/* <div className={classes.educationItem}> */}
              <main className={classes.educationItemContent}>
                <div>
                  Email :{" "}
                  {user.email.map((x, index) => (
                    <span key={index}>
                      {x} <br />
                    </span>
                  ))}
                </div>
              </main>
              <main className={classes.educationItemContent}>
                <div>
                  Number :{" "}
                  {user.number.map((x, index) => (
                    <span key={index}>
                      {x} <br />
                    </span>
                  ))}
                </div>
              </main>
              {user.branch.length > 0 && (
                <main className={classes.educationItemContent}>
                  <div>
                    Branch : <span> {user.branch.join(", ")}</span>
                  </div>
                </main>
              )}
              <main className={classes.educationItemContent}>
                <div>
                  Role : <span> {user.role.join(", ")}</span>
                </div>
              </main>
              <main className={classes.educationItemContent}>
                {user.section.length > 0 && (
                  <div>
                    Section : <span>{user.section.join(", ")}</span>
                  </div>
                )}
              </main>
              {/* </div> */}
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export default UserProfile;
