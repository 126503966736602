import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import AddRoles from "./components/admin/AddRoles";
import AdminDashboard from "./components/admin/AdminDashboard";
import AdminLogin from "./components/admin/AdminLogin";
import BatchList from "./components/admin/BatchList";
import Login from "./components/auth/Login";
import Navbar from "./components/navbar/Navbar";
import StudentDashboard from "./components/Student/StudentDashboard";
import StudentGroupData from "./components/Student/StudentGroupData";
import StuSelectBatchList from "./components/Student/StuSelectedBatchList";
import SelectedBatchList from "./components/Users/SelectedBatchList";
import UserBatchData from "./components/Users/UserBatchData";
import UserGroupData from "./components/Users/UserGroupData";
import UsersDashboard from "./components/Users/UsersDashboard";
import AdminRoute from "./helpers/AdminRoutes";
import PrivateRoute from "./helpers/PrivateRoute";
import AllRoles from "./components/admin/AllRoles";
import UpdateRoles from "./components/admin/UpdateRoles";
import CreateBatch from "./components/admin/CreateBatch";
import CreateGroup from "./components/admin/CreateGroup";
import GroupList from "./components/admin/GroupList";
import AdminSelectedBatchList from "./components/admin/AdminSelectedBatchList";
import AdminSelectedGroupList from "./components/admin/AdminSelectedGroupList";
import SelectedGroupList from "./components/Users/SelectedGroupList";
import StdSelectedGroupList from "./components/Student/StdSelectedGroupList";
import AllChatComp from "./components/Users/AllChats/AllChatComp";
import AllStudent from "./components/admin/AllStudents";
import UpdateStudents from "./components/admin/UpdateStudents";
import ForgotPass from "./components/auth/ForgotPass";
import UpdateBatch from "./components/admin/UpdateBatch";
import AddStudents from "./components/admin/AddStudents";
import UserCreateGroup from "./components/Users/UserCreateGroup";
import BlockStudents from "./components/admin/BlockStudents";
import UnBlock from "./components/admin/UnBlock";
import BlockUsers from "./components/admin/BlockUsers";
import UnBlockUser from "./components/admin/UnBlockUser";
import UpdateGroup from "./components/admin/UpdateGroup";
import PageNotFound from "./components/admin/PageNotFound";
import CreateGroupByQR from "./components/Users/CreateGroupByQR";
import Requirement from "./components/Student/Requirement";
import Mock from "./components/Student/Mock";
import Profile from "./components/Student/Profile";
import BlockedStudent from "./components/admin/BlockedStudent";
import BlockedUsers from "./components/admin/BlockedUsers";
import QandqBranchList from "./components/admin/QandqBranchList";
import { socket } from "./ioContext/socketIo";
import VisibilityCheck from "./components/Visiblity/VisibilityCheck";
import Cookies from "js-cookie";
import StudentProfile from "./components/Users/StudentProfile";
import ArchiveUsers from "./components/admin/ArchiveUsers";
import ArchiveStudents from "./components/admin/ArchiveStudents";
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import RequirementList from "./components/Users/RequirementList";
import Status from "./components/Users/Status";
import StudentStatus from "./components/Student/StudentStatus";

import InActiveUsers from "./components/admin/InActiveUsers";
import StudentsList from "./components/Users/StudentsList";
import ActiveInactive from "./components/admin/ActiveInactive";
import UserList from "./components/Users/UserToUser/UserList";
import CreateUserGroup from "./components/Users/UserToUser/CreateUserGroup";
import UpdateMock from "./components/Users/UpdateMock";
import StudentHelp from "./components/Student/StudentHelp";
import UserHelp from "./components/Help/UserHelp";
import OtherUsers from "./components/Users/OtherUsers";
import RequestDelete from "./components/Student/RequestDelete";
import AllStudentMsg from "./components/Users/UserToUser/AllStudentMsg";
import ArchiveBatchlist from "./components/admin/ArchiveBatchlist";
import ArchiveGroupList from "./components/admin/ArchiveGroupList";
import Grooming from "./components/Student/Grooming";
import HelpMainPage from "./components/Users/help/HelpMainPage";
import HelpLanding from "./components/admin/Help/HelpLanding";
import NewChatComp from "./components/Student/Chat/NewChatComp";
import BrandDashboard from "./components/Branding/BrandDashboard";
import ViewChat from "./components/Branding/ViewChat";
import CreatePost from "./components/Branding/CreatePost";
import BrandingMessage from "./components/Student/BrandingMessage";
import AdminBranding from "./components/admin/AdminBranding";
import { PostContextProvider } from "./components/Branding/BrandContext";
import Chatwithusers from "./components/admin/ChatWithusers/Chatwithusers";
import ViewChatMain from "./components/admin/ChatWithusers/ViewChatMain";
import SpideyAura from "./components/Users/SpideyAura";
import InterviewQueList from "./components/Student/InterviewQueList";
import UserProfile from "./components/Users/UserProfile";
import AddInterviewQuestion from "./components/navbar/AddInterviewQuestion";

const App = () => {
  const firebaseConfig = {
    //...
    apiKey: "AIzaSyAQ_y-GeSzlc2uvovlenwn8R3pN5wDwBw4",
    authDomain: "q-talk-d3baf.firebaseapp.com",
    projectId: "q-talk-d3baf",
    storageBucket: "q-talk-d3baf.appspot.com",
    messagingSenderId: "918487888384",
    appId: "1:918487888384:web:442e379b54ff9fe966dee5",
    measurementId: "G-TH2ZG71EHT",
  };
  let { user } = useSelector(state => state.auth);


  // Get the cookie value
  let userCookieName = Cookies.get("name", { domain: ".qspiders.com" });


  let IsCookie = sessionStorage.getItem("isCookie");

  const otherAppLogin = decodeURIComponent(window.location.search);
  const searchParams = new URLSearchParams(otherAppLogin.substring(1));
  let appId = decodeURIComponent(searchParams.get("appId")) || null;



  useEffect(() => {
    if (appId == 1) {

      let oldCookie = null;

      try {
        oldCookie = userCookieName ? JSON.parse(userCookieName) : null;
      } catch (error) {
        console.error("Failed to parse userCookieName:", error);
        oldCookie = null;
      }

      if (oldCookie) {
        sessionStorage.setItem("user", JSON.stringify(oldCookie));
        sessionStorage.setItem("token", oldCookie?.TOKEN);
        setTimeout(() => {
          appId = null;
          userCookieName = null;
          window.location.assign(
            "https://testchat.qspiders.com/student-dashboard"
          );
        }, 100);
      }
    }
  }, []);

  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);
  useEffect(() => {
    let device = Cookies.get("device", { domain: ".qspiders.com" });

    if (user !== null) {
      if (socket.connected && user !== null) {
        socket.emit(
          "addUser",
          {
            userId: user?._id,
            role: user?.role1,
            deviceId: device,
            device: "web",
            username: user?.username,
          },
          function callback(res) {}
        );
      }

      socket.on("disconnect", () => {});

      socket.on("reconnect", attemptNumber => {
        if (user !== null) {
          socket.emit(
            "addUser",
            {
              userId: user?._id,
              role: user?.role1,
              deviceId: device,
              device: "web",
              username: user?.username,
            },
            function callback(res) {}
          );
        }
      });

      // Clean up event listeners
      return () => {
        socket.off("connect");
        socket.off("disconnect");
        socket.off("reconnect");
      };
    }
  }, [user, socket]);

  const disconnectListener = () => {};

  const [isCookiePresent, setIsCookiePresent] = useState(true);

  setInterval(() => {
    const qandq1 = Cookies.get("commonuser", {
      domain: ".qspiders.com",
    });
    const isUserLoggedOut = qandq1 == null || qandq1 == undefined;
    if (isUserLoggedOut) {
      setIsCookiePresent(false);
    }
  }, 3000);

  useEffect(() => {
    const handleFocusOrVisibilityChange = () => {
      window.sessionStorage.clear();
      toast.error("User logged out");

      setTimeout(() => {
        window.location.assign(`/`);
      }, 1000);
    };
    if (IsCookie == 1 && !isCookiePresent) {
      handleFocusOrVisibilityChange();
    }
    // Cleanup function to remove event listeners
  }, [isCookiePresent]);

  useEffect(() => {
    if (user !== null) {
      if (socket.connected === false) {
        let device = Cookies.get("device", { domain: ".qspiders.com" });

        if (user !== null) {
          socket.emit(
            "addUser",
            {
              userId: user?._id,
              role: user?.role1,
              deviceId: device,
              device: "web",
              username: user?.username,
            },
            function callback(res) {}
          );
        }
      }
      socket.on("disconnect", () => {
        let device = Cookies.get("device", { domain: ".qspiders.com" });

        if (user !== null) {
          socket.emit(
            "addUser",
            {
              userId: user?._id,
              role: user?.role1,
              deviceId: device,
              device: "web",
              username: user?.username,
            },
            function callback(res) {}
          );
        }
      });

      return () => {
        socket.off("disconnect", disconnectListener);
      };
    }
  }, [user, socket]);
  useEffect(() => {}, []);

  // localStorage.setItem('isReloaded', true);
  // let removePlayerFromGame=()=>{

  //   dispatch(StudentlogOut());
  // }

  // let token = async (name, domain) => {
  //   const cookies = Cookies.get("commonuser", { domain: `${domain}` });
  //   // //("cookies", cookies);
  //   return cookies ? cookies : "nothing";
  // };

  // useEffect(() => {
  //   // window.addEventListener('beforeunload', alertUser)
  //   window.addEventListener("unload", handleTabClosing);
  //   return () => {
  //     // window.removeEventListener('beforeunload', alertUser)
  //     window.removeEventListener("unload", handleTabClosing);
  //   };
  // });

  // const handleTabClosing = () => {
  //   removePlayerFromGame();
  // };

  return (
    <Fragment>
      <Router>
        <Navbar />

        <VisibilityCheck />
        <ToastContainer
          theme="dark"
          autoClose={2000}
          style={{ zIndex: "11111111111" }}
          position="top-right"
        />
        <Routes>
          <Route path="/auth/admin/login" element={<AdminLogin />} />
          <Route path="/auth/login" element={<Login />} />
          <Route path="/auth/forgotpassword" element={<ForgotPass />} />
          <Route path="/" element={<Login />} />
          <Route
            path="admin/helpchat"
            element={
              <AdminRoute>
                <HelpLanding />
              </AdminRoute>
            }
          />
          <Route
            path="admin/branding"
            element={
              <AdminRoute>
                <AdminBranding />
              </AdminRoute>
            }
          />
          <Route
            path="/admin-dashboard"
            element={
              <AdminRoute>
                <AdminDashboard />
              </AdminRoute>
            }
          >
            <Route
              index
              element={
                <AdminRoute>
                  <BatchList />
                </AdminRoute>
              }
            />
            <Route
              path="admin/archivebatch"
              element={
                <AdminRoute>
                  <ArchiveBatchlist />
                </AdminRoute>
              }
            />
            <Route
              path="admin/chatuser"
              element={
                <AdminRoute>
                  <Chatwithusers />
                </AdminRoute>
              }
            />
            <Route
              path="admin/userviewchat"
              element={
                <AdminRoute>
                  <ViewChatMain />
                </AdminRoute>
              }
            />

            <Route
              path="admin/archivegroup"
              element={
                <AdminRoute>
                  <ArchiveGroupList />
                </AdminRoute>
              }
            />
            <Route
              path="admin/addroles"
              element={
                <AdminRoute>
                  <AddRoles />
                </AdminRoute>
              }
            />
            <Route
              path="admin/addstudents"
              element={
                <AdminRoute>
                  <AddStudents />
                </AdminRoute>
              }
            />
            <Route
              path="admin/allroles"
              element={
                <AdminRoute>
                  <AllRoles />
                </AdminRoute>
              }
            />
            <Route
              path="admin/allstudents"
              element={
                <AdminRoute>
                  <AllStudent />
                </AdminRoute>
              }
            />
            <Route path="admin/allroles/:id" element={<UpdateRoles />} />
            <Route path="admin/allstudents/:id" element={<UpdateStudents />} />
            <Route
              path="admin/allstudents/block/:id"
              element={<BlockStudents />}
            />
            <Route
              path="admin/allroles/unblock/:id"
              element={<UnBlockUser />}
            />
            <Route path="admin/allroles/block/:id" element={<BlockUsers />} />
            <Route path="admin/allstudents/unblock/:id" element={<UnBlock />} />
            <Route path="admin/createbatch" element={<CreateBatch />} />
            <Route path="admin/createGroup" element={<CreateGroup />} />
            <Route path="admin/groupList" element={<GroupList />} />
            <Route path=":batchCode" element={<AdminSelectedBatchList />} />
            <Route
              path="/admin-dashboard/admin/archiveBatch/:batchCode"
              element={<AdminSelectedBatchList />}
            />
            <Route path="admin/:batchCode" element={<UpdateBatch />} />
            <Route
              path="/admin-dashboard/admin/groupList/admin/:groupCode"
              element={<UpdateGroup />}
            />
            <Route
              path="/admin-dashboard/admin/BlockedUsers"
              element={<BlockedUsers />}
            />
            <Route
              path="/admin-dashboard/admin/BlockedStudent"
              element={<BlockedStudent />}
            />
            <Route
              path="/admin-dashboard/admin/groupList/:groupCode"
              element={<AdminSelectedGroupList />}
            />
            <Route
              path="/admin-dashboard/admin/archivegroup/:groupCode"
              element={<AdminSelectedGroupList />}
            />
            <Route
              path="/admin-dashboard/admin/BranchListfromQ"
              element={<QandqBranchList />}
            />
            <Route
              path="/admin-dashboard/admin/ArchivedUsers"
              element={<ArchiveUsers />}
            />
            <Route
              path="/admin-dashboard/admin/ArchivedStudents"
              element={<ArchiveStudents />}
            />
            <Route
              path="/admin-dashboard/admin/inactiveusers"
              element={<InActiveUsers />}
            />
            <Route
              path="/admin-dashboard/admin/inactiveusers/:branch"
              element={<ActiveInactive />}
            />
          </Route>
          <Route
            path="/student-dashboard"
            element={
              <PrivateRoute>
                <StudentDashboard />{" "}
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute>
                  <NewChatComp />
                </PrivateRoute>
              }
            />
            <Route
              path="studentgrouplist"
              element={
                <PrivateRoute>
                  <StudentGroupData />
                </PrivateRoute>
              }
            />
            <Route
              path=":batchCode"
              element={
                <PrivateRoute>
                  <StuSelectBatchList />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-dashboard/studentgrouplist/:groupCode"
              element={
                <PrivateRoute>
                  <StdSelectedGroupList />
                </PrivateRoute>
              }
            />
            <Route
              path="/student-dashboard/requirement"
              element={<Requirement />}
            />
            <Route
              path="/student-dashboard/requirement/question_list/:id"
              element={<InterviewQueList />}
            />
            {/* <Route path="/student-dashboard/chat" element={<NewChatComp />} /> */}
            <Route path="/student-dashboard/grooming" element={<Grooming />} />
            <Route path="/student-dashboard/mock" element={<Mock />} />
            <Route path="/student-dashboard/profile" element={<Profile />} />
            <Route
              path="/student-dashboard/status"
              element={<StudentStatus />}
            />
            <Route
              path="/student-dashboard/branding"
              element={<BrandingMessage />}
            />
            <Route path="/student-dashboard/help" element={<StudentHelp />} />
            <Route
              path="/student-dashboard/addquestion"
              element={<AddInterviewQuestion />}
            />
            <Route
              path="/student-dashboard/deleteaccount"
              element={<RequestDelete />}
            />
          </Route>
          <Route
            path="/help-dashboard"
            element={
              // <PrivateRoute>
              <UserHelp />
              // </PrivateRoute>
            }
          ></Route>
          <Route
            path="/spideyhome"
            element={
              <PrivateRoute>
                <BrandDashboard />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute>
                  <ViewChat />
                </PrivateRoute>
              }
            />
            <Route
              path="create-post"
              element={
                <PrivateRoute>
                  <PostContextProvider>
                    <CreatePost />
                  </PostContextProvider>
                </PrivateRoute>
              }
            />
          </Route>
          <Route
            path="/user-dashboard"
            element={
              <PrivateRoute>
                <UsersDashboard />
              </PrivateRoute>
            }
          >
            <Route
              index
              element={
                <PrivateRoute>
                  <UserBatchData />
                </PrivateRoute>
              }
            />
            <Route
              path="usergrouplist"
              element={
                <PrivateRoute>
                  <UserGroupData />
                </PrivateRoute>
              }
            />

            <Route path=":batchCode" element={<SelectedBatchList />} />
            <Route
              path="/user-dashboard/usergrouplist/:groupCode"
              element={<SelectedGroupList />}
            />
            <Route path="/user-dashboard/allChat" element={<AllChatComp />} />
            <Route path="/user-dashboard/profile" element={<UserProfile />} />

            <Route path="/user-dashboard/spideyaura" element={<SpideyAura />} />
            <Route
              path="/user-dashboard/creategroup"
              element={<UserCreateGroup />}
            />
            <Route path="/user-dashboard/help" element={<HelpMainPage />} />
            <Route
              path="/user-dashboard/CreateGroupQRcode"
              element={<CreateGroupByQR />}
            />
            <Route
              path="/user-dashboard/studentprofile/:number"
              element={<StudentProfile />}
            />
            <Route
              path="/user-dashboard/studentrequirementlist/:id"
              element={<RequirementList />}
            />
            <Route path="/user-dashboard/status" element={<Status />} />
            <Route path="/user-dashboard/students" element={<StudentsList />} />
            <Route
              path="/user-dashboard/students/updatemock:batch"
              element={<UpdateMock />}
            />
            <Route
              path="/user-dashboard/userConversation"
              element={<UserList />}
            />
            <Route
              path="/user-dashboard/userConversation/CreateGroupUser"
              element={<CreateUserGroup />}
            />
          </Route>
          <Route path="/user-dashboard" element={<OtherUsers />}>
            <Route
              index
              path="/user-dashboard/userConversation"
              element={<UserList />}
            />
            <Route path="/user-dashboard/status" element={<Status />} />
            <Route
              path="/user-dashboard/allstudents"
              element={<AllStudentMsg />}
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </Fragment>
  );
};

export default App;
