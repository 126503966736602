import React, { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { VscOutput } from "react-icons/vsc";
import { FcGraduationCap } from "react-icons/fc";
import { BsChatDotsFill } from "react-icons/bs";
import Styles from "./_user.module.css";
import { useEffect } from "react";
import { SocketContext } from "../../ioContext/socketIo";

import { toast } from "react-toastify";
import { FaBell } from "react-icons/fa";

const UserBatchListDetails = ({
  subject,
  course,
  batchCode,
  isOpen,
  notification,
  Handlepage,
}) => {
  const socket = useContext(SocketContext);
  let [permenentBlock, setPermanentBlock] = useState(false);

  let navigate = useNavigate();

  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [onlinee, setOnlinee] = useState(false);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  // //(loading)
  const handleOnline = () => {
    setOnlinee(false);
    setIsOnline(true);
  };

  const handleOffline = () => {
    setOnlinee(true);
    setIsOnline(false);
  };

  useEffect(() => {
    socket.on("blocked", data => {
      //(data);
      setPermanentBlock(true);
    });
  }, []);

  if (permenentBlock === true) {
    toast.error("Contact Administrator", { position: "top-right" });
    function greeting() {
      socket.disconnect();
      navigate("/");
    }
    setTimeout(greeting, 3000);
  }

  return (
    <>
      <div
        className={Styles.container}
        style={{
          // backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlXLm169P0IIPVSlSTI2gNkqihXI2jNcHMbfaJ_xlkm6L1leFed3LhgkUYfAw0PCrSDRY&usqp=CAU')`,
          // backgroundSize: "cover",
          // backgroundRepeat: "no-repeat",
          border: "0.3px ridge #999",
        }}
      >
        <div className={Styles.headingcard}>
          <h1
            className={isOpen === false ? `${Styles.ClosedbatchH1}` : ""}
            style={{ width: "100%", textAlign: "center" }}
          >
            {batchCode}
          </h1>
          <h2
            className={
              notification == true
                ? `${Styles.notification}`
                : `${Styles.NoNotification}`
            }
          >
            {notification == true ? <FaBell /> : ""}
          </h2>
        </div>
        {/* <h1>{groupCode}</h1> */}
        <p>
          <span>
            <strong>
              <VscOutput />
            </strong>
            <strong>Subject </strong>
          </span>
          <span className={Styles.content}> {subject}</span>
        </p>
        <p>
          <span>
            <strong>
              <FcGraduationCap />
            </strong>
            <strong>Course </strong>
          </span>
          <span className={Styles.content}> {course}</span>
        </p>
        <span onClick={Handlepage}>
          <Link to={`${batchCode}`}>
            <span>
              <BsChatDotsFill />
            </span>
            chat with students
          </Link>
        </span>
      </div>
    </>
  );
};

export default UserBatchListDetails;
