import {
  Autocomplete,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import ViewuserChat from "./ViewuserChat";
import AxiosInstance from "../../../api/AxiosInstance";
import { useSelector } from "react-redux";

const ViewChatMain = () => {
  let [branches, setBranches] = useState([]);
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  let { userAdmin } = useSelector(state => state.auth);
  const [contact, setContact] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const roles = [
    "trainer",
    "counselor",
    "human resource",
    "feecollector",
    "feetracker",
  ];

  let LoadBranch = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data[0]?.branch;
          let ccc = Branchdataaa?.map(function (ele) {
            return { value: ele, label: ele };
          });

          setBranches(ccc);
        })
        .catch(y => {})
        .finally(() => {
          setIsLoadingBranch(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenBranch = () => {
    if (branches.length === 0 && !isLoadingBranch) {
      setIsLoadingBranch(true);
      LoadBranch();
    }
  };

  return (
    <Grid style={{ height: "99vh" }}>
      <div
        style={{
          background: "#d3d3d342",
          display: "flex",
          flexDirection: "column",
          color: "black",

          padding: "5px",
          height: "7vh",
          margin: "0 15px 10px",
          borderRadius: "3px",
          border: "1px solid lightgray",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "0 10px 0 10px",
          }}
        >
          <h5 style={{ color: "#f16f1d", width: "30%" }}>Admin to user chat</h5>
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "10%",
              // background: "#fff",
            }}
          >
            <FormControl fullWidth style={{ width: "30%", background: "#fff" }}>
              <Autocomplete
                size="small"
                fullWidth
                color="warning"
                options={roles}
                value={contact}
                onChange={(event, newValue) => {
                  setContact(newValue);
                }}
                renderInput={params => (
                  <TextField {...params} label="Role" color="warning" />
                )}
              />
            </FormControl>
            <Grid item style={{ width: "30%", background: "#fff" }}>
              <FormControl fullWidth>
                <Autocomplete
                  onOpen={handleMenuOpenBranch}
                  size="small"
                  fullWidth
                  color="warning"
                  options={branches}
                  value={selectedBranch}
                  onChange={(event, newValue) => {
                    setSelectedBranch(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Branch" color="warning" />
                  )}
                />
              </FormControl>
            </Grid>
          </div>
        </div>
      </div>

      {contact &&
      contact?.length > 0 &&
      selectedBranch !== null &&
      selectedBranch !== "" &&
      selectedBranch !== undefined ? (
        <ViewuserChat contact={contact} selectedBranch={selectedBranch} />
      ) : (
        <div
          style={{
            display: "flex",
            height: "80vh",
            width: "100%",
            alignItems: "center",
            justifyContent: "center",
            color: "#f16f1d",
            fontSize: "x-large",
          }}
        >
          Select role and branch to View Chat
        </div>
      )}
    </Grid>
  );
};

export default ViewChatMain;
