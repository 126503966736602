import React, { useEffect, useState } from "react";
import { Pagination, styled } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box, List, ListItem, Typography } from "@mui/material";
import Style from "./list.module.css";
import Styles from "./_UserToUser.module.css";
import { BddtAxios } from "../../api/AxiosInstance";
import { useParams } from "react-router-dom";
import useDownloader from "react-use-downloader";



const InterviewQueList = () => {
  let { id } = useParams();

  const [interviewData, setInterviewData] = useState([]);
  const [load, setLoad] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 25,
    page: 1,
    load: false,
    length: 1,
  });
  const userToken = sessionStorage.getItem("token");
  useEffect(() => {
    setLoad(true);
    let fetchData = async () => {
      try {
        const { data } = await BddtAxios.get(
          `/hr/st-interview-question-list/?token=${userToken}&streq_id=${id}`
        );
        setPagination({
          ...pagination,
          length: data.total,
          load: data.total > 25 ? false : null,
          page: 1,
        });
        setInterviewData(
          data.results && data.results.length > 0 ? data.results : []
        );
        setLoad(false);
  
      } catch (error) {
        setInterviewData(null);
        setLoad(null);
      }
    };
    fetchData();
  }, []);
  const handleChangePage = async (_, value) => {
    if (value == pagination.page) return;
    const newPage = value;
    setPagination({ ...pagination, load: true });
    try {
      const { data } = await BddtAxios.get(
        `/hr/st-interview-question-list/?token=${userToken}&streq_id=${id}&page=${newPage}`
      );
      setInterviewData(
        data.results && data.results.length > 0 ? data.results : []
      );
      setPagination({
        ...pagination,
        length: data.total_length,
        page: value,
        load: false,
      });
    } catch (error) {
      // toast.error("something went wrong");
      setPagination({ ...pagination, load: false });
    }
  };

  return (
    <section className={Style.interviewListBlock} style={{ height: "92vh" }}>
      <h3 style={{ textAlign: "center", color: "rgb(241, 111, 29)" }}>
        Interview Questions
      </h3>
      <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: "98%",
          margin: "0 auto",
          bgcolor: "background.paper",
          borderRadius: 2,
          boxShadow: 3,
          p: 2,
          "@media (max-width: 600px)": {
            p: 1,
          },
          maxHeight: "94%",
          overflow: "auto",
        }}
      >
        <List>
          {load ? (
            <div style={{ textAlign: "center", width: "100%", height: "78vh" }}>
              {" "}
              Loading...
            </div>
          ) : !interviewData || interviewData.length === 0 ? (
            <div
              style={{
                display: "flex",
                textAlign: "center",
                width: "100%",
                height: "78vh",
                justifyContent: "center",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: "bold",
              }}
            >
              {" "}
        
              Interview questions not added
         
            </div>
          ) : (
            interviewData.map((row, ind) => (
              <ListItem
                key={ind}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  mb: 2,
                  "@media (max-width: 600px)": {
                    mb: 1,
                  },
                }}
              >
                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                  }}
                >
                  {ind + 1}.
                </Typography>
                {row.round.length > 0 && (
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      mb: 0.5,
                    }}
                  >
                    Round:{" "}
                    <Typography component="span" sx={{ fontWeight: "normal" }}>
                      {row.round.length > 0 &&
                        row.round.map(ele => ele.name).join(", ")}
                    </Typography>
                  </Typography>
                )}

                <Typography
                  variant="body1"
                  component="div"
                  sx={{
                    fontWeight: "bold",
                    mb: 0.5,
                  }}
                >
                  Question:{" "}
                  <Typography
                    component="span"
                    sx={{ fontWeight: "normal", wordBreak: "break-all" }}
                  >
                    <pre>{row.question}</pre>
                  </Typography>
                </Typography>

                {row.answer !== "" && (
                  <Typography
                    variant="body1"
                    component="div"
                    sx={{
                      fontWeight: "bold",
                      mb: 0.5,
                    }}
                  >
                    Answer:{" "}
                    <Typography component="span" sx={{ fontWeight: "normal" }}>
                      <pre> {row.answer}</pre>
                    </Typography>
                  </Typography>
                )}
              
                {row.interview_file != null && (
                  <a
                    href={row.interview_file}
                    style={{ background: "transparent" }}
                    target="_blank"
                    download="filename.ext"
                  >
                    {" "}
                    <span className={Styles.Download}></span>{" "}
                    <span className={Styles.linkBlock}>Click to view file</span>
                  </a>
                )}
              </ListItem>
            ))
          )}
        </List>
        {pagination.load !== null && (
          <Grid style={{ width: "100%" }}>
            <Pagination
              style={{ marginLeft: "40%", marginTop: "3%" }}
              count={Math.ceil(pagination.length / pagination.limit)}
              color="primary"
              defaultPage={1}
              disabled={pagination.load}
              rowsperpage={pagination.limit}
              showLastButton={
                Math.ceil(pagination.length / pagination.limit) > 5
              }
              showFirstButton={
                Math.ceil(pagination.length / pagination.limit) > 5
              }
              siblingCount={1}
              page={pagination.page}
              onChange={handleChangePage}
            />
          </Grid>
        )}
      </Box>
   
    </section>
  );
};

export default InterviewQueList;
