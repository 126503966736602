import React from "react";

import Styles from "./_chat.module.css";

const UserNameData = ({ name, online }) => {
  // //(name)
  // let { userAdmin } = useSelector(state => state?.auth);
  // const socket = useContext(SocketContext);

  // //(typing);

  return (
    <main>
      {/* <h2 id={online == "online"? Styles.online: Styles.offline}>{name.studentName}</h2> */}
      <h2>
        {online == "online" ? (
          <>
            {" "}
            <p className={Styles.Username}>{name?.studentName}</p>
            <p id={online == "online" ? Styles.online : Styles.offline}>
              Online
            </p>{" "}
          </>
        ) : (
          <>
            <p className={Styles.Username}>{name?.studentName}</p>
            <p id={online == "online" ? Styles.online : Styles.offline}>
              Offline
            </p>{" "}
          </>
        )}
      </h2>
      <span>One to One Chat</span>
      {/* <span className={online == "online"? Styles.online: Styles.offline}>
        {online}
      </span> */}
    </main>
  );
};

export default UserNameData;
