import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import batchService from "../../services/Batch/BatchService";

let initialState = {
  batches: [
    {
      Reopendata: [],
      Closedata: [],
    },
  ],
  allgroups: [
    {
      ReopengroupData: [],
      ClosegroupData: [],
    },
  ],
  // batch: null,
  // group:null,
  selectedbatch: null,

  isError: false,
  isSuccess: false,
  isLoading: false,
  message: [],
  role: "",
  isLogout: false,
  allroles: [],
  allstudents: [],
  Branch: null,
  Subject: null,
  course: null,
  purpose: null,
  reason: null,
  errmessage: "",
};

export const AllBatches = createAsyncThunk(
  "batch/allbatches",
  async (Batchdata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.AllBatches(Batchdata, token);
    } catch (error) {
      //(error)
      const message = error.response;
      // (error.response && error.response.data) ||
      // error.message ||
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const AllGroups = createAsyncThunk(
  "group/allgroups",
  async (Groupdata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;

    try {
      return await batchService.AllGroups(Groupdata, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//get all users
export const Allroles = createAsyncThunk(
  "admin/allroles",
  async (allrole, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.Allroles(token, allrole);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//get all students
export const Allstudents = createAsyncThunk(
  "admin/allstudents",
  async (allstudents, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;

    try {
      return await batchService.Allstudents(token, allstudents);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Reopen Batch
export const ReopenBatch = createAsyncThunk(
  "batch/reopenbatch",
  async (ReopenData, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.ReopenBatch(ReopenData, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Close Batch
export const CloseBatch = createAsyncThunk(
  "batch/closebatch",
  async (CloseData, thunkAPI) => {
    //(CloseData)
    //("4545454544")
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    //(token)
    //("llllllllllllll")
    try {
      //("sdjfksdfghdkjfhgkj")
      return await batchService.CloseBatch(CloseData, token);
    } catch (error) {
      //(error)
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Reopen Group
export const ReopenGroup = createAsyncThunk(
  "group/reopengroup",
  async (Reopengroup, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.ReopenGroup(Reopengroup, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Close Group
export const CloseGroup = createAsyncThunk(
  "group/closegroup",
  async (Closegroup, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.CloseGroup(Closegroup, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//CreateBranchs
export const CreateBranch = createAsyncThunk(
  "admin/createBranch",
  async (CreateBranchdata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.CreateBranch(CreateBranchdata, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//CreateSubject
export const CreateSubject = createAsyncThunk(
  "admin/createSubject",
  async (CreateSubjectdata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.CreateSubject(CreateSubjectdata, token);
    } catch (error) {
      const errmessage = error.response;
      error.toString();
      return thunkAPI.rejectWithValue(errmessage);
    }
  }
);

//CreateCourse
export const CreateCourse = createAsyncThunk(
  "admin/createCourse",
  async (CreateCoursedata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.CreateCourse(CreateCoursedata, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//CreatePurpose
export const CreatePurpose = createAsyncThunk(
  "admin/createPurpose",
  async (CreatePurposedata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.CreatePurpose(CreatePurposedata, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//CreateReasons
export const CreateReasons = createAsyncThunk(
  "admin/createReason",
  async (CreateReasonsdata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.CreateReasons(CreateReasonsdata, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export let batchSlice = createSlice({
  name: "batch",
  initialState,
  reducers: {
    reset: state => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = "";
      state.batches = null;
      state.group = null;
      state.errmessage = "";
    },
  },
  extraReducers: builder => {
    builder.addCase(AllBatches.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(AllBatches.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.batches = payload;
      // //(payload)
    });
    builder.addCase(AllBatches.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.batches = null;
      //(payload)
    });

    ////GroupList
    builder.addCase(AllGroups.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(AllGroups.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.allgroups = payload;
    });
    builder.addCase(AllGroups.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.allgroups = null;
    });

    //get all roles
    builder.addCase(Allroles.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.allroles = payload;
    });
    builder.addCase(Allroles.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.allroles = null;
    });
    // get all students
    builder.addCase(Allstudents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.allstudents = payload;
    });
    builder.addCase(Allstudents.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.allstudents = null;
    });

    //Reopen and close Bathc
    builder.addCase(ReopenBatch.fulfilled, (state, { payload }) => {
      //(payload)
      state.isLoading = false;
      state.isSuccess = true;
      state.Reopendata = payload;
    });
    builder.addCase(ReopenBatch.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.Reopendata = null;
      //(payload)
    });

    // reopen and close Group
    builder.addCase(ReopenGroup.fulfilled, (state, { payload }) => {
      //(payload)
      state.isLoading = false;
      state.isSuccess = true;
      state.ReopengroupData = payload;
    });
    builder.addCase(ReopenGroup.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.ReopengroupData = null;
      //(payload)
      //(state.isError)
    });
    //Create Branch
    builder.addCase(CreateBranch.fulfilled, (state, { payload }) => {
      //(payload)
      //(payload)
      state.isLoading = false;
      state.isSuccess = true;
      state.Branch = payload;
    });
    builder.addCase(CreateBranch.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.Branch = null;
      //(payload)
    });
    //Create Subject
    builder.addCase(CreateSubject.fulfilled, (state, { payload }) => {
      //(payload)
      state.isLoading = false;
      state.isSuccess = true;
      state.Subject = payload;
    });
    builder.addCase(CreateSubject.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.errmessage = payload;
      state.Subject = null;
      //(payload)
    });

    //Create Course
    builder.addCase(CreateCourse.fulfilled, (state, { payload }) => {
      //(payload)
      state.isLoading = false;
      state.isSuccess = true;
      state.course = payload;
    });
    builder.addCase(CreateCourse.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.course = null;
      //(payload)
    });

    //Create Purpose
    builder.addCase(CreatePurpose.fulfilled, (state, { payload }) => {
      //(payload)
      state.isLoading = false;
      state.isSuccess = true;
      state.purpose = payload;
    });
    builder.addCase(CreatePurpose.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      state.purpose = null;
      //(payload)
    });
  },
});


//archive batches
export const ArchiveBatches = createAsyncThunk(
  "batch/archivebatches",
  async (Batchdata, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;
    try {
      return await batchService.ArchiveBatches(Batchdata, token);
    } catch (error) {
      //(error)
      const message = error.response;
      // (error.response && error.response.data) ||
      // error.message ||
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export let { reset } = batchSlice.actions;
export default batchSlice.reducer;
