import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import { Button, Input } from "rsuite";
import * as yup from "yup";

import Styles from "./_auth.module.css";
import {
  stdlogin,
  reset,
  Userlogin,
  stdloginQandQ,
} from "../../redux/auth/AuthSlice";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IoMdEye, IoMdEyeOff } from "react-icons/io";

import { MdPrivacyTip } from "react-icons/md";
import Style from "./_footer.module.css";

import { FcCallback } from "react-icons/fc";
import { RotatingLines } from "react-loader-spinner";
import uniqueRandom from "unique-random";
import Cookies from "js-cookie";

import { socket } from "../../ioContext/socketIo";
import { FaUserGraduate, FaUserTie } from "react-icons/fa";
import { qandq } from "../../api/AxiosInstance";
const schema = yup.object().shape({
  password: yup.string().required("password is required"),
  username: yup
    .string()
    // .matches(/^[0-9]{10}$/, "Must be exactly 10 digits")
    .required("Number/Email is required"),
});

const Login = () => {
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(false);
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  let [StudentLoginClick, setStudentLoginClick] = useState(false);
  const [activeButton, setActiveButton] = useState("student");
  const [loginPosition, setLoginPosition] = useState(0); // Adjust the initial positions as needed
  let [qandQ, setQandQ] = useState(false);

  let {
    isLoading,
    isSuccess,
    isError,
    message,
    user,
    isLoadingLogout,
    isErrorLogout,
    isSuccessqandq,
    isErrorqandq,
  } = useSelector(state => state.auth);

  useEffect(() => {
    const cookies = Cookies.get("commonuser", {
      domain: `${qandq}`,
    });

    let finaldeviceId = Cookies.get("device");
    if (cookies != undefined) {
      setQandQ(true);

      let payload = {
        deviceId: finaldeviceId,
        device: "web",
        token: cookies,
      };
      dispatch(stdloginQandQ(payload));
    } else {
      setQandQ(false);
    }
  }, []);

  useEffect(() => {
    if (isError) {
      toast.error(message?.data?.message, {
        position: "top-right",
      });
    }
    if (isErrorqandq) {
      setQandQ(false);
    }
    if (isLoadingLogout) {
      setQandQ(false);
    }
    if (isSuccess || isSuccessqandq) {
      setAuthenticated(true);

      user?.role1 === "student"
        ? navigate("/student-dashboard", { replace: true })
        : user?.role1 === "branding"
        ? navigate("/spideyhome", { replace: true })
        : user?.role1 === "hr" ||
          user?.role1 == "human resource" ||
          user?.role1 === "trainer" ||
          user?.role1 === "tracker" ||
          user?.role1 === "feetracker" ||
          user?.role1 === "counselor"
        ? navigate("/user-dashboard", { replace: true })
        : user?.role1 === "qtalkHelp"
        ? navigate("/help-dashboard", { replace: true })
        : navigate("/user-dashboard/userConversation", { replace: true });
    }
    //(isErrorLogout)
    if (isErrorLogout) {
      setQandQ(false);
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("device");
      Cookies.remove("name", { path: "", domain: ".qspiders.com" });

      socket.disconnect();
      navigate("/");
      window.location.reload();
    }
    dispatch(reset());
  }, [
    isError,
    isSuccess,
    message,
    isErrorLogout,
    isSuccessqandq,
    isErrorqandq,
  ]);
  // //(authenticated);
  if (authenticated) {
    // Disable browser back button
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = () => {
      window.history.pushState(null, "", window.location.href);
    };
  }
  let StudentLogin = () => {
    setStudentLoginClick(false);
    setActiveButton("student");
    setLoginPosition(0);
  };
  let UserLogin = () => {
    setStudentLoginClick(true);
    setActiveButton("user");
    setLoginPosition(110);
  };

  return (
    <>
      {qandQ ? (
        <section id={Styles.authBlock}>
          <article className={Styles.Loaderrrr}>
            <RotatingLines
              strokeColor="#f16f1d"
              strokeWidth="5"
              animationDuration="0.75"
              width="60"
              visible={true}
            />
          </article>
        </section>
      ) : isLoadingLogout ? (
        <section id={Styles.authBlock}>
          <article className={Styles.Loaderrrr}>
            <RotatingLines
              strokeColor="#f16f1d"
              strokeWidth="5"
              animationDuration="0.75"
              width="60"
              visible={true}
            />
            <span className="loader-text">Logging Out</span>
          </article>
        </section>
      ) : (
        <section id={Styles.authBlock}>
          <article>
            <div className={Styles.container}>
              <div className={Styles.ImageBlock}>
                {" "}
                <h1>Qtalk Login</h1>
                <img
                  src={process.env.PUBLIC_URL + "/QtalkLogo.png"}
                  alt=""
                  style={{ width: "50px" }}
                />
              </div>

              <Formik
                initialValues={{
                  username: "",
                  password: "",
                }}
                validationSchema={schema}
                onSubmit={values => {
                  const random = uniqueRandom(1, 100000);
                  let data1 = (random(), random());
                  Cookies.set("device", JSON.stringify(data1));

                  sessionStorage.setItem("device", JSON.stringify(data1));
                  let finaldeviceId = Cookies.get("device");

                  if (StudentLoginClick === true) {
                    let payload = {
                      ...values,
                      deviceId: finaldeviceId,
                      device: "web",
                    };
                    dispatch(Userlogin(payload));

                    setAuthenticated(true);
                  } else {
                    let payload = {
                      ...values,
                      deviceId: finaldeviceId,
                      device: "web",
                    };
                    dispatch(stdlogin(payload));

                    setAuthenticated(true);
                  }
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isValid,
                }) => {
                  return (
                    <main className={Styles.MainLoginBlock}>
                      <div className={Styles.SwitchUsers}>
                        {" "}
                        <div
                          id={Styles.btn}
                          style={{ left: `${loginPosition}px` }}
                        ></div>
                        <button
                          className={
                            activeButton === "student"
                              ? Styles.SwitchStudent
                              : Styles.SwitchStudent
                          }
                          onClick={StudentLogin}
                        >
                          <FaUserGraduate /> Student
                        </button>
                        <button
                          className={
                            activeButton === "user"
                              ? Styles.SwitchStudent
                              : Styles.SwitchStudent
                          }
                          onClick={UserLogin}
                        >
                          <FaUserTie /> User
                        </button>
                      </div>
                      <form onSubmit={handleSubmit}>
                        {StudentLoginClick ? (
                          <>
                            <main>
                              <div className={Styles.pass}>
                                <label htmlFor="username">Email</label>
                                <aside>
                                  <Input
                                    id="username"
                                    autoComplete="username"
                                    size="lg"
                                    // type="email"
                                    name="username"
                                    placeholder="Enter Email / Number"
                                    style={{
                                      borderColor:
                                        errors.username && touched.username
                                          ? "red"
                                          : "inherit",
                                      marginBottom: 5,
                                    }}
                                    onChange={(val, event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.username}
                                  />
                                </aside>
                                <p className={Styles.errors}>
                                  <ErrorMessage name="username" />
                                </p>
                              </div>

                              <div className={Styles.pass}>
                                <label htmlFor="password">password</label>
                                <aside>
                                  <Input
                                    id="password"
                                    size="lg"
                                    type={isRevealPwd ? "text" : "password"}
                                    name="password"
                                    placeholder="Enter Password"
                                    style={{
                                      borderColor:
                                        errors.password && touched.password
                                          ? "red"
                                          : "inherit",
                                      marginBottom: 5,
                                    }}
                                    onChange={(val, event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.password}
                                  />
                                  <span
                                    className={Styles.eye}
                                    title={
                                      isRevealPwd
                                        ? "Hide password"
                                        : "Show password"
                                    }
                                    onClick={() =>
                                      setIsRevealPwd(prevState => !prevState)
                                    }
                                  >
                                    {isRevealPwd ? <IoMdEye /> : <IoMdEyeOff />}
                                  </span>
                                </aside>

                                <p className={Styles.errors}>
                                  <ErrorMessage name="password" />
                                </p>
                              </div>

                              {isLoading ? (
                                <Button type="submit" disabled={!isValid}>
                                  <div className={Styles.loader}>
                                    <RotatingLines
                                      strokeColor="#fff"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="20"
                                      visible={true}
                                    />
                                  </div>
                                </Button>
                              ) : (
                                <Button type="submit" disabled={!isValid}>
                                  Login
                                </Button>
                              )}
                            </main>
                          </>
                        ) : (
                          <>
                            <main>
                              <div className={Styles.pass}>
                                <label htmlFor="username">
                                  Mobile Number / Email
                                </label>
                                <aside>
                                  <Input
                                    id="username"
                                    autoComplete="username"
                                    size="lg"
                                    type="text"
                                    name="username"
                                    placeholder="Enter Number / Email"
                                    style={{
                                      borderColor:
                                        errors.username && touched.username
                                          ? "red"
                                          : "inherit",
                                      marginBottom: 5,
                                    }}
                                    onChange={(val, event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.username}
                                  />
                                </aside>
                                <p className={Styles.errors}>
                                  <ErrorMessage name="username" />
                                </p>
                              </div>

                              <div className={Styles.pass}>
                                <label htmlFor="password">password</label>
                                <aside>
                                  <Input
                                    id="password"
                                    size="lg"
                                    type={isRevealPwd ? "text" : "password"}
                                    name="password"
                                    placeholder="Enter Password"
                                    style={{
                                      borderColor:
                                        errors.password && touched.password
                                          ? "red"
                                          : "inherit",
                                      marginBottom: 5,
                                    }}
                                    onChange={(val, event) => {
                                      handleChange(event);
                                    }}
                                    onBlur={handleBlur}
                                    value={values.password}
                                  />
                                  <span
                                    className={Styles.eye}
                                    title={
                                      isRevealPwd
                                        ? "Hide password"
                                        : "Show password"
                                    }
                                    onClick={() =>
                                      setIsRevealPwd(prevState => !prevState)
                                    }
                                  >
                                    {isRevealPwd ? <IoMdEye /> : <IoMdEyeOff />}
                                  </span>
                                </aside>

                                <p className={Styles.errors}>
                                  <ErrorMessage name="password" />
                                </p>
                              </div>
                              <div className={Styles.forgot}>
                                <Link to="/auth/forgotpassword">
                                  Forgot Password?
                                </Link>{" "}
                              </div>

                              {isLoading ? (
                                <Button type="submit" disabled={!isValid}>
                                  <div className={Styles.loader}>
                                    <RotatingLines
                                      strokeColor="#fff"
                                      strokeWidth="5"
                                      animationDuration="0.75"
                                      width="20"
                                      visible={true}
                                    />
                                  </div>
                                </Button>
                              ) : (
                                <Button type="submit" disabled={!isValid}>
                                  Login
                                </Button>
                              )}
                            </main>
                          </>
                        )}
                      </form>
                    </main>
                  );
                }}
              </Formik>
            </div>
          </article>
        </section>
      )}
      <section className={Style.FooterBlock}>
        <article>
          <div>
            <span></span>
            <h3>2022 QTalk</h3>
          </div>
          <div>
            <span className={Style.icons}>
              <FcCallback />
            </span>
            <h3>Helpline Number - 7338471266</h3>
          </div>
          <div>
            <span className={Style.icons}>
              <MdPrivacyTip />
            </span>
            <h3>Privacy</h3>
          </div>
        </article>
      </section>
    </>
  );
};

export default Login;
