import { Autocomplete, Box, Button, Tab, Tabs } from "@mui/material";
import { FormControl, MenuItem } from "@mui/material";
import { Container, Divider, Grid } from "@mui/material";
import { TextField, InputLabel } from "@mui/material";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import Style from "../../Branding/brand.module.css";
import styles from "../../Student/Chat/Modal.module.css";
import AxiosInstance, {
  BddtAxios,
  fileAxios,
} from "../../../api/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";

const ItemHeight = 44;
const itemPaddingTop = 8;
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ItemHeight * 4.5 + itemPaddingTop, width: "250" },
  },
};

const Chatwithusers = () => {
  let { userAdmin } = useSelector(state => state?.auth);

  const navigate = useNavigate();

  // state to store api response data

  const [qualTypeData, setQualTypeData] = useState([]);

  // state to store value
  const [percentageRange, setPercentageRange] = useState([0, 0]);

  // Generate years from 1980 to current year
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array(currentYear - 1980 + 1),
    (val, index) => 1980 + index
  );

  const [highestDegree, setHighestDegree] = useState("");

  //? autocomplete for qualification type starts here
  const [loadingQualifications, setLoadingQualifications] = useState(false);

  // modal part to type message and send file or image
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);

  const [newMessage, setNewMessage] = useState("");
  const [contact, setContact] = useState([]);
  const [branche, setBranche] = useState([]);

  let [branches, setBranches] = useState([]);
  let [roles, setRoles] = useState([]);
  const role = [
    "trainer",
    "counselor",
    "human resource",
    "feecollector",
    "feetracker",
    "admin",
  ];
  useEffect(() => {
    let ccc = role?.map(ele => ({
      value: ele,
      label: ele,
    }));

    setRoles(ccc);
  }, []);
  const handleChangeBranch = e => {
    setContact(Array.isArray(e) ? e.map(option => option.value) : []);
  };
  const handleChangeBranches = e => {
    setSelectedBranch(Array.isArray(e) ? e.map(option => option.value) : []);
  };
  const [file, setFile] = useState(null);

  const [imagePreview, setImagePreview] = useState(null);

  const handlePreview = e => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);
    if (selectedFile && selectedFile.type.startsWith("image")) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(selectedFile);
    } else {
      setImagePreview(null);
    }
  };

  const handleDelete = () => {
    setFile(null);
    setImagePreview(null);
  };

  // fetch branch
  const [isLoadingBranch, setIsLoadingBranch] = useState(false);
  const [selectedBranch, setSelectedBranch] = useState("");

  let LoadBranch = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data[0]?.branch;
          let ccc = Branchdataaa?.map(ele => ({
            value: ele,
            label: ele,
          }));

          setBranches(ccc);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          // setIsLoadingBranch(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenBranch = () => {
    if (branches.length === 0 && !isLoadingBranch) {
      setIsLoadingBranch(true);
      LoadBranch();
    }
  };

  useEffect(() => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data[0]?.branch;
          let ccc = Branchdataaa?.map(ele => ({
            value: ele,
            label: ele,
          }));


          setBranches(ccc);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingBranch(false);
        });
    }
    fetchData();
  }, []);

  //   //? more filter for message part ends here

  const handleSubmitSendMEssage = async () => {
    if (contact.length <= 0) {
      toast.warn("Please select role");
    } else if (newMessage.trim() === "" && file === null) {
      toast.warn("Please type the message or select the file");
    } else {
      let formData = new FormData();
      formData.append("text", newMessage);
      formData.append("files", file);
      formData.append("branch", selectedBranch);
      formData.append("role", "admin");
      formData.append("senderId", userAdmin?._id);
      formData.append("senderName", userAdmin?.username);
      formData.append("recieverRole", contact);
      if (branche.length > 0) {
        formData.append("branch", branche);
      }

      try {
        const options = {
          headers: {
            Authorization: `Token ${userAdmin?.TOKEN}`,
            "Content-Type": "multipart/form-data",
          },
        };

        const response = await fileAxios.post(
          `/chat/braodcastUser`,
          formData,
          options
        );

        toast.success(response?.data?.message || "message sent successfully");
        // setOpenModal(false);
        setTimeout(() => {
          navigate("/admin-dashboard/admin/userviewchat");
        }, 1100);
      } catch (error) {
        toast.error(error?.response?.data.error);
        console.log("error", error);
      }
    }
  };

  return (
    <Container
      className={Style.updateStudentBlockContainer}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        height: "90vh",
        width: "50vw",
        paddingTop: window.innerHeight < 750 ? "5%" : "8%",
      }}
    >
      <Box
        sx={{
          height: "auto",
          padding: "17px",
          width: "100%",
          borderRadius: "10px",
          border: "0.3px ridge gray",
          boxShadow: "1px 0px 8px #f16f1d",
          background: "#fff",
        }}
      >
        <form style={{ width: "100%" }}>
          <h3 style={{ color: "#f16f1d", textAlign: "center" }}>
            Chat with User
          </h3>
          <Divider />
          <br />

          <Grid container spacing={2} className={Style.formContainerFields}>
            <Grid item sm={12}>
              <FormControl fullWidth>
                {/* <InputLabel size="small" color="warning">
                  Select role
                </InputLabel> */}
                <Select
                  options={roles}
                  // value={contact}
                  isMulti
                  onChange={handleChangeBranch}
                />
                {/* <Select
                  MenuProps={MenuProps}
                  size="small"
                  label="Select Contact Person "
                  color="warning"
                  name="subject"
                  value={contact}
                  onChange={e => {
                    setContact(e.target.value);
                  }}
                  multiple
                >
                  {roles.map((role, key) => {
                    return (
                      <MenuItem key={key} value={role}>
                        {role}
                      </MenuItem>
                    );
                  })}
                </Select> */}
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <FormControl fullWidth>
                {/* <InputLabel size="small" color="warning">
                  Select role
                </InputLabel> */}
                <Select
                  options={branches}
                  // value={contact}
                  isMulti
                  onChange={handleChangeBranches}
                />
                {/* <Autocomplete
                  MenuProps={MenuProps}
                  onOpen={handleMenuOpenBranch}
                  size="small"
                  fullWidth
                  color="warning"
                  limitTags={4}
                  disablePortal
                  multiple
                  options={branches}
                  value={selectedBranch}
                  onChange={(event, newValue) => {
                    setSelectedBranch(newValue);
                  }}
                  renderInput={params => (
                    <TextField {...params} label="Branch" color="warning" />
                  )}
                /> */}
              </FormControl>
            </Grid>
            <Grid item sm={12}>
              <TextField
                fullWidth
                color="warning"
                // label="Message"
                variant="outlined"
                placeholder="Type your Message here...."
                multiline
                minRows={3}
                value={newMessage}
                onChange={e => {
                  setNewMessage(e.target.value);
                }}
              />
            </Grid>

            <Grid item sm={12}>
              <Button
                size="small"
                variant="outlined"
                component="label"
                className={styles.fileUpload}
                color="success"
              >
                Choose File
                <input
                  type="file"
                  name="file"
                  onChange={handlePreview}
                  hidden
                />
              </Button>
              <br />
              {file && (
                <div className={styles.previewContainer}>
                  {imagePreview ? (
                    <div className={styles.imagePreviewContainer}>
                      <img
                        src={imagePreview}
                        alt="Preview"
                        className={styles.imagePreview}
                        width={100}
                        height={100}
                      />
                      <span
                        className={styles.deleteButton}
                        onClick={handleDelete}
                        style={{ color: "crimson", fontSize: "medium" }}
                      >
                        &#10006;
                      </span>
                    </div>
                  ) : (
                    <div
                      className={styles.filePreview}
                      style={{ position: "relative" }}
                    >
                      <div className={styles.fileIcon}>📄</div>
                      <div className={styles.fileName}>{file.name}</div>
                      <span
                        className={styles.deleteButton}
                        onClick={handleDelete}
                        style={{ color: "crimson", fontSize: "medium" }}
                      >
                        &#10006;
                      </span>
                    </div>
                  )}
                </div>
              )}
            </Grid>

            <Grid
              item
              sm={12}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
              }}
            >
              <Button
                type="button"
                size="small"
                variant="contained"
                color="info"
                onClick={() => {
                  handleCloseModal();
                  setFile(null);
                  setNewMessage("");
                  setContact([]);
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                variant="contained"
                color="warning"
                size="small"
                onClick={() => {
                  handleSubmitSendMEssage();
                }}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Container>
  );
};

export default Chatwithusers;
