import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import Styles from "./_user.module.css";
import AxiosInstance from "../../api/AxiosInstance";
import { useState } from "react";

const UpdateMock = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [raiting, setRaiting] = useState([]);
  let [raitingLoading, setRatingLoading] = useState([]);
  let [Subject, setSubject] = useState([]);
  let [subjectLoading, setSubjectLoading] = useState([]);
  const initialValues = {
    selectedOptions1: [],
    selectedOptions2: [],
    selectedOptions3: [],
    comment: "",
  };

  const validationSchema = Yup.object().shape({
    selectedOptions1: Yup.array()
      .min(1, "Select at least one option for Select 1")
      .required("Select 1 is required"),
    selectedOptions2: Yup.array()
      .min(1, "Select at least one option for Select 2")
      .required("Select 2 is required"),
    selectedOptions3: Yup.array()
      .min(1, "Select at least one option for Select 3")
      .required("Select 3 is required"),
    // comment: Yup.string().required("Comment is required"),
  });

  const onSubmit = (values, { setSubmitting }) => {
    // Handle form submission here

    setSubmitting(false);
  };

  let LoadRating = () => {
    async function fetchData() {
      await AxiosInstance.get(`/employee/users/ratinglist`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          let trainersss = x?.data.results;

          let eee = trainersss.map(function (ele) {
            return { value: ele.id, label: ele.name };
          });
          setRaiting(eee);
        })
        .catch(y => {})
        .finally(() => {
          setRatingLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenTrainer = () => {
    // if (raiting.length === 0 && !raitingLoading) {
    setRatingLoading(true);
    LoadRating();
    // }
  };

  let LoadSubject = () => {
    async function fetchData() {
      await AxiosInstance.get(`/employee/users/courses_subject`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          let trainersss = x?.data.results;

          let eee = trainersss.map(function (ele) {
            return { value: ele.id, label: ele.name };
          });
          setSubject(eee);
        })
        .catch(y => {})
        .finally(() => {
          setSubjectLoading(false);
        });
    }
    fetchData();
  };
  const handleMenuOpenSubject = () => {
    // if (raiting.length === 0 && !raitingLoading) {
    setSubjectLoading(true);
    LoadSubject();
    // }
  };

  // Define options for Select 2 and Select 3

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ isSubmitting, setFieldTouched, setFieldValue }) => (
        <Form>
          <div className={Styles.formcontainer}>
            <div className={Styles.formgroup}>
              <label>Technical:</label>
              <div className={Styles.selectcontainer}>
                <Field
                  onMenuOpen={handleMenuOpenTrainer}
                  isLoading={raitingLoading}
                  name="selectedOptions1"
                  component={Select}
                  options={raiting}
                  //   isMulti
                  classNamePrefix="react-select"
                  onChange={selectedOptions => {
                    setFieldValue("selectedOptions1", selectedOptions);
                    // setFieldTouched("selectedOptions1", true);
                  }}
                  onBlur={() => setFieldTouched("selectedOptions1", true)} // Add onBlur handler
                />
              </div>
              {/* Conditionally render the error message */}
              <ErrorMessage
                name="selectedOptions1"
                component="div"
                className={Styles.error}
              />
            </div>
            <div className={Styles.formgroup}>
              <label>Subject:</label>
              <div className={Styles.selectcontainer}>
                <Field
                  onMenuOpen={handleMenuOpenSubject}
                  isLoading={subjectLoading}
                  name="selectedOptions2"
                  component={Select}
                  options={Subject}
                  //   isMulti
                  classNamePrefix="react-select"
                  onChange={selectedOptions => {
                    setFieldValue("selectedOptions2", selectedOptions);
                    // setFieldTouched("selectedOptions2", true);
                  }}
                />
              </div>
              <ErrorMessage
                name="selectedOptions2"
                component="div"
                className={Styles.error}
              />
            </div>
            <div className={Styles.formgroup}>
              <label>communication:</label>
              <div className={Styles.selectcontainer}>
                <Field
                  onMenuOpen={handleMenuOpenTrainer}
                  isLoading={raitingLoading}
                  name="selectedOptions3"
                  component={Select}
                  options={raiting}
                  //   isMulti
                  classNamePrefix="react-select"
                  onChange={selectedOptions => {
                    setFieldValue("selectedOptions3", selectedOptions);
                    // setFieldTouched("selectedOptions3", true);
                  }}
                />
              </div>
              <ErrorMessage
                name="selectedOptions3"
                component="div"
                className={Styles.error}
              />
            </div>
            <div className={Styles.formgroup}>
              <label>Comment:</label>
              <Field
                as="textarea"
                name="comment"
                id="comment"
                placeholder="Comment"
                className={Styles.textarea}
                onBlur={() => setFieldTouched("selectedOptions1", true)}
              />
              <ErrorMessage
                name="comment"
                component="div"
                className={Styles.error}
              />
            </div>

            <div className={Styles.formgroup}>
              <button
                type="submit"
                className={Styles.sendbutton}
                disabled={isSubmitting}
              >
                Send
              </button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateMock;

// export default UpdateMock
