import React, { useRef } from "react";
import Moment from "react-moment";
import Styles from "./_chat.module.css";
import { IoCheckmarkDoneSharp, IoSendSharp } from "react-icons/io5";
import { FcCheckmark } from "react-icons/fc";
import { socket } from "../../../ioContext/socketIo";
import Linkify from "react-linkify";
import linkifyHtml from "linkify-html";
import "./styles.css";
import { Button, Dropdown, IconButton, Modal } from "rsuite";
import { useState } from "react";
import useDownloader from "react-use-downloader";
import AxiosInstance from "../../../api/AxiosInstance";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { FaAngleDown, FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { saveAs } from "file-saver";
import handleError from "../../Users/ErrorHandler";
import fetchImageUrl from "../../functions/FetchFileWithToken";
import {
  Modal as MuiModal,
  Box,
  IconButton as MuiIconButton,
} from "@mui/material";
const StuMessage = ({
  message,
  own,
  particularStudent,
  onClick,
  setReply,
  reply,
  selectedMessages,
  index,
  setSelectedMessages,
}) => {
  let navigate = useNavigate();
  const [previewImage, setPreviewImage] = useState("");
  const [open, setOpen] = React.useState(false);
  const { download, isInProgress } = useDownloader();
  let [pollData, setPollData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [maindata, setMainData] = useState([]);
  let [viewPollData, setViewPollData] = useState([]);
  let [viewOptions, setViewOptions] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [openPoll, setOpenPoll] = React.useState(false);
  const [openPollView, setOpenPollView] = React.useState(false);
  const [backgroundColor1, setBackgroundColor1] = useState("");
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const imgRef = useRef(null);
  const userToken = sessionStorage.getItem("token");

  const handleDownload = () => {
    const fileUrl = message?.messages?.files[0].path; // Assuming `fileUrl` is the URL of the file
    const fileName = message?.messages?.files[0].filename; // Assuming `fileName` is the name of the file

    fetch(fileUrl)
      .then(response => response.blob())
      .then(blob => saveAs(blob, fileName))
      .catch(error => console.error("Error downloading file:", error));
  };

  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };

  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };

  const handleMouseUp = () => {
    setDragging(false);
  };

  const handleMouseLeave = () => {
    setDragging(false);
  };
  //Error handling

  useEffect(() => {
    const isMessageSelected = selectedMessages.some(
      msg =>
        msg?.messages?.text === message?.messages?.text && msg?.index === index
    );

    setBackgroundColor1(isMessageSelected ? "rgb(0 0 0 / 12%)" : "");
  }, [selectedMessages, message, index]);

  const handleClick = () => {
    const isMessageSelected = selectedMessages.some(
      msg => msg.messages.text === message.messages.text && msg.index === index
    );

    if (selectedMessages?.length < 1) {
      setBackgroundColor1(backgroundColor1 === "" ? "rgb(0 0 0 / 12%)" : "");
      const modifiedMessage = { ...message, index, isMessageSelected };
      onClick(modifiedMessage);
    } else if (reply === false && selectedMessages?.length === 1) {
      setBackgroundColor1(backgroundColor1 === "");
      setSelectedMessages([]);
    }
  };
  useEffect(() => {
    setBackgroundColor1("");
  }, [particularStudent || reply]);

  const item123 = [
    <Dropdown.Item onClick={() => HandleReply()}>Reply</Dropdown.Item>,
  ];
  let HandleReply = () => {
    setReply(true);
  };
  const renderIconButton = (props, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<FaAngleDown />}
        circle
        color="white"
        size="10px"
      />
    );
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenpoll = () => setOpenPoll(true);
  const handleClosepoll = () => {
    setOpenPoll(false);
  };
  const handleOpenpollView = () => setOpenPollView(true);
  const handleClosepollView = () => {
    setOpenPollView(false);
  };
  function stopPropagation(e) {
    e.stopPropagation(); // Stop the event from propagating to the outer div
  }
  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };
  let handleImage = e => {
    handleOpen(true);
    openImagePreview(e);
  };

  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  socket.on("reconnect", attemptNumber => {});

  const [imageUrl, setImageUrl] = useState("");
  let [replyimgUrl, setReplyImageUrl] = useState("");

  useEffect(() => {
    const fetchUrl = async () => {
      if (
        message?.replayback &&
        Object.keys(message?.replayback).length > 0 &&
        message?.replayback?.file !== "" &&
        message?.replayback?.file != undefined
      ) {
        const fileData = await fetchImageUrl(
          message?.replayback?.file[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setReplyImageUrl(fileData);
        }
      }
    };

    fetchUrl();
  }, [message]);

  useEffect(() => {
    const fetchUrl = async () => {
      if (message?.messages?.files !== undefined) {
        const fileData = await fetchImageUrl(
          message?.messages?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }
      if (message?.status && message?.status?.message?.files != undefined) {
        const fileData = await fetchImageUrl(
          message?.status?.message?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }
    };

    fetchUrl();
  }, [message]);

  let handlePollAnswer = e => {
    async function fetchData() {
      setLoading(true);

      await AxiosInstance.get(`/employee/stu_getpoll?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let eee = x?.data?.voters[0]?.votefor?.map(x => x);

          let lll = data?.options?.map(outerOption => {
            return {
              ...outerOption,
              vote_type: data?.vote_type,
              isMatch:
                eee != undefined &&
                eee?.some(
                  voterOption =>
                    voterOption?.options_id === outerOption?.options_id
                ),
            };
          });

          let fff = lll.filter(x => {
            return x.isMatch === true;
          });
          setSelectedLabels(fff);
          setPollData(lll);
          setMainData(data);
          setLoading(false);
        })
        .catch(y => {
          setLoading(false);
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };

  let currentDate = new Date(); // Get the current date and time
  let year = currentDate.getFullYear(); // Get the year
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Get the month (add 1 because months are 0-based) and format it to have leading zeros if necessary
  let day = String(currentDate.getDate()).padStart(2, "0"); // Get the day of the month and format it with leading zeros if necessary
  let hours = String(currentDate.getHours()).padStart(2, "0"); // Get the hours and format them with leading zeros if necessary
  let minutes = String(currentDate.getMinutes()).padStart(2, "0"); // Get the minutes and format them with leading zeros if necessary

  let currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;
  // //(currentDateTime);
  let handlePollView = e => {
    // //(e);
    async function fetchData() {
      await AxiosInstance.get(`/employee/users_pollviewvotes?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let final = data?.polldata?.options;

          const updatedArray1 = final.filter(
            obj1 =>
              !data?.voters.some(obj2 => obj1.options_id === obj2.options_id)
          );
          const voterOptions = data?.voters?.map(voter => voter._id);

          // Filter updatedArray1 to remove objects with 'option' values that are also in voterOptions
          const filteredArray = updatedArray1.filter(
            obj => !voterOptions.includes(obj.options_id)
          );
          // Concatenate array2 to updatedArray1
          const finalArray = filteredArray.concat(data?.voters);
          // //(finalArray);
          // Update the state with the modified array
          setViewOptions(finalArray);
          setViewPollData(data);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };

  const handleLabelClick = user => {
    setSelectedLabels(prevLabels => {
      const labelIndex = prevLabels.findIndex(
        label => label.options_id === user.options_id
      );

      if (labelIndex !== -1) {
        return prevLabels.filter((_, index) => index !== labelIndex);
      } else {
        return [...prevLabels, user];
      }
    });
  };

  let handleLabelClickRadio = option => {
    setSelectedLabels([option]);
  };

  let HandleAnswerPoll = () => {
    if (selectedLabels.length === 0) {
      toast.error("Select any options", { position: "top-right" });
    } else {
      let payload = {
        pollid: maindata._id,
        userdata: {
          userid: user?._id,
          username: user?.username,
          number: user?.number,
          role: user?.role1,
          votefor: selectedLabels,
        },
      };
      async function students() {
        await AxiosInstance.post(`/employee/vote_poll`, payload, {
          headers: {
            Authorization: `Bearer ${userToken}`,
          },
        })
          .then(x => {
            console?.log(x);
          })
          .catch(x => {
            console?.log(x);
            toast.error("There was an error", { position: "top-right" });
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          })
          .finally(() => {
            setOpenPoll(false);
          });
      }
      students();
    }
  };

  function linkifyHtmlContent(text) {
    // Process the text to convert URLs into clickable links
    const linkifyOptions = {
      defaultProtocol: "https",
      target: "_blank", // Open links in a new tab
      rel: "noopener noreferrer", // For security
    };

    return linkifyHtml(text, linkifyOptions);
  }

  function linkify(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    // extract your url by urlRegex something like
    const url = text.match(urlRegex);

    function openInNewTab(url) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    }
    return (
      <Linkify componentDecorator={openInNewTab}>
        <p className={Styles.messageText}>
          {message?.messages?.text} <br />
        </p>
      </Linkify>
    );
  }

  return (
    <>
      <div
        className={
          message?.messages?.text !== ""
            ? `${Styles.Messageeee}`
            : `${Styles.Emptymsg}`
        }
      >
        <div
          className={
            own
              ? `${Styles.sendermsg}`
              : message?.sender === particularStudent?.trainersId
              ? `${Styles.recivermsg}`
              : `${Styles.displaynone}`
          }
        >
          <Modal
            onClose={handleClosepoll}
            // backdrop={backdrop}
            open={openPoll}
            size="lg"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Header>
              <Modal.Title
                style={{
                  textAlign: "center",
                  color: "#f16f1d",
                  fontWeight: "bold",
                }}
              >
                Poll
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                overflow: "unset",
                maxheight: "none",
              }}
            >
              <aside className={Styles.MainContainer}>
                <h4 className={Styles.TextAlign} style={{ color: "green" }}>
                  {" "}
                  <small style={{ fontSize: "x-small", color: "black" }}>
                    Question:
                  </small>{" "}
                  {maindata?.poll_question}
                </h4>
                {maindata?.vote_type ? <span>Select one or more</span> : ""}
                {loading ? (
                  ""
                ) : (
                  <form>
                    {pollData?.map((ele, ind) => {
                      return (
                        <div
                          key={ele?.options_id}
                          className={Styles.polloption}
                        >
                          {" "}
                          {maindata?.vote_type === false ? (
                            <>
                              <input
                                type="radio"
                                name="poll_option"
                                value={ele?.options_id}
                                id={ele?.options_id}
                                defaultChecked={ele?.isMatch === true}
                              />
                              <label
                                htmlFor={ele?.options_id}
                                onClick={() => {
                                  handleLabelClickRadio(ele);
                                }}
                                className={Styles.TextAlign}
                              >
                                {ele?.option}
                              </label>
                            </>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                name="poll_option"
                                value={ele?.options_id}
                                id={ele?.options_id}
                                defaultChecked={ele?.isMatch === true}
                              />
                              <label
                                htmlFor={ele?.options_id}
                                onClick={() => {
                                  handleLabelClick(ele);
                                }}
                                className={Styles.TextAlign}
                              >
                                {ele?.option}
                              </label>
                            </>
                          )}
                        </div>
                      );
                    })}
                  </form>
                )}
              </aside>
            </Modal.Body>
            <Modal.Footer>
              <button
                className={Styles.SubmitPoll}
                onClick={() => HandleAnswerPoll()}
              >
                <IoSendSharp />
              </button>
            </Modal.Footer>
          </Modal>

          <Modal
            onClose={handleClosepollView}
            // backdrop={backdrop}
            open={openPollView}
            size="lg"
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Modal.Header>
              <Modal.Title
                style={{
                  color: "#f16f1d",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                Poll Details
              </Modal.Title>
            </Modal.Header>
            <Modal.Body
              style={{
                overflow: "unset",
                maxheight: "none",
              }}
            >
              <div className={Styles.ViewPollBlock}>
                <h4 className={Styles.TextAlign} style={{ color: "green" }}>
                  <small style={{ fontSize: "x-small", color: "black" }}>
                    Question:
                  </small>{" "}
                  {viewPollData?.polldata?.poll_question}
                </h4>
                {viewOptions?.map((ele, ind) => {
                  return (
                    <aside className={Styles.options} key={ind}>
                      <div className={Styles.Voters}>
                        <h6 className={Styles.TextAlign}>{ele?.option}</h6>
                        {ele?.voters ? (
                          <span>Votes- {ele?.voters?.length}</span>
                        ) : (
                          <span>Votes- 0</span>
                        )}
                      </div>
                      {ele.voters ? (
                        <div>
                          {ele.voters.map((ele, ind) => {
                            return (
                              <p style={{ textTransform: "capitalize" }}>
                                {ele.username}
                              </p>
                            );
                          })}
                        </div>
                      ) : (
                        ""
                      )}
                    </aside>
                  );
                })}
              </div>
            </Modal.Body>
          </Modal>
          {/* <Modal onClose={handleClose} open={open} size="full">
            <Modal.Body>
              <div
                style={{
                  overflow: "hidden",
                  cursor: zoomLevel > 1 ? "grab" : "auto",
                }}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseLeave}
              >
                <img
                  ref={imgRef}
                  src={previewImage}
                  style={{
                    transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                    transition: "transform 0.3s ease",
                    maxWidth: "700px",
                    maxHeight: "80%",
                    width: "80%",
                    margin: "auto",
                    display: "block",
                  }}
                  alt="Preview"
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleZoomOut}>
                <FaSearchMinus />
              </Button>
              <Button variant="secondary" onClick={handleZoomIn}>
                <FaSearchPlus />
              </Button>
              <Button variant="primary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal> */}
          <MuiModal
            open={open}
            onClose={handleClose}
            aria-labelledby="image-modal"
            aria-describedby="image-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                maxWidth: "100%",
                maxHeight: "100%",
                bgcolor: "background.paper",
                boxShadow: 24,
                outline: "none",
                overflow: "hidden",
                cursor: zoomLevel > 1 ? "grab" : "auto",
              }}
              onMouseDown={handleMouseDown}
              onMouseMove={handleMouseMove}
              onMouseUp={handleMouseUp}
              onMouseLeave={handleMouseLeave}
            >
              <MuiIconButton
                sx={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  zIndex: "2222",
                }}
                onClick={handleClose}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={22}
                  height={22}
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="crimson"
                    d="M20 6.91L17.09 4L12 9.09L6.91 4L4 6.91L9.09 12L4 17.09L6.91 20L12 14.91L17.09 20L20 17.09L14.91 12z"
                  ></path>
                </svg>
              </MuiIconButton>
              <img
                ref={imgRef}
                src={previewImage}
                alt="Modal Content"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90%",
                  objectFit: "contain",
                  display: "block",
                  margin: "0 auto",
                  transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                  transition: "transform 0.3s ease",
                }}
              />
              <Box
                sx={{
                  position: "absolute",
                  display: "flex",
                  flexDirection: "column",
                  top: "35px",
                  right: "-3px",
                }}
              >
                <MuiIconButton variant="secondary" onClick={handleZoomOut}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#555"
                      d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M5 8v2h8V8z"
                    ></path>
                  </svg>
                </MuiIconButton>
                <MuiIconButton variant="secondary" onClick={handleZoomIn}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={20}
                    height={20}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="#555"
                      d="M9 2a7 7 0 0 1 7 7c0 1.57-.5 3-1.39 4.19l.8.81H16l6 6l-2 2l-6-6v-.59l-.81-.8A6.9 6.9 0 0 1 9 16a7 7 0 0 1-7-7a7 7 0 0 1 7-7M8 5v3H5v2h3v3h2v-3h3V8h-3V5z"
                    ></path>
                  </svg>
                </MuiIconButton>
              </Box>
            </Box>
          </MuiModal>
          <main
            style={{ backgroundColor: backgroundColor1 }}
            onClick={handleClick}
          >
            <div>
              {message?.replayback &&
              Object.keys(message?.replayback).length > 0 ? (
                <div className={Styles.StatusText}>
                  Reply-{" "}
                  {message?.replayback?.message &&
                  message?.replayback?.message !== "" ? (
                    message?.replayback?.message?.length > 85 ? (
                      message?.replayback?.message.slice(0, 85)
                    ) : (
                      message?.replayback?.message
                    )
                  ) : message?.replayback?.file?.length > 0 ? (
                    <>
                      {(() => {
                        switch (message?.replayback?.file[0]?.mimetype) {
                          case "image/jpg":
                          case "image/jpeg":
                          case "image/avif":
                          case "image/png":
                          case "image/webp":
                          case "image/tiff":
                          case "image/bmp":
                            return (
                              <>
                                <img
                                  src={replyimgUrl?.url}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "5px",
                                    display: "flex",
                                  }}
                                  onClick={e => {
                                    stopPropagation(e);
                                    handleImage(replyimgUrl?.url);
                                  }}
                                />{" "}
                              </>
                            );
                          default:
                            return (
                              <button className={Styles.btndownload1}>
                                {" "}
                                {message?.replayback?.file[0]?.originalname}
                              </button>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : message?.status ? (
                <div
                  className={Styles.StatusText}
                  style={{
                    wordBreak: "break-all",
                    textAlign: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  Status- &nbsp;
                  {message?.status?.message?.text ? (
                    message?.status?.message?.text?.slice(0, 85)
                  ) : message?.status?.message?.files?.length > 0 ? (
                    <>
                      {(() => {
                        switch (message?.status?.message?.files[0]?.mimetype) {
                          case "image/jpg":
                          case "image/jpeg":
                          case "image/avif":
                          case "image/png":
                          case "image/webp":
                          case "image/tiff":
                          case "image/bmp":
                            return (
                              <>
                                <img
                                  src={imageUrl?.url}
                                  alt=""
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: "5px",
                                    display: "flex",
                                  }}
                                />{" "}
                              </>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {message?.poll && message?.poll !== null ? (
              <div className={Styles.messageTop}>
                <div className={Styles.Pollhead}> poll</div>
                <h6 className={Styles.TextAlign}>
                  {message?.poll?.poll_question}
                </h6>

                <div>
                  {currentDateTime < message?.poll?.endTime ? (
                    <button
                      className={Styles.AnswerPoll}
                      onClick={e => {
                        stopPropagation(e);
                        handlePollAnswer(message?.poll?._id);
                        handleOpenpoll();
                      }}
                    >
                      Answer
                    </button>
                  ) : (
                    <p style={{ color: "red" }}>Poll Expired</p>
                  )}
                  {message?.poll?.stu_view_vote && (
                    <button
                      className={Styles.AnswerView}
                      onClick={e => {
                        stopPropagation(e);
                        handlePollView(message?.poll?._id);
                        handleOpenpollView();
                      }}
                    >
                      View
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <div className={Styles.messageTop}>
                {message?.messages?.text ? (
                  message?.messages?.text && message?.html ? (
                    // <pre
                    //   dangerouslySetInnerHTML={{
                    //     __html: message?.messages?.text,
                    //   }}
                    //   className={Styles.textEditStyle}
                    // />
                    <Linkify
                      componentDecorator={(
                        decoratedHref,
                        decoratedText,
                        key
                      ) => (
                        <a
                          href={decoratedHref}
                          target="_blank"
                          rel="noopener noreferrer"
                          key={key}
                        >
                          {decoratedText}
                        </a>
                      )}
                    >
                      <pre
                        dangerouslySetInnerHTML={{
                          __html: linkifyHtmlContent(
                            message?.messages?.text || ""
                          ),
                        }}
                        className={Styles.textEditStyle}
                      />
                    </Linkify>
                  ) : (
                    linkify(message?.messages?.text)
                  )
                ) : message?.messages?.files?.length > 0 ? (
                  <>
                    {(() => {
                      switch (message?.messages?.files[0].mimetype) {
                        case "image/jpg":
                        case "image/jpeg":
                        case "image/avif":
                        case "image/png":
                        case "image/webp":
                        case "image/tiff":
                        case "image/bmp":
                          return (
                            <>
                              <img
                                alt=""
                                src={imageUrl?.url}
                                style={{
                                  width: "200px",
                                  height: "130px",
                                  borderRadius: "5px",
                                  display: "flex",
                                }}
                                onClick={e => {
                                  stopPropagation(e);
                                  handleImage(imageUrl?.url);
                                }}
                              />

                              <button className={Styles.btndownload}>
                                <span
                                  className={Styles.Download}
                                  onClick={e => {
                                    stopPropagation(e);
                                    download(
                                      imageUrl?.url,
                                      message?.messages?.files[0]?.originalname,
                                      message?.messages?.files[0].filename
                                    );
                                  }}
                                >
                                  download
                                </span>
                              </button>
                            </>
                          );

                        case "application/docx":
                        case "application/doc":
                        case "application/msword":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                        case "application/vnd.ms-word.document.macroEnabled.12":
                        case "application/vnd.ms-excel":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                        case "application/vnd.ms-excel.sheet.macroEnabled.12":
                        case "application/vnd.ms-powerpoint":
                        case "application/xlsx":
                        case "application/pptx":
                        case "application/octet-stream":
                        case "text/plain":
                        case "text/html":
                        case "application/apk":
                        case "application/zip":
                        case "application/x-zip":
                        case "application/octet-stream":
                        case "application/x-zip-compressed":
                        case "application/pdf":
                        case "application/x-pdf":
                        case "application/x-gzpdf":
                        case "applications/vnd.pdf":
                        case "application/acrobat":
                        case "application/x-google-chrome-pdf":
                        case "text/pdf":
                        case "text/x-pdf":
                        case "text/csv":
                        case "application/json":
                          return (
                            <button
                              className={Styles.btndownload1}
                              disabled={isInProgress}
                            >
                              {" "}
                              {message?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  // handleDownload()
                                  download(
                                    imageUrl?.url,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                          );
                        case "audio/mpeg":
                        case "audio/mp3":
                        case "audio/webm":
                        case "application/wav":
                        case "video/webm":
                        case "audio/wav":
                          return (
                            <audio
                              src={imageUrl?.url}
                              controls
                              style={{ width: "250px", height: "40px" }}
                            >
                              <span className={Styles.Download}>
                                Click to download
                              </span>
                            </audio>
                          );
                        case "video/mp4":
                        case "video/x-matroska":
                          return (
                            <button
                              className={Styles.btndownload1}
                              disabled={isInProgress}
                            >
                              {" "}
                              {message?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  download(
                                    imageUrl?.url,
                                    message?.messages?.files[0]?.originalname,
                                    message?.messages?.files[0].filename
                                  )
                                }
                              >
                                Click to download
                              </span>
                            </button>
                          );

                        default:
                          return (
                            <>
                              <button
                                className={Styles.btndownload1}
                                disabled={isInProgress}
                              >
                                {" "}
                                {message?.messages?.files[0]?.originalname}
                                <span
                                  className={Styles.Download}
                                  onClick={() =>
                                    download(
                                      imageUrl?.url,
                                      message?.messages?.files[0]?.originalname,
                                      message?.messages?.files[0].filename
                                    )
                                  }
                                >
                                  Click to download
                                </span>
                              </button>
                            </>
                          );
                      }
                    })()}
                  </>
                ) : (
                  <h1 className={Styles.Emptymsg}></h1>
                )}
              </div>
            )}
            <div className={Styles.messageBottom}>
              {Date.now ? (
                <Moment format="MMM DD - h:mm a">{message?.createdAt}</Moment>
              ) : (
                <Moment format="MMM DD YYYY h A">{message?.createdAt}</Moment>
              )}
              <span className={Styles.BatchGroup}>
                {/* {message?.broadcastchat ? (
                  <>
                    {`${message?.broadcastchat}`}
                    <HiSpeakerphone />
                  </>
                ) : ( */}
                {message?.messages?.code}
                {/* )} */}
              </span>

              {own ? (
                message?.tick == false ? (
                  <FcCheckmark />
                ) : (
                  <IoCheckmarkDoneSharp
                    style={{ color: " rgb(67, 160, 71)" }}
                  />
                )
              ) : (
                ""
              )}
              {backgroundColor1 === "rgb(0 0 0 / 12%)" ? (
                // forwardTrue === false
                <span
                  className={Styles.ForwardMsgBlock}
                  onClick={e => stopPropagation(e)}
                >
                  {" "}
                  <Dropdown renderToggle={renderIconButton} placement="leftEnd">
                    {item123}
                  </Dropdown>
                </span>
              ) : (
                ""
              )}
            </div>
          </main>
        </div>
      </div>
    </>
  );
};

export default StuMessage;
