
import Styles from "./_chat.module.css"

const StudentName = ({ name ,online}) => {

  return (
    <main>
      <div>
      <h2>
          {online === "online" ? (
            <>
              {" "}
              <p className={Styles.Username}>{name}</p>
              <p id={online === "online" ? Styles.online : Styles.offline}>
                Online
              </p>{" "}
            </>
          ) : (
            <>
              <p className={Styles.Username}>{name}</p>
              <p id={online === "online" ? Styles.online : Styles.offline}>
                Offline
              </p>{" "}
            </>
          )}
        </h2>
      <span>These chats are monitored by admin</span> 
      </div>
    </main>
  );
};

export default StudentName;
