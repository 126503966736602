import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import { useNavigate, useParams } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import Styles from "./_chat.module.css";

import { SocketContext } from "../../../ioContext/socketIo";
import Conversation from "./Conversation";
import Message from "./Message";
import UserNameData from "./UserNameData";
import { RotatingLines } from "react-loader-spinner";
import { AiFillDelete } from "react-icons/ai";
import { Tooltip, Whisper } from "rsuite";
import wavEncoder from "wav-encoder";
import {
  BsFillBarChartFill,
  BsFillMicFill,
  BsFillMicMuteFill,
  BsFillPlayCircleFill,
} from "react-icons/bs";
import { Modal, ButtonToolbar, Toggle, DatePicker } from "rsuite";
import FileUpload from "./FileUpload";
import { ImAttachment, ImCross, ImSpinner11 } from "react-icons/im";
import { toast } from "react-toastify";
import { FaMinus, FaPlus } from "react-icons/fa";
import { IoSendSharp } from "react-icons/io5";
import { BiPlusMedical } from "react-icons/bi";
import handleError from "../ErrorHandler";
// text editor
import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import { Box, Button, Divider, Modal as ModalMui } from "@mui/material";

const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};
const UserBroadcastComp = () => {
  let navigate = useNavigate();

  let { batchCode } = useParams();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const socket = useContext(SocketContext);
  let scrollRef = useRef();
  let [loading, setLoading] = useState(false);
  // slected batch data
  let [selctBatchStud, setSelctBatchStud] = useState([]);
  let [batchStdentData, setBatchStdentData] = useState([]);
  let [particularStudent, setParticularStudent] = useState({});

  let [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [arrivalMessage, setArrivalMessage] = useState([]);

  const [pastedImage, setPastedImage] = useState(null);
  // MODAL
  // const [isOpen, setIsOpen] = useState(false);
  let [batchName, setBatchName] = useState("");
  let [selected, setSelected] = useState(false);
  let [msgScroll, setMsgScroll] = useState(0);
  let [loadingmsg, setLoadingMsg] = useState(false);
  const iconRef = useRef(null);
  // const [isOpen, setIsOpen] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [openPoll, setOpenPoll] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClose = () => {
    // setImagePreview(null);
    setOpen(false);
  };

  const handleOpenPoll = () => {
    setOpenPoll(true);
    setAreOptionsVisible(!areOptionsVisible);
  };
  const handleClosePoll = () => {
    // setImagePreview(null);
    setOpenPoll(false);
    setPoll([
      {
        question: "",
        options: [""],
        toggleValue: false,
        toggleValue1: false,
        selectedDate: null,
      },
    ]);
    setErrors({});
  };
  const toggleOptions = () => {
    setAreOptionsVisible(!areOptionsVisible);
  };

  const [poll, setPoll] = useState([
    {
      question: "",
      options: [""],
      toggleValue: false,
      toggleValue1: false,
      selectedDate: null,
    },
  ]);
  const [errors, setErrors] = useState({});
  const handleDateChange = (index, newDate) => {
    const updatedPoll = [...poll];
    // Convert the newDate to ISO string format
    updatedPoll[index].selectedDate = newDate?.toISOString();
    setPoll(updatedPoll);
  };
  const handleQuestionChange = (pollIndex, newQuestion) => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].question = newQuestion;
    setPoll(updatedPoll);
  };

  const handleToggleChange = pollIndex => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].toggleValue = !updatedPoll[pollIndex].toggleValue;
    setPoll(updatedPoll);
  };
  const handleToggleChange1 = index => {
    const updatedPoll = [...poll];
    updatedPoll[index].toggleValue1 = !updatedPoll[index].toggleValue1;
    setPoll(updatedPoll);
  };
  const handleOptionChange = (index, ind, newOption) => {
    const updatedPoll = [...poll];
    updatedPoll[index].options[ind] = newOption;
    setPoll(updatedPoll);
  };

  const handleAddOption = pollIndex => {
    const updatedPoll = [...poll];
    updatedPoll[pollIndex].options = [...updatedPoll[pollIndex].options, ""];
    setPoll(updatedPoll);
  };

  const handleClearOption = (index, ind) => {
    const updatedPoll = [...poll];
    updatedPoll[index].options.splice(ind, 1);
    setPoll(updatedPoll);
  };
  // api
  let [permenentBlock, setPermanentBlock] = useState(false);

  const [isRecording, setIsRecording] = useState(false);
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  // const [recordingStatus, setRecordingStatus] = useState("");
  const [isPlaybackActive, setIsPlaybackActive] = useState(false);
  const [recordedAudio, setRecordedAudio] = useState(null);
  // const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [stop, setStop] = useState(false);
  const [isAudioVisible, setIsAudioVisible] = useState(false);
  const [arrivalMessageFile, setArrivalMessageFile] = useState([]);
  const [areOptionsVisible, setAreOptionsVisible] = useState(false);
  const [messageCount, setMessageCount] = useState("");

  const isFormValid = () => {
    const newErrors = {};

    for (let i = 0; i < poll?.length; i++) {
      if (!poll[i]?.question) {
        newErrors[`question_${i}`] = "Question is required";
      }
      // if (poll[i].options.some(option => !option)) {
      //   newErrors[`options_${i}`] = "Add options";
      // }
      const emptyOptions = poll[i]?.options?.filter(option => !option.trim());

      if (emptyOptions?.length > 0) {
        newErrors[`options_${i}`] = "All options must be filled";
      }
      if (poll[i]?.options?.length == 1) {
        newErrors[`options_${i}`] = "Add  atleast 2 options ";
      }
      if (!poll[i]?.selectedDate) {
        newErrors[`date_${i}`] = "Date is required";
      }
    }

    // Clear errors for fields that are now valid
    for (let i = 0; i < poll?.length; i++) {
      if (poll[i]?.question) {
        delete newErrors[`question_${i}`];
      }
      // if (!poll[i].options.some(option => !option)) {
      //   delete newErrors[`options_${i}`];
      // }
      // if (!poll[i].options.length == 1) {
      //   delete newErrors[`options_${i}`];
      // }
      if (poll[i]?.selectedDate) {
        delete newErrors[`date_${i}`];
      }
    }

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };
  const addDiv = () => {
    setPoll([
      ...poll,
      {
        question: "",
        options: [""],
        toggleValue: false,
        toggleValue1: false,
        selectedDate: null,
      },
    ]);
  };
  const deleteDiv = index => {
    const newQuestionAnswers = [...poll];
    newQuestionAnswers.splice(index, 1);
    setPoll(newQuestionAnswers);
  };

  // text Editor
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [showEditor, setShowEditor] = useState(false);
  const handleEditorChange = state => {
    setEditorState(state);
  };

  const handleAddIconClick = () => {
    setShowEditor(!showEditor); // Toggle editor visibility
  };
  // text Editor modal
  const [openEditor, setOpenEditor] = React.useState(false);

  const handleOpenEditor = () => {
    const contentState = ContentState.createFromText(newMessage);
    const newEditorState = EditorState.createWithContent(contentState);
    setEditorState(newEditorState);
    setOpenEditor(true);
    setShowEditor(!showEditor);
  };
  const handleCloseEditor = () => {
    setOpenEditor(false);
    setShowEditor(false);
    setEditorState(EditorState.createEmpty());
  };

  useEffect(() => {
    let audioStream;
    let recorder;

    const startRecording = async () => {
      try {
        audioStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
        });
        recorder = new MediaRecorder(audioStream);
        recorder.addEventListener("dataavailable", handleDataAvailable);
        recorder.start();
        setMediaRecorder(recorder);
        setIsRecording(true);
        // setRecordingStatus("Recording...");
      } catch (error) {
        alert("Error accessing microphone");
        // setStop(true)
        setIsRecording(false);

        console.error("Error accessing microphone:", error);
      }
    };

    const stopRecording = () => {
      if (mediaRecorder && mediaRecorder.state !== "inactive") {
        mediaRecorder.stop();
        mediaRecorder.removeEventListener("dataavailable", handleDataAvailable);
        setIsRecording(false);
        // setRecordingStatus("Recording stopped.");
      }
      if (audioStream) {
        audioStream.getTracks().forEach(track => track.stop());
      }
    };

    if (isRecording) {
      startRecording();
    } else {
      stopRecording();
      setRecordedChunks([]); // Clear recorded
    }

    return () => {
      stopRecording();
    };
  }, [isRecording]);

  const handleStartRecording = () => {
    setIsRecording(true);
  };

  const handleDataAvailable = event => {
    if (event.data.size > 0) {
      const audioData = event.data;
      //(event.data);
      //(audioData);
      setRecordedAudio(audioData);
      setRecordedChunks(prevChunks => [...prevChunks, event.data]);
    }
  };
  // //(recordedAudio);
  const handleStopRecording = () => {
    setStop(true);
    setIsRecording(false);
    //(recordedAudio);
    if (recordedAudio) {
      // storeAudioLocally(recordedAudio);
    }
  };
  useEffect(() => {
    if (recordedAudio) {
      storeAudioLocally(recordedAudio);
    }
  }, [recordedAudio]);
  const getAudioURL = () => {
    if (recordedChunks.length === 0) {
      console.error("No audio recorded");
      return null;
    }

    const audioBlob = new Blob(recordedChunks, { type: "audio/webm" });
    return URL.createObjectURL(audioBlob);
  };
  const handlePlayButtonClick = () => {
    setIsPlaying(true);
    setIsPlaybackActive(prevPlaybackState => !prevPlaybackState);
  };

  const audioPlayer = isPlaybackActive ? (
    <audio controls autoPlay>
      <source src={getAudioURL()} type="audio/webm" />
      Your browser does not support the audio element.
    </audio>
  ) : null;
  const storeAudioLocally = audioBlob => {
    try {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result.split(",")[1];
        localStorage.setItem("audioBlob", base64String);
        //("Audio stored locally");
      };
      reader.readAsDataURL(audioBlob);
    } catch (error) {
      console.error("Error storing audio locally:", error);
    }
  };
  const disablePastDate = date => {
    const today = new Date();
    // Set the time to midnight for comparison
    today.setHours(0, 0, 0, 0);

    return date < today;
  };
  const handleClickOutside = event => {
    if (iconRef.current && !iconRef.current.contains(event.target)) {
      setAreOptionsVisible(false);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);
  let HandleAudio = async e => {
    e.preventDefault();
    setStop(false);
    setIsPlaying(false);
    const base64String = localStorage.getItem("audioBlob");
    const blobToArrayBuffer = blob => {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsArrayBuffer(blob);
      });
    };

    const decodeAudioData = arrayBuffer => {
      return new Promise((resolve, reject) => {
        const audioContext = new (window.AudioContext ||
          window.webkitAudioContext)();
        audioContext.decodeAudioData(arrayBuffer, resolve, reject);
      });
    };
    if (base64String) {
      const arrayBuffer = await blobToArrayBuffer(recordedAudio);
      const audioBuffer = await decodeAudioData(arrayBuffer);

      const wavArrayBuffer = await wavEncoder.encode({
        sampleRate: audioBuffer.sampleRate,
        channelData: [audioBuffer.getChannelData(0)], // Assuming mono audio
      });

      const wavBlob = new Blob([wavArrayBuffer], { type: "audio/wav" });
      const wavFile = new File(
        [wavBlob],
        `audio${Math.round(Math.random() * 100)}.wav`,
        {
          type: "audio/wav",
        }
      );

      if (wavFile !== "") {
        try {
          let payload = {
            wavFile,
            sender: user._id,
          };
          //(payload);
          const options = {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
              "Content-Type": "multipart/form-data",
            },
          };
          const res = await AxiosInstance.post(
            "/chat/newmsgfiles",
            payload,
            options
          );
          let filesData = res.data.files;
          // let aaa = filesData.map((x) => {
          //(filesData);

          socket.emit(
            "broadcastchat",
            {
              username: user?.username,
              files: filesData,
              senderId: user?._id,
              role: user?.role1,
              broadcastchat: batchCode,
              batchId: selctBatchStud?._id,
              device: "web",
              poll: null,
            },
            function callback(msg) {
              //(msg);
              //(msg)
              setMessages([...messages, msg]);
              setIsPlaybackActive(false);
              setIsAudioVisible(false); // Hide the audio tag
              setRecordedChunks([]); // Clear the recorded audio chunks
              //("end");
              setIsPlaying(false);
            }
          );
        } catch (error) {
          if (error?.response?.status === 403) {
            handleError(navigate, socket);
          }
          //(error);
        }
      } else {
        //("first");
      }
    }
    return null;
  };
  let handleDeleteButtonClick = () => {
    localStorage.removeItem("audioBlob");
    setStop(false);
    setIsPlaying(false);
    setRecordedAudio(null);
    setIsPlaybackActive(false);
    setIsAudioVisible(false); // Hide the audio tag
    setRecordedChunks([]); // Clear the recorded audio chunks
    setIsPlaying(false);
  };

  useEffect(() => {
    socket.on("blocked", data => {
      //(data)
      setPermanentBlock(true);
    });
  }, []);
  if (permenentBlock === true) {
    toast.error("Contact Administrator", { position: "top-right" });
    function greeting() {
      socket.disconnect();
      navigate("/");
      // window.location.reload();
    }
    setTimeout(greeting, 3000);
  }

  useEffect(() => {
    async function fetchlist() {
      AxiosInstance.get(`users/batches/changes/${batchCode}`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(data => {
          //(data);
          let payload = data?.data?.batchData;
          let studentData = data?.data?.usersdata;
          let batchname = data?.data?.batchData?.batchCode;
          // //(batchname)
          setBatchName(batchname);
          setSelctBatchStud(payload);
          setBatchStdentData(studentData);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchlist();
  }, []);
  useEffect(() => {
    async function demo() {
      if (selctBatchStud?._id != undefined) {
        setLoadingMsg(true);
        const res = await AxiosInstance.post(
          `/chat/msg/new/allbrcamsg?scroll=${msgScroll}`,
          {
            senderId: user._id,
            receiversId: selctBatchStud?._id,
          },
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );

        res?.data?.reverse();

        setMessages(res?.data);
        setMessageCount(res?.data?.length);
        setLoadingMsg(false);
        if (res?.data?.length > 0 && msgScroll > 0) {
          let mess = res?.data;
          setMessages([...mess, ...messages]);
          setLoadingMsg(false);
        } else if (res?.data?.length > 0 && msgScroll <= 0) {
          setLoadingMsg(false);
        }
      }
    }
    demo();
  }, [selctBatchStud?._id, msgScroll]);
  let HandleLoadMessages = () => {
    setMsgScroll(msgScroll + 20);
  };

  useEffect(() => {
    socket.on("getbroadcastchat", data => {
      if (data.sender !== user._id) {
        if (data.brodcastchat) {
          setArrivalMessage(data);
        } else {
          setArrivalMessage(data);
        }
      }
    });
  }, []);
  useEffect(() => {
    setMessages(prev => [...prev, arrivalMessage]);
  }, [arrivalMessage]);

  useEffect(() => {
    if (arrivalMessageFile.length > 0) {
      setMessages(prevMessages => prevMessages.concat(arrivalMessageFile));
      setArrivalMessageFile([]);
    }
  }, [arrivalMessageFile.length > 0]);
  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [messages]);

  const handleSubmit = () => {
    setErrors({});

    // You can implement your logic to send the poll data here

    if (!isFormValid()) {
      const newErrors = {};

      for (let i = 0; i < poll?.length; i++) {
        if (!poll[i]?.question) {
          newErrors[`question_${i}`] = "Question is required";
        }
        if (poll[i]?.options?.some(option => !option)) {
          newErrors[`options_${i}`] = "Add options";
        }
        if (poll[i]?.options?.length == 1) {
          newErrors[`options_${i}`] = "Add  atleast 2 options ";
        }
        if (!poll[i]?.selectedDate) {
          newErrors[`date_${i}`] = "Date is required";
        }
      }

      setErrors(newErrors);
    } else {
      const currentDate = new Date();
      const currentDateTime = currentDate.toISOString();

      let payload = poll.map((ele, ind) => {
        return {
          pollcreaterid: user?._id,
          poll_question: ele?.question,
          options: ele?.options,
          startTime: currentDateTime,
          endTime: ele?.selectedDate,
          stu_view_vote: ele?.toggleValue1,
          vote_type: ele?.toggleValue,
        };
      });
      async function students() {
        await AxiosInstance.post(`/employee/users_pollcreate`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            console?.log(x);
            let data = x?.data;
            let poll1 = data?.map((ele, ind) => {
              const date = new Date(ele?.endTime);

              // Extract date and time components
              const year = date.getFullYear();
              const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month because it's 0-based
              const day = String(date.getDate()).padStart(2, "0");
              const hours = String(date.getHours()).padStart(2, "0");
              const minutes = String(date.getMinutes()).padStart(2, "0");

              // Create the desired format
              const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}`;
              let time = formattedDate;

              return {
                _id: ele._id,
                poll_question: ele.poll_question,
                endTime: ele.endTime,
                stu_view_vote: ele.stu_view_vote,
                user_id: user._id,
                time: time,
              };
            });
            let aa = poll1?.map(x => {
              return x;
            });
            let aaa = poll1.map(x => {
              return new Promise((resolve, reject) => {
                socket?.emit(
                  "broadcastchat",
                  {
                    username: user?.username,
                    senderId: user._id,
                    text: x.poll_question,
                    role: user?.role1,
                    broadcastchat: selctBatchStud?.batchCode,
                    batchId: selctBatchStud?._id,
                    code: selctBatchStud?.batchCode,
                    type: "batch",
                    device: "web",
                    poll: x,
                  },

                  function callback(msg) {
                    setLoading(false);
                    resolve(msg);
                    msg.tick = false;
                  }
                );
              });
            });
            Promise.all(aaa)
              .then(message => {
                message.forEach(msg => {
                  setMessages(prevMessages => prevMessages.concat(msg));
                });
              })
              .catch(error => {
                console.error("Error:", error);
              });
          })
          .catch(x => {
            console?.log(x);
            handleClosePoll();
            if (x?.response?.status === 403) {
              handleError(navigate, socket);
            }
          })
          .finally(() => {
            handleClosePoll();
          });
      }
      students();
    }
  };
  let handelSubmit = async e => {
    e?.preventDefault();
    //(selctBatchStud);
    if (newMessage.trim() !== "") {
      try {
        setLoading(true);
        socket.emit(
          "broadcastchat",
          {
            username: user?.username,
            senderId: user?._id,
            role: user?.role1,
            text: newMessage,
            broadcastchat: selctBatchStud?.batchCode,
            batchId: selctBatchStud?._id,
            code: selctBatchStud?.batchCode,
            device: "web",
            poll: null,
          },
          function callback(msg) {
            setMessages([...messages, msg]);
            setLoading(false);
          }
        );
        setNewMessage("");
      } catch (error) {}
    } else {
      //("type message");
    }
  };
  let handleEditorSend = e => {
    const contentState = editorState.getCurrentContent();
    // Check if the content is empty
    const isEmpty =
      !contentState.hasText() || contentState.getPlainText().trim() === "";
    if (!isEmpty) {
      const contentState = editorState.getCurrentContent();

      const rawContentState = convertToRaw(contentState);

      let content = draftToHtml(rawContentState);
      setNewMessage(content);
      socket?.emit(
        "broadcastchat",
        {
          username: user?.username,
          senderId: user?._id,
          role: user?.role1,
          text: content,
          html: true,
          broadcastchat: selctBatchStud?.batchCode,
          batchId: selctBatchStud?._id,
          code: selctBatchStud?.batchCode,
          device: "web",
          poll: null,
        },
        function callback(msg) {
          if (msg.read === false) {
            msg.tick = false;
            setMessages([...messages, msg]);
            setLoading(false);
          } else {
            setMessages([...messages, msg]);
            setLoading(false);
          }
        }
      );
      setEditorState(EditorState.createEmpty());
      setOpenEditor(false);
      setShowEditor(false);
    }
    setNewMessage("");
  };
  let handelSubmitButton = e => {
    handelSubmit();
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      handelSubmit();
    }
  };
  const handlePaste = e => {
    e.preventDefault(); // Prevent the default paste behavior

    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedText = clipboardData.getData("text"); // Get the text from the clipboard
    if (pastedText) {
      setNewMessage(pastedText);
    }

    // Handle image paste
    const items = clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      if (item.type.indexOf("image") !== -1) {
        setOpen(true);
        const file = item.getAsFile();
        const imageUrl = URL.createObjectURL(file);
        console.log(imageUrl);
        setPastedImage(imageUrl); // Store pasted image
        break;
      }
    }
  };

  let forwardmsg = [];

  let selectedmsg = data => {
    // //(e)
    // forwardmsg.push(data);
    let aaa = forwardmsg?.map(x => x.index);
    const index = data.index;
    //(index);
    //
    //(aaa.includes(index));

    if (aaa.includes(index)) {
      //(index, "cut ");
      //("no data");
      for (let i = 0; i < forwardmsg.length; i++) {
        if (forwardmsg[i].index === index) {
          forwardmsg.splice(i, 1);
          i--; // decrement i to account for removed element
        }
      }
    } else {
      //("else");
      if (forwardmsg.length == 5 || forwardmsg.length > 5) {
        //("5 limit");
        alert("you can only send 5 messages at a time");
      } else {
        //("else");
        forwardmsg.push(data);
      }
      // forwardmsg.push(data);
      // //(forwardmsg.length)
      // if(forwardmsg.length == 5){
      //   alert("you can only send 5 messages at a time");
      //   //("you can only send 5 messages at a time")
      // }
      // data found, remove it from the array
    }
    //(forwardmsg);
  };
  return (
    <>
      {/* <Modal
        backdrop="static"
        // keyboard={false}
        role="alertdialog"
        open={openEditor}
        onClose={handleCloseEditor}
      >
        <div className={Styles.HeaderPoll}>
          <span onClick={handleCloseEditor}>
            <ImCross />
          </span>
          <h4>Text Editor</h4>
        </div>

        <Modal.Body>
          <Editor
            editorState={editorState}
            wrapperClassName="chatEditorWrapper"
            editorClassName="chatEditor"
            onEditorStateChange={handleEditorChange}
            placeholder="Write something..."
            toolbar={{
              options: [
                "inline",
                // "blockType",
                // "fontSize",
                // "list",
                // "textAlign",
                "colorPicker",
                // "link",
                // "embedded",
                "emoji",
                // "image",
                // "remove",
                "history",
              ],
              inline: {
                options: ["bold", "italic", "underline", "strikethrough"],
              },
              colorPicker: {
                colors: [
                  "#000000",
                  "#ffffff",
                  "#ff0000",
                  "#00ff00",
                  "#0000ff",
                  "#FFFF00",
                ],
              },
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          {" "}
          <div
            style={{ display: "flex", gap: "1%", justifyContent: "flex-end" }}
          >
            <Button
              type="button"
              variant="contained"
              color="error"
              onClick={handleCloseEditor}
            >
              cancel
            </Button>
            <Button
              type="button"
              variant="contained"
              color="warning"
              onClick={handleEditorSend}
            >
              Send
            </Button>
            <button
              type="submit"
              style={{ display: "none" }}
              className="chatSubmitButton"
              onClick={handleEditorSend}
            >
              Send
            </button>
          </div>
        </Modal.Footer>
      </Modal> */}
      <ModalMui open={openEditor}>
        <Box sx={styleModal1} position="relative">
          <h4 style={{ textAlign: "center", color: "#f16f1d" }}>Text Editor</h4>
          <Divider />
          <br />
          <button
            style={{
              border: "none",
              background: "none",
              position: "absolute",
              right: "5px",
              top: "5px",
            }}
            onClick={handleCloseEditor}
          >
            <svg width={18.54} height={20} viewBox="0 0 1216 1312">
              <path
                fill="crimson"
                d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
              ></path>
            </svg>
          </button>
          <Box id="modal-description" sx={{ mt: 2 }}>
            <Editor
              editorState={editorState}
              wrapperClassName="chatEditorWrapper"
              editorClassName="chatEditor"
              onEditorStateChange={handleEditorChange}
              placeholder="Write something..."
              toolbar={{
                options: ["inline", "colorPicker", "emoji", "history"],
                inline: {
                  options: ["bold", "italic", "underline", "strikethrough"],
                },
                colorPicker: {
                  colors: [
                    "#000000",
                    "#ffffff",
                    "#ff0000",
                    "#00ff00",
                    "#0000ff",
                    "#FFFF00",
                  ],
                },
              }}
            />
          </Box>

          <Box
            sx={{ display: "flex", gap: 1, justifyContent: "flex-end", mt: 2 }}
          >
            <Button
              variant="contained"
              color="error"
              onClick={handleCloseEditor}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="warning"
              onClick={handleEditorSend}
            >
              Send
            </Button>
          </Box>
        </Box>
      </ModalMui>
      <section id={Styles.chatBoxSection}>
        <article>
          <aside className={Styles.chatMenu}>
            <Conversation
              batchStdent={batchStdentData}
              setParticularStudent={setParticularStudent}
              trainername={selctBatchStud}
            />
          </aside>
          <aside
            className={Styles.chatBox}
            style={{
              backgroundImage: `url('https://img.freepik.com/free-vector/white-minimal-hexagons-background_79603-1452.jpg?w=1380&t=st=1709614450~exp=1709615050~hmac=668ef59441d2abb437b3ef108bd9723052bb0437bf40825025b54f57b58fd037')`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
            }}
          >
            <div className={Styles.chatBoxWrapper}>
              {user._id ? (
                <>
                  <div className={Styles.chatBoxTop}>
                    <UserNameData
                      name={batchStdentData}
                      batchName={batchName}
                    />
                    <div className={Styles.chatBoxStore}>
                      {messageCount > 0 && messageCount == 20 ? (
                        <div className={Styles.SpinnerBlock}>
                          {" "}
                          <button
                            onClick={HandleLoadMessages}
                            className={Styles.Spinner}
                          >
                            <ImSpinner11 />
                          </button>
                        </div>
                      ) : (
                        ""
                      )}
                      {messages?.length > 0 ? (
                        messages?.map((m, ind) => (
                          <Fragment key={ind}>
                            <Message
                              message={m}
                              own={m.sender === user._id}
                              index={ind}
                              selectedmsg={selectedmsg}
                              forwardmsg={forwardmsg}
                              setSelected={setSelected}
                              selected={selected}
                              loadingmsg={loadingmsg}
                              // selctBatchStud={selctBatchStud}
                            />
                          </Fragment>
                        ))
                      ) : (
                        <span className={Styles.noConversation}>
                          <p style={{ color: "#f16f1d" }}>No Messages</p>
                        </span>
                      )}
                    </div>
                  </div>
                  <div ref={scrollRef} style={{ visibility: "hidden" }}>
                    top
                  </div>
                  <form onSubmit={handelSubmit}>
                    <div className={Styles.chatBoxBottom}>
                      <input
                        className="chatMessageInput"
                        placeholder="write something..."
                        onChange={e => {
                          setNewMessage(e.target.value);
                        }}
                        value={newMessage}
                        onKeyPress={handleKeyPress}
                        onPaste={handlePaste}
                      />
                      <aside
                        style={{
                          position: "relative",
                          margin: "1%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                        // className={Styles.UploadfileContainer}
                      >
                        <div onClick={handleOpenEditor}>
                          <span>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 32 32"
                            >
                              <path
                                fill="#475569"
                                d="M9.5 2c.502 0 .956.3 1.152.763l5.072 11.989l-1.908 1.907L12.48 13.5H6.52l-1.37 3.237a1.25 1.25 0 0 1-2.302-.974l5.5-13A1.25 1.25 0 0 1 9.5 2m1.922 9L9.5 6.458L7.58 11zm9.838.328a4.536 4.536 0 0 1 6.414 6.415l-9.418 9.417a4 4 0 0 1-1.73 1.018l-6.253 1.787a1 1 0 0 1-1.236-1.236l1.785-6.251a4 4 0 0 1 1.018-1.73z"
                              ></path>
                            </svg>
                          </span>
                        </div>
                      </aside>

                      <aside
                        style={{ position: "relative" }}
                        className={Styles.UploadfileContainer}
                      >
                        <>
                          {isRecording ? (
                            ""
                          ) : stop ? (
                            ""
                          ) : (
                            <>
                              <ButtonToolbar ref={iconRef}>
                                <FaPlus onClick={toggleOptions} />
                              </ButtonToolbar>
                              {areOptionsVisible && (
                                <div className={Styles.ropdowncontent}>
                                  {/* Add your dropdown content here */}
                                  <ul>
                                    <li
                                      className={Styles.FileShare}
                                      onClick={handleOpen}
                                    >
                                      <ImAttachment></ImAttachment>
                                      files
                                    </li>
                                    <li
                                      className={Styles.FileShare}
                                      onClick={handleOpenPoll}
                                    >
                                      <BsFillBarChartFill></BsFillBarChartFill>{" "}
                                      Poll
                                    </li>
                                    {/* <li
                                      className={Styles.FileShare}
                                      onClick={handleOpenEditor}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={20}
                                        height={20}
                                        viewBox="0 0 24 24"
                                      >
                                        <path
                                          fill="#555"
                                          d="M9.243 18.997H21v2H3v-4.243l9.9-9.9l4.242 4.243zm5.07-13.557l2.122-2.121a1 1 0 0 1 1.414 0l2.829 2.828a1 1 0 0 1 0 1.415l-2.122 2.121z"
                                        ></path>
                                      </svg>
                                      Text editor
                                    </li> */}
                                  </ul>
                                </div>
                              )}
                              <Modal
                                backdrop="static"
                                keyboard={false}
                                open={openPoll}
                                onClose={handleClosePoll}
                              >
                                {/* <Modal.Header> */}
                                {/* <Modal.Title> */}
                                <div className={Styles.HeaderPoll}>
                                  <span onClick={handleClosePoll}>
                                    <ImCross />
                                  </span>
                                  <h4>Create Poll</h4>
                                </div>

                                <Modal.Body>
                                  {poll?.map((qa, index) => (
                                    <div
                                      className={Styles.modalcontent}
                                      key={index}
                                    >
                                      <div>
                                        <div className={Styles.QuestionBlock}>
                                          <h5>Question:</h5>

                                          <span
                                            className={Styles.deleteicon}
                                            onClick={() => deleteDiv(index)}
                                          >
                                            <FaMinus />
                                          </span>
                                        </div>
                                        <input
                                          className={Styles.InputQue}
                                          type="text"
                                          id="question"
                                          placeholder="Add question"
                                          value={qa?.question}
                                          onChange={e => {
                                            handleQuestionChange(
                                              index,
                                              e.target.value
                                            );
                                            const updatedErrors = {
                                              ...errors,
                                            };
                                            delete updatedErrors[
                                              `question_${index}`
                                            ];
                                            setErrors(updatedErrors);
                                          }}
                                        />
                                        {errors[`question_${index}`] && (
                                          <p className={Styles.errormessage}>
                                            {errors[`question_${index}`]}
                                          </p>
                                        )}
                                      </div>
                                      <h5>Options:</h5>
                                      {qa?.options?.map((option, ind) => (
                                        <div
                                          key={ind}
                                          className={Styles.OptionBlock}
                                        >
                                          <div className={Styles.InputOption}>
                                            {" "}
                                            <input
                                              className={Styles.InputQue}
                                              type="text"
                                              placeholder="Add"
                                              value={option}
                                              onChange={e => {
                                                handleOptionChange(
                                                  index,
                                                  ind,
                                                  e.target.value
                                                );
                                                const updatedErrors = {
                                                  ...errors,
                                                };
                                                delete updatedErrors[
                                                  `options_${index}`
                                                ];
                                                setErrors(updatedErrors);
                                              }}
                                            />
                                            <button
                                              className={Styles.ClearOption}
                                              onClick={() =>
                                                handleClearOption(index, ind)
                                              }
                                            >
                                              <ImCross />
                                            </button>
                                          </div>
                                          {errors[`options_${index}`] && (
                                            <p className={Styles.errormessage}>
                                              {errors[`options_${index}`]}
                                            </p>
                                          )}
                                        </div>
                                      ))}
                                      <button
                                        onClick={() => handleAddOption(index)}
                                        className={Styles.AddOptions}
                                      >
                                        <FaPlus />
                                      </button>
                                      <div className={Styles.datePicker}>
                                        {" "}
                                        <DatePicker
                                          format="yyyy-MM-dd HH:mm"
                                          calendarDefaultDate={new Date()}
                                          disabledDate={disablePastDate}
                                          onChange={date => {
                                            handleDateChange(index, date);
                                            const updatedErrors = {
                                              ...errors,
                                            };
                                            delete updatedErrors[
                                              `date_${index}`
                                            ];
                                            setErrors(updatedErrors);
                                          }}
                                          ranges={[
                                            {
                                              label: "Now",
                                              value: new Date(),
                                            },
                                          ]}
                                          style={{ width: 260 }}
                                        />
                                        {errors[`date_${index}`] && (
                                          <p className={Styles.errormessage}>
                                            {errors[`date_${index}`]}
                                          </p>
                                        )}
                                      </div>
                                      <div className={Styles.Allow}>
                                        <h6>Allow multiple answers</h6>{" "}
                                        <span>
                                          {" "}
                                          <Toggle
                                            checked={qa?.toggleValue}
                                            onChange={() =>
                                              handleToggleChange(index)
                                            }
                                          />
                                        </span>
                                      </div>
                                      <div className={Styles.Allow}>
                                        <h6>Allow to View votes</h6>{" "}
                                        <span>
                                          {" "}
                                          <Toggle
                                            checked={qa?.toggleValue1}
                                            onChange={() =>
                                              handleToggleChange1(index)
                                            }
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  ))}
                                  <button
                                    onClick={addDiv}
                                    className={Styles.AddPoll}
                                  >
                                    <BiPlusMedical />
                                  </button>
                                </Modal.Body>
                                <Modal.Footer>
                                  {" "}
                                  <button
                                    onClick={handleSubmit}
                                    className={Styles.SubmitPoll}
                                  >
                                    <IoSendSharp />
                                  </button>
                                </Modal.Footer>
                              </Modal>
                            </>
                          )}

                          <Modal
                            keyboard={false}
                            open={open}
                            onClose={handleClose}
                          >
                            <Modal.Header>
                              <Modal.Title>Send File</Modal.Title>
                            </Modal.Header>

                            <Modal.Body>
                              <FileUpload
                                PStudent={particularStudent}
                                // conversations={conversations}
                                setMessages={setMessages}
                                messages={messages}
                                setOpen={setOpen}
                                batchData={selctBatchStud}
                                setArrivalMessage={setArrivalMessage}
                                setArrivalMessageFile={setArrivalMessageFile}
                                pastedImage={pastedImage}
                                setPastedImage={setPastedImage}
                              />
                            </Modal.Body>
                          </Modal>
                        </>
                      </aside>
                      <aside className={Styles.AudioBlock}>
                        <div>
                          {!stop && !isRecording && !isPlaying && (
                            <Whisper
                              followCursor
                              speaker={<Tooltip>{"Start"}</Tooltip>}
                            >
                              <main
                                className={Styles.StartAudio}
                                onClick={handleStartRecording}
                                disabled={isRecording}
                              >
                                <span>
                                  {/* start */}
                                  <BsFillMicFill />
                                </span>
                              </main>
                            </Whisper>
                          )}
                          <div
                            className={`${Styles.recordingAnimation} ${
                              isRecording ? Styles.active : ""
                            }`}
                          ></div>
                          {isRecording && (
                            <Whisper
                              followCursor
                              speaker={<Tooltip>{"Stop"}</Tooltip>}
                            >
                              <main
                                className={Styles.StopAudio}
                                onClick={handleStopRecording}
                                disabled={!isRecording}
                              >
                                <span>
                                  {/* stop */}
                                  <BsFillMicMuteFill />
                                </span>
                              </main>
                            </Whisper>
                          )}
                          {stop && (
                            <Whisper
                              followCursor
                              speaker={<Tooltip>{"Play"}</Tooltip>}
                            >
                              <main
                                className={Styles.PlayAudio}
                                onClick={handlePlayButtonClick}
                                disabled={isRecording}
                              >
                                <span>
                                  {/* play */}
                                  <BsFillPlayCircleFill />
                                </span>
                              </main>
                            </Whisper>
                          )}
                          {/* {stop &&<p>{recordingStatus}</p>} */}
                          {audioPlayer}
                        </div>
                      </aside>
                      {stop && (
                        <div className={Styles.SendAudioBlock}>
                          <Whisper
                            followCursor
                            speaker={<Tooltip>{"Delete"}</Tooltip>}
                          >
                            <span
                              onClick={handleDeleteButtonClick}
                              className={Styles.DeleteAudio}
                            >
                              <AiFillDelete />
                            </span>
                          </Whisper>
                          <span
                            onClick={HandleAudio}
                            className={Styles.SendAudio}
                          >
                            send
                            {/* <AiOutlineSend /> */}
                          </span>
                        </div>
                      )}
                      {!stop && (
                        <div>
                          {loading || isRecording ? (
                            <button
                              className="chatSubmitButton"
                              disabled
                              onClick={handelSubmitButton}
                            >
                              <div className={Styles.loader}>
                                <RotatingLines
                                  strokeColor="#f16f1d"
                                  strokeWidth="5"
                                  animationDuration="0.75"
                                  width="20"
                                  visible={true}
                                  // z-index={111}
                                />
                              </div>
                            </button>
                          ) : (
                            <>
                              <button
                                className="chatSubmitButton"
                                onClick={handelSubmitButton}
                              >
                                Send
                              </button>
                              <button
                                type="submit"
                                style={{ display: "none" }}
                                className="chatSubmitButton"
                                onClick={handelSubmitButton}
                              >
                                Send
                              </button>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </form>
                </>
              ) : (
                <span className={Styles.noConversationText}>
                  <p>Open a conversation to start a chat.</p>
                </span>
              )}
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};

export default UserBroadcastComp;

// export default UserBroadcastComp;
