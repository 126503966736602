export const getRandomColor = () => {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const getInitials = name => {
  let initials;
  const nameSplit = name && name?.split(" ");
  const nameLength = nameSplit && nameSplit?.length;
  if (nameLength > 1) {
    initials =
      nameSplit[0].substring(0, 1) + nameSplit[nameLength - 1].substring(0, 1);
  } else if (nameLength === 1) {
    initials = nameSplit[0].substring(0, 1);
  } else return;
  return initials.toUpperCase();
};
export const createImageFromInitials = (size, name, color) => {
  name = getInitials(name);
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d");
  canvas.width = canvas.height = size;

  context.fillStyle = "#ffffff";
  context.fillRect(0, 0, size, size);

  context.fillStyle = `${color}50`;
  context.fillRect(0, 0, size, size);

  context.fillStyle = color;
  context.textBaseline = "middle";
  context.textAlign = "center";
  context.fontWeight = "bold";
  context.font = `${size / 1.5}px Roboto`;
  context.fillText(name, size / 2, size / 1.8);
  return canvas.toDataURL();
};
