import React, { useContext, useState } from "react";
import Styles from "./_chat.module.css";
import { SocketContext } from "../../../ioContext/socketIo";
import { useEffect } from "react";
import dateFormat from "dateformat";
import { ImCross } from "react-icons/im";
import { FiSearch } from "react-icons/fi";
import { FaBell } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import Moment from "react-moment";
import { RotatingLines } from "react-loader-spinner";
const Conversation = ({
  batchStdentData,
  setParticularStudent,
  messages,
  SetNumber,
  number,
  setBatchStdentData,
  particularStudent,
  messageread,
  setStop,
  setIsPlaying,
  setRecordedAudio,
  setIsPlaybackActive,
  setIsAudioVisible,
  setRecordedChunks,
  search,
  setSearch,
  HandleSearch,
  HandleClear,
  loadingStudents,
  setIsRecording,
  setSort,
  sort,
  setMsgScroll,
  stopscroll,
  setMessages,
  setSelectedMessages,
  setReply,
  setForward,
  handleSearchConvo,
  handleReset,
  handleLoadMore,
  setCountedData,
  countedData,
}) => {
  const socket = useContext(SocketContext);
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [notification, setNotification] = useState({});
  let [batchStudentList, setBatchStudentList] = useState([]);
  let [loadmore, SetLoadMore] = useState(false);
  let [notificationCount, setNotificationCount] = useState(0);
  // const [dataaaaa, setDataaaaa] = useState(batchStdentData);
  let [searchTrue, setSearchTrue] = useState(false);

  useEffect(() => {
    socket.on("notification", data => {
      setNotification(data);
    });
  }, []);

  useEffect(() => {
    if (batchStdentData?.length > 0 && batchStdentData !== undefined) {
      // setDataaaaa(batchStdentData);
      setBatchStdentData(batchStdentData);
    }
  }, [batchStdentData]);

  batchStudentList = batchStdentData;

  useEffect(() => {
    if (particularStudent?.notificationCount !== 0) {
      // if (batchName) {
      socket.emit("msgRead", {
        senderId: user?._id,
        role: user?.role1,
        receiverId: particularStudent?.studentId,
        seen: true,
        // code: batchName,
        count: notificationCount,
        // type: "batch",
        device: "web",
      });
      setNotificationCount(0);
    } else {
    }
  }, [particularStudent?.studentId, socket]);

  useEffect(() => {
    let data1 = batchStudentList?.map(y => {
      // //(y);
      return y._id === notification?.msg?.sender
        ? {
            ...y,
            createdAt: (y.createdAt = notification?.msg?.createdAt),
            lastmsg: y.lastmsg
              ? {
                  ...y.lastmsg,
                  usersNotification: (y.lastmsg.usersNotification =
                    Number(y.lastmsg.usersNotification) + 1),
                  msg: (y.lastmsg.msg = notification.msg),
                }
              : (y.lastmsg = {
                  // Conversation: notification?.msg?.receiver,
                  msg: notification?.msg,
                  usersNotification: Number(1),
                  createdAt: notification?.msg?.createdAt,
                }),
          }
        : { ...y };
    });
    setBatchStudentList(data1);
    // }
  }, [notification]);

  useEffect(() => {
    let data1 = batchStudentList?.map(x => {
      return x._id === messages?.slice(-1)[0]?.receiver
        ? {
            ...x,
            createdAt: (x.createdAt = messages?.slice(-1)[0]?.createdAt),
            lastmsg: x.lastmsg
              ? {
                  ...x.lastmsg,
                  msg: (x.lastmsg.msg = messages?.slice(-1)[0]),
                }
              : (x.lastmsg = [
                  {
                    studentid: messages?.slice(-1)[0]?.sender,
                    msg: messages?.slice(-1)[0],
                    createdAt: messages?.slice(-1)[0]?.createdAt,
                  },
                ]),
          }
        : { ...x };
    });
    setBatchStudentList(data1);
    // }
  }, [messages]);

  let handleNotification = sid => {
    let data1 = batchStudentList?.map(x => {
      if (x?.lastmsg?.usersNotification > 0) {
        setNotificationCount(x?.lastmsg?.usersNotification);
      }

      return x._id === sid
        ? x.lastmsg?.usersNotification > 0
          ? {
              ...x,

              lastmsg: [
                {
                  ...x.lastmsg,
                  usersNotification: (x.lastmsg.usersNotification = 0),
                },
              ],
            }
          : ""
        : x;
    });

    setBatchStudentList(data1);
  };

  let d = Date.now();
  let today = dateFormat(d, "mmm d, yyyy");

  let Loadmore = () => {
    if (search.length <= 0) {
      SetNumber(number + 50);
      SetLoadMore(true);
      const c = countedData - 50;
      setCountedData(prev => {
        return prev - 50;
      });

      handleLoadMore(number + 50);
    }
  };

  let handleSearch = e => {
  
    if (e.target.value.length > 0) {
      let data = e.target.value.trim();
      setSearch(data);
    }
  };

  // let HandleClickSearch = () => {
  //   if (search.length > 0) {
  //     HandleSearch(search);
  //     setSearchTrue(true);
  //   }
  // };
  let HandleClearSearch = () => {
    handleReset();
    setSearchTrue(false);
    // setBatchStdentData([]);
  };
  const handleKeyPress = event => {
    if (event.key === "Enter") {
      if (search.length > 0) {
        handleSearchConvo(search);
        setSearchTrue(true);
      }
    }
  };
  const handleBackspaceUser = event => {
    if (event.key === "Backspace" && search.length === 1) {
      handleReset();
      setSearchTrue(false);
      // setBatchStdentData([]);
    }
  };
  let HandleNotificationSort = () => {
    if (sort === true) {
      SetNumber(0);

      setSort(false);
    } else {
      SetNumber(0);
      setSort(true);
    }
  };

  return (
    <>
      <div>
        <div className={Styles.chatMenuWrapper}>
          <div className={Styles.SearchBlock}>
            <span className={Styles.SearchText}>
              <input
                name="search"
                type="text"
                value={search}
                placeholder="Search student"
                onChange={e => handleSearch(e)}
                onKeyPress={handleKeyPress}
                onKeyDown={handleBackspaceUser}
              />
            </span>
            &nbsp;
            {searchTrue === false ? (
              <button
                className={Styles.searchIcon}
                onClick={() => {
                  handleSearchConvo();
                  setSearchTrue(true);
                }}
              >
                <FiSearch />
              </button>
            ) : (
              <button
                className={Styles.searchCancel}
                onClick={HandleClearSearch}
              >
                <ImCross />
              </button>
            )}
            <div className={Styles.NotySort} onClick={HandleNotificationSort}>
              <FaBell />
            </div>
          </div>

          {loadingStudents ? (
            <div className={Styles.loader}>
              <RotatingLines
                strokeColor="#f16f1d"
                strokeWidth="5"
                animationDuration="0.75"
                width="96"
                visible={true}
              />
            </div>
          ) : (
            <div
              className={Styles.ConversationBlock}
              id="scrollableDiv"
              style={{ height: 550, overflow: "auto" }}
            >
              <>
                <InfiniteScroll
                  dataLength={batchStudentList?.length}
                  next={Loadmore}
                  // hasMore={batchStudentList?.length < 0}
                  hasMore={stopscroll}
                  // loader={<h4>Loading...</h4>}
                  scrollableTarget="scrollableDiv"
                >
                  {/* {batchStudentList?.length <= 0 ? (
                  <div className={Styles.ResentChat}>NoRecent Chats</div>
                ) : (
                  ""
                )} */}
                  {batchStudentList?.length === 0 ? (
                    <p style={{ textAlign: "center" }}>No Data Found</p>
                  ) : (
                    batchStudentList
                      .sort((a, b) => {
                        return !sort
                          ? new Date(b?.lastmsg?.msg?.createdAt).getTime() -
                              new Date(a?.lastmsg?.msg?.createdAt).getTime()
                          : 0; // You can modify this condition based on your sorting needs
                      })
                      .map((students, ind) => {
                        return (
                          <div className={Styles.BlockConersation} key={ind}>
                            <div
                              className={Styles.listUser}
                              style={{
                                backgroundImage: `url('https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRGzAJznGUJCegtKaMXwIvYMmcuBt14EwrlXXjudhc3DIfPnFFa')`,
                                backgroundSize: "cover",
                                backgroundRepeat: "no-repeat",
                                border: "0.3px ridge #999",
                              }}
                              key={ind}
                              onClick={() => {
                                setParticularStudent({
                                  studentId: students?._id,
                                  studentName: students?.username,
                                  notification: true,
                                  notiCount: messageread?.studentNotification,
                                  usernoti: notificationCount,
                                });
                                handleNotification(students?._id);
                                setStop(false);
                                setIsPlaying(false);
                                setRecordedAudio(null);
                                setIsPlaybackActive(false);
                                setIsAudioVisible(false); // Hide the audio tag
                                setRecordedChunks([]); // Clear the recorded audio chunks
                                setIsPlaying(false);
                                setIsRecording(false);
                                setSelectedMessages([]);
                                setReply(false);
                                setForward(false);
                                if (
                                  particularStudent?.studentId !==
                                  particularStudent?.studentId
                                ) {
                                  setMessages([]);
                                }
                                setMsgScroll(0);
                              }}
                            >
                              <div className={Styles.conversation}>
                                <span>
                                  {students?.username} -{" "}
                                  {students?.number
                                    ?.toString()
                                    ?.substring(6, 10)}
                                </span>
                                <div
                                  className={
                                    students?.lastmsg?.usersNotification
                                      ? students?.lastmsg?.usersNotification !==
                                        0
                                        ? `${Styles.notify}`
                                        : `${Styles.nonotify}`
                                      : ""
                                  }
                                >
                                  <span>
                                    {students?.lastmsg?.usersNotification !== 0
                                      ? students?.lastmsg?.usersNotification
                                      : ""}
                                  </span>
                                </div>
                              </div>
                              <div className={Styles.tickmark}>
                                <div>
                                  <p
                                    className={
                                      students.lastmsg
                                        ? `${Styles.lastmsg}`
                                        : `${Styles.Nolastmsg}`
                                    }
                                  >
                                    <span>
                                      {students?.lastmsg ? (
                                        students?.lastmsg?.msg?.messages
                                          ?.files ? (
                                          `${students?.lastmsg?.msg?.messages?.files[0]?.originalname.slice(
                                            0,
                                            15
                                          )}(File)`
                                        ) : students?.lastmsg?.msg?.messages
                                            ?.text?.length > 20 ? (
                                          students?.lastmsg?.msg?.messages?.text?.slice(
                                            0,
                                            20
                                          ) +
                                          " " +
                                          ". . ."
                                        ) : (
                                          students?.lastmsg?.msg?.messages?.text
                                        )
                                      ) : (
                                        <span>No recent message</span>
                                      )}
                                    </span>
                                  </p>
                                </div>
                                <span className={Styles.time}>
                                  {students?.lastmsg?.msg?.createdAt ? (
                                    dateFormat(
                                      students?.lastmsg?.createdAt,
                                      "mmm d, yyyy"
                                    ) === today ? (
                                      dateFormat(
                                        students?.lastmsg?.msg?.createdAt,
                                        "DDDD h:MM TT"
                                      )
                                    ) : (
                                      <Moment format="MMM DD - h:mm a">
                                        {students?.lastmsg?.msg?.createdAt}
                                      </Moment>
                                    )
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </div>
                            </div>
                          </div>
                        );
                      })
                  )}
                </InfiniteScroll>
              </>
            </div>
          )}

          <div className={Styles?.loadmoreBlock}>
            {/* {
              <div className={Styles.loadmoreBlock}>
                <button onClick={Loadmore}>loadmore....</button>
              </div>
            } */}
          </div>
        </div>
      </div>
    </>
  );
};

export default Conversation;
