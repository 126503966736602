import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { HiChat } from "react-icons/hi";
import Styles from "./table.module.css";
import styles from "./table.module.css";
import { FiSearch } from "react-icons/fi";
import AxiosInstance from "../../api/AxiosInstance";
import { RotatingLines } from "react-loader-spinner";
import { Tooltip, Whisper } from "rsuite";
import { ImCross } from "react-icons/im";
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#D35400",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const ArchiveBatchlist = () => {
  const [page, setPage] = useState(1);
  let [status, setStatus] = useState([]);
  let [msg, setMsg] = useState("");
  let [itmes, setItems] = useState([]);
  let [pag, setPag] = useState("");
  let [username, setUsername] = useState("");
  let [userName, setUserName] = useState("");
  let [branchname, setBranchname] = useState("");
  let [branchName, setBranchName] = useState("");
  let [searchTrue, setSearchTrue] = useState(false);
  let [searchTruebranch, setSearchTruebranch] = useState(false);
  let [loading, setLoading] = useState(false);
  let { userAdmin } = useSelector(state => state.auth);

  useEffect(() => {
    async function fetchlist() {
      setLoading(true);
      await AxiosInstance.get(
        `/archive/getArchivebatches?page=${+page}&branch_batch=${branchname}&trainer_tracker=${username}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      )
        .then(data => {
          setLoading(false);
          let pagedata = data?.data.result;
          let dataList = data?.data?.result.results;
          let error = data.response?.status;
          setStatus(error);
          setItems(dataList);
          setPag(pagedata);
        })
        .catch(x => {
          setLoading(false);
          let error = x?.response?.status;
          let message = x?.response?.data?.result?.results;
          setStatus(error);
          setMsg(message);
        });
    }
    fetchlist();
  }, [page, branchname, username]);

  const handlePageClick = event => {
    setPage(Number(event.selected + 1));
  };

  let HandleClickSearch = () => {
    if (userName.length > 0) {
      setSearchTrue(true);
      setUsername(userName);
    }
  };

  let HandleClearSearch = () => {
    setSearchTrue(false);
    setUsername("");
    setUserName("");
  };
  let HandleClickbranchSearch = () => {
    setSearchTruebranch(true);
    setBranchname(branchName);
  };
  let HandleClearbranchSearch = () => {
    setSearchTruebranch(false);
    setBranchname("");
    setBranchName("");
  };
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (branchName.trim() !== "") {
        HandleClickbranchSearch();
      }
    }
  };
  const handleKeyPressUser = e => {
    if (e.key === "Enter") {
      if (userName.trim() !== "") {
        HandleClickSearch();
      }
    }
  };
  const handleBackspace = event => {
    if (
      (event.key === "Backspace" && branchName.length === 1) ||
      branchName.length === 0
    ) {
      setSearchTruebranch(false);
      setBranchname("");
      setBranchName("");
    }
  };
  const handleBackspaceUser = event => {
    if (
      (event.key === "Backspace" && userName.length === 1) ||
      userName.length === 0
    ) {
      setSearchTrue(false);
      setUsername("");
      setUserName("");
    }
  };
  return (
    <section>
      <Paper
        elevation={12}
        sx={{
          width: "98%",
          overflow: "hidden",
          margin: "0 auto",
          position: "relative",
          marginTop: "20px",
        }}
        className={Styles.studentListTable}
      >
        <Grid
          container
          spacing={1}
          className={Styles.filterBlock}
          alignItems="center"
        >
          <Grid item md={5}>
            <h4 style={{ color: "#fff", textAlign: "center" }}>
              Archived BatchList
            </h4>
          </Grid>
          <Grid item md={2} position="relative">
            <span className={styles.searchIcon}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="#fff"
                stroke="#F8931F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </span>
            <input
              type="text"
              name="search"
              id="inputName"
              className={styles.studentNameList}
              value={branchName}
              placeholder="Search Branch / BatchCode"
              onChange={e => setBranchName(e.target.value)}
              onKeyPress={handleKeyPress}
              onKeyDown={handleBackspace}
              style={{ fontWeight: "bold" }}
            />
          </Grid>
          <Grid item md={1}>
            {searchTruebranch === false ? (
              <Button
                size="small"
                type="button"
                color="warning"
                sx={{
                  background: "#F87000",
                  color: "#111",
                  fontWeight: "bold",
                  "&:hover": {
                    background: "#F87000",
                    color: "#444",
                  },
                }}
                variant="contained"
                onClick={HandleClickbranchSearch}
              >
                Search
              </Button>
            ) : (
              <Button
                sx={{ fontWeight: "bold" }}
                size="small"
                type="button"
                color="error"
                variant="contained"
                onClick={HandleClearbranchSearch}
              >
                Reset
              </Button>
            )}
          </Grid>
          <Grid item md={2} position="relative">
            <span className={styles.searchIcon}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="#fff"
                stroke="#F8931F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </span>
            <input
              name="searchuser"
              style={{ fontWeight: "bold" }}
              id="inputNameReq"
              value={userName}
              className={styles.studentNameList}
              type="text"
              placeholder="Search Users"
              onChange={e => setUserName(e.target.value)}
              onKeyPress={handleKeyPressUser}
              onKeyDown={handleBackspaceUser}
            />
          </Grid>
          <Grid item md={1}>
            {searchTrue === false ? (
              <Button
                size="small"
                type="button"
                color="warning"
                sx={{
                  background: "#F87000",
                  color: "#111",
                  fontWeight: "bold",
                  "&:hover": {
                    background: "#F87000",
                    color: "#444",
                  },
                }}
                variant="contained"
                onClick={HandleClickSearch}
              >
                Search
              </Button>
            ) : (
              <Button
                sx={{ fontWeight: "bold" }}
                size="small"
                type="button"
                color="error"
                variant="contained"
                onClick={HandleClearSearch}
              >
                Reset
              </Button>
            )}
          </Grid>
        </Grid>
        {/* table */}

        <TableContainer
          component={Paper}
          sx={{ height: "75vh", borderRadius: "6px" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ borderCollapse: "collapse" }}
          >
            <TableHead>
              <TableRow>
                <StyledTableCell size="small" align="center">
                  Id
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  BatchCode
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Branch
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Subject
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Course
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Trainer
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Students
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  View Chat
                </StyledTableCell>
              </TableRow>
            </TableHead>
            {loading === true ? (
              <div className={Styles.loader}>
                <RotatingLines
                  strokeColor="#f16f1d"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            ) : (
              <TableBody>
                {itmes
                  ?.sort((a, b) => b.isOpen - a.isOpen)
                  .map((ele, ind) => {
                    return (
                      <StyledTableRow
                        key={ind}
                        className={
                          ele.isOpen === false
                            ? `${styles.BatchClosed}`
                            : `${styles.BatchOpened}`
                        }
                      >
                        <StyledTableCell align="center" size="small">
                          {ele.dbbatchcodeid}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {ele.batchCode}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {ele.branch}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {ele.subject}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {ele.course}
                        </StyledTableCell>
                        {ele?.juniorTrainer?.length > 0 ? (
                          <Whisper
                            followCursor
                            speaker={
                              <Tooltip>
                                {ele?.juniorTrainer.map(x => x.username)}
                              </Tooltip>
                            }
                          >
                            <StyledTableCell align="center" size="small">
                              {ele?.trainer}
                            </StyledTableCell>
                          </Whisper>
                        ) : (
                          <StyledTableCell align="center" size="small">
                            {ele?.trainer}
                          </StyledTableCell>
                        )}
                        <StyledTableCell align="center" size="small">
                          {ele.No_ofStudents}
                        </StyledTableCell>
                        <StyledTableCell align="center" size="small">
                          {ele.No_ofStudents == 0 ? (
                            <span
                              title="No students"
                              className="color_chat"
                              style={{
                                fontSize: "x-large",
                                cursor: "not-allowed",
                              }}
                            >
                              <HiChat />
                            </span>
                          ) : (
                            <Link
                              to={`/admin-dashboard/admin/archivebatch/${ele.batchCode}`}
                            >
                              <span className="color_chat">
                                <HiChat />
                              </span>
                            </Link>
                          )}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Grid
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            margin: "0 auto",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        >
          <div id={Styles.reactpaginate}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              pageRangeDisplayed={3}
              previousLabel="< previous"
              pageCount={pag?.pages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </Grid>
      </Paper>
    </section>
  );
};

export default ArchiveBatchlist;
