import React from "react";
import Logo from "./Logo";
import Menu from "./Menu";
import Styles from "./_navbar.module.css";
import MenuBar from "./MenuBar";

const Navbar = () => {
  return (
    <section id={Styles.navbarSection}>
      <article>
        <Logo />
        {/* <Menu /> */}
        <MenuBar/>
      </article>
    </section>
  );
};

export default Navbar;
