import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import AxiosInstance from "../../api/AxiosInstance";
import Styles from "./table.module.css";
import { useEffect } from "react";
import { RotatingLines } from "react-loader-spinner";
import { Fragment } from "react";
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#D35400",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));
const InActiveUsers = () => {
  let { userAdmin } = useSelector(state => state.auth);

  let [inActive, setInActive] = useState([]);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function fetchData() {
      setLoading(true);
      await AxiosInstance.get(`/qtalk/msg/count?branch=${true}`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data;

          setInActive(data);
          setLoading(false);
        })
        .catch(y => {
          setLoading(false);

        })
        .finally(() => {
          setLoading(false);
        });
    }
    fetchData();
  }, []);

  return (
    <>
      <section>
        {loading ? (
          <div className={Styles.loader}>
            <RotatingLines
              strokeColor="#f16f1d"
              strokeWidth="5"
              animationDuration="0.75"
              width="96"
              visible={true}
            />
          </div>
        ) : (
          <Paper
            elevation={12}
            sx={{
              width: "98%",
              overflow: "hidden",
              margin: "0 auto",
              position: "relative",
              marginTop: "20px",
            }}
            className={Styles.studentListTable}
          >
            <Grid
              container
              spacing={1}
              className={Styles.filterBlock}
              alignItems="center"
            >
              <Grid item md={12}>
                <h4 style={{ color: "#fff", textAlign: "center" }}>
                  Branch details
                </h4>
              </Grid>
            </Grid>
            <TableContainer
              component={Paper}
              sx={{ height: "75vh", borderRadius: "6px" }}
            >
              <Table
                stickyHeader
                aria-label="sticky table"
                style={{ borderCollapse: "collapse" }}
              >
                <TableHead>
                  <TableRow>
                    <StyledTableCell size="small">Branch</StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Total Users
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Active Users
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Inactive Users
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Total Students
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Active Students
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Inactive Students
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      No of Batches
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      No of Groups
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      Message Count
                    </StyledTableCell>
                    <StyledTableCell align="center" size="small">
                      BroadCast Count
                    </StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {inActive?.length > 0 &&
                    inActive?.map((ele, ind) => {
                      return (
                        <StyledTableRow key={ind}>
                          <StyledTableCell align="center" size="small">
                            {ele.branch}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            <Link
                              to={`/admin-dashboard/admin/InActiveUsers/${ele.branch}`}
                            >
                              {ele.total_users}
                            </Link>
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.active_users}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.inactive_users}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.totaltudentsCount}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.active_Students}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.inactive_Students}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.no_of_batches}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.no_of_groups}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.One_one_chat_MsgCount === false ||
                            ele.One_one_chat_MsgCount === 0
                              ? "--"
                              : ele.One_one_chat_MsgCount}
                          </StyledTableCell>
                          <StyledTableCell align="center" size="small">
                            {ele.broadcast_messages === false ||
                            ele.broadcast_messages === 0
                              ? "--"
                              : ele.broadcast_messages}
                          </StyledTableCell>
                        </StyledTableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <Grid
            style={{
              width: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              margin: "0 auto",
              marginTop: "1%",
              marginBottom: "1%",
            }}
          ></Grid> */}
          </Paper>
        )}
      </section>
    </>
  );
};

export default InActiveUsers;
