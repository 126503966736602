import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Styles from "./table.module.css";
import AxiosInstance from "../../api/AxiosInstance";
import ReactPaginate from "react-paginate";
import { RotatingLines } from "react-loader-spinner";
import { Tooltip, Whisper } from "rsuite";
import { toast } from "react-toastify";
import styles from "../auth/_auth.module.css";
import { Modal } from "rsuite";
import Select from "react-select";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  styled,
  Button,
} from "@mui/material";
import { tableCellClasses } from "@mui/material/TableCell";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#D35400",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));
const AllRoles = () => {
  const [page, setPage] = useState(1);
  let [itmes, setItems] = useState([]);
  let [filter, setFilter] = useState([]);
  let [pag, setPag] = useState("");
  let [username, setUsername] = useState("");
  let [status, setStatus] = useState([]);
  let [msg, setMsg] = useState("");
  let [loading, setLoading] = useState(false);
  let [userName, setUserName] = useState("");

  const [openCourse, setopenCourse] = useState(false);

  let [reasons, setReasons] = useState("");

  let [reasonsList, setReasonsList] = useState([]);
  let [reasonsListUnblock, setReasonsListUnblock] = useState([]);
  const [errors, setErrors] = useState({});
  // const [overflow, setOverflow] = React.useState(true);
  let [selectedId, setSelectedId] = useState("");
  let { userAdmin } = useSelector(state => state.auth);
  let [searchTrue, setSearchTrue] = useState(false);

  //(userAdmin, "userAdmin")
  //validation
  const validate = () => {
    let errors = {};
    if (!reasons) {
      errors.reasons = "Reason field is required";
    }
    return errors;
  };

  function OpenCourse() {
    // //(e)
    // setSelectedId(e)
    //("clicked");
    setopenCourse(true);
  }
  function closeCourse() {
    setopenCourse(false);
    // window.location.reload()
  }

  let handleReason = e => {
    if (e?.value) {
      setReasons(e.value);
    } else {
      setReasons("");
    }
  };

  let handleBlockreasons = () => {
    async function fetchData(e) {
      let resondata = await AxiosInstance.get(
        `auth/getdata?data=block_reasons
      `,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );
      // //(Coursedata);
      //(resondata);
      let Coursedataaa = resondata?.data[0].block_reasons;
      // //(Coursedataaa);
      let eee = Coursedataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setReasonsListUnblock(eee);
    }
    fetchData();
  };
  let handleUnBlockreasons = () => {
    async function fetchData(e) {
      let resondata = await AxiosInstance.get(
        `auth/getdata?data=unblock_reasons
      `,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );
      // //(Coursedata);
      //(resondata);
      let Coursedataaa = resondata?.data[0].unblock_reasons;
      // //(Coursedataaa);
      let eee = Coursedataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setReasonsList(eee);
    }
    fetchData();
  };

  useEffect(() => {
    async function fetchlist() {
      setLoading(true);
      await AxiosInstance.get(
        `admin/getusers?page=${+page}&search=${username}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      )
        .then(data => {
          setLoading(false);
          let pagedata = data?.data.result;
          let dataList = data?.data?.result?.results;
          let error = data.response?.status;
          //(pagedata)
          setStatus(error);
          setItems(dataList);
          setFilter(dataList);
          setPag(pagedata);
        })
        .catch(x => {
          setLoading(false);
          let error = x?.response?.status;
          let message = x?.response?.data?.result?.results;
          setStatus(error);
          setMsg(message);
        });
    }
    fetchlist();
  }, [page, username]);

  let handleSearch = e => {
    setUserName(e.target.value);
  };
  const handlePageClick = event => {
    setPage(Number(event.selected + 1));
  };
  let PermanentBlock = async e => {
    const options = {
      headers: {
        Authorization: `Bearer ${userAdmin.TOKEN}`,
      },
    };
    if (reasons === "") {
      setErrors(validate(reasons));
    } else {
      await AxiosInstance.post(`/auth/userblock`, { id: e }, options)
        .then(x => {
          //(x);
          //(x);
          toast.success(x.data.message, {
            position: "top-right",
          });
          window.location.assign("/admin-dashboard/admin/allroles");
        })
        .catch(x => {
          //(x?.response?.data?.message);
          toast.error(x?.response?.data?.message, {
            position: "top-right",
          });
        });
    }
  };

  let UnBlockPermanentBlock = async e => {
    //(e);

    const options = {
      headers: {
        Authorization: `Bearer ${userAdmin.TOKEN}`,
      },
    };
    if (reasons === "") {
      setErrors(validate(reasons));
    } else {
      await AxiosInstance.post(`/auth/userunblock`, { id: e }, options)
        .then(x => {
          //(x);
          //(x);
          toast.success(x.data.message, {
            position: "top-right",
          });
          window.location.assign("/admin-dashboard/admin/allroles");
        })
        .catch(x => {
          //(x?.response?.data?.message);
          toast.error(x?.response?.data?.message, {
            position: "top-right",
          });
        });
    }
  };
  let handleClick = () => {
    //("clicked");
  };
  let HandleClickSearch = () => {
    if (userName.length > 0) {
      setSearchTrue(true);
      setUsername(userName);
    }
  };
  let HandleClearSearch = () => {
    setSearchTrue(false);
    setUsername("");
    setUserName("");
  };
  const handleKeyPressUser = e => {
    if (e.key === "Enter") {
      if (userName.trim() !== "") {
        // Trigger search if input is not empty
        HandleClickSearch();
      }
    }
  };
  const handleBackspaceUser = event => {
    if (event.key === "Backspace" && userName.length === 1) {
      setSearchTrue(false);
      setUsername("");
      setUserName("");
    }
  };

  return (
    <section>
      <Paper
        elevation={12}
        sx={{
          width: "98%",
          overflow: "hidden",
          margin: "0 auto",
          position: "relative",
          marginTop: "20px",
        }}
        className={Styles.studentListTable}
      >
        <Grid
          container
          spacing={1}
          className={Styles.filterBlock}
          alignItems="center"
        >
          <Grid item md={5}>
            <h4 style={{ color: "#fff", textAlign: "center" }}>Users List</h4>
          </Grid>
          <Grid item md={3.5}></Grid>
          <Grid item md={2} position="relative">
            <span className={Styles.searchIcon}>
              <svg
                width="18"
                height="18"
                viewBox="0 0 24 24"
                fill="#fff"
                stroke="#F8931F"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="11" cy="11" r="8"></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
              </svg>
            </span>
            <input
              type="text"
              id="inputName"
              className={Styles.studentNameList}
              value={userName}
              placeholder="Search User"
              onChange={e => handleSearch(e)}
              onKeyPress={handleKeyPressUser}
              onKeyDown={handleBackspaceUser}
              style={{ fontWeight: "bold" }}
            />
          </Grid>
          <Grid item md={1}>
            {searchTrue === false ? (
              <Button
                size="small"
                type="button"
                color="warning"
                sx={{
                  background: "#F87000",
                  color: "#111",
                  fontWeight: "bold",
                  "&:hover": {
                    background: "#F87000",
                    color: "#444",
                  },
                }}
                variant="contained"
                onClick={HandleClickSearch}
              >
                Search
              </Button>
            ) : (
              <Button
                sx={{ fontWeight: "bold" }}
                size="small"
                type="button"
                color="error"
                variant="contained"
                onClick={HandleClearSearch}
              >
                Reset
              </Button>
            )}
          </Grid>
        </Grid>

        <TableContainer
          component={Paper}
          sx={{ height: "75vh", borderRadius: "6px" }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            style={{ borderCollapse: "collapse" }}
          >
            <TableHead>
              <TableRow>
                {" "}
                <StyledTableCell size="small">Id</StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Username
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Email ID
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Number
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Role
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Branch
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Batches
                </StyledTableCell>
                <StyledTableCell align="center" size="small">
                  Groups
                </StyledTableCell>
                {userAdmin.role[0] === "corporate" ? (
                  <StyledTableCell className={Styles.Tableidr} size="small">
                    Update
                  </StyledTableCell>
                ) : (
                  ""
                )}
                {userAdmin.role[0] === "corporate" ? (
                  <StyledTableCell className={Styles.Tableidr} size="small">
                    Block
                  </StyledTableCell>
                ) : (
                  ""
                )}
                {userAdmin.role[0] === "corporate" ? (
                  <StyledTableCell className={Styles.Tableidr} size="small">
                    UnBlock
                  </StyledTableCell>
                ) : (
                  ""
                )}
                {userAdmin.role[0] === "corporate" ? (
                  <StyledTableCell className={Styles.Tableidr} size="small">
                    Permanent Block
                  </StyledTableCell>
                ) : (
                  ""
                )}
              </TableRow>
            </TableHead>
            {loading === true ? (
              <div className={Styles.loader}>
                <RotatingLines
                  strokeColor="#f16f1d"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="96"
                  visible={true}
                />
              </div>
            ) : (
              <TableBody>
                {
                  <>
                    {itmes.length === 0 ? (
                      <StyledTableRow>
                        <StyledTableCell
                          align="center"
                          size="small"
                          colSpan={13}
                        >
                          No Data Found
                        </StyledTableCell>
                      </StyledTableRow>
                    ) : (
                      itmes
                        ?.sort((a, b) => a.permanentBlock - b.permanentBlock)
                        .map((ele, ind) => {
                          return (
                            <>
                              <StyledTableRow key={ind}>
                                <StyledTableCell align="center" size="small">
                                  {" "}
                                  {ele.dbbranchuserid || ele.bddthrid}
                                </StyledTableCell>
                                <StyledTableCell align="center" size="small">
                                  {ele?.username}
                                </StyledTableCell>
                                <StyledTableCell align="center" size="small">
                                  {" "}
                                  {ele?.email[0]} <br />
                                  {ele?.email[1]}
                                </StyledTableCell>
                                <StyledTableCell align="center" size="small">
                                  {ele?.number[0]} <br /> {ele?.number[1]}
                                </StyledTableCell>

                                {ele?.role?.length > 3 ? (
                                  <Whisper
                                    followCursor
                                    speaker={<Tooltip>{ele?.role}</Tooltip>}
                                  >
                                    <StyledTableCell size="small">
                                      {ele?.role?.toString().substring(0, 20) +
                                        " " +
                                        ". . ."}
                                      {ele?.section &&
                                      ele?.section?.length > 0 ? (
                                        <span> -- {ele?.section}</span>
                                      ) : (
                                        ""
                                      )}
                                    </StyledTableCell>
                                  </Whisper>
                                ) : (
                                  <StyledTableCell size="small">
                                    {ele.role.map((role, idx) => (
                                      <span key={idx}>
                                        {role}
                                        {idx !== ele.role.length - 1 && (
                                          <span> / </span>
                                        )}
                                      </span>
                                    ))}
                                    {ele?.section && ele?.section.length > 0 ? (
                                      <span> -- {ele?.section}</span>
                                    ) : (
                                      ""
                                    )}
                                  </StyledTableCell>
                                )}

                                {ele?.branch?.length > 2 ? (
                                  // <Whisper
                                  //   followCursor
                                  //   speaker={<Tooltip>{ele?.branch}</Tooltip>}
                                  // >
                                  <StyledTableCell
                                    size="small"
                                    title={ele?.branch}
                                  >
                                    {ele?.branch?.toString().substring(0, 25) +
                                      " " +
                                      ". . ."}
                                    {/* <title>{ele?.branch}</title> */}
                                  </StyledTableCell>
                                ) : (
                                  // </Whisper>
                                  <StyledTableCell size="small">
                                    {" "}
                                    {ele.branch.map((branch, idx) => (
                                      <span key={idx}>
                                        {branch}
                                        {idx !== ele.branch.length - 1 && (
                                          <span> / </span>
                                        )}
                                      </span>
                                    ))}
                                  </StyledTableCell>
                                )}
                                <StyledTableCell align="center" size="small">
                                  {ele?.batchCode?.length}
                                </StyledTableCell>
                                <StyledTableCell align="center" size="small">
                                  {ele?.groupCode?.length}
                                </StyledTableCell>
                                {userAdmin.role[0] === "corporate" ? (
                                  <StyledTableCell size="small" align="center">
                                    <Link to={`${ele._id}`}>
                                      <span>
                                        <svg
                                          width={20}
                                          height={20}
                                          viewBox="0 0 640 512"
                                        >
                                          <path
                                            fill="#f16f1d"
                                            d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128m89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h274.9c-2.4-6.8-3.4-14-2.6-21.3l6.8-60.9l1.2-11.1l7.9-7.9l77.3-77.3c-24.5-27.7-60-45.5-99.9-45.5m45.3 145.3l-6.8 61c-1.1 10.2 7.5 18.8 17.6 17.6l60.9-6.8l137.9-137.9l-71.7-71.7zM633 268.9L595.1 231c-9.3-9.3-24.5-9.3-33.8 0l-37.8 37.8l-4.1 4.1l71.8 71.7l41.8-41.8c9.3-9.4 9.3-24.5 0-33.9"
                                          ></path>
                                        </svg>
                                      </span>
                                    </Link>
                                  </StyledTableCell>
                                ) : (
                                  ""
                                )}
                                {userAdmin.role[0] === "corporate" ? (
                                  <StyledTableCell
                                    className={Styles.Block}
                                    size="small"
                                    align="center"
                                  >
                                    <Link to={`block/${ele?._id}`}>
                                      <svg
                                        width={20}
                                        height={20}
                                        viewBox="0 0 24 24"
                                      >
                                        <g fill="none" fillRule="evenodd">
                                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z"></path>
                                          <path
                                            fill="#f16f1d"
                                            d="m12 14.122l5.303 5.303a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879L6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.304a1.5 1.5 0 1 0 2.122 2.12z"
                                          ></path>
                                        </g>
                                      </svg>
                                    </Link>
                                  </StyledTableCell>
                                ) : (
                                  ""
                                )}
                                {userAdmin.role[0] === "corporate" ? (
                                  <StyledTableCell
                                    className={Styles.UnBlock}
                                    size="small"
                                    align="center"
                                  >
                                    <Link to={`unblock/${ele?._id}`}>
                                      <svg
                                        width={20}
                                        height={20}
                                        viewBox="0 0 512 512"
                                      >
                                        <path
                                          fill="#2dd4bf"
                                          d="M437.3 30L202.7 339.3L64 200.7l-64 64L213.3 478L512 94z"
                                        ></path>
                                      </svg>
                                    </Link>
                                  </StyledTableCell>
                                ) : (
                                  ""
                                )}
                                {userAdmin.role[0] === "corporate" ? (
                                  <StyledTableCell size="small" align="center">
                                    {ele.permanentBlock === true ? (
                                      <button
                                        className={Styles.permanentUnBlock}
                                        onClick={e => {
                                          setSelectedId(ele);
                                          setopenCourse(true);
                                          handleUnBlockreasons();
                                        }}
                                      >
                                        UnBlock
                                      </button>
                                    ) : (
                                      <button
                                        className={Styles.permanentBlock}
                                        onClick={e => {
                                          setSelectedId(ele);
                                          setopenCourse(true);
                                          handleBlockreasons();
                                        }}
                                      >
                                        Block
                                      </button>
                                    )}
                                    <Modal
                                      size="lg"
                                      keyboard={false}
                                      open={openCourse}
                                      onClose={closeCourse}
                                    >
                                      {selectedId.permanentBlock === true ? (
                                        <>
                                          <Modal.Header>
                                            <Modal.Title>
                                              Add reason To UnBlock
                                            </Modal.Title>
                                          </Modal.Header>

                                          <Modal.Body
                                            style={{
                                              overflow: "unset",
                                              maxheight: "none",
                                            }}
                                          >
                                            <>
                                              <div className={Styles.reasons}>
                                                <label htmlFor="reasons">
                                                  {selectedId.username}
                                                </label>
                                                <div
                                                  className={
                                                    styles.dropdownforreason
                                                  }
                                                >
                                                  <Select
                                                    name="reasons"
                                                    options={reasonsList}
                                                    onChange={handleReason}
                                                  />
                                                </div>
                                                <p
                                                  className={
                                                    errors.reasons
                                                      ? `${Styles.errrr}`
                                                      : `${Styles.gapbtw}`
                                                  }
                                                ></p>
                                                {errors.reasons && (
                                                  <p className={Styles.errors}>
                                                    {errors.reasons}
                                                  </p>
                                                )}
                                              </div>
                                            </>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            {" "}
                                            <button
                                              className={Styles.permanentBlock}
                                              onClick={e => {
                                                UnBlockPermanentBlock(
                                                  selectedId._id
                                                );
                                              }}
                                            >
                                              UnBlock
                                            </button>
                                          </Modal.Footer>
                                        </>
                                      ) : (
                                        <>
                                          <Modal.Header>
                                            <Modal.Title>
                                              Add reason to Permanent Block
                                            </Modal.Title>
                                          </Modal.Header>

                                          <Modal.Body
                                            style={{
                                              overflow: "unset",
                                              maxheight: "none",
                                            }}
                                          >
                                            <>
                                              <div className={Styles.reasons}>
                                                <label htmlFor="reasons">
                                                  Name - {selectedId.username}
                                                </label>
                                                <div
                                                  className={
                                                    styles.dropdownforreason
                                                  }
                                                >
                                                  <Select
                                                    options={reasonsListUnblock}
                                                    name="reasons"
                                                    onChange={handleReason}
                                                  />
                                                </div>
                                                <p
                                                  className={
                                                    errors.reasons
                                                      ? `${Styles.errrr}`
                                                      : `${Styles.gapbtw}`
                                                  }
                                                ></p>
                                                {errors.reasons && (
                                                  <p className={Styles.errors}>
                                                    {errors.reasons}
                                                  </p>
                                                )}
                                              </div>
                                            </>
                                          </Modal.Body>
                                          <Modal.Footer>
                                            {" "}
                                            <button
                                              className={Styles.permanentBlock}
                                              onClick={e => {
                                                PermanentBlock(selectedId._id);
                                              }}
                                            >
                                              Block
                                            </button>{" "}
                                          </Modal.Footer>
                                        </>
                                      )}
                                    </Modal>
                                  </StyledTableCell>
                                ) : (
                                  ""
                                )}
                              </StyledTableRow>
                            </>
                          );
                        })
                    )}
                  </>
                }
              </TableBody>
            )}
          </Table>
        </TableContainer>

        <Grid
          style={{
            width: "50%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            margin: "0 auto",
            marginTop: "1%",
            marginBottom: "1%",
          }}
        >
          {" "}
          <div id={Styles.reactpaginate}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              pageRangeDisplayed={3}
              previousLabel="< previous"
              pageCount={pag?.pages}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
            />
          </div>
        </Grid>
      </Paper>
    </section>
  );
};
export default AllRoles;
