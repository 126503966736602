import React from "react";
import { FcCheckmark } from "react-icons/fc";
import { IoCheckmarkDoneSharp, IoSendSharp } from "react-icons/io5";
import Moment from "react-moment";
import Styles from "./_UserToUser.module.css";
import styles from "../Chat/Modal.module.css";
import Linkify from "react-linkify";
import { GiWideArrowDunk } from "react-icons/gi";
import { useState } from "react";
import useDownloader from "react-use-downloader";
import { Modal, IconButton, Dropdown } from "rsuite";
import AxiosInstance from "../../../api/AxiosInstance";
import { IoIosShareAlt } from "react-icons/io";
import Select from "react-select";
import { useEffect } from "react";
import { FiSend } from "react-icons/fi";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { socket } from "../../../ioContext/socketIo";
import { FaAngleDown } from "react-icons/fa";
import handleError from "../ErrorHandler";
import fetchImageUrl from "../../functions/FetchFileWithToken";

const UserMessage = ({
  messages,
  own,
  particularStudent,
  selectedmsg,
  forwardmsg,
  index,
  setReply,
  loadingmsg,
  setForward,
  forward,
  onClick,
  selectedMessages,
  reply,
  setSelectedMessages,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let navigate = useNavigate();

  const [previewImage, setPreviewImage] = useState("");

  const [open, setOpen] = React.useState(false);
  const [openmodal, setOpenmodal] = React.useState(false);
  const [openmodal2, setOpenmodal2] = React.useState(false);

  let [batchData, setBatchData] = useState([]);
  let [groupData, setGroupData] = useState([]);
  let [studentData, setStudentData] = useState([]);
  let [student, setStudent] = useState([]);

  let [batchselected, setBatchSelected] = useState(false);
  let [groupselected, setGroupSelected] = useState(false);
  let [studentselected, setStudentSelected] = useState(false);
  let [userselected, setuserSelected] = useState(false);

  const [backgroundColor1, setBackgroundColor1] = useState("");

  let [forwardUser, setForwardUser] = useState([]);

  const [SelectedBatches, setSelectedBatches] = useState([]);
  const [SelectedUsers, setSelectedUsers] = useState([]);

  const [SelectedGroups, setSelectedGroups] = useState([]);
  const [SelectedStudents, setSelectedStudents] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const { download } = useDownloader();
  const [openPoll, setOpenPoll] = React.useState(false);
  const [openPollView, setOpenPollView] = React.useState(false);
  let [pollData, setPollData] = useState([]);
  let [loading, setLoading] = useState(false);
  let [maindata, setMainData] = useState([]);
  let [viewPollData, setViewPollData] = useState([]);
  let [viewOptions, setViewOptions] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen2 = () => setOpenmodal2(true);
  const handleClose2 = () => {
    setOpenmodal2(false);
  };
  const handleOpenpoll = () => setOpenPoll(true);
  const handleClosepoll = () => {
    setOpenPoll(false);
  };
  const handleOpenpollView = () => setOpenPollView(true);
  const handleClosepollView = () => {
    setOpenPollView(false);
  };
  let currentDate = new Date(); // Get the current date and time
  let year = currentDate.getFullYear(); // Get the year
  let month = String(currentDate.getMonth() + 1).padStart(2, "0"); // Get the month (add 1 because months are 0-based) and format it to have leading zeros if necessary
  let day = String(currentDate.getDate()).padStart(2, "0"); // Get the day of the month and format it with leading zeros if necessary
  let hours = String(currentDate.getHours()).padStart(2, "0"); // Get the hours and format them with leading zeros if necessary
  let minutes = String(currentDate.getMinutes()).padStart(2, "0"); // Get the minutes and format them with leading zeros if necessary

  let currentDateTime = `${year}-${month}-${day} ${hours}:${minutes}`;

  useEffect(() => {
    const isMessageSelected = selectedMessages.some(
      msg =>
        msg?.messages?.text === messages?.messages?.text && msg?.index === index
    );

    setBackgroundColor1(isMessageSelected ? "rgb(0 0 0 / 12%)" : "");
  }, [selectedMessages, messages, index]);
  const handleClick = () => {
    const isMessageSelected = selectedMessages.some(
      msg => msg.messages.text === messages.messages.text && msg.index === index
    );
    if (
      messages?.poll === null &&
      selectedMessages?.length < 1 &&
      forward === false
    ) {
      setBackgroundColor1(backgroundColor1 === "" ? "rgb(0 0 0 / 12%)" : "");
      const modifiedMessage = { ...messages, index, isMessageSelected };
      onClick(modifiedMessage);
    } else if (messages?.poll === null && forward === true) {
      setBackgroundColor1(backgroundColor1 === "" ? "rgb(0 0 0 / 12%)" : "");
      const modifiedMessage = { ...messages, index, isMessageSelected };
      onClick(modifiedMessage);
    } else if (
      forward === false &&
      reply === false &&
      selectedMessages?.length === 1
    ) {
      setBackgroundColor1(backgroundColor1 === "");
      setSelectedMessages([]);
    }
  };
  const renderIconButton = (props, ref) => {
    return forward ? (
      ""
    ) : (
      <IconButton
        {...props}
        ref={ref}
        icon={<FaAngleDown />}
        circle
        color="white"
        size="10px"
      />
    );
  };

  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    const fetchUrl = async () => {
      if (messages?.messages?.files != undefined) {
        const fileData = await fetchImageUrl(
          messages?.messages?.files[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }

      if (
        messages?.replayback &&
        Object.keys(messages?.replayback).length > 0 &&
        messages?.replayback?.file !== "" &&
        messages?.replayback?.file != undefined
      ) {
        const fileData = await fetchImageUrl(
          messages?.replayback?.file[0]?.path,
          user?.TOKEN
        );
        if (fileData) {
          setImageUrl(fileData);
        }
      }
    };

    fetchUrl();
  }, [messages]);

  let handlePollAnswer = e => {
    async function fetchData() {
      setLoading(true);
      await AxiosInstance.get(`/employee/stu_getpoll?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let eee = x?.data?.voters[0]?.votefor?.map(x => x);
          let lll = data?.options?.map(outerOption => {
            return {
              ...outerOption,
              vote_type: data?.vote_type,
              isMatch:
                eee !== undefined &&
                eee?.some(
                  voterOption =>
                    voterOption?.options_id === outerOption?.options_id
                ),
            };
          });

          let matching = lll?.filter(x => {
            return x?.isMatch === true;
          });

          setPollData(lll);
          setMainData(data);
          setSelectedLabels(matching);
          setLoading(false);
        })
        .catch(y => {
          setLoading(false);
        });
    }
    fetchData();
  };
  let handlePollView = e => {
    async function fetchData() {
      await AxiosInstance.get(`/employee/users_pollviewvotes?poll_id=${e}`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data;
          let final = data?.polldata?.options;

          const updatedArray1 = final.filter(
            obj1 =>
              !data?.voters.some(obj2 => obj1.options_id === obj2.options_id)
          );
          const voterOptions = data?.voters?.map(voter => voter._id);

          const filteredArray = updatedArray1.filter(
            obj => !voterOptions.includes(obj.options_id)
          );

          const finalArray = filteredArray.concat(data?.voters);

          setViewOptions(finalArray);
          setViewPollData(data);
        })
        .catch(y => {});
    }
    fetchData();
  };

  const handleLabelClick = user => {
    setSelectedLabels(prevLabels => {
      const labelIndex = prevLabels.findIndex(
        label => label.options_id === user.options_id
      );

      if (labelIndex !== -1) {
        // If the label is already selected, remove it from selectedLabels
        return prevLabels.filter((_, index) => index !== labelIndex);
      } else {
        // If the label is not selected, add it to selectedLabels
        return [...prevLabels, user];
      }
    });
  };
  const handleCloseModal = () => {
    setOpenmodal(false);
    setSelectedBatches([]);
    setSelectedGroups([]);
    setSelectedStudents([]);
    setBatchData([]);
    setGroupData([]);
    setStudentData([]);
    setSelectedUsers([]);
    setReply(false);
    setForward(false);
    setSelectedMessages([]);
  };

  let FetchBatch = async () => {
    setBatchSelected(true);
    setOpenmodal(true);

    async function fetchData() {
      await AxiosInstance.get(`users/batches`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data?.BatchsDetails;
          const uniqueArray = data.filter((obj, index, arr) => {
            return index === arr.findIndex(o => o.batchCode === obj.batchCode);
          });

          let eee = uniqueArray.map(function (ele) {
            return {
              value: ele.batchCode,
              label: ele.batchCode,
            };
          });

          setBatchData(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };
  let FetchGroup = () => {
    setOpenmodal(true);
    setGroupSelected(true);
    async function fetchData() {
      await AxiosInstance.get(`users/groups`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      })
        .then(x => {
          let data = x?.data?.GroupDetails;
          const uniqueArray = data.filter((obj, index, arr) => {
            return index === arr.findIndex(o => o.groupCode === obj.groupCode);
          });
          let eee = uniqueArray.map(function (ele) {
            return {
              value: ele.groupCode,
              label: ele.groupCode,
            };
          });

          setGroupData(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchData();
  };

  let FetchStudents = () => {
    async function fetchData() {
      let StudentData = await AxiosInstance.get(`users/getstudents/`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });
      let Studentdataaa = StudentData?.data?.students;

      let eee = Studentdataaa.map(function (ele) {
        return {
          value: ele._id,
          label: ele.username,
        };
      });

      setStudentData(eee);
      setStudent(eee);
    }
    fetchData();
  };

  useEffect(() => {
    if (searchQuery.length > 2) {
      async function fetchData() {
        let StudentData = await AxiosInstance.get(
          `users/getstudents/?search=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        let Studentdataaa = StudentData?.data?.students;

        let eee = Studentdataaa.map(function (ele) {
          return {
            value: ele._id,
            label: ele.username,
          };
        });

        setStudentData(eee);
      }
      fetchData();
    } else {
      setStudentData(student);
    }
  }, [searchQuery]);

  let HandleReply = () => {
    setReply(true);
    setForward(false);
  };
  let HandleForward = () => {
    setForward(true);
    setReply(false);
  };

  const items = [
    <Dropdown.Item onClick={() => HandleForward()}>Forward</Dropdown.Item>,
    <Dropdown.Item onClick={() => HandleReply()}>Reply</Dropdown.Item>,
  ];

  const handleChangeStudent = e => {
    if (e.length <= 5) {
      setSelectedBatches(Array.isArray(e) ? e.map(x => x.value) : []);
    } else {
      alert("you can only send 5 Batches at a time");
    }
  };

  let handleChangeStudents = e => {
    setSelectedStudents(Array.isArray(e) ? e.map(x => x.value) : []);
  };
  useEffect(() => {
    if (searchQuery.length > 2) {
      async function fetchData() {
        let StudentData = await AxiosInstance.get(
          `users/getstudents/?search=${searchQuery}`,
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        let Studentdataaa = StudentData?.data?.students;

        let eee = Studentdataaa.map(function (ele) {
          return {
            value: ele._id,
            label: ele.username,
          };
        });

        setStudentData(eee);
      }
      fetchData();
    } else {
      setStudentData(student);
    }
  }, [searchQuery]);
  let FetchUsers = () => {
    async function fetchData() {
      let UserData = await AxiosInstance.get(
        `/employee/users_coneverstions_list`,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );

      let converName = UserData?.data?.findUserslist;
      let data = converName.map((ele, ind) => {
        let newid = ele.conversation.map(x => {
          return {
            ...x,
            userid: x.id ? x.id : x.id1,
          };
        });

        let id = newid.filter(y => {
          return y.userid !== user._id;
        });

        return {
          ...ele,
          Conver: true,

          user_id: ele.group === false ? id[0].userid : ele._id,
          name: ele.group === false ? id[0].username : "",
        };
      });

      let UserList = await AxiosInstance.get(`/employee/users_list`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });

      let NotConvo = UserList?.data?.UsersList;
      let NoCon = NotConvo.map(x => {
        return {
          ...x,
          user_id: x._id,
          Conver: false,
        };
      });
      let ConbinedList = [...data, ...NoCon];

      let finalList = ConbinedList.filter((ele, ind, self) => {
        return ind === self.findIndex(obj => obj.user_id === ele.user_id);
      });

      let eee = finalList.map(function (ele) {
        return {
          value: ele,

          label:
            ele.Conver === true && ele.group === false
              ? ele.name
              : ele.group === true && ele.Conver === true
              ? ele.groupName
              : ele.username,
        };
      });

      setForwardUser(eee);
    }
    fetchData();
  };

  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };

  function stopPropagation(e) {
    e.stopPropagation(); // Stop the event from propagating to the outer div
  }
  let handleImage = e => {
    handleOpen(true);
    openImagePreview(e);
  };

  const handleInputChange = value => {
    setSearchQuery(value);
  };

  const handleChangeUser = e => {
    if (e.length <= 5) {
      setSelectedUsers(Array.isArray(e) ? e.map(x => x.value) : []);
    } else {
      alert("you can only send 5 Batches at a time");
    }
  };
  let handleChangeGroup = e => {
    if (e.length <= 5) {
      setSelectedGroups(Array.isArray(e) ? e.map(x => x.value) : []);
    } else {
      alert("you can only send 5 Groups at a time");
    }
  };
  const selectedOptions = SelectedGroups.map(groupValue => ({
    value: groupValue,
    label: groupValue,
  }));

  const selectedOptionsBatch = SelectedBatches.map(groupValue => ({
    value: groupValue,
    label: groupValue,
  }));
  let handleLabelClickRadio = option => {
    setSelectedLabels([option]);
  };
  useEffect(() => {
    setBackgroundColor1("");
  }, [particularStudent || reply]);
  let HandleForwordMessage = async e => {
    setBackgroundColor1("");
    e.preventDefault();
    let username = user.username;
    let senderId = user._id;
    let role = user?.role1;

    let mmm = selectedMessages.map(x => {
      return x.messages.text
        ? {
            text: x.messages.text,
            code: x.messages.code ? x.messages.code : "",
          }
        : {
            files: x.messages.files,
            code: x.messages.code ? x.messages.code : "",
          };
    });

    let codeName = SelectedBatches.map(x => x);
    let groupName = SelectedGroups.map(x => x);
    let StudentName = SelectedStudents.map(x => x);

    if (batchselected === true) {
      let payload = {
        forwardTo: "batch_group",
        username: username,
        senderId: senderId,
        codelist: codeName,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
      };
      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setBatchSelected(false);

            setSelectedBatches([]);
            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
            setReply(false);
            setForward(false);
            setSelectedMessages([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }

    if (groupselected === true) {
      let payload = {
        forwardTo: "batch_group",
        username: username,
        senderId: senderId,
        codelist: groupName,
        role: role,
        index: "0",
        type: "group",
        forward_msgs: mmm,
      };
      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
          }
          setOpenmodal(false);
          setSelectedBatches([]);
          setGroupSelected(false);

          setSelectedGroups([]);
          setSelectedStudents([]);
          setBatchData([]);
          setGroupData([]);
          setStudentData([]);
          setReply(false);
          setForward(false);
          setSelectedMessages([]);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }

    if (studentselected === true) {
      let payload = {
        forwardTo: "students",
        receiverId: StudentName,
        senderId: senderId,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
      };

      await AxiosInstance.post("/chat/msg/forward_msg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setSelectedBatches([]);
            setStudentSelected(false);

            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
            setReply(false);
            setForward(false);
            setSelectedMessages([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }

    if (userselected === true) {

      let codeName = SelectedUsers.filter(x => {
        return x.group === false || (x.group === true && x);
      });
      let code = codeName.map(x => {
        return x._id;
      });

      let NonRecive = SelectedUsers.filter(x => {
        return !x.hasOwnProperty("group") && x;
      });
      let non = NonRecive.map(x => {
        return x._id;
      });

      let payload = {
        sendername: username,
        senderId: senderId,
        role: role,
        index: "0",
        type: "batch",
        forward_msgs: mmm,
        nonreceiverId: code,
        receiverIds: non,
      };

      await AxiosInstance.post("/employee/users_forwardmsg", payload, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      })
        .then(x => {
          if (x?.status === 200) {
            toast.success("Message sent successfully", {
              position: "top-right",
            });
            setOpenmodal(false);
            setSelectedBatches([]);
            setuserSelected(false);

            setSelectedGroups([]);
            setSelectedStudents([]);
            setBatchData([]);
            setGroupData([]);
            setStudentData([]);
            setSelectedUsers([]);
            setReply(false);
            setForward(false);
            setSelectedMessages([]);
          }
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
  };

  let HandleAnswerPoll = () => {
    let payload = {
      pollid: maindata._id,
      userdata: {
        userid: user._id,
        username: user.username,
        number: user.number,
        role: user?.role1,
        votefor: selectedLabels,
      },
    };
    if (selectedLabels?.length === 0) {
      toast.error("Select any option", { position: "top-right" });
    } else {
      async function students() {
        await AxiosInstance.post(`/employee/vote_poll`, payload, {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        })
          .then(x => {
            console?.log(x);
          })
          .catch(x => {
            console?.log(x);
          })
          .finally(() => {
            setOpenPoll(false);
          });
      }
      students();
    }
  };
  function linkify(text) {
    const urlRegex =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gi;
    const url = text.match(urlRegex);

    function openInNewTab(url) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    }

    return (
      <Linkify componentDecorator={openInNewTab}>
        <p className={Styles.messageText}>
          {messages?.messages?.text} <br />
          {messages.forwarded ? (
            <span className={Styles.broadcast}>
              <GiWideArrowDunk />
            </span>
          ) : (
            ""
          )}
        </p>
      </Linkify>
    );
  }
  return (
    <div className={Styles.chat}>
      <div
        className={
          own
            ? `${Styles.sendermsg}`
            : messages?.sender
            ? `${Styles.recivermsg}`
            : `${Styles.displaynone}`
        }
      >
        <Modal onClose={handleClose} open={open}>
          <Modal.Header>
            {/* <Modal.Title>ImagePreview</Modal.Title> */}
          </Modal.Header>

          <Modal.Body>
            <img src={previewImage} />
          </Modal.Body>
        </Modal>
        <Modal onClose={handleClose2} open={openmodal2} size="xs">
          <Modal.Header>
            <Modal.Title>
              <h4>Forward To </h4>
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div
              className={Styles.ListForward}
              onClick={() => {
                FetchBatch();
                handleClose2();
              }}
            >
              Batches
            </div>
            <div
              className={Styles.ListForward}
              onClick={() => {
                FetchGroup();
                handleClose2();
              }}
            >
              Groups
            </div>
            <div
              className={Styles.ListForward}
              onClick={() => {
                FetchStudents();
                setOpenmodal(true);
                setStudentSelected(true);
                handleClose2();
              }}
            >
              Students
            </div>
            <div
              className={Styles.ListForward}
              onClick={() => {
                FetchUsers();
                setOpenmodal(true);
                setuserSelected(true);
                handleClose2();
              }}
            >
              {" "}
              Users
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          onClose={handleClosepoll}
          open={openPoll}
          size="lg"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Header>
            <Modal.Title>Poll</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              overflow: "unset",
              maxheight: "none",
            }}
          >
            <aside className={Styles.MainContainer}>
              <h4 className={Styles.TextAlign}>
                {maindata && maindata?.poll_question}
              </h4>
              {maindata && maindata?.vote_type ? (
                <span>Select one or more</span>
              ) : (
                ""
              )}
              {loading ? (
                ""
              ) : (
                <form>
                  {pollData &&
                    pollData?.map((ele, ind) => {
                      return (
                        <div
                          key={ele?.options_id}
                          className={Styles.polloption}
                        >
                          {" "}
                          {maindata && maindata?.vote_type === false ? (
                            <>
                              <input
                                type="radio"
                                name="poll_option"
                                value={ele?.options_id}
                                id={ele?.options_id}
                                defaultChecked={ele?.isMatch === true}
                              />
                              <label
                                htmlFor={ele?.options_id}
                                onClick={() => handleLabelClickRadio(ele)}
                              >
                                {ele?.option}
                              </label>
                            </>
                          ) : (
                            <>
                              <input
                                type="checkbox"
                                name="poll_option"
                                value={ele?.options_id}
                                id={ele?.options_id}
                                defaultChecked={ele?.isMatch === true}
                              />
                              <label
                                htmlFor={ele?.options_id}
                                onClick={() => handleLabelClick(ele)}
                                className={Styles.TextAlign}
                              >
                                {ele?.option}
                              </label>
                            </>
                          )}
                        </div>
                      );
                    })}
                </form>
              )}
            </aside>
          </Modal.Body>
          <Modal.Footer>
            <button
              className={Styles.SubmitPoll}
              onClick={() => HandleAnswerPoll()}
            >
              <IoSendSharp />
            </button>
          </Modal.Footer>
        </Modal>

        <Modal
          onClose={handleClosepollView}
          open={openPollView}
          size="lg"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Header>
            <Modal.Title>Poll Details</Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              overflow: "unset",
              maxheight: "none",
            }}
          >
            <div className={Styles.ViewPollBlock}>
              <h4 className={Styles.TextAlign}>
                {viewPollData && viewPollData?.polldata?.poll_question}
              </h4>
              {viewOptions &&
                viewOptions?.map((ele, ind) => {
                  return (
                    <aside className={Styles.options}>
                      <div className={Styles.Voters}>
                        <h6 className={Styles.TextAlign}>{ele?.option}</h6>
                        {ele?.voters ? (
                          <span>Votes- {ele?.voters?.length}</span>
                        ) : (
                          <span>Votes- 0</span>
                        )}
                      </div>
                      {ele.voters
                        ? ele.voters.map(x => {
                            return <p>{x.username}</p>;
                          })
                        : ""}
                    </aside>
                  );
                })}
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          open={openmodal}
          onClose={handleCloseModal}
          size="lg"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Modal.Header>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body
            style={{
              overflow: "unset",
              maxheight: "none",
            }}
          >
            {
              <div className={Styles.chatMenuWrapper1}>
                <div className={Styles.ConversationBlock1}>
                  <>
                    {batchData && batchData?.length > 0 ? (
                      <>
                        <h4>.Forward Messages To Batches. </h4>
                        <Select
                          closeMenuOnSelect={false}
                          isMulti
                          name="addStudentstogroup"
                          options={batchData}
                          onChange={handleChangeStudent}
                          onMenuScrollToBottom
                          value={selectedOptionsBatch}
                        />
                      </>
                    ) : groupData && groupData.length > 0 ? (
                      <>
                        <>
                          <h4>.Forward Messages To Groups. </h4>
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            name="addStudentstogroup"
                            options={groupData}
                            onChange={handleChangeGroup}
                            onMenuScrollToBottom
                            value={selectedOptions}
                          />
                        </>
                      </>
                    ) : studentData && studentData.length > 0 ? (
                      <>
                        <>
                          <h4>Forward Messages To Students</h4>
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            onInputChange={handleInputChange}
                            name="addStudentstogroup"
                            options={studentData}
                            onChange={handleChangeStudents}
                            onMenuScrollToBottom
                          />
                        </>
                      </>
                    ) : forwardUser && forwardUser.length > 0 ? (
                      <>
                        <>
                          <h4>.Forward Messages To Users. </h4>
                          <Select
                            closeMenuOnSelect={false}
                            isMulti
                            onInputChange={handleInputChange}
                            name="addStudentstogroup"
                            options={forwardUser}
                            onChange={handleChangeUser}
                            onMenuScrollToBottom
                          />
                        </>
                      </>
                    ) : (
                      ""
                    )}
                  </>
                </div>

                <div className={Styles.loadmoreBlock}></div>
              </div>
            }
          </Modal.Body>
          <Modal.Footer>
            <div className={styles.sendbtnfile}>
              <button id={styles.filesubmit}>
                <FiSend onClick={HandleForwordMessage} />
              </button>
            </div>
          </Modal.Footer>
        </Modal>
        <>
          <main
            style={{ backgroundColor: backgroundColor1 }}
            onClick={handleClick}
          >
            <div>
              {messages?.replayback &&
              Object.keys(messages?.replayback).length > 0 ? (
                <div className={Styles.StatusText}>
                  Reply-{" "}
                  {messages?.replayback?.messages &&
                  messages?.replayback?.messages !== "" ? (
                    <span
                      style={{
                        maxHeight: "100px",
                        maxWidth: "200px",
                        overflow: "hidden",
                      }}
                      dangerouslySetInnerHTML={{
                        __html: messages?.replayback?.messages,
                      }}
                    >
                      {/* {messages?.replayback?.messages} */}
                    </span>
                  ) : messages?.replayback?.file?.length > 0 ? (
                    <>
                      {(() => {
                        switch (messages?.replayback?.file[0]?.mimetype) {
                          case "image/jpg":
                          case "image/jpeg":
                          case "image/avif":
                          case "image/png":
                          case "image/webp":
                          case "image/tiff":
                          case "image/bmp":
                            return (
                              <>
                                <img
                                  src={imageUrl?.url}
                                  alt=""
                                  style={{
                                    width: "100%",
                                    height: "100px",
                                    borderRadius: "5px",
                                    display: "flex",
                                  }}
                                />{" "}
                              </>
                            );
                          default:
                            return (
                              <button className={Styles.btndownload1}>
                                {" "}
                                {messages?.replayback?.file[0]?.originalname}
                              </button>
                            );
                        }
                      })()}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            <div className={Styles.senderinfo}>
              {messages?.sendername &&
              typeof messages?.sendername === "string" ? (
                <div className={Styles.sendername}>{messages.sendername}</div>
              ) : (
                ""
              )}
              <div className={Styles.tickicon}></div>
            </div>
            <div>
              <p className={Styles.textmsg}>
                {messages?.poll && messages?.poll !== null ? (
                  <div className={Styles.messageTop}>
                    <div className={Styles.Pollhead}> poll</div>
                    <h6 className={Styles.TextAlign}>
                      {messages?.poll?.poll_question}
                    </h6>
                    <div>
                      {currentDateTime < messages?.poll?.endTime ? (
                        <button
                          className={Styles.AnswerPoll}
                          onClick={e => {
                            stopPropagation(e);
                            handlePollAnswer(messages?.poll?._id);
                            handleOpenpoll();
                          }}
                        >
                          Answer
                        </button>
                      ) : (
                        <span style={{ color: "red" }}>Poll Expired</span>
                      )}

                      {(messages.sender === user._id &&
                        messages?.poll?.stu_view_vote === false) ||
                      messages?.poll?.stu_view_vote === true ? (
                        <button
                          className={Styles.AnswerView}
                          onClick={e => {
                            stopPropagation(e);
                            handlePollView(messages?.poll?._id);
                            handleOpenpollView();
                          }}
                        >
                          View
                        </button>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                ) : messages?.messages?.text ? (
                  <div
                    dangerouslySetInnerHTML={{
                      __html: messages?.messages?.text,
                    }}
                  ></div>
                ) : messages?.messages?.files?.length > 0 ? (
                  <>
                    {(() => {
                      switch (messages?.messages?.files[0]?.mimetype) {
                        case "image/jpg":
                        case "image/jpeg":
                        case "image/avif":
                        case "image/png":
                        case "image/webp":
                        case "image/tiff":
                        case "image/bmp":
                          return (
                            <>
                              <img
                                src={imageUrl?.url}
                                alt=""
                                style={{
                                  width: "200px",
                                  height: "130px",
                                  borderRadius: "5px",
                                  display: "flex",
                                }}
                                onClick={e => {
                                  stopPropagation(e);
                                  handleImage(imageUrl?.url);
                                }}
                              />{" "}
                              <button className={Styles.btndownload}>
                                <span
                                  className={Styles.Download}
                                  onClick={e => {
                                    stopPropagation(e);
                                    download(
                                      imageUrl?.url,
                                      messages?.messages?.files[0]
                                        ?.originalname,
                                      messages?.messages?.files[0]?.filename
                                    );
                                  }}
                                ></span>
                                <span className={Styles.linkBlock}>
                                  download
                                </span>
                              </button>
                              <>
                                <span>
                                  {messages?.forwarded ? (
                                    <span className={Styles.broadcast}>
                                      <GiWideArrowDunk />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </>
                            </>
                          );
                        case "audio/mpeg":
                        case "audio/mp3":
                        case "audio/webm":
                        case "application/wav":
                        case "video/webm":
                        case "audio/wav":
                          return (
                            <>
                              <audio
                                src={imageUrl?.url}
                                controls
                                style={{ width: "250px", height: "40px" }}
                              ></audio>

                              <>
                                <span>
                                  {messages.forwarded ? (
                                    <span className={Styles.broadcast}>
                                      <GiWideArrowDunk />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </>
                            </>
                          );
                        case "video/mp4":
                        case "video/x-matroska":
                        case "application/docx":
                        case "application/doc":
                        case "application/msword":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                        case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                        case "application/vnd.ms-word.document.macroEnabled.12":
                        case "application/vnd.ms-excel":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                        case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                        case "application/vnd.ms-excel.sheet.macroEnabled.12":
                        case "application/vnd.ms-powerpoint":
                        case "application/xlsx":
                        case "application/pptx":
                        case "application/octet-stream":
                        case "text/plain":
                        case "text/html":
                        case "application/apk":
                        case "application/pdf":
                        case "application/x-pdf":
                        case "application/x-gzpdf":
                        case "applications/vnd.pdf":
                        case "application/acrobat":
                        case "application/x-google-chrome-pdf":
                        case "text/pdf":
                        case "text/x-pdf":
                        case "text/csv":
                        case "application/zip":
                        case "application/x-zip":
                        case "application/x-zip-compressed":
                        case "application/json":
                          return (
                            <>
                              <button className={Styles.btndownload1}>
                                {" "}
                                {messages?.messages?.files[0]?.originalname}
                                <span
                                  className={Styles.Download}
                                  onClick={() =>
                                    download(
                                      imageUrl?.url,
                                      messages?.messages?.files[0]
                                        ?.originalname,
                                      messages?.messages?.files[0]?.filename
                                    )
                                  }
                                ></span>
                                <span className={Styles.linkBlock}>
                                  Click to download
                                </span>
                              </button>

                              <>
                                <span>
                                  {messages?.forwarded ? (
                                    <span className={Styles.broadcast}>
                                      <GiWideArrowDunk />
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </span>
                              </>
                            </>
                          );
                        default:
                          return (
                            <button className={Styles.btndownload1}>
                              {" "}
                              {messages?.messages?.files[0]?.originalname}
                              <span
                                className={Styles.Download}
                                onClick={() =>
                                  download(
                                    imageUrl?.url,
                                    messages?.messages?.files[0]?.originalname,
                                    messages?.messages?.files[0].filename
                                  )
                                }
                              ></span>
                              <span>Click to download</span>
                            </button>
                          );
                      }
                    })()}
                  </>
                ) : (
                  ""
                )}
              </p>
            </div>
            <div className={Styles.messageBottom}>
              {Date.now ? (
                <Moment format="MMM DD - h:mm a">{messages?.createdAt}</Moment>
              ) : (
                <Moment format="MMM DD YYYY h A">{messages?.createdAt}</Moment>
              )}

              {own ? (
                messages?.tick === false ? (
                  <FcCheckmark />
                ) : (
                  <IoCheckmarkDoneSharp
                    style={{ color: " rgb(67, 160, 71)" }}
                  />
                )
              ) : (
                ""
              )}
              {backgroundColor1 === "rgb(0 0 0 / 12%)" && !forward ? (
                <span
                  className={Styles.ForwardMsgBlock}
                  onClick={e => stopPropagation(e)}
                >
                  {" "}
                  <Dropdown
                    renderToggle={renderIconButton}
                    placement="leftEnd"
                    style={{ zIndex: 2222222 }}
                  >
                    {items}
                  </Dropdown>
                </span>
              ) : backgroundColor1 === "rgb(0 0 0 / 12%)" && forward ? (
                <span
                  onClick={e => stopPropagation(e)}
                  className={Styles.ForwardMsgBlock}
                >
                  <div onClick={handleOpen2} className={Styles.ForwardBlock}>
                    {" "}
                    <IoIosShareAlt />
                  </div>
                </span>
              ) : (
                ""
              )}
            </div>
          </main>
        </>
      </div>
      {/* ))} */}
    </div>
  );
};

export default UserMessage;
