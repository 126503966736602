import React, { useState } from "react";

import Styles from "./_auth.module.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AxiosInstance from "../../api/AxiosInstance";
import PassValidation from "./PassValidation";
import { Loader } from "rsuite";
const ForgotPass = () => {
  let navigate = useNavigate();
  let [number, setNumber] = useState("");
  let [password, setPassword] = useState("");
  let [confirmPassword, setConfirmPassword] = useState("");
  let [loading, setLoading] = useState(false);
  let [message, setMessage] = useState(false);
  let [errors, setErrors] = useState({});
  let [otp, setOtp] = useState("");
  let [otpsucess, setOtpSucess] = useState(false);
  let [otpvarify, setOtpVerify] = useState(false);

  // //(otpvarify)
  //(loading);

  let HandleClickLink = () => {
    navigate("/");
    const newTab = window.open("https://student.qspiders.com/login", "_blank");
    newTab.focus();
  };
  let handleSubmit = async e => {
    setLoading(true);
    e.preventDefault();
    //(number);
    if (number === "") {
      toast.error("number is required", {
        position: "top-right",
      });
    } else {
      if (otpsucess === false) {
        let payload = { number: number };
        //  //(payload)
        await AxiosInstance.post("/users/otpsent", payload)
          .then(data => {
            setLoading(false);
            //(data);
            setOtpSucess(true);
            setMessage(data.data.res);
            toast.success(data.data.res, {
              position: "top-right",
            });
          })
          .catch(data => {
            setLoading(false);

            toast.error(data?.response?.data?.message, {
              position: "top-right",
            });
            if (data?.response?.status === 404) {
              HandleClickLink();
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };
  let submitOtp = async e => {
    e.preventDefault();
    let payload = { number: number, otp: otp };
    //(payload);
    //  //(payload)
    await AxiosInstance.post("/users/otpvalidate", payload)
      .then(data => {
        //(data);
        //(data);
        setOtpVerify(true);
      })
      .catch(data => {
        //(data.res);
        toast.error(data.response.data, {
          position: "top-right",
        });
      });
  };

  let ChangePass = e => {
    //("first");
    // //(password);
    // //(confirmPassword !== password);
    // //(password === "");
    // //(password.length <= 8);
    e.preventDefault();
    if (
      password.length < 8 ||
      confirmPassword === "" ||
      confirmPassword !== password
    ) {
      // //("if");
      //(errors)
      setErrors(PassValidation(password, confirmPassword));
    } else {
      // //("else");
      let pass = password.trim();
      // //(pass);
      let payload = {
        number: number,
        newpassword: pass,
        confirmpassword: confirmPassword,
      };
      //(payload);
      async function fetchData() {
        await AxiosInstance.post("/users/password_reset", payload)
          .then(data => {
            //(data);
            //(data);
            toast.success(data.data.msg, {
              position: "top-right",
            });
            navigate("/");
          })
          .catch(data => {
            //(data.res);
            toast.error(data.response.data, {
              position: "top-right",
            });
          });
      }
      fetchData();
    }
  };

  return (
    <>
      <section id={Styles.authBlock}>
        <article>
          <div className={Styles.container}>
            <form>
              <>
                <h1>Reset Password </h1>
                <div className={Styles.pass}>
                  {loading === true ? (
                    <div className={Styles.Loader}>
                      {" "}
                      <Loader
                        size="sm"
                        top
                        color="white"
                        content="OTP is sending"
                      />
                    </div>
                  ) : (
                    ""
                  )}
                  <label htmlFor="number">Number</label>
                  <aside>
                    <input
                      className="form-control"
                      type="text"
                      name="number"
                      value={number}
                      placeholder="Enter Number"
                      onChange={e => setNumber(e.target.value)}
                    />
                  </aside>
                </div>

                {otpsucess === true ? (
                  <>
                    <div className={Styles.pass}>
                      <label htmlFor="otp">OTP</label>
                      <input
                        type="text"
                        placeholder="Enter OTP"
                        value={otp}
                        onChange={e => setOtp(e.target.value)}
                      />
                    </div>
                    <p style={{ color: "white" }}>{message}</p>
                  </>
                ) : (
                  ""
                )}
                {otpsucess === true ? (
                  <div
                    className={
                      otpvarify === true
                        ? `${Styles.Displaynone}`
                        : `${Styles.display}`
                    }
                  >
                    <button onClick={submitOtp}>Submit</button>
                  </div>
                ) : (
                  <div>
                    <button onClick={handleSubmit}>Get Otp</button>
                  </div>
                )}
                {otpvarify === true ? (
                  // //("hiii")
                  <>
                    <div>
                      <label htmlFor="password">Password</label>
                      <input
                        type="text"
                        placeholder="Enter password"
                        value={password}
                        onChange={e => setPassword(e.target.value)}
                      />
                      <p
                        className={
                          errors.password
                            ? `${Styles.errrr}`
                            : `${Styles.gapbtw}`
                        }
                      ></p>

                      {errors.password && (
                        <p className={Styles.errors}>{errors.password}</p>
                      )}
                    </div>
                    <div>
                      <label htmlFor="confirmPassword">Confirm password</label>
                      <input
                        type="text"
                        placeholder="Confirm password"
                        value={confirmPassword}
                        onChange={e => setConfirmPassword(e.target.value)}
                      />
                      <p
                        className={
                          errors.confirmPassword
                            ? `${Styles.errrr}`
                            : `${Styles.gapbtw}`
                        }
                      ></p>

                      {errors.confirmPassword && (
                        <p className={Styles.errors}>
                          {errors.confirmPassword}
                        </p>
                      )}
                    </div>
                    <div>
                      <button onClick={ChangePass}>Reset Password</button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </>
            </form>
          </div>
        </article>
      </section>
    </>
  );
};

export default ForgotPass;
