import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../../api/AxiosInstance";
import { SocketContext } from "../../../ioContext/socketIo";
import styles from "./Modal.module.css";
import { FiSend } from "react-icons/fi";
import { RotatingLines } from "react-loader-spinner";
import { Button } from "@mui/material";

const FileUpload = ({
  batchData,
  groupData,
  setOpen,
  pastedImage,
  setPastedImage,
  setArrivalMessageFile,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const socket = useContext(SocketContext);
  let [files, setNewFiles] = useState([]);
  let [loading, setLoading] = useState(false);

  const [imagePreview, setImagePreview] = useState(null);
  // const [videoPreview, setVideoPreview] = useState(null);
  // Integrate pastedImage into files state if it's available
  useEffect(() => {
    if (pastedImage) {
      fetch(pastedImage)
        .then(res => res.blob())
        .then(blob => {
          const file = new File([blob], "pasted-image.png", {
            type: blob.type,
          });
          setNewFiles([...files, file]); // Add the pasted image to the files array
          setImagePreview([
            <img src={pastedImage} alt="" width={250} height={200} />,
          ]);
        });
    }
  }, [pastedImage]);

  function previewFile(e) {
    // Reading New File (open file Picker Box)
    // const reader = new FileReader();
    // Gettting Selected File (user can select multiple but we are choosing only one)
    const selectedFile = e.target.files;
    setNewFiles([...files, ...e.target.files]);
    const previews = [];

    for (let i = 0; i < selectedFile.length; i++) {
      const reader = new FileReader();
      const file = selectedFile[i];
      reader.readAsDataURL(file);
      reader.onload = readerEvent => {
        if (file.type.includes("image")) {
          previews.push(
            <img
              src={readerEvent.target.result}
              alt=""
              width={250}
              height={200}
            />
          );
        }
        setImagePreview(previews);
      };
    }
  }
  const handleDelete = index => {
    setNewFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
  };
  let handleSubmit = async e => {
    e.preventDefault();
    if (files !== "") {
      setLoading(true);
      let result = Array.isArray(files);
      //(result);
      let abc = files?.map(x => x);

      try {
        let payload = {
          files,
          sender: user._id,
        };
        //(payload)
        const options = {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
            "Content-Type": "multipart/form-data",
          },
        };
        const res = await AxiosInstance.post(
          "/chat/newmsgfiles",
          payload,
          options
        );
        let filesData = res.data.files;
        let aaa = filesData.map(x => {
          if (batchData) {
            return new Promise((resolve, reject) => {
              socket.emit(
                "broadcastchat",
                {
                  username: user?.username,
                  files: [x],
                  senderId: user?._id,
                  role: user?.role1,
                  broadcastchat: batchData?.batchCode,
                  batchId: batchData?._id,
                  device: "web",
                },
                function callback(msg) {
                  setLoading(false);

                  resolve(msg);
                  // setMessages([...messages, msg]);
                }
              );
            });
          } else if (groupData) {
            return new Promise((resolve, reject) => {
              socket.emit(
                "broadcastchat",
                {
                  username: user?.username,
                  files: [x],
                  senderId: user?._id,
                  role: user?.role1,
                  broadcastchat: groupData?.groupCode,
                  groupId: groupData?._id,
                  device: "web",
                },
                function callback(msg) {
                  setLoading(false);

                  resolve(msg);
                  //  setMessages([...messages, msg]);
                }
              );
            });
          }
        });
        Promise.all(aaa)
          .then(messages => {
            // Update the state with the received messages
            messages.forEach(msg => {
              if (batchData) {
                setArrivalMessageFile(prevMessages => [...prevMessages, msg]);
                setOpen(false);
              } else {
                setArrivalMessageFile(prevMessages => [...prevMessages, msg]);
                setOpen(false);
              }
            });
            setLoading(false);
          })
          .catch(error => {
            console.error("Error:", error);
            // Handle any errors that may occur during the socket.emit calls
          });
        setOpen(false);
        setImagePreview(null);
        setPastedImage(null);
        setNewFiles([]);
      } catch (error) {}
    } else {
    }
  };
  console.log(files);
  return (
    <div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className={styles.fileupload}>
          {files.length <= 0 && (
            <Button
              size="small"
              variant="contained"
              component="label"
              className={styles.fileUpload}
              title="Add File"
              color="success"
              style={{ background: "green", color: "#fff" }}
            >
              <input
                type="file"
                name="image"
                onChange={e => previewFile(e)}
                hidden
                multiple
                style={{ display: "none" }}
              />
              Choose File
            </Button>
          )}
          <span style={{ fontWeight: "bold" }}>{files[0]?.name}</span>
        </div>
        <br />
        <div
          className={styles.previewContainer}
          style={{ display: "flex", flexWrap: "wrap" }}
        >
          {files.length > 0 &&
            files.map((file, index) => (
              <div className={styles.imagePreviewContainer} key={index}>
                {file.type && file.type.includes("image") ? (
                  <React.Fragment>
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      width={100}
                      height={100}
                      className={styles.imagePreview}
                    />
                    <span
                      className={styles.deleteButton}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <svg width={26} height={26} viewBox="0 0 12 12">
                        <path
                          fill="crimson"
                          d="M5 3h2a1 1 0 0 0-2 0M4 3a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 7.623 11H4.377a2 2 0 0 1-1.993-1.83L1.941 4H1.5a.5.5 0 0 1 0-1zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0zM5 5.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M3.38 9.085a1 1 0 0 0 .997.915h3.246a1 1 0 0 0 .996-.915L9.055 4h-6.11z"
                        ></path>
                      </svg>
                    </span>
                  </React.Fragment>
                ) : (
                  <div className={styles.nonImagePreview}>
                    <div className={styles.fileIcon}>
                      <span role="img" aria-label="File Icon">
                        📄
                      </span>
                    </div>
                    <div className={styles.fileName}>{file.name}</div>
                    <span
                      className={styles.deleteButton}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <svg width={26} height={26} viewBox="0 0 12 12">
                        <path
                          fill="crimson"
                          d="M5 3h2a1 1 0 0 0-2 0M4 3a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 7.623 11H4.377a2 2 0 0 1-1.993-1.83L1.941 4H1.5a.5.5 0 0 1 0-1zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0zM5 5.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M3.38 9.085a1 1 0 0 0 .997.915h3.246a1 1 0 0 0 .996-.915L9.055 4h-6.11z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
        {/* <div>
          <div className="preview">
            {imagePreview && imagePreview.map(preview => preview)}
       
          </div>
        </div> */}
        <div className={styles.sendbtnfile}>
          {loading ? (
            <button disabled id={styles.filesubmit}>
              <div className={styles.loader}>
                <RotatingLines
                  strokeColor="#f16f1d"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              </div>
            </button>
          ) : (
            <button
              id={styles.filesubmit}
              disabled={files.length === 0}
              title={files.length === 0 ? "Select file" : "send"}
            >
              <FiSend />
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FileUpload;
