import React, { useContext, useEffect, useState } from "react";
import Styles from "./_chat.module.css";
import { SocketContext } from "../../../ioContext/socketIo";
import dateFormat from "dateformat";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { FcCheckmark } from "react-icons/fc";

import { Tooltip, Whisper } from "rsuite";
const StuConversation = ({
  batchTrainer,
  setParticularStudent,
  batchname,
  groupname,
  messages,
  setStop,
  setIsPlaying,
  setRecordedAudio,
  setIsPlaybackActive,
  setIsAudioVisible,
  setRecordedChunks,

  setMessages,
  particularStudent,
  setSelectedMessages,
  setReply,
  handleBackClick,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let [search, setSearch] = useState("");
  const socket = useContext(SocketContext);
  let [notification, setNotification] = useState({});
  let [batchStudentList, setBatchStudentList] = useState([]);
  batchStudentList = batchTrainer;

  useEffect(() => {
    socket.on("ischatroom", data => {
      console.log("hello");
      console.log(data);
    });
    socket.on("notification", data => {
      setNotification(data);
    });
  }, [notification]);

  useEffect(() => {
    let data1 = batchStudentList?.map(y => {
      return y._id === notification?.msg?.sender
        ? {
            ...y,
            createdAt: (y.createdAt = notification?.msg?.createdAt),
            lastmsg: y.lastmsg
              ? {
                  ...y.lastmsg,
                  studentNotification: (y.lastmsg.studentNotification =
                    Number(y.lastmsg.studentNotification) + 1),
                  msg: (y.lastmsg.msg = notification.msg),
                }
              : (y.lastmsg = {
                  Conversation: notification?.msg?.receiver,
                  msg: notification?.msg,
                  studentNotification: 1,
                  createdAt: notification?.msg?.createdAt,
                }),
          }
        : { ...y };
    });
    setBatchStudentList(data1);
  }, [notification]);
  useEffect(() => {
    let data1 = batchStudentList?.map(x => {
      return x?._id === messages?.slice(-1)[0]?.receiver
        ? {
            ...x,
            createdAt: (x.createdAt = messages?.slice(-1)[0]?.createdAt),
            lastmsg: x.lastmsg
              ? {
                  ...x.lastmsg,
                  msg: (x.lastmsg.msg = messages?.slice(-1)[0]),
                }
              : (x.lastmsg = {
                  Conversation: messages?.slice(-1)[0]?.sender,
                  studentid: messages?.slice(-1)[0]?.sender,
                  msg: messages?.slice(-1)[0],
                  createdAt: messages?.slice(-1)[0]?.createdAt,
                }),
          }
        : { ...x };
    });

    setBatchStudentList(data1);
  }, [messages]);

  let handleNotification = sid => {
    let data1 = batchStudentList?.map(x => {
      return x._id === sid
        ? {
            ...x,
            lastmsg: [
              {
                ...x.lastmsg,
                studentNotification: (x.lastmsg.studentNotification = 0),
              },
            ],
          }
        : x;
    });

    setBatchStudentList(data1);
  };

  let d = Date.now();
  let today = dateFormat(d, "mmm d, yyyy");
  //(search);
  return (
    <>
      <div className={Styles.Head}>
        <h1>{user.username}</h1>
        {batchname ? <h4>{batchname}</h4> : <h4>{groupname}</h4>}
      </div>

      <div>
        <div className={Styles.chatMenuWrapper}>
          <span>
            <input
              name="search"
              type="search"
              placeholder="Search"
              onChange={e => setSearch(e.target.value.toLowerCase())}
            />
          </span>
          <span className={Styles.searchIcon}></span>
          <div className={Styles.ConversationBlock}>
            <>
              {batchStudentList?.length > 0 &&
                batchStudentList
                  .sort(
                    (a, b) =>
                      new Date(b.createdAt).getTime() -
                      new Date(a.createdAt).getTime()
                  )
                  .filter(trainer =>
                    trainer?.username?.toLowerCase().includes(search)
                  )
                  .map((trainer, ind) => {
                    return (
                      <div
                        style={{
                          backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT7t0PfqiaGFMmJDHK4GReTM2kZBwY4dfB6EQ&usqp=CAU')`,
                          backgroundSize: "cover",
                          backgroundRepeat: "no-repeat",
                          border: "0.3px ridge #999",
                          borderRadius: "6px",
                          marginBottom: "5px",
                        }}
                        className={Styles.listUser}
                        key={ind}
                        onClick={() => {
                          setParticularStudent({
                            role: user?.role1,
                            code: batchname ? batchname : groupname,
                            trainersId: trainer?._id,
                            trainerName: trainer?.username,
                            notification: true,
                            notiCount: trainer?.lastmsg?.usersNotification,
                            studentnoti: trainer?.lastmsg?.studentNotification,
                            userleft: trainer?.userleft,
                          });
                          handleNotification(trainer?._id);
                          setStop(false);
                          setIsPlaying(false);
                          setRecordedAudio(null);
                          setIsPlaybackActive(false);
                          setSelectedMessages([]);
                          setReply(false);

                          setIsAudioVisible(false); // Hide the audio tag
                          setRecordedChunks([]); // Clear the recorded audio chunks
                          setIsPlaying(false);
                          handleBackClick();

                          if (
                            particularStudent.trainersId !==
                            particularStudent.trainersId
                          ) {
                            setMessages([]);
                          }
                        }}
                      >
                        <div className={Styles.conversation}>
                          {trainer?.role?.length > 1 ? (
                            <Whisper
                              followCursor
                              speaker={
                                <Tooltip>{trainer?.role.join()}</Tooltip>
                              }
                            >
                              <span>
                                {trainer?.username} -{" "}
                                {trainer?.role?.length > 1
                                  ? trainer?.role
                                      .join()
                                      ?.toString()
                                      .substring(0, 15) + ". . ."
                                  : trainer?.role}
                              </span>
                            </Whisper>
                          ) : (
                            <span>
                              {trainer?.username} - {trainer?.role[0]}
                            </span>
                          )}

                          <div
                            className={
                              trainer?.lastmsg?.studentNotification
                                ? trainer?.lastmsg?.studentNotification !== 0
                                  ? `${Styles.notify}`
                                  : `${Styles.nonotify}`
                                : ""
                            }
                          >
                            <span>
                              {trainer?.lastmsg?.studentNotification !== 0
                                ? trainer?.lastmsg?.studentNotification
                                : ""}
                            </span>
                          </div>
                        </div>
                        <div className={Styles.tickmark}>
                          <div>
                            {/* <span> */}
                            {trainer?.lastmsg ? (
                              trainer?.sender !== user?._id ? (
                                ""
                              ) : trainer?.read ? (
                                <IoCheckmarkDoneSharp
                                  className={Styles.icontick}
                                  style={{ color: " rgb(67, 160, 71)" }}
                                />
                              ) : (
                                <FcCheckmark className={Styles.icontick} />
                              )
                            ) : (
                              ""
                            )}

                            <p
                              className={
                                trainer.lastmsg
                                  ? `${Styles.lastmsg}`
                                  : `${Styles.Nolastmsg}`
                              }
                            >
                              <span>
                                {trainer?.lastmsg?.msg ? (
                                  trainer?.lastmsg?.msg?.messages?.files ? (
                                    `${trainer?.lastmsg?.msg?.messages?.files[0]?.originalname.slice(
                                      0,
                                      15
                                    )}(File)`
                                  ) : trainer?.lastmsg?.msg?.messages?.text
                                      ?.length > 20 ? (
                                    trainer?.lastmsg?.msg?.messages?.text?.slice(
                                      0,
                                      20
                                    ) +
                                    " " +
                                    ". . ."
                                  ) : (
                                    trainer?.lastmsg?.msg?.messages?.text
                                  )
                                ) : (
                                  <span>No recent message</span>
                                )}
                              </span>
                            </p>
                          </div>
                          <span className={Styles.time}>
                            {trainer?.lastmsg?.msg?.createdAt
                              ? dateFormat(
                                  trainer?.lastmsg?.createdAt,
                                  "mmm d, yyyy"
                                ) === today
                                ? dateFormat(
                                    trainer?.lastmsg?.msg?.createdAt,
                                    "DDDD h:MM TT"
                                  )
                                : dateFormat(
                                    trainer?.lastmsg?.msg?.createdAt,
                                    "DDDD h:MM TT"
                                  )
                              : ""}
                          </span>
                        </div>
                      </div>
                    );
                  })}
            </>
          </div>
        </div>
      </div>
    </>
  );
};

export default StuConversation;
