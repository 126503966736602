import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "rsuite";
import { Qandq } from "../../api/AxiosInstance";
import Styles from "./_admin.module.css";

const QandqBranchList = () => {
  let { userAdmin } = useSelector(state => state.auth);
  let [branch, setBranch] = useState("");
  let [branch1, setBranch1] = useState("");

  let Handlehitapi = async e => {
    // e.preventDefalt()
    let payload = {
      type: "qtusers",
      branch: branch,
    };
    //(payload);
    await Qandq.post(`/api/hrbd/ping`, payload, {
      headers: {
        Authorization: `Bearer ${userAdmin.TOKEN}`,
      },
    });
    //(data);
  };

  let Handlehitapi1 = async e => {
    // e.preventDefalt()
    let payload = {
      type: "bbp",
      branch: branch,
    };
    //(payload);
    await Qandq.post(`/api/hrbd/ping`, payload, {
      headers: {
        Authorization: `Bearer ${userAdmin.TOKEN}`,
      },
    });
    //(data);
  };

  let HandleBatchListPing = async () => {
    await Qandq.post(
      `/api/hrbd/ping`,

      {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      }
    );
    //(data);
  };
  return (
    <section className={Styles.QandqBatchList}>
      {/* <h2 className={Styles.Heading}>QandqBranchList</h2>
      <Button
        appearance="primary"
        size="lg"
        onClick={HandleBatchListPing}
        className={Styles.PingButton}
      >
        PingBatchList
      </Button> */}
      <table>
        <thead>
          <tr>
            <th>QandqBranchList</th>
            <td></td>
            <td>
              {" "}
              <Button
                appearance="primary"
                size="lg"
                onClick={HandleBatchListPing}
                className={Styles.PingButton}
              >
                PingBatchList
              </Button>
            </td>
          </tr>
          <tr>
            <th>BranchUserhitapi</th>
            <td>
              <input
                type="text"
                placeholder="Branch"
                name="branch"
                value={branch}
                className={Styles.Inputhitapi}
                onChange={e => setBranch(e.target.value)}
              />
            </td>
            <td>
              <Button appearance="primary" size="sm" onClick={Handlehitapi}>
                Submit
              </Button>
            </td>
          </tr>
          <tr>
            <th>BranchBatchPlan</th>
            <td>
              <input
                type="text"
                placeholder="Branch"
                name="branch1"
                value={branch1}
                className={Styles.Inputhitapi}
                onChange={e => setBranch1(e.target.value)}
              />
            </td>
            <td>
              <Button appearance="primary" size="sm" onClick={Handlehitapi1}>
                Submit
              </Button>
            </td>
          </tr>
        </thead>
      </table>
    </section>
  );
};

export default QandqBranchList;
