import React from "react";
import { Outlet } from "react-router-dom";


const OtherMainBar = () => {
  return (
    <div>
      <Outlet />
    </div>
  );
};

export default OtherMainBar;
