import {
  Avatar,
  Box,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { getRandomColor, createImageFromInitials } from "./profilePic";
import Styles from "./_navbar.module.css";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { Modal } from "rsuite";
import { Button as MUIButton } from "@mui/material";
import Cookies from "js-cookie";
import { socket } from "../../ioContext/socketIo";
import { useDispatch, useSelector } from "react-redux";
import { StudentlogOut } from "../../redux/auth/AuthSlice";
import AxiosInstance, { qandq } from "../../api/AxiosInstance";
import { testFreshers } from "../../api/AxiosInstance";

const menuStyle = {
  borderBottom: "2px solid #f16f1d",
  color: "#f16f1d",
  fontWeight: "bold",
};

const StudentMenu = () => {
  const isCookieLogin = sessionStorage.getItem("isCookie");

  let [notification, setNotification] = useState(false);
  const location = useLocation();
  const [brandNotification, setBrandNotification] = useState([]);
  let dispatch = useDispatch();
  const navigate = useNavigate();
  let { isLogoutSuccess } = useSelector(state => state.auth);
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  let name = user && user?.username;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open1 = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose1 = () => {
    setAnchorEl(null);
  };

  // media query
  const isMobile = useMediaQuery("(max-width: 706px)");
  const [showSideBar, setShowSideBar] = useState(false);
  const [helpNoti, setHelpNoti] = useState(false);
  const handleShowSideBar = () => {
    setShowSideBar(prevState => !prevState);
  };

  //logout
  const [open, setOpen] = React.useState(false);
  let IsCookie = sessionStorage.getItem("isCookie");

  const handleClose = () => {
    setOpen(false);
    // navigate(-1);
  };

  const IsStudentLogout = () => {
    let finaldeviceId = Cookies.get("device");
    let payload = { deviceId: finaldeviceId };
    socket.emit("logoutuser", {
      userId: user?._id,

      device: "web",
    });
    dispatch(StudentlogOut(payload));

    if (socket) {
      socket.disconnect();
    }
    navigate("/");
  };

  let handleLogout = () => {
    IsStudentLogout();
  };
  useEffect(() => {
    if (isLogoutSuccess) {
      window.location.reload();
      navigate("/");
    }
  }, [isLogoutSuccess]);

  // notification socket
  useEffect(() => {
    socket.on("helpNotification", data => {
      if (data) {
        setNotification(true);
      }
      // setNotification(prev => [...prev, data]);
    });
    return () => {
      socket.off("helpNotification", handleNotification);
    };
  }, [socket]);
  useEffect(() => {
    const fetchNoti = async () => {
      const { data } = await AxiosInstance.get(`help/helpgroup_notification`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      });

      setNotification(data?.helpgroup_notification);
    };
    fetchNoti();
  }, []);
  // branding requirement from hr notification
  useEffect(() => {
    socket.on("requirement", data => {
      if (data) {
        setBrandNotification([123]);
      }
    });
    return () => {
      socket.off("requirement", handleNotification);
    };
  }, [socket]);

  // Normal branding notification in student
  useEffect(() => {
    socket.on("broadcastnotification", data => {
      if (data) {
        setBrandNotification([123]);
      }
    });
    return () => {
      socket.off("broadcastnotification", handleNotification);
    };
  }, []);

  let handleNotification = () => {
    setNotification(false);
  };

  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location]);

  const [selectedMenu, setSelectedMenu] = useState("/student-dashboard");

  let handleNavigateOtherApps = url => {
    if (url) {
      const newWindow = window.open(url, "_blank");

      if (newWindow) {
        newWindow.focus();
      } else {
        window.location.href = url;
      }
    }
  };

  useEffect(() => {
    const fetchNoti = async () => {
      const { data } = await AxiosInstance.get(`/brand/getBrandNotification`, {
        headers: {
          Authorization: `Bearer ${user?.TOKEN}`,
        },
      });
      let noti = data.student?.brandNotification;

      if (noti) {
        setBrandNotification([123]);
      }
    };
    fetchNoti();
  }, []);

  let handleModalLogout = () => {
    setOpen(true);
  };

  return (
    <section>
      {/* Logout modal */}
      <Modal
        backdrop="static"
        role="alertdialog"
        open={open}
        onClose={handleClose}
        size="xs"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Body style={{ fontSize: "16px" }}>
          Are You Sure You Want To Logout?
        </Modal.Body>
        <Modal.Footer>
          <MUIButton
            size="small"
            color="warning"
            variant="contained"
            onClick={handleLogout}
          >
            OK
          </MUIButton>{" "}
          &nbsp;
          <MUIButton size="small" variant="outlined" onClick={handleClose}>
            Cancel
          </MUIButton>
        </Modal.Footer>
      </Modal>
      {isMobile ? (
        <div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="1em"
            height="1em"
            style={{
              marginRight: "0.7rem",
              fontSize: "1.5rem",
              color: "#3C3838",
            }}
            viewBox="0 0 24 24"
            onClick={handleShowSideBar}
          >
            <path
              fill="currentColor"
              d="M4 6a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m0 12a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1m1-7a1 1 0 1 0 0 2h8a1 1 0 1 0 0-2z"
            />
          </svg>
          {showSideBar && (
            <Box
              style={{
                height: "100vh",
                width: "100%",
                position: "absolute",
                zIndex: "2",
                backgroundColor: "white",
                left: "1px",
                top: "0px",
                Margin: "20px",
              }}
            >
              <Box
                style={{
                  width: "100%",
                  height: "7.5vh",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                }}
              >
                <span
                  style={{
                    height: "24px",
                    width: "24px",
                    backgroundColor: "#F4F4F4",
                    borderRadius: "50%",
                    display: "inline-block",
                    marginRight: "1.2rem",
                    justifyContent: "center",
                  }}
                  onClick={handleShowSideBar}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="1em"
                    height="1em"
                    style={{
                      marginRight: "0.7rem",
                      fontSize: "1.5rem",
                      color: "#3C3838",
                    }}
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.5"
                      d="M6.758 17.243L12.001 12m5.243-5.243L12 12m0 0L6.758 6.757M12.001 12l5.243 5.243"
                    />
                  </svg>
                </span>
              </Box>
              <Box sx={{ margin: "16px" }}>
                <NavLink
                  to="/student-dashboard/"
                  title="Requirement page"
                  style={({ isActive }) => ({
                    textDecoration: "none",

                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 256 256"
                      >
                        <path
                          fill="#333"
                          d="M172 108a12 12 0 0 1-12 12H96a12 12 0 0 1 0-24h64a12 12 0 0 1 12 12m-12 28H96a12 12 0 0 0 0 24h64a12 12 0 0 0 0-24m76-8a108 108 0 0 1-157.23 96.15L46.34 235A20 20 0 0 1 21 209.66l10.81-32.43A108 108 0 1 1 236 128m-24 0a84 84 0 1 0-156.73 42.06a12 12 0 0 1 1 9.81l-9.93 29.79l29.79-9.93a12.1 12.1 0 0 1 3.8-.62a12 12 0 0 1 6 1.62A84 84 0 0 0 212 128"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Home
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/student-dashboard/requirement"
                  title="Requirement page"
                  style={({ isActive }) => ({
                    textDecoration: "none",

                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="#333"
                          d="M5 1a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V5.414a1.5 1.5 0 0 0-.44-1.06L9.647 1.439A1.5 1.5 0 0 0 8.586 1zM4 3a1 1 0 0 1 1-1h3v2.5A1.5 1.5 0 0 0 9.5 6H12v7a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1zm7.793 2H9.5a.5.5 0 0 1-.5-.5V2.207zM7 7.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M7.5 9a.5.5 0 0 0 0 1h3a.5.5 0 0 0 0-1zM7 11.5a.5.5 0 0 1 .5-.5h3a.5.5 0 0 1 0 1h-3a.5.5 0 0 1-.5-.5M5.5 8a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1M6 9.5a.5.5 0 1 1-1 0a.5.5 0 0 1 1 0M5.5 12a.5.5 0 1 0 0-1a.5.5 0 0 0 0 1"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Requirement
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/student-dashboard/grooming"
                  title="Grooming page"
                  style={({ isActive }) => ({
                    textDecoration: "none",

                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 14 14"
                      >
                        <g
                          fill="none"
                          stroke="#333"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        >
                          <path d="M6.35.5h6.302c.469 0 .849.38.849.849v6.778c0 .47-.38.85-.849.85H7.5M3.149 4.001a1.75 1.75 0 1 0 0-3.501a1.75 1.75 0 0 0 0 3.501" />
                          <path d="M9 5.527C9 4.96 8.54 4.5 7.973 4.5H3.149v0A2.65 2.65 0 0 0 .5 7.149V9.5h1.135l.379 4h2.27l.872-6.945h2.817C8.54 6.555 9 6.095 9 5.527" />
                        </g>
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Grooming
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/student-dashboard/mock"
                  title="Mock page"
                  style={({ isActive }) => ({
                    textDecoration: "none",

                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#333"
                          d="M16 20V4H4v15a1 1 0 0 0 1 1zm3 2H5a3 3 0 0 1-3-3V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v7h4v9a3 3 0 0 1-3 3m-1-10v7a1 1 0 1 0 2 0v-7zM6 6h6v6H6zm2 2v2h2V8zm-2 5h8v2H6zm0 3h8v2H6z"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Mock
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/student-dashboard/status"
                  title="Grooming page"
                  style={({ isActive }) => ({
                    textDecoration: "none",

                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 56 56"
                      >
                        <path
                          fill="#333"
                          d="m50.923 21.002l.046.131l.171.566l.143.508l.061.232l.1.42a23.93 23.93 0 0 1-2.653 17.167a23.93 23.93 0 0 1-13.57 10.89l-.404.12l-.496.128l-.717.17a1.89 1.89 0 0 1-2.288-1.558a2.127 2.127 0 0 1 1.606-2.389l.577-.145q.54-.142.929-.273a19.93 19.93 0 0 0 10.899-8.943a19.93 19.93 0 0 0 2.292-13.923l-.069-.313l-.092-.365l-.115-.418l-.138-.47a2.135 2.135 0 0 1 1.26-2.602a1.894 1.894 0 0 1 2.458 1.067M7.385 19.92q.065.02.128.044A2.127 2.127 0 0 1 8.78 22.55q-.27.909-.39 1.513a19.93 19.93 0 0 0 2.295 13.91a19.93 19.93 0 0 0 10.911 8.947l.306.097l.174.05l.39.106l.694.171a2.135 2.135 0 0 1 1.623 2.393a1.894 1.894 0 0 1-2.152 1.594l-.138-.025l-.576-.135l-.51-.13l-.446-.125l-.2-.06A23.93 23.93 0 0 1 7.22 39.972a23.93 23.93 0 0 1-2.647-17.197l.077-.32l.1-.375l.194-.665l.076-.25a1.89 1.89 0 0 1 2.365-1.246M28.051 12c8.837 0 16 7.163 16 16s-7.163 16-16 16s-16-7.163-16-16s7.164-16 16-16m0 4c-6.627 0-12 5.373-12 12s5.373 12 12 12c6.628 0 12-5.373 12-12s-5.372-12-12-12m0-12a23.93 23.93 0 0 1 16.217 6.306l.239.227l.275.274l.31.322l.346.369a1.89 1.89 0 0 1-.205 2.76a2.127 2.127 0 0 1-2.873-.196q-.326-.345-.605-.617l-.35-.334l-.16-.143A19.93 19.93 0 0 0 28.051 8a19.93 19.93 0 0 0-13.204 4.976l-.114.102l-.253.24l-.287.285l-.495.515c-.76.809-2.014.9-2.883.21a1.894 1.894 0 0 1-.305-2.662l.09-.106l.405-.431l.368-.378q.262-.263.484-.465A23.93 23.93 0 0 1 28.05 4"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Status
                    </span>
                  </Box>
                </NavLink>
                <NavLink
                  to="/student-dashboard/branding"
                  title="Spidey aura page"
                  style={({ isActive }) => ({
                    textDecoration: "none",

                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#333"
                          d="M12 2C6.47 2 2 6.5 2 12s4.5 10 10 10s10-4.5 10-10S17.5 2 12 2m0 18c-4.42 0-8-3.58-8-8s3.58-8 8-8s8 3.58 8 8s-3.58 8-8 8m-4-6L7 8l3 2l2-3l2 3l3-2l-1 6zm.56 2c-.34 0-.56-.22-.56-.56V15h8v.44c0 .34-.22.56-.56.56z"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Spidey Aura
                    </span>
                    {brandNotification.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "10%",
                          left: "28%",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 48 48"
                        >
                          <path
                            fill="green"
                            stroke="green"
                            stroke-width="4"
                            d="M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z"
                          />
                        </svg>
                      </div>
                    )}
                  </Box>
                </NavLink>
                <NavLink
                  to="/student-dashboard/help"
                  title="Help page"
                  style={({ isActive }) => ({
                    textDecoration: "none",

                    color: isActive ? "#f96900" : "black",
                  })}
                  onClick={showSideBar}
                  exact
                  activeClassName="active-link"
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                    onClick={() => {
                      handleNotification();
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#333"
                          d="M11.95 18q.525 0 .888-.363t.362-.887t-.362-.888t-.888-.362t-.887.363t-.363.887t.363.888t.887.362m-.9-3.85h1.85q0-.825.188-1.3t1.062-1.3q.65-.65 1.025-1.238T15.55 8.9q0-1.4-1.025-2.15T12.1 6q-1.425 0-2.312.75T8.55 8.55l1.65.65q.125-.45.563-.975T12.1 7.7q.8 0 1.2.438t.4.962q0 .5-.3.938t-.75.812q-1.1.975-1.35 1.475t-.25 1.825M12 22q-2.075 0-3.9-.787t-3.175-2.138T2.788 15.9T2 12t.788-3.9t2.137-3.175T8.1 2.788T12 2t3.9.788t3.175 2.137T21.213 8.1T22 12t-.788 3.9t-2.137 3.175t-3.175 2.138T12 22m0-2q3.35 0 5.675-2.325T20 12t-2.325-5.675T12 4T6.325 6.325T4 12t2.325 5.675T12 20m0-8"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Help
                    </span>
                    {notification.length > 0 && (
                      <div
                        style={{
                          position: "absolute",
                          top: "8%",
                          left: "20%",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          viewBox="0 0 48 48"
                        >
                          <path
                            fill="green"
                            stroke="green"
                            stroke-width="4"
                            d="M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z"
                          />
                        </svg>
                      </div>
                    )}
                  </Box>
                </NavLink>

                <NavLink
                  to={"/student-dashboard/addquestion"}
                  title="Add interview question page"
                  style={({ isActive }) => ({
                    textDecoration: "none",

                    color: isActive ? "#f96900" : "black",
                  })}
                  exact
                  activeClassName="active-link"
                  onClick={() => showSideBar}
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                    }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#333"
                          fill-rule="evenodd"
                          d="M6.86 1.25h.127c.351 0 .577 0 .798.02a4.75 4.75 0 0 1 2.59 1.073c.17.142.33.302.579.55l.576.577c.846.845 1.171 1.161 1.547 1.37q.328.182.689.286c.413.117.866.124 2.062.124h.425c1.273 0 2.3 0 3.111.102c.841.106 1.556.332 2.144.86q.147.133.28.28c.529.588.754 1.303.86 2.144c.102.812.102 1.838.102 3.111v2.31c0 1.837 0 3.293-.153 4.432c-.158 1.172-.49 2.121-1.238 2.87c-.749.748-1.698 1.08-2.87 1.238c-1.14.153-2.595.153-4.433.153H9.944c-1.838 0-3.294 0-4.433-.153c-1.172-.158-2.121-.49-2.87-1.238c-.748-.749-1.08-1.698-1.238-2.87c-.153-1.14-.153-2.595-.153-4.433V6.86c0-.797 0-1.303.082-1.74A4.75 4.75 0 0 1 5.12 1.331c.438-.082.944-.082 1.74-.082m.09 1.5c-.917 0-1.271.003-1.553.056a3.25 3.25 0 0 0-2.59 2.591c-.054.282-.057.636-.057 1.553V14c0 1.907.002 3.262.14 4.29c.135 1.005.389 1.585.812 2.008s1.003.677 2.009.812c1.028.138 2.382.14 4.289.14h4c1.907 0 3.262-.002 4.29-.14c1.005-.135 1.585-.389 2.008-.812s.677-1.003.812-2.009c.138-1.027.14-2.382.14-4.289v-2.202c0-1.336-.001-2.267-.09-2.975c-.087-.689-.246-1.06-.487-1.328a2 2 0 0 0-.168-.168c-.268-.241-.64-.4-1.328-.487c-.707-.089-1.639-.09-2.975-.09h-.484c-1.048 0-1.724 0-2.363-.182c-.35-.1-.689-.24-1.008-.417c-.58-.324-1.058-.801-1.8-1.543l-.077-.078l-.55-.55a8 8 0 0 0-.503-.482a3.25 3.25 0 0 0-1.771-.734a8 8 0 0 0-.696-.014m5.05 8.5a.75.75 0 0 1 .75.75v1.25H14a.75.75 0 0 1 0 1.5h-1.25V16a.75.75 0 0 1-1.5 0v-1.25H10a.75.75 0 0 1 0-1.5h1.25V12a.75.75 0 0 1 .75-.75"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Add interview question
                    </span>
                  </Box>
                </NavLink>

                <a
                  rel="noreferrer"
                  href={testFreshers}
                  target="_blank"
                  style={{ textDecoration: "none", color: "#333" }}
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg height="23" viewBox="0 0 288 269" fill="none">
                        <path
                          d="M74.5 0.66C82.5 4.50075 101.385 6.95174 109.837 10.1984C123.135 15.3061 135.756 22.6037 145.706 31.2745C154.445 38.8897 161.761 47.728 167.214 57.0444C169.877 61.5955 171.673 69.0778 178.005 71.3639C183.809 73.4594 192.505 72.2834 198.731 72.2834H243.178C249.293 72.2834 259.956 73.834 264.555 69.84C269.75 65.3308 270.975 55.2022 272.882 49.4249C278.094 33.6418 286.721 17.008 287.625 0.66L74.5 0.66Z"
                          fill="#1F8597"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M229.878 122H229.847V122.107L229.878 122ZM161 161.98L218.087 161.013L228.808 123H170.63L161 161.98Z"
                          fill="#1F8597"
                        />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M60.1048 92.8336H0C0.0396622 76.7496 4.16191 61.0484 8.24305 45.5037C9.65936 40.1091 11.0707 34.7334 12.3048 29.367C12.6463 27.8834 12.9439 26.1241 13.2621 24.2424C14.3239 17.9645 15.616 10.3248 19.5391 7.01896C24.3185 2.99098 34.7135 3.91142 42.8752 4.6341C45.6361 4.87856 48.1414 5.1004 50.0873 5.1004C71.945 5.1004 95.3347 7.45355 115.616 17.3675C148.275 30.2955 167.891 56.4979 170.27 93C170.015 91.6011 169.705 90.1874 169.344 88.7632C169.582 90.1375 169.763 91.496 169.881 92.8336C170.544 100.341 168.311 107.984 166.147 115.389C165.238 118.5 164.341 121.568 163.677 124.567C159.807 142.041 155.558 159.4 151.31 176.759C148.869 186.732 146.429 196.705 144.06 206.7C140.655 221.07 137.4 235.212 134.189 249.633C133.975 250.594 133.778 251.637 133.573 252.722C132.629 257.718 131.517 263.598 127.84 266.381C123.451 269.705 115.67 269.107 109.226 268.612C107.125 268.451 105.166 268.3 103.514 268.3H41.7395C43.0055 257.8 45.7362 247.484 48.452 237.225C49.6651 232.643 50.8753 228.072 51.9506 223.5C55.2579 209.434 58.6317 195.388 62.0054 181.341C65.3538 167.4 68.7022 153.46 71.9855 139.5C72.4784 137.403 72.9614 135.306 73.4442 133.209C75.4132 124.658 77.3777 116.127 79.9828 107.767C80.1325 107.286 80.2995 106.788 80.4707 106.277C81.6119 102.87 82.9442 98.8931 80.6273 95.8267C77.4915 91.6782 69.8634 92.2204 64.131 92.6279C62.6176 92.7355 61.2364 92.8336 60.1048 92.8336Z"
                          fill="#2F2F2F"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Test Freshers
                    </span>
                  </Box>
                </a>

                <NavLink
                  to={"/student-dashboard/logout"}
                  style={{ textDecoration: "none", color: "inherit" }}
                  onClick={handleModalLogout}
                >
                  <Box
                    className={Styles.conatinerName}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 24 24"
                      >
                        <path
                          fill="#333"
                          d="M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h6q.425 0 .713.288T12 4t-.288.713T11 5H5v14h6q.425 0 .713.288T12 20t-.288.713T11 21zm12.175-8H10q-.425 0-.712-.288T9 12t.288-.712T10 11h7.175L15.3 9.125q-.275-.275-.275-.675t.275-.7t.7-.313t.725.288L20.3 11.3q.3.3.3.7t-.3.7l-3.575 3.575q-.3.3-.712.288t-.713-.313q-.275-.3-.262-.712t.287-.688z"
                        />
                      </svg>
                    </span>
                    <span style={{ marginLeft: "15px", marginBottom: "4px" }}>
                      Logout
                    </span>
                  </Box>
                </NavLink>
              </Box>
            </Box>
          )}
        </div>
      ) : (
        <aside
          className={Styles.StudentNavBlock}
          onClickCapture={e => {
            socket.emit("chatwith", {
              senderId: user._id,
              role: user?.role1,
              receiverId: null,
              device: "web",
            });
          }}
        >
          <div className={Styles.HomeBlock}>
            {" "}
            <span>
              <Link
                to={"/student-dashboard"}
                className={Styles.StudHome}
                style={selectedMenu === "/student-dashboard" ? menuStyle : {}}
                state={{ model: true, chat: false }}
              >
                Home
              </Link>
            </span>
          </div>
          <div className={Styles.HomeBlock}>
            {" "}
            <span>
              <Link
                to={"/student-dashboard/requirement"}
                className={Styles.StudHome}
                style={
                  selectedMenu === "/student-dashboard/requirement"
                    ? menuStyle
                    : {}
                }
                state={{ model: true, chat: false }}
              >
                Requirement
              </Link>
            </span>
          </div>
          <div className={Styles.HomeBlock}>
            {" "}
            <span>
              <Link
                to={"/student-dashboard/grooming"}
                className={Styles.StudHome}
                style={
                  selectedMenu === "/student-dashboard/grooming"
                    ? menuStyle
                    : {}
                }
                state={{ model: true, chat: false }}
              >
                Grooming
              </Link>
            </span>
          </div>
          <div className={Styles.HomeBlock}>
            {" "}
            <span>
              <Link
                to={"/student-dashboard/mock"}
                className={Styles.StudHome}
                style={
                  selectedMenu === "/student-dashboard/mock" ? menuStyle : {}
                }
                state={{ model: true, chat: false }}
              >
                Mock
              </Link>
            </span>
          </div>
          <div className={Styles.HomeBlock}>
            {" "}
            <span>
              <Link
                to={"/student-dashboard/status"}
                className={Styles.StudHome}
                style={
                  selectedMenu === "/student-dashboard/status" ? menuStyle : {}
                }
                state={{ model: true, chat: false }}
              >
                Status
              </Link>
            </span>
          </div>
          <div
            className={Styles.HomeBlock}
            onClick={() => {
              setBrandNotification([]);
              // SpideyauraFunc();
            }}
            style={{ position: "relative" }}
          >
            {" "}
            <span>
              <Link
                to={"/student-dashboard/branding"}
                className={Styles.StudHome}
                style={
                  selectedMenu === "/student-dashboard/branding"
                    ? menuStyle
                    : {}
                }
                state={{ model: true, chat: false }}
              >
                Spidey Aura
              </Link>
            </span>
            {brandNotification.length > 0 && (
              <div style={{ position: "absolute", top: "0", right: "-7px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="green"
                    stroke="green"
                    stroke-width="4"
                    d="M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z"
                  />
                </svg>
              </div>
            )}
          </div>
          <div
            className={Styles.HomeBlock}
            onClick={() => {
              handleNotification();
            }}
            style={{ position: "relative" }}
            title="Help chat"
          >
            {" "}
            <span>
              <Link
                to={"/student-dashboard/help"}
                className={Styles.StudHome}
                style={
                  selectedMenu === "/student-dashboard/help" ? menuStyle : {}
                }
                state={{ model: true, chat: false }}
              >
                Help
              </Link>
            </span>
            {notification && (
              <div style={{ position: "absolute", top: "0", right: "-7px" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="green"
                    stroke="green"
                    stroke-width="4"
                    d="M24 33a9 9 0 1 0 0-18a9 9 0 0 0 0 18Z"
                  />
                </svg>
              </div>
            )}
            {/* {<div className={Styles.Noti}>.</div>} */}
          </div>
          <div
            className={Styles.HomeBlock}
            title="Add tnterview question"
            style={{ position: "relative" }}
          >
            {" "}
            <Link
              to={"/student-dashboard/addquestion"}
              className={Styles.StudHome}
              style={
                selectedMenu === "/student-dashboard/addquestion"
                  ? menuStyle
                  : {}
              }
              state={{ model: true, chat: false }}
            >
              Add interview question
            </Link>{" "}
          </div>
          {IsCookie == 1 && (
            <div
              className={Styles.HomeBlock}
              title="Go to Student Home Page"
              style={{ position: "relative" }}
            >
              {" "}
              <span
                onClick={() => {
                  const url = `${qandq}`;
                  handleNavigateOtherApps(url);
                }}
                className={Styles.StudHome}
              >
                Attendance
              </span>
            </div>
          )}

          <div
            className={Styles.HomeBlock}
            title="Add tnterview question"
            style={{ position: "relative" }}
          >
            {" "}
            <span
              onClick={() => {
                if (isCookieLogin) {
                  Cookies.set("QtoQ", 1, {
                    domain: ".qspiders.com",
                  });
                }
              }}
              className={Styles.StudHome}
            >
              <a
                rel="noreferrer"
                href={testFreshers}
                target="_blank"
                className={Styles.StudHome}
              >
                Test Freshers
              </a>{" "}
            </span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* <span> */}
            <React.Fragment>
              {/* <Tooltip title="Account settings"> */}
              <IconButton
                size="small"
                sx={{ ml: 2 }}
                aria-controls={open1 ? "account-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open1 ? "true" : undefined}
                onClick={handleClick}
              >
                <img
                  loading="lazy"
                  id={Styles.preview}
                  src={createImageFromInitials(500, name, getRandomColor())}
                  alt="profile-pic"
                  width="100%"
                  height="100%"
                />
              </IconButton>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open1}
                onClose={handleClose1}
                onClick={handleClose1}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },

                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem
                  onClick={() => navigate("/student-dashboard/profile")}
                >
                  <Avatar sx={{ background: "#2677B0", color: "#ECECEC" }} />{" "}
                  View Profile
                </MenuItem>
                <Divider />

                {!IsCookie && (
                  <MenuItem
                    onClick={handleModalLogout}
                    // onClick={e => {
                    //   setOpen(true);
                    // }}
                  >
                    <ListItemIcon>
                      <span
                        title="logout"
                        style={{
                          cursor: "pointer",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <svg
                          width={26}
                          height={26}
                          viewBox="0 0 26 26"
                          strokeWidth={3}
                        >
                          <path
                            fill="crimson"
                            d="M13 0a2 2 0 0 0-2 2v10a2 2 0 0 0 4 0V2a2 2 0 0 0-2-2M5.844 2.594a1.5 1.5 0 0 0-.782.344A12.796 12.796 0 0 0 .188 13C.188 20.058 5.94 25.813 13 25.813S25.813 20.058 25.813 13c0-4.073-1.902-7.717-4.875-10.063a1.5 1.5 0 1 0-1.875 2.344A9.804 9.804 0 0 1 22.813 13A9.789 9.789 0 0 1 13 22.813A9.789 9.789 0 0 1 3.187 13a9.808 9.808 0 0 1 3.75-7.719a1.5 1.5 0 0 0-1.093-2.687"
                          ></path>
                        </svg>
                      </span>{" "}
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                )}
              </Menu>
            </React.Fragment>
            {/* </span> */}
          </div>
        </aside>
      )}
    </section>
  );
};

export default StudentMenu;
