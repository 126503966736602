import React, { useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { SocketContext } from "../../ioContext/socketIo";
import Cookies from "js-cookie";
import { date } from "yup/lib/locale";
const VisibilityCheck = () => {
  const socket = useContext(SocketContext);
  let { user } = useSelector(state => state?.auth);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        //("hidden from file");
        //(user)

        if (
          Cookies.get("device", { domain: ".qspiders.com" }) === null &&
          user?.role === undefined &&
          user?._id === undefined
        ) {
        } else {

        }
        // Emit event or perform actions when the page is hidden
      } else {


        if (!Cookies.get("visitedHomePage", { domain: ".qspiders.com" })) {
          Cookies.set(
            "visitedHomePage",
            true,
            { expires: 1 },
            { domain: ".qspiders.com" }
          );
          //("emit")
          socket.emit("dailylogin", {
            id: user?._id,
            role: user?.role1,
            device: "web",
          });
        } else {
          const lastVisit = Cookies.get("lastVisit", {
            domain: ".qspiders.com",
          });
          const twoMinutes = 24 * 60 * 60 * 1000;

          if (lastVisit && Date.now() - lastVisit > twoMinutes) {
            Cookies.set(
              "lastVisit",
              Date.now(),
              { expires: 1 },
              { domain: ".qspiders.com" }
            );
            //("second emit")
            socket.emit("dailylogin", {
              id: user?._id,
              role: user?.role1,
              device: "web",
            });
          }
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  return null;
};

export default VisibilityCheck;
