import Cookies from "js-cookie";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import useDownloader from "react-use-downloader";
import Styles from "./_help.module.css";
import { GiWideArrowDunk } from "react-icons/gi";
import Linkify from "react-linkify";
import Moment from "react-moment";
import { Modal, Dropdown, IconButton, Button } from "rsuite";
import { IoIosShareAlt } from "react-icons/io";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";
import AxiosInstance, { imageUrl } from "../../api/AxiosInstance";
import fetchImageUrl from "../functions/FetchFileWithToken";

const OldChats = ({ particularStudent }) => {
  let scrollRef = useRef();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const [previewImage, setPreviewImage] = useState("");
  let [message, setMessage] = useState([]);
  const imgRef = useRef(null);

  const [openmodal2, setOpenmodal2] = React.useState(false);

  const handleClose2 = () => {
    setOpenmodal2(false);
  };

  const [open, setOpen] = useState(false);
  const [backgroundColor1, setBackgroundColor1] = useState("");
  const [openImg, setOpenImg] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [offsetX, setOffsetX] = useState(0);
  const [offsetY, setOffsetY] = useState(0);
  const handleZoomIn = () => {
    setZoomLevel(zoomLevel + 0.1);
  };
  const handleZoomOut = () => {
    setZoomLevel(zoomLevel - 0.1);
  };
  const handleMouseDown = e => {
    if (zoomLevel > 1) {
      setDragging(true);
      setStartX(e.pageX - offsetX);
      setStartY(e.pageY - offsetY);
    }
  };
  const handleMouseMove = e => {
    if (dragging) {
      e.preventDefault();
      const x = e.pageX - startX;
      const y = e.pageY - startY;
      setOffsetX(x);
      setOffsetY(y);
    }
  };
  const handleMouseUp = () => {
    setDragging(false);
  };
  const handleMouseLeave = () => {
    setDragging(false);
  };
  const { download } = useDownloader();
  const handleClose = () => {
    setOpen(false);
  };
  function stopPropagation(e) {
    e.stopPropagation();
  }
  const openImagePreview = imageSrc => {
    setPreviewImage(imageSrc);
  };
  let handleImage = e => {
    setOpenImg(true);
    openImagePreview(e);
  };

  useEffect(() => {
    scrollRef.current?.scrollIntoView({
      behavior: "auto",
      block: "end",
      inline: "nearest",
    });
  }, [message]);

  function linkify(text) {
    function openInNewTab(url) {
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {url}
        </a>
      );
    }
    return (
      <Linkify componentDecorator={openInNewTab}>
        <p className={Styles.messageText}>
          {message?.message?.text} <br />
          {message.forwarded ? (
            <span className={Styles.broadcast}>
              <GiWideArrowDunk />
             
            </span>
          ) : (
            ""
          )}
        </p>
      </Linkify>
    );
  }

  const renderIconButton = (props, ref) => {
    return (
      <IconButton
        {...props}
        ref={ref}
        icon={<IoIosShareAlt />}
        circle
        color="white"
        size="10px"
      />
    );
  };

  const items = [
    <Dropdown.Item

    >
      Forward To
    </Dropdown.Item>,
  ];
  useEffect(() => {
    async function fetchlist() {
      try {
        // setLoadingMsg(true);

        let x = await AxiosInstance.post(
          `/users/helpIndividualChat`,
          { studentId: particularStudent.studentId },
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        x?.data?.chat[0]?.helpChat?.reverse();
        let data = x?.data?.chat[0]?.helpChat;
        if (x?.data?.chat?.length !== 0) {
          setMessage(data);
        }
        //  setLoadingMsg(false);
      } catch (error) {}
    }
    fetchlist();
  }, []);

  function openInNewTab(url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        message.map(async message => {
          if (message?.message.files) {
            const fileData = await fetchImageUrl(
              message?.message?.files[0]?.path,
              user?.TOKEN
            );
            if (fileData) {
              newFileUrls[message?.messages?.file?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [message]);

  return (
    <aside
      style={{
        width: "72%",
        overflowY: "auto",
        height: "100%",
        background: "#fff",
      }}
    >
      <div className={Styles.chat}>
        {message?.length === 0 ? (
          <span className={Styles.noConversation}>
            <p style={{ color: "#f16f1d", textAlign: "center" }}>No Messages</p>
          </span>
        ) : (
          message.map((messages, ind) => {
            return (
              <div
                key={ind + 1}
                className={
                  messages?.Conversation === user?._id
                    ? `${Styles.sendermsg}`
                    : messages.Conversation !== user._id
                    ? `${Styles.recivermsg}`
                    : `${Styles.displaynone}`
                }
              >
                <Modal onClose={handleClose} open={open} size="full">
                  <Modal.Body>
                    <div
                      style={{
                        overflow: "hidden",
                        cursor: zoomLevel > 1 ? "grab" : "auto",
                      }}
                      onMouseDown={handleMouseDown}
                      onMouseMove={handleMouseMove}
                      onMouseUp={handleMouseUp}
                      onMouseLeave={handleMouseLeave}
                    >
                      <img
                        ref={imgRef}
                        src={previewImage}
                        style={{
                          transform: `scale(${zoomLevel}) translate(${offsetX}px, ${offsetY}px)`,
                          transition: "transform 0.3s ease",
                          maxWidth: "700px",
                          maxHeight: "80%",
                          width: "80%",
                          margin: "auto",
                          display: "block",
                          // objectFit: "cover",
                        }}
                        alt="Preview"
                      />
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleZoomOut}>
                      <FaSearchMinus />
                    </Button>
                    <Button variant="secondary" onClick={handleZoomIn}>
                      <FaSearchPlus />
                    </Button>
                    <Button variant="primary" onClick={handleClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>

                <main
                  style={{ backgroundColor: backgroundColor1 }}
                  onClick={e => {
                    messages?.poll === null &&
                      setBackgroundColor1(
                        backgroundColor1 === "" ? "rgb(0 0 0 / 12%)" : ""
                      );
                  }}
                >
                  <div>
                    <p className={Styles.textmsg}>
                      {messages?.message?.text ? (
                        messages?.message?.text
                      ) : messages?.message?.files?.length > 0 ? (
                        <>
                          {(() => {
                            switch (messages?.message?.files[0]?.mimetype) {
                              case "image/jpg":
                              case "image/jpeg":
                              case "image/avif":
                              case "image/png":
                              case "image/webp":
                              case "image/tiff":
                              case "image/bmp":
                                return (
                                  <>
                                    <img
                                      src={
                                        imageUrls[
                                          messages?.message?.files[0]?.path
                                        ]?.url
                                      }
                                      alt=""
                                      style={{
                                        width: "200px",
                                        height: "130px",
                                        borderRadius: "5px",
                                        display: "flex",
                                      }}
                                      onClick={e => {
                                        // stopPropagation(e);
                                        handleImage(
                                          imageUrls[
                                            messages?.message?.files[0]?.path
                                          ]?.url
                                        );
                                      }}
                                    />{" "}
                                    <button
                                      className={Styles.btndownload1}
                                      onClick={() =>
                                        download(
                                          imageUrls[
                                            messages?.message?.files[0]?.path
                                          ]?.url,
                                          messages?.message?.files[0]
                                            ?.originalname,
                                          messages?.message?.files[0]?.filename
                                        )
                                      }
                                    >
                                      {
                                        messages?.message?.files[0]
                                          ?.originalname
                                      }
                                      <span className={Styles.Download}></span>
                                      <span className={Styles.linkBlock}>
                                        Click to download
                                      </span>
                                    </button>
                                    {/* {messages.broadcastchat ? ( */}
                                    <>
                                      <span>
                                        {message?.forwarded ? (
                                          <span className={Styles.broadcast}>
                                            <GiWideArrowDunk />
                                            {/* {message.broadcastchat} */}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </>
                                  </>
                                );
                              case "audio/mpeg":
                              case "audio/mp3":
                              case "audio/webm":
                              case "application/wav":
                              case "video/webm":
                              case "audio/wav":
                                return (
                                  <>
                                    <audio
                                      src={
                                        imageUrls[
                                          messages?.message?.files[0]?.path
                                        ]?.url
                                      }
                                      controls
                                      style={{
                                        width: "250px",
                                        height: "40px",
                                      }}
                                    ></audio>
                                    <>
                                      <span>
                                        {messages.forwarded ? (
                                          <span className={Styles.broadcast}>
                                            <GiWideArrowDunk />
                                            {/* {message.broadcastchat} */}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </>
                                  </>
                                );
                              case "video/mp4":
                              case "video/x-matroska":
                              case "application/docx":
                              case "application/doc":
                              case "application/msword":
                              case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                              case "application/vnd.openxmlformats-officedocument.wordprocessingml.template":
                              case "application/vnd.ms-word.document.macroEnabled.12":
                              case "application/vnd.ms-excel":
                              case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                              case "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
                              case "application/vnd.ms-excel.sheet.macroEnabled.12":
                              case "application/vnd.ms-powerpoint":
                              case "application/xlsx":
                              case "application/pptx":
                              case "application/octet-stream":
                              case "text/plain":
                              case "text/html":
                              case "application/apk":
                              case "application/pdf":
                              case "application/x-pdf":
                              case "application/x-gzpdf":
                              case "applications/vnd.pdf":
                              case "application/acrobat":
                              case "application/x-google-chrome-pdf":
                              case "text/pdf":
                              case "text/x-pdf":
                              case "text/csv":
                              case "application/zip":
                              case "application/x-zip":
                              case "application/x-zip-compressed":
                              case "application/json":
                                return (
                                  <>
                                    <button
                                      className={Styles.btndownload1}
                                      onClick={() =>
                                        download(
                                          imageUrls[
                                            messages?.message?.files[0]?.path
                                          ]?.url,
                                          messages?.message?.files[0]
                                            ?.originalname,
                                          messages?.message?.files[0]?.filename
                                        )
                                      }
                                    >
                                      {
                                        messages?.message?.files[0]
                                          ?.originalname
                                      }
                                      <span className={Styles.Download}></span>
                                      <span className={Styles.linkBlock}>
                                        Click to download
                                      </span>
                                    </button>
                                    <>
                                      <span>
                                        {messages?.forwarded ? (
                                          <span className={Styles.broadcast}>
                                            <GiWideArrowDunk />
                                            {/* {message.broadcastchat} */}
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </span>
                                    </>
                                  </>
                                );
                              default:
                                return (
                                  <button
                                    className={Styles.btndownload1}
                                    onClick={() =>
                                      download(
                                        imageUrls[
                                          messages?.message?.files[0]?.path
                                        ]?.url,
                                        messages?.message?.files[0]
                                          ?.originalname,
                                        messages?.message?.files[0]?.filename
                                      )
                                    }
                                  >
                                    {messages?.message?.files[0]?.originalname}
                                    <span className={Styles.Download}></span>
                                    <span className={Styles.linkBlock}>
                                      Click to download
                                    </span>
                                  </button>
                                );
                            }
                          })()}
                        </>
                      ) : (
                        ""
                      )}
                    </p>
                  </div>
                  <div id={Styles.FooterBlock}>
                    <span></span>
                    <p className={Styles.namesender}>
                      {messages?.senderName} - {messages?.section}
                    </p>
                    <div className={Styles.messageBottom}>
                      {Date.now ? (
                        <Moment format="MMM DD - h:mm a">
                          {messages?.createdAt}
                        </Moment>
                      ) : (
                        <Moment format="MMM DD YYYY h A">
                          {messages?.createdAt}
                        </Moment>
                      )}
                      {backgroundColor1 === "rgb(0 0 0 / 12%)" ? (
                        <span
                          className={Styles.ForwardMsgBlock}
                          onClick={e => stopPropagation(e)}
                        >
                          {" "}
                          <Dropdown
                            renderToggle={renderIconButton}
                            placement="leftEnd"
                            style={{ zIndex: 2222222 }}
                          >
                            {items}
                          </Dropdown>
                        </span>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </main>
              </div>
            );
          })
        )}
      </div>
    </aside>
  );
};

export default OldChats;
