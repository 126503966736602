import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik, FieldArray } from "formik";
import * as yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import { register, reset } from "../../redux/auth/AuthSlice";
import Styles from "../auth/_auth.module.css";

import { IoMdAddCircle } from "react-icons/io";
import { TiDelete } from "react-icons/ti";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const ItemHeight = 48;
const itemPaddingTop = 8;
const MenuProps = {
  PaperProps: {
    style: { maxHeight: ItemHeight * 4.5 + itemPaddingTop, width: "250" },
  },
};
const AddStudents = () => {
  let dispatch = useDispatch();
  let navigate = useNavigate();
  let { isLoading, isSuccess, isError, message } = useSelector(
    state => state.auth
  );

  let [username, setUsername] = useState("");
  let [email, setEmail] = useState([]);
  let [number, setNumber] = useState("");
  let [role, setRole] = useState("student");
  let [branch, setBranch] = useState("");
  let [branches, setBranches] = useState([]);
  let [batchCode, setBatchCode] = useState([]);
  let [batchcode, setBatchcode] = useState([]);
  let [addmail, setAddMail] = useState(false);
  let [groupCode, setGroupCode] = useState([]);
  let [groupcode, setGroupcode] = useState([]);

  const [isLoadingBatch, setIsLoadingBatch] = useState(false);
  const [isLoadingGroup, setIsLoadingGroup] = useState(false);

  let { user, userAdmin } = useSelector(state => state.auth);
  useEffect(() => {
    if (isError) {
      toast.error(message.message, { position: "top-right" });
    }

    if (isSuccess) {
      toast.success("User is added successfully", { position: "top-right" });
      setUsername("");
      setEmail("");
      setNumber("");
      setRole("");
      setBranch("");

      //   navigate("/admin-dashboard/admin/a");
    }
    dispatch(reset());
  }, [isError, isSuccess, message]);

  // email and mobile dynamic input field
  let [emails, setEmails] = useState([""]);
  let [mobiles, setMobiles] = useState([""]);

  const handleChanger = (value, index) => {
    const newEmail = emails.map((Item, Index) => {
      return Index === index ? value : Item;
    });
    setEmails(newEmail);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...emails];
    list.splice(index, 1);
    setEmails(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setEmails([...emails, ""]);
  };

  const handleMobChange = (value, index) => {
    const newMobile = mobiles.map((Item, Index) => {
      return Index === index ? value : Item;
    });
    setMobiles(newMobile);
  };

  const handleRemoveMob = index => {
    const list = [...mobiles];
    list.splice(index, 1);
    setMobiles(list);
  };
  const handleAddMob = () => {
    setMobiles([...mobiles, ""]);
  };

  // to prevent Alphabets
  const preventMinus = e => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  // email and mobile dynamic input field

  let LoadSubject = () => {
    async function fetchData() {
      // get Subjects
      await AxiosInstance.get(`auth/getbatchcodes?code=batch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data?.batch_groupdata;

          // //(Branchdataaa);

          let eee = Branchdataaa.map(function (ele) {
            return { value: ele.batchCode, label: ele.batchCode };
          });
          setBatchcode(eee);
        })
        .catch(y => {
          //(y);
        })
        .finally(z => {
          setIsLoadingBatch(false);
        });
    }
    fetchData();
  };

  useEffect(() => {
    async function fetchData() {
      // get Subjects
      await AxiosInstance.get(`auth/getbatchcodes?code=batch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data?.batch_groupdata;

          // //(Branchdataaa);

          let eee = Branchdataaa.map(function (ele) {
            return { value: ele.batchCode, label: ele.batchCode };
          });
          setBatchcode(eee);
        })
        .catch(y => {
          //(y);
        })
        .finally(z => {
          setIsLoadingBatch(false);
        });
    }
    fetchData();
  }, []);

  const handleMenuOpen = () => {
    if (batchcode.length === 0 && !isLoading) {
      setIsLoadingBatch(true);
      LoadSubject();
    }
  };

  let LoadCourse = () => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getbatchcodes?code=group`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data?.batch_groupdata;
          // //(Branchdataaa);

          let eee = Branchdataaa.map(function (ele) {
            return { value: ele.groupCode, label: ele.groupCode };
          });
          setGroupcode(eee);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingGroup(false);
        });
    }
    fetchData();
  };

  useEffect(() => {
    async function fetchData() {
      await AxiosInstance.get(`auth/getbatchcodes?code=group`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(x => {
          let Branchdataaa = x?.data?.batch_groupdata;
          // //(Branchdataaa);

          let eee = Branchdataaa.map(function (ele) {
            return { value: ele.groupCode, label: ele.groupCode };
          });
          setGroupcode(eee);
        })
        .catch(y => {
          //(y);
        })
        .finally(() => {
          setIsLoadingGroup(false);
        });
    }
    fetchData();
  }, []);

  const handleMenuOpenCourse = () => {
    if (groupcode.length === 0 && !isLoadingGroup) {
      setIsLoadingGroup(true);
      LoadCourse();
    }
  };

  const schema = yup.object().shape({
    username: yup.string().required("username is required"),
    email: yup
      .array()
      .of(
        yup.string().email("invalid email format").required("email is required")
      ),
    number: yup.array().of(
      yup
        .string()
        .matches(/^[0-9]{10}$/, "Number should contain only digits")
        .required("Number is required")
    ),
    role: yup.string().required("role is required"),
    // branch: yup.string().required("branch is required"),
  });

  let AddInputEmail = [""];
  let AddNumber = [""];
  const handleChangeStudent = e => {
    // //(e)
    setBatchCode(Array.isArray(e) ? e.map(x => ({ _id: x.value })) : []);
  };
  const handleChangeGroup = e => {
    // //(e)
    setGroupCode(Array.isArray(e) ? e.map(x => ({ _id: x.value })) : []);
  };
  // //(batchCode)

  const handleSubmit = e => {
    e.preventDefault();
    try {
      let payload = {
        username: username,
        email: emails,
        number: mobiles,
        role: role,
        batchCode: batchCode,
        groupCode: groupCode,
      };

      AxiosInstance.post(`/auth/addstudent`, payload, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      });
      toast.success("student added successfully");
      setTimeout(() => {
        navigate("/admin-dashboard/admin/allstudents");
      }, 900);
    } catch (error) {
      toast.error(error?.message || "something went wrong");
    }
  };

  return (
    <>
      {/* <section id={Styles.authBlockStudent}>
        <article>
          <div className={Styles.RegisterBlock}>
            <h2>Add Students</h2>

            <Formik
              initialValues={{
                username: "",
                email: [""],
                number: [""],
                role: "student",
                branch: "",
                batchCode: [""],
                groupCode: [""],
              }}
              validationSchema={schema}
              onSubmit={(values, action) => {
                let payload = { ...values, batchCode, groupCode };
                // //(payload);
                async function students() {
                  let data = await AxiosInstance.post(
                    `/auth/addstudent`,
                    payload,
                    {
                      headers: {
                        Authorization: `Bearer ${userAdmin.TOKEN}`,
                      },
                    }
                  )
                    .then(x => {
                      // //(x);
                      toast.success(x.data.message, {
                        position: "top-right",
                      });
                      navigate("/admin-dashboard/admin/allstudents");
                    })
                    .catch(x => {
                      // //(x?.response?.data?.message);
                      toast.error(x?.response?.data?.message);
                    });
                }
                students();
                action.resetForm();
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isValid,
              }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <div className={Styles.form_style}>
                      <div className={Styles.pass}>
                        <label htmlFor="number">Username</label>
                        <aside>
                          <Input
                            size="lg"
                            type="text"
                            name="username"
                            placeholder="Enter username"
                            style={{
                              borderColor:
                                errors.username && touched.username
                                  ? "red"
                                  : "inherit",
                              marginBottom: 5,
                            }}
                            onChange={(val, event) => {
                              handleChange(event);
                            }}
                            onBlur={handleBlur}
                            value={values.username}
                          />
                        </aside>
                        <p className={Styles.errors}>
                          <ErrorMessage name="username" />
                        </p>
                      </div>

                      <FieldArray name="email">
                        {({ push, remove }) => (
                          <div className={Styles.pass}>
                            <label htmlFor="email">email</label>
                            {values.email.slice(0, 2).map((_, index) => (
                              <>
                                <aside>
                                  <Input
                                    size="lg"
                                    type="text"
                                    name={`email.${index}`}
                                    placeholder="Enter email"
                                    style={{
                                      borderColor:
                                        errors.password && touched.password
                                          ? "red"
                                          : "inherit",
                                      marginBottom: 5,
                                    }}
                                    onChange={(val, event) => {
                                      handleChange(event);
                                      // updateValFromStore('password', val)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.event}
                                  />
                                </aside>
                              </>
                            ))}{" "}
                            <main
                              className={
                                values.email.length === 2
                                  ? `${Styles.removeemail}`
                                  : `${Styles.addEmail}`
                              }
                            >
                              <span>
                                <IoMdAddCircle
                                  onClick={() => push(AddInputEmail)}
                                ></IoMdAddCircle>
                              </span>
                            </main>
                            <main
                              className={
                                values.email.length === 2
                                  ? `${Styles.removeemail1}`
                                  : `${Styles.addEmail1}`
                              }
                            >
                              <span>
                                <TiDelete
                                  onClick={() => remove(AddInputEmail)}
                                ></TiDelete>
                              </span>
                            </main>
                            <p className={Styles.errors}>
                              <ErrorMessage name="email" />
                            </p>
                          </div>
                        )}
                      </FieldArray>

                      <FieldArray name="number">
                        {({ push, remove }) => (
                          <div className={Styles.pass}>
                            <label htmlFor="number">Number</label>
                            {values.number.slice(0, 2).map((_, index) => (
                              <>
                                <aside>
                                  <Input
                                    maxLength={10}
                                    size="lg"
                                    type="text"
                                    name={`number.${index}`}
                                    placeholder="Number"
                                    style={{
                                      borderColor:
                                        errors.number && touched.number
                                          ? "red"
                                          : "inherit",
                                      marginBottom: 5,
                                    }}
                                    onChange={(val, event) => {
                                      handleChange(event);
                                      // updateValFromStore ('username', val)
                                    }}
                                    onBlur={handleBlur}
                                    value={values.event}
                                  />
                                </aside>
                              </>
                            ))}{" "}
                            <main
                              className={
                                values.number.length === 2
                                  ? `${Styles.removeemail}`
                                  : `${Styles.addEmail}`
                              }
                            >
                              <span>
                                <IoMdAddCircle
                                  onClick={() => push(AddInputEmail)}
                                ></IoMdAddCircle>
                              </span>
                            </main>
                            <main
                              className={
                                values.number.length === 2
                                  ? `${Styles.removeemail1}`
                                  : `${Styles.addEmail1}`
                              }
                            >
                              <span>
                                <TiDelete
                                  onClick={() => remove(AddInputEmail)}
                                ></TiDelete>
                              </span>
                            </main>
                            <p className={Styles.errors}>
                              <ErrorMessage name="number" />
                            </p>
                          </div>
                        )}
                      </FieldArray>

                      <div className={Styles.pass}>
                        <label htmlFor="role">Role</label>

                        <Input type="text" value="student" name="role" />
                        <p className={Styles.errors}>
                          <ErrorMessage name="role" />
                        </p>
                      </div>

                      <div className={Styles.trainer12}>
                        <label htmlFor="batchCode">Batch Code</label>
                        <aside>
                          <Select
                            onMenuOpen={handleMenuOpen}
                            isLoading={isLoadingBatch}
                            closeMenuOnSelect={false}
                            isMulti
                            name="students"
                            options={batchcode}
                            onChange={e => {
                              setBatchCode(
                                Array.isArray(e)
                                  ? e.map(x => ({ _id: x.value }))
                                  : []
                              );
                            }}
                          />
                        </aside>
                        <p className={Styles.errors}>
                          <ErrorMessage name="batchCode" />
                        </p>
                      </div>

                      <div className={Styles.trainer12}>
                        <label htmlFor="groupCode">Group Code</label>
                        <aside>
                          <Select
                            onMenuOpen={handleMenuOpenCourse}
                            isLoading={isLoadingGroup}
                            closeMenuOnSelect={false}
                            isMulti
                            name="students"
                            options={groupcode}
                            onChange={handleChangeGroup}
                          />
                        </aside>
                        <p className={Styles.errors}>
                          <ErrorMessage name="groupCode" />
                        </p>
                      </div>
                    </div>
                    <Button type="submit" disabled={!isValid}>
                      Submit
                    </Button>
                  </form>
                );
              }}
            </Formik>
          </div>
        </article>
      </section> */}
      <Container
        className={Styles.updateStudentBlockContainer}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "42vw",
        }}
      >
        <Box
          sx={{
            height: "auto",
            padding: "17px",
            width: "100%",
            borderRadius: "10px",
            border: "0.3px ridge gray",
            boxShadow: "1px 0px 8px #f16f4d",
          }}
        >
          <form style={{ width: "100%" }} onSubmit={handleSubmit}>
            <h3 style={{ color: "#f16f4d", textAlign: "center" }}>
              Add Student
            </h3>
            <Divider />
            <br />
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <TextField
                  color="warning"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={username}
                  placeholder="Enter Username"
                  label="Username"
                  name="username"
                  required
                  onChange={e => setUsername(e.target.value)}
                />
              </Grid>
              {emails.map((item, index) => {
                return (
                  <Grid
                    item
                    sm={6}
                    position="relative"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                    key={index + 1}
                  >
                    <TextField
                      style={{ marginBottom: "5px" }}
                      type="email"
                      label="Email"
                      color="warning"
                      variant="outlined"
                      className="email"
                      size="small"
                      fullWidth
                      value={item}
                      required
                      onChange={e => handleChanger(e.target.value, index)}
                    />
                    <div className="btn-box">
                      {emails.length !== 1 && index !== 0 && (
                        <button
                          title="Delete Email"
                          className={Styles.btns}
                          type="button"
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "-8px",
                          }}
                          onClick={() => handleRemoveClick(index)}
                        >
                          <svg width={15} height={15} viewBox="0 0 20 20">
                            <path
                              fill="crimson"
                              d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z"
                            ></path>
                          </svg>
                        </button>
                      )}
                      {emails.length - 1 === index && (
                        <button
                          title="Add more Email"
                          className={Styles.btns}
                          type="button"
                          style={
                            emails.length < 2
                              ? {
                                  display: "inline-block",
                                  position: "absolute",
                                  right: "-8px",
                                  bottom: "-8px",
                                }
                              : { display: "none" }
                          }
                          onClick={handleAddClick}
                        >
                          <svg width={18} height={18} viewBox="0 0 48 48">
                            <circle
                              cx={24}
                              cy={24}
                              r={21}
                              fill="#4caf50"
                            ></circle>
                            <g fill="#fff">
                              <path d="M21 14h6v20h-6z"></path>
                              <path d="M14 21h20v6H14z"></path>
                            </g>
                          </svg>{" "}
                        </button>
                      )}
                    </div>
                  </Grid>
                );
              })}

              {mobiles.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      position: "relative",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                    key={index + 1}
                  >
                    <TextField
                      style={{ marginBottom: "5px" }}
                      type="tel"
                      label="Number"
                      color="warning"
                      variant="outlined"
                      onKeyPress={preventMinus}
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      size="small"
                      fullWidth
                      required
                      value={item}
                      onChange={e => handleMobChange(e.target.value, index)}
                    />
                    <div className="btn-box">
                      {mobiles.length !== 1 && index !== 0 && (
                        <button
                          title="Delete Mobile Number"
                          className={Styles.btns}
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "-8px",
                          }}
                          type="button"
                          onClick={() => handleRemoveMob(index)}
                        >
                          <svg width={15} height={15} viewBox="0 0 20 20">
                            <path
                              fill="crimson"
                              d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z"
                            ></path>
                          </svg>
                        </button>
                      )}
                      {mobiles.length - 1 === index && (
                        <button
                          title="Add More Mobile Number"
                          className={Styles.btns}
                          type="button"
                          style={
                            mobiles.length < 2
                              ? {
                                  display: "inline-block",
                                  position: "absolute",
                                  right: "-10px",
                                  bottom: "-3px",
                                }
                              : { display: "none" }
                          }
                          onClick={handleAddMob}
                        >
                          <svg width={17} height={17} viewBox="0 0 48 48">
                            <circle
                              cx={24}
                              cy={24}
                              r={21}
                              fill="#4caf50"
                            ></circle>
                            <g fill="#fff">
                              <path d="M21 14h6v20h-6z"></path>
                              <path d="M14 21h20v6H14z"></path>
                            </g>
                          </svg>
                        </button>
                      )}
                    </div>
                  </Grid>
                );
              })}
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size="small" color="warning">
                    Role *
                  </InputLabel>
                  <Select
                    label="Role"
                    size="small"
                    color="warning"
                    required
                    value={role}
                    onChange={e => {
                      setRole(e.target.value);
                    }}
                  >
                    <MenuItem value="student">Student</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth size="small">
                  <InputLabel color="warning">Batch Code *</InputLabel>
                  <Select
                    MenuProps={MenuProps}
                    label="Batch Code"
                    // onOpen={handleMenuOpen}
                    color="warning"
                    required
                    multiple
                    value={batchCode}
                    onChange={e => {
                      setBatchCode(e.target.value);
                    }}
                  >
                    {isLoadingBatch ? (
                      <MenuItem disabled style={{ textAlign: "center" }}>
                        Loading...
                      </MenuItem>
                    ) : (
                      batchcode.map((ele, index) => {
                        return (
                          <MenuItem key={index} value={ele.value}>
                            {ele.value}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel size="small" color="warning">
                    Group Code *
                  </InputLabel>
                  <Select
                    // onOpen={handleMenuOpenCourse}
                    label="Group Code"
                    size="small"
                    color="warning"
                    required
                    multiple
                    value={groupCode}
                    onChange={e => {
                      setGroupCode(e.target.value);
                    }}
                  >
                    {isLoadingGroup ? (
                      <MenuItem disabled style={{ textAlign: "center" }}>
                        Loading...
                      </MenuItem>
                    ) : (
                      groupcode.map((ele, index) => {
                        return (
                          <MenuItem key={index} value={ele.value}>
                            {ele.value}
                          </MenuItem>
                        );
                      })
                    )}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  type="submit"
                  color="warning"
                  fullWidth
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default AddStudents;
// export default AddStudents
