const ValidationCreateGroup = (
  groupCode,
  purpose,
  course,
  branch,
  startDate,
  endDate,
  trainer,
  hr,
  addStudentstogroup
) => {
  let errors = {};

  if (groupCode.trim() === "") {
    errors.groupCode = "GroupCode is required";
  }
  if (!purpose) {
    errors.purpose = "Purpose is required";
  }
  if (!course) {
    errors.course = "Course is required";
  }
  if (!branch) {
    errors.branch = "Branch is required";
  }
  if (!startDate) {
    errors.startDate = "Start Date is required";
  }
  if (!endDate) {
    errors.endDate = "End Date is required";
  }
  if (!trainer) {
    errors.trainer = "Trainer is required";
  }
  if (!hr) {
    errors.hr = "HR is required";
  }
  if (addStudentstogroup?.length <= 0) {
    errors.addStudentstogroup = "Add Students field is required";
  }

  return errors;
};

export default ValidationCreateGroup;
