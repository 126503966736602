import React, { Fragment, useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Styles from "./_user.module.css";

import UserGroupListDetails from "./UserGroupListDetails";
import { useNavigate } from "react-router-dom";
import { SocketContext } from "../../ioContext/socketIo";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import { FiSearch } from "react-icons/fi";
import { RotatingLines } from "react-loader-spinner";
import ReactPaginate from "react-paginate";
import Cookies from "js-cookie";
const UserGroupData = () => {
  let navigate = useNavigate();

  let [batchData, setBatchData] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  let [nnotification, setNnotification] = useState({});
  let [batchBlock, setBatchBlock] = useState(false);
  let [data, setSData] = useState("");
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const socket = useContext(SocketContext);
  let [searchdata, setSearchData] = useState(false);
  let [search, setSearch] = useState("");
  let [search1, setSearch1] = useState("");

  let [loading, setLoading] = useState(false);
  const [currentPageCount, setCurrentPageCount] = useState(1);

  let { message } = useSelector(state => state.usersBatches);

  const itemsPerPage = 15;
  let [totalcount, setTotalCount] = useState(0);
  const totalPages = Math.ceil(filteredItems.length / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(0);
  const startIndex = currentPage * itemsPerPage;
  const endIndex = Math.min(startIndex + itemsPerPage, filteredItems.length);
  const currentItems = filteredItems.slice(startIndex, endIndex);

  const [isLocked, setIsLocked] = useState(false);

  useEffect(() => {
    socket.on("batchclose_open", data => {
      setBatchBlock(true);
      setSData(data.result.res);
    });
  }, []);
  if (batchBlock === true) {
    toast.error(data, { position: "top-right" });
    function greeting() {}
    setTimeout(greeting, 3000);
    setBatchBlock(false);
    setSData("");
  }
  useEffect(() => {
    if (message?.status === 403) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("device");
      Cookies.remove("name", { path: "", domain: ".qspiders.com" });

      navigate("/");
      Cookies.remove("student", { path: "", domain: ".qspiders.com" });
      Cookies.remove("visitedHomePage", { path: "", domain: ".qspiders.com" });
      socket.disconnect();
    } else {
      async function fetchData() {
        setLoading(true);
        await AxiosInstance.get(`users/groups`, {
          headers: {
            Authorization: `Bearer ${user?.TOKEN}`,
          },
        })
          .then(x => {
            setLoading(false);
            let data = x?.data?.GroupDetails;
            const uniqueArray = data?.filter((obj, index, arr) => {
              return (
                index === arr.findIndex(o => o.groupCode === obj.groupCode)
              );
            });

            let count = uniqueArray?.length;
            setTotalCount(count);
            setBatchData(uniqueArray);
            setFilteredItems(uniqueArray);
          })
          .catch(x => {
            setLoading(false);

            if (x?.response?.status === 403) {
              sessionStorage.removeItem("user");
              sessionStorage.removeItem("token");
              sessionStorage.removeItem("device");
              Cookies.remove("name", { path: "", domain: ".qspiders.com" });
              navigate("/");
              Cookies.remove("student", { path: "", domain: ".qspiders.com" });
              Cookies.remove("visitedHomePage", {
                path: "",
                domain: ".qspiders.com",
              });
              socket.disconnect();
            }
          });
      }
      fetchData();
    }
  }, []);

  useEffect(() => {
    // Filter items based on search input
    const filtered = batchData?.filter(item =>
      item.groupCode.toLowerCase().includes(search?.toLowerCase())
    );
    setFilteredItems(filtered);
    setCurrentPage(0); // Reset to the first page on new search
  }, [search, batchData]);

  useEffect(() => {
    const handleNotification = data => {
      setNnotification(data);
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, []);

  let handleBatchname = e => {
    let data = e.target.value;
    if (data.length > 0) {
      setSearchData(true);
    } else {
      setSearchData(false);
    }
    setSearch1(e.target.value?.toLowerCase());
    setSearch(e.target.value);
  };

  const handlePageClick = event => {
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    const storedPage = sessionStorage.getItem("currentPageCount1");
    if (storedPage) {
      setCurrentPageCount(parseInt(storedPage));
      if (storedPage > 0) {
        setCurrentPage(parseInt(storedPage - 1));
      }
      sessionStorage.setItem("currentPageCount1", 0);
    }
  }, []);
  let Handlepage = () => {
    sessionStorage.setItem("currentPageCount1", currentPage + 1);
  };


  return (
    <>
      <div>{isLocked ? "" : ""}</div>
      {loading === true ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        ""
      )}
      {batchData?.length > 0 ? (
        <div className={Styles.Search}>
          <span>
            <FiSearch />
          </span>
          <input
            id="search"
            name="search"
            value={search}
            type="search"
            placeholder="Search GroupCode"
            onChange={e => handleBatchname(e)}
          />
        </div>
      ) : (
        ""
      )}
      <section id={Styles.BatchGridLayout}>
        <article>
          {batchData?.length === 0 ? (
            <div
              className={Styles.MessageError1}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              {/* <img src={groom} alt="" /> */}
              <svg width={40} height={40} viewBox="0 0 20 20">
                <path
                  fill="#f16f1d"
                  d="M8.03 4.46c-.29 1.28.55 3.46 1.97 3.46c1.41 0 2.25-2.18 1.96-3.46c-.22-.98-1.08-1.63-1.96-1.63c-.89 0-1.74.65-1.97 1.63m-4.13.9c-.25 1.08.47 2.93 1.67 2.93s1.92-1.85 1.67-2.93c-.19-.83-.92-1.39-1.67-1.39s-1.48.56-1.67 1.39m8.86 0c-.25 1.08.47 2.93 1.66 2.93c1.2 0 1.92-1.85 1.67-2.93c-.19-.83-.92-1.39-1.67-1.39c-.74 0-1.47.56-1.66 1.39m-.59 11.43l1.25-4.3C14.2 10 12.71 8.47 10 8.47c-2.72 0-4.21 1.53-3.44 4.02l1.26 4.3C8.05 17.51 9 18 10 18c.98 0 1.94-.49 2.17-1.21m-6.1-7.63c-.49.67-.96 1.83-.42 3.59l1.12 3.79c-.34.2-.77.31-1.2.31c-.85 0-1.65-.41-1.85-1.03l-1.07-3.65c-.65-2.11.61-3.4 2.92-3.4c.27 0 .54.02.79.06c-.1.1-.2.22-.29.33m8.35-.39c2.31 0 3.58 1.29 2.92 3.4l-1.07 3.65c-.2.62-1 1.03-1.85 1.03c-.43 0-.86-.11-1.2-.31l1.11-3.77c.55-1.78.08-2.94-.42-3.61c-.08-.11-.18-.23-.28-.33c.25-.04.51-.06.79-.06"
                ></path>
              </svg>
              <h3 style={{ color: "#f16f1d" }}>
                You are not assigned to any group
              </h3>
            </div>
          ) : searchdata === false ? (
            currentItems
              ?.sort(
                (a, b) =>
                  new Date(b?.date).getTime() - new Date(a?.date).getTime()
              )
              ?.filter(val => val?.groupCode?.toLowerCase().includes(search1))
              ?.map((val, index) => {
                return (
                  <Fragment key={index + 1}>
                    <UserGroupListDetails
                      {...val}
                      Handlepage={Handlepage}
                      index={index}
                      currentPage={currentPage}
                    />
                  </Fragment>
                );
              })
          ) : (
            currentItems
              ?.sort(
                (a, b) =>
                  new Date(b?.date).getTime() - new Date(a?.date).getTime()
              )
              ?.filter(val => val?.groupCode?.toLowerCase().includes(search1))
              ?.map((val, index) => {
                return (
                  <Fragment key={index + 1}>
                    <UserGroupListDetails {...val} index={index} />
                  </Fragment>
                );
              })
          )}
        </article>
        {totalPages > 1 ? (
          <div id={Styles.reactpaginate}>
            <ReactPaginate
              breakLabel="..."
              nextLabel="next >"
              pageRangeDisplayed={3}
              previousLabel="< previous"
              pageCount={totalPages}
              onPageChange={handlePageClick}
              // onPageChange={({ selected }) => setCurrentPage(selected)}
              containerClassName={"pagination"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"}
              forcePage={currentPage}
            />
          </div>
        ) : null}
      </section>
    </>
  );
};

export default UserGroupData;
