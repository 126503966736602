import React from "react";
import { Navigate } from "react-router-dom";
const PrivateRoute = ({ children }) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  if (user === null) {
    return <Navigate to="/" />;
  } else if (user?.TOKEN) {
    return <>{children}</>;
  }
};

export default PrivateRoute;
