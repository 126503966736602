import React, { useEffect, useState } from "react";
import Creatable from "react-select/creatable";
import Styles from "./batch.module.css";
import AxiosInstance from "../../api/AxiosInstance";
import { useSelector } from "react-redux";
import ValidationCreateGroup from "../admin/ValidationCreateGroup";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
const CreateGroupByQR = () => {
  let navigate = useNavigate();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1); // //(user)
  let [errors, setErrors] = useState({});

  let [purposes, setPurposes] = useState([]);
  let [courses, setCourses] = useState([]);
  let [branchs, setBranchs] = useState([]);
  let [allstudents, setAllStudents] = useState([]);

  let [groupCode, setGroupCode] = useState("");
  let [purpose, setPurpose] = useState("");
  let [course, setCourse] = useState("");
  let [branch, setBranch] = useState("");
  let [startDate, setStartDate] = useState("");
  //   let [addStudentstogroup, setAddStudentsToGroup] = useState({});
  let [author, setAuthor] = useState({});

  let [users, setUsers] = useState("");
  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  // const options = [
  //   { label: 'QR Code', value: 'fruit' },
  //   { label: 'Vegetable', value: 'vegetable' },

  // ];

  useEffect(() => {
    let data = user.username;
    setUsers(data);
  }, []);
  //   const handleChangeStudent = (e) => {
  //     //(e);
  //     setAddStudentsToGroup(
  //       Array.isArray(e)
  //         ? e.map(
  //             (x) =>
  //               // //(x)
  //               ({ _id: x.value })
  //             // x.value
  //           )
  //         : []
  //     );
  //   };
  useEffect(() => {
    async function fetchData() {
      //   // get Purpose
      let Subjectdata = await AxiosInstance.get(`auth/getdata?data=purpose`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });
      //(Subjectdata);
      let Subjectdataaa = Subjectdata?.data[0].purpose;
      //(Subjectdataaa);
      let ddd = Subjectdataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setPurposes(ddd);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      //   // get Students
      let StudentData = await AxiosInstance.get(`users/getstudents`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });
      //(StudentData);
      let Studentdataaa = StudentData?.data?.students;
      //(Studentdataaa);
      // //(Studentdataaa)
      let eee = Studentdataaa.map(function (ele) {
        //(ele)
        return {
          value: ele._id,
          label: ele.username + "-" + ele.number.toString().substring(6, 10),
        };
      });
      setAllStudents(eee);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      //   // get Course
      let Coursedata = await AxiosInstance.get(`auth/getdata?data=course`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });
      //(Coursedata);
      let Coursedataaa = Coursedata?.data[0].course;
      //(Coursedataaa);
      let eee = Coursedataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      setCourses(eee);
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchData() {
      //   // get branch
      let Branchdata = await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${user.TOKEN}`,
        },
      });
      // //(Branchdata)
      let Branchdataaa = Branchdata?.data[0]?.branch;
      //(Branchdataaa)
      let ccc = Branchdataaa.map(function (ele) {
        return { value: ele, label: ele };
      });
      //(ccc)
      setBranchs(ccc);
    }
    fetchData();
  }, []);
  const handleChangeBranch = e => {
    if (e?.value) {
      setBranch(e.value);
    } else {
      setBranch("");
    }
  };

  const handleChangePurpose = e => {
    if (e?.value) {
      setPurpose(e.value);
    } else {
      setPurpose("");
    }
  };
  const handleChangeCourse = e => {
    setAuthor({
      id: user?._id,
      name: user?.username,
    });
    if (e?.value) {
      setCourse(e.value);
    } else {
      setCourse("");
    }
  };
  let handleRole = e => {
    setUsers([user.username]);
  };
  let handleSubmit = async e => {
    e.preventDefault();

    if (
      groupCode === "" ||
      purpose === "" ||
      course === "" ||
      branch === "" ||
      startDate === "" ||
      branch === ""
    ) {
      setErrors(
        ValidationCreateGroup(
          groupCode,
          purpose,
          course,
          branch,
          startDate,

          // trainer,

          //   addStudentstogroup,
          author
        )
      );
    } else {
      try {
        if (user?.role1 === "trainer") {
          let trainer = users;
          let payload = {
            groupCode,
            purpose,
            course,
            branch,
            trainer,
            startDate,
            // addStudentstogroup,
            author,
          };
          //(payload)
          //(payload);
          await AxiosInstance.post("users/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then(x => {
              //(x);
              toast.success(x.data.message, {
                position: "top-right",
              });
              navigate("/user-dashboard/usergrouplist");
            })
            .catch(x => {
              //(x?.response?.data?.message);
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
          //(data);
        } else if (user?.role1 === "tracker") {
          let tracker = users;
          let payload = {
            groupCode,
            purpose,
            course,
            branch,
            tracker,
            startDate,
            // addStudentstogroup,
            author,
          };
          //(payload)
          await AxiosInstance.post("users/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then(x => {
              //(x);
              toast.success(x.data.message, {
                position: "top-right",
              });
              //   navigate("/user-dashboard/usergrouplist");
            })
            .catch(x => {
              //(x?.response?.data?.message);
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
        } else if (user?.role1 === "hr" || user?.role1 == "human resource") {
          let hr = users;
          let payload = {
            groupCode,
            purpose,
            course,
            branch,
            hr,
            startDate,
            // addStudentstogroup,
            author,
          };
          //(payload)
          await AxiosInstance.post("users/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then(x => {
              //(x);
              toast.success(x.data.message, {
                position: "top-right",
              });
              navigate("/user-dashboard/usergrouplist");
            })
            .catch(x => {
              //(x?.response?.data?.message);
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
          //(data);
        } else {
          let counselor = users;
          let payload = {
            groupCode,
            purpose,
            course,
            branch,
            counselor,
            startDate,
            // addStudentstogroup,
            author,
          };
          //(payload)
          await AxiosInstance.post("users/creategroup", payload, {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          })
            .then(x => {
              //(x);
              toast.success(x.data.message, {
                position: "top-right",
              });
              navigate("/user-dashboard/usergrouplist");
            })
            .catch(x => {
              //(x?.response?.data?.message);
              toast.error(x?.response?.data?.message, {
                position: "top-right",
              });
            });
          //(data);
        }
      } catch (error) {
        //(error);
      }
    }
  };
  // //(errors)

  return (
    <>
      <section id={Styles.CreateGroup}>
        <main>
          <div className={Styles.form_design}>
            <h1>Create Group</h1>
            <form onSubmit={handleSubmit}>
              <div>
                <label htmlFor="groupCode">GroupCode</label>
                <input
                  className="form-control"
                  type="text"
                  name="groupCode"
                  value={groupCode}
                  placeholder="Enter groupCode"
                  onChange={e => setGroupCode(e.target.value)}
                />
                {errors.groupCode && (
                  <p className={Styles.errors}>{errors.groupCode}</p>
                )}
              </div>
              <aside>
                <label htmlFor="purpose">Purpose</label>
                <Creatable
                  isClearable
                  labelKey="name"
                  options={purposes}
                  onChange={handleChangePurpose}
                  // className={Styles.option}
                />
                {errors.purpose && (
                  <p className={Styles.errors}>{errors.purpose}</p>
                )}
              </aside>
              <aside>
                <label htmlFor="course">course</label>
                <Creatable
                  isClearable
                  labelKey="name"
                  // className={Styles.option}
                  options={courses}
                  onChange={handleChangeCourse}
                />
                {errors.course && (
                  <p className={Styles.errors}>{errors.course}</p>
                )}
              </aside>
              <aside>
                <label htmlFor="branch">Branch</label>
                <Creatable
                  isClearable
                  labelKey="name"
                  // className={Styles.option}
                  options={branchs}
                  onChange={handleChangeBranch}
                />
                {errors.branch && (
                  <p className={Styles.errors}>{errors.branch}</p>
                )}
              </aside>
              <div>
                <label htmlFor="startDate">Start Date</label>
                <input
                  className="form-control"
                  type="date"
                  name="startDate"
                  min={disablePastDate()}
                  // required
                  value={startDate}
                  onChange={e => setStartDate(e.target.value)}
                />
                {errors.startDate && (
                  <p className={Styles.errors}>{errors.startDate}</p>
                )}
              </div>
              <div>
                <label htmlFor="role">User</label>

                <input
                  type="text"
                  value={user?.username}
                  onChange={handleRole}
                  name={`${user?.role1}`}
                />
              </div>

              {/* <article>
                <label htmlFor="addStudentstogroup">Add Students</label>
              
                <Select
                  closeMenuOnSelect={false}
                  isMulti
                  name="addStudentstogroup"
                  options={allstudents}
                  onChange={handleChangeStudent}
                  onMenuScrollToBottom
                />
                {errors.addStudentstogroup && (
                  <p className={Styles.errors}>{errors.addStudentstogroup}</p>
                )}
              </article> */}

              <div className={Styles.button_color}>
                <button>Add Group</button>
              </div>
            </form>
          </div>
        </main>
      </section>
    </>
  );
};

export default CreateGroupByQR;

// export default CreateGroupByQR
