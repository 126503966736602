import React from "react";
import { Outlet } from "react-router-dom";

const StudentMainBar = () => {
  return (
    <div style={{ width: "100%" }}>
      <Outlet />
    </div>
  );
};

export default StudentMainBar;
