import React, { useContext, useEffect, useState } from "react";
import { BddtAxios, BddtFile, GroomingUri } from "../../api/AxiosInstance";
import jwt_decode from "jwt-decode";
import Styles from "./_user.module.css";
import { RotatingLines } from "react-loader-spinner";
import { Fragment } from "react";
import { toast } from "react-toastify";
import { SocketContext } from "../../ioContext/socketIo";
import Cookies from "js-cookie";
import Select from "react-select";
import { IoMdAddCircle } from "react-icons/io";
import { IoMdClose } from "react-icons/io";
import req from "../../assets/req.webp";
import {
  Box,
  Divider,
  Modal as MUIModal,
  Button as MUIButton,
  Button,
  IconButton,
  Grid,
} from "@mui/material";
import handleError from "../Users/ErrorHandler";
import { Link, useNavigate } from "react-router-dom";
// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 550,
  height: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "6px",
  p: 4,
};

const styleModal = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "50%",
  width: {
    xs: "100%", // 0px to 599px
    sm: "70%", // 600px to 899px
    md: "50%", // 900px to 1199px
    lg: "40%", // 1200px and up
  },
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "2px solid #f57c00",
};

const styleModal2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 600,
  width: "90%", // Use percentage to make it responsive
  maxWidth: "600px", // Add max-width for large screens
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "2px solid #f57c00",
  maxheight: "85vh",
  overflow: "auto",
};

const Requirement = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");
  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);
  const [openCourse, setopenCourse] = useState(false);

  const [openAddQuestions, setOpenAddQuestions] = useState(false);
  let [requirement, setRequirement] = useState([]);
  let [selectedData, setSelectedData] = useState([]);
  // let [selectedAttend, setSelectedAttend] = useState(false);

  let [attendrounds, setAttendRounds] = useState([]);
  let [attend, setAttend] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryCompany, setSearchQueryCompany] = useState("");

  let [page, setPage] = useState(1);
  const [questionAnswers, setQuestionAnswers] = useState([
    {
      question: "",
      answer: "",
      subject: [],
      comment: "",
      rounds: [],
    },
  ]);
  const [isLoading, setIsLoading] = useState(false);
  let [subjects, setSubjects] = useState([]);

  const [file, setFile] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);

  // attended states
  let [totalRound, setTotalRound] = useState([]);
  let [inteviewRound, setInterviewRound] = useState([]);
  const socket = useContext(SocketContext);

  const addDiv = () => {
    setQuestionAnswers([
      ...questionAnswers,
      {
        question: "",
        answer: "",
        subject: [],
        rounds: [],
        company: "",
      },
    ]);
  };

  //! image Preview
  let [openModel, setOpenModel] = useState(false);
  let [imageAddress, setImageAddress] = useState("");

  const handleOpen = () => {
    setOpenModel(true);
  };

  let handleMouseEnter = async e => {
    e.preventDefault();
    // setImageAddress(currentQuestion[0].fields.questions[0].image);
  };

  const handleClose = () => {
    setOpenModel(false);
    setImageAddress("");
  };

  useEffect(() => {
    const handleNotification = data => {
      let flash = new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, []);


  const handleChangeBranch = e => {
    //(e);
    setInterviewRound(Array.isArray(e) ? e.map(option => option.value) : []);
    setSelectedOption(e);
  };

  const handleChangeTotalRounds = e => {
    setTotalRound(Array.isArray(e) ? e.map(option => option.value) : []);
    setSelectedOption(e);
  };
  function closeCourse() {
    setopenCourse(false);
  }

  function closeAddQues() {
    setFile("");
    setQuestionAnswers([
      {
        question: "",
        answer: "",
        subject: [],
        comment: "",
        rounds: [],
      },
    ]);
    setTotalRound([]);
    setInterviewRound([]);
    setPage(1);
    setOpenAddQuestions(false);
  }

  useEffect(() => {
    async function fetchlist() {
      setLoading(true);
      var decoded = jwt_decode(userToken);
      if (decoded.aid != null) {
        BddtAxios.get(`/hr/student-main-reqdetails/?token=${userToken}`, {})
          .then(x => {
            setLoading(false);

            let data = x?.data?.results;

            setRequirement(data);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
            setLoading(false);
          });
      } else if (user?.number) {
        BddtAxios.get(`/hr/student-main-reqdetails/?token=${userToken}`, {})
          .then(x => {
            setLoading(false);

            let data = x?.data?.results;

            setRequirement(data);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
            setLoading(false);
          });
      } else if (user?.email) {
        BddtAxios.get(`/hr/student-main-reqdetails/?token=${userToken}`, {})
          .then(x => {
            setLoading(false);

            let data = x?.data?.results;

            setRequirement(data);
          })
          .catch(y => {
            setLoading(false);
            if (y?.response?.status === 403) {
              handleError(navigate, socket);
            }
          });
      }
    }
    fetchlist();
  }, []);

  let fetchIterviewRounds = async () => {
    async function fetchlist() {
      BddtAxios.get(`/familiar/interview-rounds/`)
        .then(x => {
          let dat = x?.data?.results;
          let eee = dat.map(function (ele) {
            return {
              value: ele.id,
              label: ele.name,
            };
          });
          setAttendRounds(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchlist();
  };
  let handleMenuOpenRound = () => {
    async function fetchlist() {
      BddtAxios.get(`/familiar/interview-rounds/`)
        .then(x => {
          let dat = x?.data?.results;
          let eee = dat.map(function (ele) {
            return {
              value: ele.id,
              label: ele.name,
            };
          });
          setAttend(eee);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }
    fetchlist();
  };

  let handleFile = event => {
    const file = event.target.files[0];
  
    setFile(file);
    // You can perform additional operations like uploading the file to a server
  };

  const handleChangeQuestion = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].question = e.target.value;
    setQuestionAnswers(newQuestionAnswers);
  };

  const handleChangeAnswer = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].answer = e.target.value;
    setQuestionAnswers(newQuestionAnswers);
  };

  const handleChangeComment = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].comment = e.target.value;
    setQuestionAnswers(newQuestionAnswers);
  };

  const handleChangeRounds = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].rounds = Array.isArray(e)
      ? e.map(x => x.value)
      : [];

    setQuestionAnswers(newQuestionAnswers);
  };

  const [disableBtn, setDisableBtn] = useState(false);

  let HandleAttended = async e => {
    e.preventDefault();
    const emptyQuestionExists = questionAnswers.some(
      qa => qa.question.trim() === ""
    );

    if (file === "" && emptyQuestionExists) {
      toast.error("Add Question");
    } else if (file && emptyQuestionExists) {
      toast.error("Add Question or Title in Question Field");
    } else {
      setDisableBtn(true);
      const formData = new FormData();
      if (file !== "") {
        formData.append("interview_file", file);
      }
      formData.append("questions", JSON.stringify(questionAnswers));
      formData.append("sid", selectedData.id);

      {
        inteviewRound.length > 0 &&
          formData.append(
            "rounds_attend",
            inteviewRound ? inteviewRound : selectedOption
          );
      }

      {
        totalRound.length > 0 && formData.append("total_rounds", totalRound);
      }

      formData.append("token", userToken);
      // await BddtFile.post(`/hr/student-interview-ques/`, formData); !-------------// old api

      await BddtFile.post(`/hr/student-interview/`, formData)
        .then(x => {
          toast.success("Successfully added Interview question");
          setFile("");
          setQuestionAnswers([
            {
              question: "",
              answer: "",
              subject: [],
              comment: "",
              rounds: [],
            },
          ]);
          setTotalRound([]);
          setInterviewRound([]);

          setOpenAddQuestions(false);
          setDisableBtn(false);

          navigate(
            `/student-dashboard/requirement/question_list/${selectedData.id}`
          );
        })
        .catch(x => {
          setDisableBtn(false);
          toast.error(x?.response?.data?.message);
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
          // toast.error("Not added");
        });
    }
  };

  const redirectToAnotherDomain = e => {
    const param1 = e?.split;

    Cookies.set("hrst", userToken, { domain: ".qspiders.com" });

    const url = `${GroomingUri}?splitid=${encodeURIComponent(param1)}`;
    window.open(url, "_blank");
  };
  let HandleCancelForm = () => {
    setOpenAddQuestions(false);
    setQuestionAnswers([
      {
        question: "",
        answer: "",
        subject: [],
        comment: "",
        rounds: [],
      },
    ]);
    setFile("");
    setTotalRound([]);
    setInterviewRound([]);
  };
  let onChangeselect = (e, index) => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers[index].subject = Array.isArray(e)
      ? e.map(x => x.value)
      : [];

    setQuestionAnswers(newQuestionAnswers);
  };

  let LoadSubject = () => {
    async function fetchData() {
      await BddtAxios.get(
        `/courses/subject/?token=${userToken}&name=${searchQuery}&page=${page}`
      )
        .then(x => {
          let Subjectdataaa = x?.data?.results;

          let ddd = Subjectdataaa.map(function (ele) {
            return { value: ele?.id, label: ele?.name };
          });
          setSubjects(ddd);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate, socket);
          }
        })
        .finally(z => {
          setIsLoading(false);
        });
    }
    fetchData();
  };

  const handleMenuOpen = () => {
    if (subjects.length === 0 && !isLoading) {
      setIsLoading(true);
      LoadSubject();
    }
  };

  const handleInputChange = value => {
    setSearchQuery(value);
  };

  useEffect(() => {
    if (searchQuery?.length > 3) {
      LoadSubject();
    }
  }, [searchQuery?.length > 3]);

  const deleteDiv = index => {
    const newQuestionAnswers = [...questionAnswers];
    newQuestionAnswers.splice(index, 1);

    setQuestionAnswers(newQuestionAnswers);
  };
  let handleRemoveImage = e => {
    setFile("");
  };
  // //(selectedOption);
  return (
    <section id={Styles.RequirementBlock}>
      {/* modal for image preview */}
      <MUIModal open={openModel} onClose={handleClose}>
        <Box sx={style}>
          {file !== "" ? (
            <img
              loading="lazy"
              src={URL.createObjectURL(file)}
              alt=""
              height="100%"
              width="100%"
            />
          ) : (
            ""
          )}
        </Box>
      </MUIModal>
      {/* modal for image preview */}
      {loading === true ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        ""
      )}
      {requirement?.length <= 0 ? (
        <div className={Styles.MessageError1}>
          <img src={req} alt="" />
          <h3>You are not assigned to any Requirement yet</h3>
        </div>
      ) : (
        <Fragment>
          {requirement?.map((ele, ind) => {
            return (
              <section key={ind}>
                <div
                  className={Styles.card}
                  key={ind}
                  style={{
                    // backgroundImage: `url('${bg3}')`,
                    // backgroundSize: "cover",
                    // backgroundRepeat: "no-repeat",
                    border: "0.3px ridge #1A5276",
                    height: "auto",
                  }}
                >
                  <div className={Styles.CardHeading}>
                    {" "}
                    <h4 className={Styles.cardTitle}>
                      Requirement Details
                    </h4>{" "}
                  </div>
                  <div className={Styles.cardContent}>
                    <h4 className={Styles.cardTitle}>Requirement Name</h4>{" "}
                    <span className={Styles.Colan}>:</span>
                    <p className={Styles.cardDescription}>
                      {ele?.requirement[0]?.name}
                    </p>
                  </div>

                  <div className={Styles.buttonContainer}>
                    <button
                      className={Styles.button1}
                      onClick={e => {
                        setopenCourse(true);
                        setSelectedData(ele.requirement);
                      }}
                    >
                      View
                    </button>
                    <button
                      className={Styles.button2}
                      appearance="primary"
                      size="xs"
                      onClick={e => {
                        setOpenAddQuestions(true);
                        setSelectedData(ele);
                      }}
                    >
                      Add Interview Questions
                    </button>
                  
                  </div>
                  <div className={Styles.buttonContainer1}>
                    <Link
                      to={`/student-dashboard/requirement/question_list/${ele.id}`}
                    >
                      <button
                        className={Styles.button3}
                        appearance="primary"
                        size="xs"
                      >
                        Interview Questions List
                      </button>
                    </Link>
                  </div>
                </div>
              </section>
            );
          })}
          <MUIModal
            open={openAddQuestions}
            onClose={closeAddQues}
            closeAfterTransition
          >
            <Box sx={styleModal2}>
              <aside
                className={Styles.Heading}
                style={{
                  color: "#f57c00",
                  fontWeight: "bold",
                  textAlign: "center",
                  position: "relative",
                  marginBottom: "5px",
                }}
              >
                Add Interview Questions
                <button
                  style={{
                    position: "absolute",
                    right: "-10px",
                    background: "transparent",
                    top: "-10px",
                  }}
                  onClick={closeAddQues}
                >
                  <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                    <path
                      fill="crimson"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    ></path>
                  </svg>
                </button>
              </aside>
              <aside style={{ maxHeight: "60vh", overflow: "auto" }}>
                <div className={Styles.inputBlock}>
                  <div className={Styles.trainer12}>
                    <h6 htmlFor="Selectattended">Interview Rounds</h6>
                    <aside>
                      <Select
                        id="Selectattended"
                        name="Selectattended"
                        onMenuOpen={handleMenuOpenRound}
                        options={attend}
                        // value={selectedOption}
                        isMulti
                        onChange={handleChangeTotalRounds}
                      />
                    </aside>
                  </div>
                  <div className={Styles.trainer12}>
                    <h6 htmlFor="Selectattended">Select Attended Round</h6>
                    <aside>
                      <Select
                        id="Selectattended"
                        name="Selectattended"
                        onMenuOpen={handleMenuOpenRound}
                        options={attend}
                        // value={selectedOption}
                        isMulti
                        onChange={handleChangeBranch}
                      />
                    </aside>
                  </div>
                </div>
                <div>
                  {" "}
                  <h5>Questions</h5>
                </div>

                {questionAnswers?.map((qa, index) => (
                  <div className={Styles.inputBlock} key={index}>
                    <header>
                      <aside>
                        <h6 htmlFor="rounds">Select Rounds</h6>
                        <Select
                          onMenuOpen={fetchIterviewRounds}
                          id="rounds"
                          isMulti
                          className={Styles.option}
                          options={attendrounds}
                          onChange={e => handleChangeRounds(e, index)}
                        />
                      </aside>
                      <aside>
                        <h6 htmlFor="rounds">Select Subject</h6>
                        <Select
                          id="rounds"
                          onMenuOpen={handleMenuOpen}
                          isMulti
                          isLoading={isLoading}
                          isClearable
                          isSearchable
                          escapeClearsValue={true}
                          labelKey="name"
                          className={Styles.option}
                          options={subjects}
                          onInputChange={handleInputChange}
                          onChange={e => onChangeselect(e, index)}
                          // onMenuScrollToBottom={handleMenuScrollToBottom}
                        />
                      </aside>
                      <label htmlFor="Que">
                        <h6>Question</h6>
                      </label>
                      <textarea
                        id="Que"
                        type="text"
                        style={{ padding: "9px", borderRadius: "5px" }}
                        placeholder="Question"
                        name={`input_${index}`}
                        value={qa?.question}
                        onChange={e => handleChangeQuestion(e, index)}
                      />
                      {questionAnswers.length > 1 && (
                        <span
                          className={Styles.deleteicon}
                          onClick={() => deleteDiv(index)}
                        >
                          <IoMdClose />
                        </span>
                      )}
                    </header>
                    <footer>
                      <aside htmlFor="Que">
                        <h6>Answer</h6>
                      </aside>
                      <textarea
                        name="Ques"
                        id="answer"
                        type="text"
                        style={{ padding: "9px", borderRadius: "5px" }}
                        placeholder="Answer"
                        value={qa?.answer}
                        onChange={e => handleChangeAnswer(e, index)}
                      />
                    </footer>

                    <div className={Styles.Comment}>
                      <h6 htmlFor="comment">Comment</h6>
                      <textarea
                        name="comment"
                        id="comment"
                        value={qa.comment}
                        placeholder="Comment"
                        onChange={e => handleChangeComment(e, index)}
                      ></textarea>
                    </div>
                  </div>
                ))}

                <span className={Styles.AddButton} onClick={addDiv}>
                  <IoMdAddCircle />
                </span>
              </aside>

              <Grid item md={4} style={{ display: "flex" }}>
                {file === "" ? (
                  <div style={{ marginTop: "8px" }}>
                    <IconButton
                      color="white"
                      title="Upload File"
                      aria-label="upload picture"
                      component="label"
                      style={{
                        border: "2px solid",
                        borderRadius: "5px",
                        fontSize: "16px",
                        background: "rgb(245, 124, 0)",
                        fontWeight: "bold",
                        color: "#fff",
                      }}
                    >
                      Upload File
                      <input
                        type="file"
                        onChange={handleFile}
                        // style={{ display: "none" }}
                        hidden
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                      >
                        <g fill="none">
                          <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                          <path
                            fill="#fff"
                            d="M20 14.5a1.5 1.5 0 0 1 1.5 1.5v4a2.5 2.5 0 0 1-2.5 2.5H5A2.5 2.5 0 0 1 2.5 20v-4a1.5 1.5 0 0 1 3 0v3.5h13V16a1.5 1.5 0 0 1 1.5-1.5M13.237 3.12l4.066 4.067a1.5 1.5 0 1 1-2.121 2.121L13.5 7.626V16a1.5 1.5 0 0 1-3 0V7.626L8.818 9.308a1.5 1.5 0 1 1-2.121-2.121l4.066-4.066a1.75 1.75 0 0 1 2.474 0Z"
                          />
                        </g>
                      </svg>
                    </IconButton>
                  </div>
                ) : (
                  <Button
                    title="Delete file"
                    color="error"
                    onClick={handleRemoveImage}
                    style={{ fontSize: "25px" }}
                  >
                    <svg fill="crimson" height="1em" viewBox="0 0 448 512">
                      <path d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z" />
                    </svg>
                  </Button>
                )}

                {(file !== "" && file[0]?.mimetype === "image/jpeg") ||
                  file[0]?.mimetype === "image/jpg" ||
                  file[0]?.mimetype === "image/avif" ||
                  (file[0]?.mimetype === "image/png" && (
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Button
                        style={{
                          color: "#2677b0",
                          fontWeight: "bold",
                          marginLeft: "3%",
                        }}
                        onClick={handleOpen}
                        onMouseEnter={handleMouseEnter}
                        title="Preview File"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35"
                          height="35"
                          viewBox="0 0 16 16"
                        >
                          <path
                            fill="#4394ea"
                            d="M.002 3a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-12a2 2 0 0 1-2-2zm1 9v1a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V9.5l-3.777-1.947a.5.5 0 0 0-.577.093l-3.71 3.71l-2.66-1.772a.5.5 0 0 0-.63.062zm5-6.5a1.5 1.5 0 1 0-3 0a1.5 1.5 0 0 0 3 0"
                          />
                        </svg>{" "}
                        &nbsp;
                      </Button>
                      <br />
                    </div>
                  ))}
                <p
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {file &&
                    file.name.slice(file.name.length - 35, file.name.length)}
                </p>
              </Grid>

              <div
                style={{
                  width: "80%",
                  margin: "8px auto",
                  display: "flex",
                  justifyContent: "space-evenly",
                }}
              >
                <MUIButton
                  size="small"
                  variant="contained"
                  color="error"
                  onClick={() => HandleCancelForm()}
                >
                  Cancel
                </MUIButton>
                <MUIButton
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={HandleAttended}
                  disabled={disableBtn}
                >
                  {disableBtn ? "In Progress" : "Submit"}
                </MUIButton>
              </div>
              <Divider />
            </Box>
          </MUIModal>

          <MUIModal
            open={openCourse}
            onClose={closeCourse}
            closeAfterTransition
          >
            <Box sx={styleModal}>
              <aside
                className={Styles.Heading}
                style={{
                  color: "#f57c00",
                  fontWeight: "bold",
                  textAlign: "center",
                  position: "relative",
                }}
              >
                Requirement Details
                <button
                  style={{
                    position: "absolute",
                    right: "-10px",
                    background: "transparent",
                    top: "-10px",
                  }}
                  onClick={closeCourse}
                >
                  <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                    <path
                      fill="crimson"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    ></path>
                  </svg>
                </button>
              </aside>
              <Divider />
              <div className={Styles.ReqListView}>
                <div className={Styles.detailsRow}>
                  <h5>
                    Designation:{" "}
                    <span className={Styles.datareq}>
                      {selectedData[0]?.designation[0]?.name}
                    </span>
                  </h5>
                </div>
                <div className={Styles.detailsRow}>
                  <h5>
                    Job Location:{" "}
                    <span className={Styles.datareq}>
                      {" "}
                      {selectedData[0]?.location[0]?.name}
                    </span>
                  </h5>
                </div>
                {/* <div className={Styles.detailsRow}>
                  <h5>
                    Tentative Date:{" "}
                    <span className={Styles.datareq}>
                      {selectedData[0]?.tentative_date}{" "}
                    </span>
                  </h5>
                </div> */}
                <div className={Styles.detailsRow}>
                  <h5>
                    Interview Rounds:{" "}
                    <span className={Styles.datareq}>
                      {selectedData[0]?.rounds?.map(x => x.name)}
                    </span>
                  </h5>
                </div>
                <div className={Styles.detailsRow}>
                  {selectedData[0]?.deposite === true ? (
                    <h5>
                      Deposit:{" "}
                      <span className={Styles.datareq}>
                        {selectedData[0]?.amount}
                      </span>
                    </h5>
                  ) : (
                    ""
                  )}
                </div>
                <div className={Styles.detailsRow}>
                  {selectedData[0]?.bond === true ? (
                    <h5>
                      Bond:{" "}
                      <span className={Styles.datareq}>
                        {selectedData[0]?.years}- years
                      </span>
                    </h5>
                  ) : (
                    ""
                  )}
                </div>

                <div className={Styles.detailsRow}>
                  {selectedData[0]?.certification_sub === true ? (
                    <h5>
                      Certificate:{" "}
                      <span className={Styles.datareq}>
                        {selectedData[0]?.certificate?.map(x => x.name)}
                      </span>
                    </h5>
                  ) : (
                    ""
                  )}
                </div>
                {selectedData[0]?.shift.length > 0 && (
                  <div className={Styles.detailsRow}>
                    <h5>
                      Shift:{" "}
                      <span className={Styles.datareq}>
                        {selectedData[0]?.shift?.map(x => x.name)}
                      </span>
                    </h5>
                  </div>
                )}

                {selectedData[0]?.subject ? (
                  <h5>
                    Skills:{" "}
                    <span className={Styles.datareq}>
                      {selectedData[0]?.subject?.map(x => x.name)}
                    </span>
                  </h5>
                ) : (
                  ""
                )}
                <h5>
                  Interview type:{" "}
                  <span className={Styles.datareq}>
                    {" "}
                    {selectedData[0]?.interview.name}
                  </span>
                </h5>
                {/* {selectedData[0]?.interview.name === "Online and Offline" ? (
                  <>
                    {selectedData[0]?.meeting_url === "" ? (
                      ""
                    ) : (
                      <h5>
                        Meeting Link:{" "}
                        <span className={Styles.datareq}>
                          {" "}
                          {selectedData[0]?.meeting_url}
                        </span>
                      </h5>
                    )}
                    <h5>
                      Location Link:{" "}
                      <span className={Styles.datareq}>
                        {" "}
                        {selectedData[0]?.google_link}
                      </span>
                    </h5>
                    {selectedData[0]?.venue === "" ? (
                      ""
                    ) : (
                      <h5>
                        Venue:{" "}
                        <span className={Styles.datareq}>
                          {" "}
                          {selectedData[0]?.venue}
                        </span>
                      </h5>
                    )}
                  </>
                ) : selectedData[0]?.interview.name === "Online" ? (
                  <h5>
                    Meeting Link:{" "}
                    <span className={Styles.datareq}>
                      {" "}
                      {selectedData[0]?.meeting_url}
                    </span>
                  </h5>
                ) : selectedData[0]?.interview.name === "Offline" ? (
                  <>
          
                    {selectedData[0]?.google_link !== null && (
                      <h5>
                        Location Link:{" "}
                        <span className={Styles.datareq}>
                          {" "}
                          {selectedData[0]?.google_link}
                        </span>
                      </h5>
                    )}
                    <h5>
                      Venue:{" "}
                      <span className={Styles.datareq}>
                        {" "}
                        {selectedData[0]?.venue}
                      </span>
                    </h5>
                  </>
                ) : (
                  ""
                )} */}
              </div>
            </Box>
          </MUIModal>

          {/* <div id={Styles.reactpaginate}>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          pageRangeDisplayed={3}
          previousLabel="< previous"
          pageCount={pag?.pages}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div> */}
        </Fragment>
      )}
    </section>
  );
};

export default Requirement;
