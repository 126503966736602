import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import Styles from "./table.module.css";
import { Box, Button, Container, Divider } from "@mui/material";
import { FormControl, Grid, IconButton, InputAdornment } from "@mui/material";
import { InputLabel, OutlinedInput, TextField } from "@mui/material";

const UpdateStudents = () => {
  let navigate = useNavigate();

  let { id } = useParams();
  let { userAdmin } = useSelector(state => state?.auth);

  useEffect(() => {
    async function fetchlist() {
      try {
        const { data } = await AxiosInstance.get(`auth/student/${id}`, {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        });
        let result = data.userData;
        setUsername(result?.username);
        setPassword(result?.password);
        setEmails(result?.email);
        setMobiles(result?.number);
      } catch (error) {
        toast.error("something went wrong");
      }
    }
    fetchlist();
  }, [id]);

  let [username, setUsername] = useState("");
  let [password, setPassword] = useState("");
  let [batchcode, setBatchcode] = useState([]);
  let [groupcode, setGroupcode] = useState([]);
  let [batchCode, setBatchCode] = useState([]);
  let [groupCode, setGroupCode] = useState([]);
  const [isRevealPwd, setIsRevealPwd] = useState(false);

  let handleBatchCode = e => {
    setBatchcode(e.target.value);

    if (e.target.value) {
      //(e.target.value);
      let data = e.target.value.split("/");
      setBatchCode(data);
    } else {
      setBatchCode = [];
    }
  };
  let handleGroupCode = e => {
    setGroupcode(e.target.value);
    if (e.target.value) {
      let gggg = e.target.value;
      let data = gggg.split("/");
      setGroupCode(data);
    } else {
      setGroupCode = [];
    }
  };

  // email and mobile dynamic input field
  let [emails, setEmails] = useState([""]);
  let [mobiles, setMobiles] = useState([""]);

  const handleChanger = (value, index) => {
    const newEmail = emails.map((Item, Index) => {
      return Index === index ? value : Item;
    });
    setEmails(newEmail);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...emails];
    list.splice(index, 1);
    setEmails(list);
  };

  // handle click event of the Add button
  const handleAddClick = () => {
    setEmails([...emails, ""]);
  };

  const handleMobChange = (value, index) => {
    const newMobile = mobiles.map((Item, Index) => {
      return Index === index ? value : Item;
    });
    setMobiles(newMobile);
  };

  const handleRemoveMob = index => {
    const list = [...mobiles];
    list.splice(index, 1);
    setMobiles(list);
  };
  const handleAddMob = () => {
    setMobiles([...mobiles, ""]);
  };

  const preventDigits = event => {
    let condtionAlpha =
      (event.charCode >= 65 && event.charCode <= 90) ||
      (event.charCode >= 97 && event.charCode <= 122) ||
      event.charCode === 32;
    if (!condtionAlpha) {
      event.preventDefault();
    }
  };
  // to prevent Alphabets
  const preventMinus = e => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  // email and mobile dynamic input field

  // ?submit function
  let handleSubmit = async e => {
    e.preventDefault();
    try {
      let payload = {
        email: emails,
        username,
        number: mobiles,
        password,
        batchCode,
        groupCode,
      };

      const options = {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      };
      await AxiosInstance.patch(`/auth/student/${id}`, payload, options)
        .then(x => {
          toast.success(x?.data?.message);
          navigate("/admin-dashboard/admin/allstudents");
        })
        .catch(x => {
          toast.error(x?.response?.data?.message);
        });
    } catch (error) {
      toast.error("something went wrong");
    }
  };

  return (
    <>
      <Container
        className={Styles.updateStudentBlockContainer}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
          width: "40vw",
        }}
      >
        <Box
          sx={{
            height: "auto",
            padding: "17px",
            width: "100%",
            borderRadius: "10px",
            border: "0.3px ridge gray",
            boxShadow: "1px 0px 8px #f16f4d",
          }}
        >
          <form onSubmit={handleSubmit} style={{ width: "100%" }}>
            <h3 style={{ color: "#f16f4d", textAlign: "center" }}>
              Update Student
            </h3>
            <Divider />
            <br />
            <Grid container spacing={2}>
              <Grid item sm={6}>
                <TextField
                  color="warning"
                  variant="outlined"
                  size="small"
                  fullWidth
                  value={username}
                  placeholder="Enter User Name"
                  label="User Name"
                  name="username"
                  required
                  onChange={e => setUsername(e.target.value)}
                />
              </Grid>

              {/* mobile/email */}
              {emails.map((item, index) => {
                return (
                  <Grid
                    item
                    sm={6}
                    position="relative"
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                    key={index + 1}
                  >
                    <TextField
                      style={{ marginBottom: "5px" }}
                      label="Email"
                      color="warning"
                      variant="outlined"
                      className="email"
                      size="small"
                      fullWidth
                      value={item}
                      required
                      onChange={e => handleChanger(e.target.value, index)}
                    />
                    <div className="btn-box">
                      {emails.length !== 1 && index !== 0 && (
                        <button
                          title="Delete Email"
                          className={Styles.btns}
                          type="button"
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "-8px",
                          }}
                          onClick={() => handleRemoveClick(index)}
                        >
                          <svg width={15} height={15} viewBox="0 0 20 20">
                            <path
                              fill="crimson"
                              d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z"
                            ></path>
                          </svg>
                        </button>
                      )}
                      {emails.length - 1 === index && (
                        <button
                          title="Add more Email"
                          className={Styles.btns}
                          type="button"
                          style={
                            emails.length < 2
                              ? {
                                  display: "inline-block",
                                  position: "absolute",
                                  right: "-8px",
                                  bottom: "-8px",
                                }
                              : { display: "none" }
                          }
                          onClick={handleAddClick}
                        >
                          <svg width={18} height={18} viewBox="0 0 48 48">
                            <circle
                              cx={24}
                              cy={24}
                              r={21}
                              fill="#4caf50"
                            ></circle>
                            <g fill="#fff">
                              <path d="M21 14h6v20h-6z"></path>
                              <path d="M14 21h20v6H14z"></path>
                            </g>
                          </svg>{" "}
                        </button>
                      )}
                    </div>
                  </Grid>
                );
              })}

              {mobiles.map((item, index) => {
                return (
                  <Grid
                    item
                    xs={6}
                    style={{
                      display: "flex",
                      position: "relative",
                      flexDirection: "column",
                      alignItems: "flex-end",
                    }}
                    key={index + 1}
                  >
                    <TextField
                      style={{ marginBottom: "5px" }}
                      type="tel"
                      label="Mobile"
                      color="warning"
                      variant="outlined"
                      onKeyPress={preventMinus}
                      inputProps={{ minLength: 10, maxLength: 10 }}
                      size="small"
                      fullWidth
                      required
                      value={item}
                      onChange={e => handleMobChange(e.target.value, index)}
                    />
                    <div className="btn-box">
                      {mobiles.length !== 1 && index !== 0 && (
                        <button
                          title="Delete Mobile Number"
                          className={Styles.btns}
                          style={{
                            position: "absolute",
                            top: "12px",
                            right: "-8px",
                          }}
                          type="button"
                          onClick={() => handleRemoveMob(index)}
                        >
                          <svg width={15} height={15} viewBox="0 0 20 20">
                            <path
                              fill="crimson"
                              d="M2.93 17.07A10 10 0 1 1 17.07 2.93A10 10 0 0 1 2.93 17.07M11.4 10l2.83-2.83l-1.41-1.41L10 8.59L7.17 5.76L5.76 7.17L8.59 10l-2.83 2.83l1.41 1.41L10 11.41l2.83 2.83l1.41-1.41L11.41 10z"
                            ></path>
                          </svg>
                        </button>
                      )}
                      {mobiles.length - 1 === index && (
                        <button
                          title="Add More Mobile Number"
                          className={Styles.btns}
                          type="button"
                          style={
                            mobiles.length < 2
                              ? {
                                  display: "inline-block",
                                  position: "absolute",
                                  right: "-10px",
                                  bottom: "-3px",
                                }
                              : { display: "none" }
                          }
                          onClick={handleAddMob}
                        >
                          <svg width={17} height={17} viewBox="0 0 48 48">
                            <circle
                              cx={24}
                              cy={24}
                              r={21}
                              fill="#4caf50"
                            ></circle>
                            <g fill="#fff">
                              <path d="M21 14h6v20h-6z"></path>
                              <path d="M14 21h20v6H14z"></path>
                            </g>
                          </svg>
                        </button>
                      )}
                    </div>
                  </Grid>
                );
              })}
              {/* mobile/email */}
              <Grid item sm={6}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    htmlFor="outlined-adornment-password"
                    size="small"
                    color="warning"
                  >
                    Password*
                  </InputLabel>
                  <OutlinedInput
                    required
                    color="warning"
                    id="outlined-adornment-password"
                    type={isRevealPwd ? "text" : "password"}
                    value={password}
                    size="small"
                    placeholder="Enter password"
                    onChange={e => setPassword(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          size="small"
                          aria-label="toggle password visibility"
                          onClick={() =>
                            setIsRevealPwd(prevState => !prevState)
                          }
                          edge="end"
                        >
                          {isRevealPwd ? (
                            <svg width={20} height={20} viewBox="0 0 24 24">
                              <path
                                fill="gray"
                                d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                              ></path>
                            </svg>
                          ) : (
                            <svg width={20} height={20} viewBox="0 0 1024 1024">
                              <path
                                fill="gray"
                                d="M508 624a112 112 0 0 0 112-112c0-3.28-.15-6.53-.43-9.74L498.26 623.57c3.21.28 6.45.43 9.74.43m370.72-458.44L836 122.88a8 8 0 0 0-11.31 0L715.37 232.23Q624.91 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 0 0 0 51.5q56.7 119.43 136.55 191.45L112.56 835a8 8 0 0 0 0 11.31L155.25 889a8 8 0 0 0 11.31 0l712.16-712.12a8 8 0 0 0 0-11.32M332 512a176 176 0 0 1 258.88-155.28l-48.62 48.62a112.08 112.08 0 0 0-140.92 140.92l-48.62 48.62A175.09 175.09 0 0 1 332 512"
                              ></path>
                              <path
                                fill="gray"
                                d="M942.2 486.2Q889.4 375 816.51 304.85L672.37 449A176.08 176.08 0 0 1 445 676.37L322.74 798.63Q407.82 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 0 0 0-51.5"
                              ></path>
                            </svg>
                          )}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item sm={6}>
                <TextField
                  color="warning"
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Batch Code"
                  name="batchcode"
                  value={batchcode}
                  placeholder="Enter Batch Code"
                  onChange={e => handleBatchCode(e)}
                />
              </Grid>
              <Grid item sm={6}>
                <TextField
                  color="warning"
                  variant="outlined"
                  size="small"
                  fullWidth
                  label="Group Code"
                  name="groupcode"
                  value={groupcode}
                  placeholder="Enter Group Code"
                  onChange={handleGroupCode}
                />
              </Grid>
              <Grid
                item
                sm={12}
                sx={{ display: "flex", justifyContent: "space-evenly" }}
              >
                <Button
                  type="button"
                  variant="outlined"
                  size="small"
                  color="warning"
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  size="small"
                  color="success"
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Container>
    </>
  );
};

export default UpdateStudents;
