import React from "react";
import Styles from "./_user.module.css";
import OtherMainBar from "./OtherMainBar";
import UserSidebar from "./UserSidebar";

const OtherUsers = () => {
  return (
    <section id={Styles.adminDashboardBlock}>
      <article>
        {/* <aside className={Styles.sidebarBlock}>
          <UserSidebar />
        </aside> */}
        <aside className={Styles.mainadminBlock}>{<OtherMainBar />}</aside>
      </article>
    </section>
  );
};

export default OtherUsers;
