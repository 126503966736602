import React, { useState } from "react";
import { FaUser } from "react-icons/fa";
import { FcCancel, FcViewDetails } from "react-icons/fc";
import { SiGoogleclassroom } from "react-icons/si";
import { HiUserGroup } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminSidebar = () => {
  const location = useLocation();
  let { userAdmin } = useSelector(state => state.auth);
  // const [isActive, setIsActive] = useState(false);
  const [activeItem, setActiveItem] = useState(null);
  const handleItemClick = item => {
    setActiveItem(item);
  };

  return (
    <>
      {userAdmin?.role[0] === "corporate" ? (
        <ul>
          <li
            className={location.pathname === "/admin-dashboard" ? "active" : ""}
          >
            <Link
              to="/admin-dashboard"
              onClick={() => handleItemClick("Batch")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0e83be"
                    d="M8.5 11.5h7v-1h-7zm0-4h7v-1h-7zm10.412 12.437l-3.416-4.43q-.367-.478-.877-.742q-.51-.265-1.119-.265H5V4.616q0-.691.463-1.153T6.616 3h10.769q.69 0 1.153.463T19 4.616v14.769q0 .144-.022.285q-.022.142-.066.267M6.616 21q-.691 0-1.153-.462T5 19.385V15.5h8.5q.366 0 .674.16q.309.161.536.457l3.527 4.616q-.183.127-.397.197t-.456.07z"
                  />
                </svg>
              </span>
              <span>BatchList</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/groupList"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/groupList"
              onClick={() => handleItemClick("Group")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="#0e83be"
                    d="M152 80a12 12 0 0 1 12-12h80a12 12 0 0 1 0 24h-80a12 12 0 0 1-12-12m92 36h-80a12 12 0 0 0 0 24h80a12 12 0 0 0 0-24m0 48h-56a12 12 0 0 0 0 24h56a12 12 0 0 0 0-24m-88.38 25a12 12 0 1 1-23.24 6c-5.72-22.23-28.24-39-52.38-39s-46.66 16.76-52.38 39a12 12 0 1 1-23.24-6c5.38-20.9 20.09-38.16 39.11-48a52 52 0 1 1 73 0c19.04 9.85 33.75 27.11 39.13 48M80 132a28 28 0 1 0-28-28a28 28 0 0 0 28 28"
                  />
                </svg>
              </span>
              <span>GroupList</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/allroles"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/allroles"
              onClick={() => handleItemClick("Users")}
            >
              <span>
                <FcViewDetails />
              </span>
              <span>Users List</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/allstudents"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/allstudents"
              onClick={() => handleItemClick("Students")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0e83be"
                    d="M18 10.5V6l-2.11 1.06A4 4 0 0 1 12 12a4 4 0 0 1-3.89-4.94L5 5.5L12 2l7 3.5v5zM12 9l-2-1c0 1.1.9 2 2 2s2-.9 2-2zm2.75-3.58L12.16 4.1L9.47 5.47l2.6 1.32zM12 13c2.67 0 8 1.33 8 4v3H4v-3c0-2.67 5.33-4 8-4m0 1.9c-3 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1"
                  />
                </svg>
              </span>
              <span>Students List</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/BlockedUsers"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/BlockedUsers"
              onClick={() => handleItemClick("BlockedUsers")}
            >
              <span>
                <FcCancel />
              </span>
              <span>Blocked Users</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/BlockedStudent"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/BlockedStudent"
              onClick={() => handleItemClick("BlockedStudents")}
            >
              <span>
                <FcCancel />
              </span>
              <span>Blocked Students</span>
            </Link>
          </li>
          {/* <li
            className={
              location.pathname === "/admin-dashboard/admin/addroles"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/addroles"
              onClick={() => handleItemClick("AddUsers")}
            >
              <span>
                <FaUser />
              </span>
              <span>Add Users</span>
            </Link>
          </li> */}
          {/* <li
            className={
              location.pathname === "/admin-dashboard/admin/addstudents"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/addstudents"
              onClick={() => handleItemClick("AddStudents")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0e83be"
                    d="M16 8c0 2.21-1.79 4-4 4s-4-1.79-4-4l.11-.94L5 5.5L12 2l7 3.5v5h-1V6l-2.11 1.06zm-4 6c4.42 0 8 1.79 8 4v2H4v-2c0-2.21 3.58-4 8-4"
                  />
                </svg>
              </span>
              <span>Add Students</span>
            </Link>
          </li> */}
          {/* <li className={activeItem === "chatuser" ? "active" : ""}>
            <Link
              to="/admin-dashboard/admin/chatuser"
              onClick={() => handleItemClick("chatuser")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0ebe2c"
                    d="M10.997 17.499c0-1.29.375-2.49 1.022-3.501H6.25A2.25 2.25 0 0 0 4 16.248v.92c0 .572.178 1.13.51 1.596c1.403 1.968 3.617 3.034 6.58 3.21l.522-1.713a6.5 6.5 0 0 1-.615-2.762M11.998 2a5.001 5.001 0 1 1 0 10.002a5.001 5.001 0 0 1 0-10.002M23 17.499a5.5 5.5 0 0 1-8.168 4.813l-2.187.665a.5.5 0 0 1-.624-.624l.666-2.187A5.501 5.501 0 1 1 23 17.498m-7.501-1.5a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1zm-.5 2.5a.5.5 0 0 0 .5.5h2a.5.5 0 1 0 0-1h-2a.5.5 0 0 0-.5.5"
                  />
                </svg>
              </span>
              <span>Chat with users</span>
            </Link>
          </li> */}
          {/* className={location.pathname === "/about" ? "active" : ""} */}
          <li
            className={
              location.pathname === "/admin-dashboard/admin/userviewchat"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/userviewchat"
              onClick={() => handleItemClick("viewchatuser")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0ebe2c"
                    d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2M6 9h12v2H6zm8 5H6v-2h8zm4-6H6V6h12z"
                  />
                </svg>
              </span>
              <span>View users Chat</span>
            </Link>
          </li>
          {/* <li
            className={
              location.pathname === "/admin-dashboard/admin/createbatch"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/createbatch"
              onClick={() => handleItemClick("createbatch")}
            >
              <span>
                <SiGoogleclassroom />
              </span>
              <span>Create Batch</span>
            </Link>
          </li> */}
          <li
            className={
              location.pathname === "/admin-dashboard/admin/createGroup"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/createGroup"
              onClick={() => handleItemClick("createGroup")}
            >
              <span>
                <HiUserGroup />
              </span>
              <span>Create Group</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/ArchivedUsers"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/ArchivedUsers"
              onClick={() => handleItemClick("ArchivedUsers")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0383ba"
                    fill-rule="evenodd"
                    d="M16.5 15.75a2.75 2.75 0 0 0-2.383 4.123l3.756-3.756a2.735 2.735 0 0 0-1.373-.367m2.42 1.442l-3.728 3.728a2.75 2.75 0 0 0 3.728-3.728M12.25 18.5a4.25 4.25 0 1 1 8.5 0a4.25 4.25 0 0 1-8.5 0"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#0383ba"
                    d="M16 6a4 4 0 1 1-8 0a4 4 0 0 1 8 0m-1.705 7.188A5.752 5.752 0 0 0 11.938 22C4 21.99 4 19.979 4 17.5c0-2.485 3.582-4.5 8-4.5c.798 0 1.568.066 2.295.188"
                  />
                </svg>
              </span>
              <span>Archived Users</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/ArchivedStudents"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/ArchivedStudents"
              onClick={() => handleItemClick("ArchivedStudents")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path fill="#0383ba" d="M12 10a4 4 0 1 0 0-8a4 4 0 0 0 0 8" />
                  <path
                    fill="#0383ba"
                    fill-rule="evenodd"
                    d="M16.5 15.75a2.75 2.75 0 0 0-2.383 4.123l3.756-3.756a2.735 2.735 0 0 0-1.373-.367m2.42 1.442l-3.728 3.728a2.75 2.75 0 0 0 3.728-3.728M12.25 18.5a4.25 4.25 0 1 1 8.5 0a4.25 4.25 0 0 1-8.5 0"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#0383ba"
                    d="M17.996 14.521a4.25 4.25 0 0 0-3.979 7.429c-.608.033-1.278.05-2.017.05c-8 0-8-2.015-8-4.5S7.582 13 12 13c2.387 0 4.53.588 5.996 1.521"
                    opacity="0.6"
                  />
                </svg>
              </span>
              <span>Archived Students</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/inactiveusers"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/inactiveusers"
              onClick={() => handleItemClick("InActiveUsers")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0383ba"
                    d="M17.327 16.87q0-.166-.115-.278q-.116-.111-.27-.111q-.153 0-.269.115q-.115.116-.115.27v2.495q0 .166.115.278q.116.111.27.111q.153 0 .268-.115q.116-.116.116-.27zm1.346-.005v2.495q0 .167.116.278q.115.112.269.112t.269-.116t.115-.269V16.87q0-.166-.115-.278q-.115-.111-.27-.111q-.153 0-.268.115q-.116.116-.116.27M16.731 8.73q.212 0 .356-.144t.144-.357t-.144-.356t-.356-.143H7.269q-.212 0-.356.144t-.144.356t.144.356t.356.144zM18 22.116q-1.671 0-2.835-1.165Q14 19.787 14 18.116t1.165-2.836T18 14.116t2.836 1.164T22 18.116q0 1.67-1.164 2.835Q19.67 22.116 18 22.116M4 20.72V5.616q0-.667.475-1.141T5.615 4h12.77q.666 0 1.14.475T20 5.615v5.116q0 .313-.254.506q-.254.194-.563.127q-.777-.137-1.55-.106q-.774.03-1.537.242H7.27q-.212 0-.356.144t-.144.357t.144.356t.356.143h6.812q-.752.521-1.326 1.223t-.946 1.546H7.27q-.212 0-.356.144t-.144.357t.144.356t.356.143h4.21q-.108.404-.168.815t-.061.858q0 .477.077.955q.077.477.2.955q.067.179-.09.293q-.158.114-.3.01l-.249-.17q-.111-.074-.234-.074t-.235.073l-.877.608q-.111.073-.234.073t-.235-.073l-.877-.608q-.112-.073-.235-.073t-.234.073l-.877.608q-.111.074-.234.074t-.235-.074l-.877-.607q-.112-.073-.235-.073t-.234.073l-.781.607q-.058.039-.254.13"
                  />
                </svg>
              </span>
              <span>Inactive Users</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/archivebatch"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/archivebatch"
              onClick={() => handleItemClick("archiveBatch")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="none"
                    stroke="#0383ba"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M6.099 19.5q-1.949-.192-2.927-1.172C2 17.157 2 15.271 2 11.5V11c0-3.771 0-5.657 1.172-6.828S6.229 3 10 3h1.5m-5 15c-.205 1.002-1.122 3.166-.184 3.865c.49.357 1.271-.024 2.834-.786c1.096-.535 2.206-1.148 3.405-1.424c.438-.1.885-.143 1.445-.155c3.771 0 5.657 0 6.828-1.172C21.947 17.21 21.998 15.44 22 12M8 14h6M8 9h3m4.2-5.8l5.6 5.6M22 6a4 4 0 1 0-8 0a4 4 0 0 0 8 0"
                    color="#0383ba"
                  />
                </svg>
              </span>
              <span>Archive Batch</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/archivegroup"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/archivegroup"
              onClick={() => handleItemClick("archiveGroup")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0383ba"
                    fill-rule="evenodd"
                    d="M12 7.5c0 .972.308 1.872.832 2.607A4.48 4.48 0 0 0 16.5 12A4.5 4.5 0 1 0 12 7.5m4.5 3a2.985 2.985 0 0 1-1.524-.415l4.109-4.109A3 3 0 0 1 16.5 10.5m-2.585-1.476l4.109-4.109a3 3 0 0 0-4.109 4.109"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#0383ba"
                    d="M4.823 15.21C2.796 10.233 6.057 4.82 11.17 4.085c.17-.025.282.166.194.312a6 6 0 0 0 8.782 7.869c.139-.107.345-.01.333.165a8.733 8.733 0 0 1-8.711 8.119h-7.82a.5.5 0 0 1-.314-.89l1.972-1.583a.5.5 0 0 0 .15-.579z"
                  />
                </svg>
              </span>
              <span>Archive Group</span>
            </Link>
          </li>
          {/* <li className={activeItem === 'BranchListfromQ&q' ? 'active' : ''}>
            <Link to="/admin-dashboard/admin/BranchListfromQ" onClick={() => handleItemClick('BranchListfromQ&q')}>
              <span>
                <HiUserGroup />
              </span>
              <span>BranchList from Q&q</span>
            </Link>
          </li> */}
        </ul>
      ) : (
        <ul>
          <li
            className={location.pathname === "/admin-dashboard" ? "active" : ""}
          >
            <Link
              to="/admin-dashboard"
              onClick={() => handleItemClick("Batch")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0e83be"
                    d="M8.5 11.5h7v-1h-7zm0-4h7v-1h-7zm10.412 12.437l-3.416-4.43q-.367-.478-.877-.742q-.51-.265-1.119-.265H5V4.616q0-.691.463-1.153T6.616 3h10.769q.69 0 1.153.463T19 4.616v14.769q0 .144-.022.285q-.022.142-.066.267M6.616 21q-.691 0-1.153-.462T5 19.385V15.5h8.5q.366 0 .674.16q.309.161.536.457l3.527 4.616q-.183.127-.397.197t-.456.07z"
                  />
                </svg>
              </span>
              <span>BatchList</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/groupList"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/groupList"
              onClick={() => handleItemClick("Group")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 256 256"
                >
                  <path
                    fill="#0e83be"
                    d="M152 80a12 12 0 0 1 12-12h80a12 12 0 0 1 0 24h-80a12 12 0 0 1-12-12m92 36h-80a12 12 0 0 0 0 24h80a12 12 0 0 0 0-24m0 48h-56a12 12 0 0 0 0 24h56a12 12 0 0 0 0-24m-88.38 25a12 12 0 1 1-23.24 6c-5.72-22.23-28.24-39-52.38-39s-46.66 16.76-52.38 39a12 12 0 1 1-23.24-6c5.38-20.9 20.09-38.16 39.11-48a52 52 0 1 1 73 0c19.04 9.85 33.75 27.11 39.13 48M80 132a28 28 0 1 0-28-28a28 28 0 0 0 28 28"
                  />
                </svg>
              </span>
              <span>GroupList</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/allroles"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/allroles"
              onClick={() => handleItemClick("Users")}
            >
              <span>
                <FcViewDetails />
              </span>
              <span>Users List</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/BlockedUsers"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/BlockedUsers"
              onClick={() => handleItemClick("BlockedUsers")}
            >
              <span>
                <FcCancel />
              </span>
              <span>Blocked Users</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/BlockedStudent"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/BlockedStudent"
              onClick={() => handleItemClick("BlockedStudents")}
            >
              <span>
                <FcCancel />
              </span>
              <span>Blocked Students</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/allstudents"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/allstudents"
              onClick={() => handleItemClick("Students")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0e83be"
                    d="M18 10.5V6l-2.11 1.06A4 4 0 0 1 12 12a4 4 0 0 1-3.89-4.94L5 5.5L12 2l7 3.5v5zM12 9l-2-1c0 1.1.9 2 2 2s2-.9 2-2zm2.75-3.58L12.16 4.1L9.47 5.47l2.6 1.32zM12 13c2.67 0 8 1.33 8 4v3H4v-3c0-2.67 5.33-4 8-4m0 1.9c-3 0-6.1 1.46-6.1 2.1v1.1h12.2V17c0-.64-3.13-2.1-6.1-2.1"
                  />
                </svg>
              </span>
              <span>Students List</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/createGroup"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/createGroup"
              onClick={() => handleItemClick("createGroup")}
            >
              <span>
                <HiUserGroup />
              </span>
              <span>Create Group</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/ArchivedUsers"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/ArchivedUsers"
              onClick={() => handleItemClick("ArchivedUsers")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0383ba"
                    fill-rule="evenodd"
                    d="M16.5 15.75a2.75 2.75 0 0 0-2.383 4.123l3.756-3.756a2.735 2.735 0 0 0-1.373-.367m2.42 1.442l-3.728 3.728a2.75 2.75 0 0 0 3.728-3.728M12.25 18.5a4.25 4.25 0 1 1 8.5 0a4.25 4.25 0 0 1-8.5 0"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#0383ba"
                    d="M16 6a4 4 0 1 1-8 0a4 4 0 0 1 8 0m-1.705 7.188A5.752 5.752 0 0 0 11.938 22C4 21.99 4 19.979 4 17.5c0-2.485 3.582-4.5 8-4.5c.798 0 1.568.066 2.295.188"
                  />
                </svg>
              </span>
              <span>Archived Users</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/ArchivedStudents"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/ArchivedStudents"
              onClick={() => handleItemClick("ArchivedStudents")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path fill="#0383ba" d="M12 10a4 4 0 1 0 0-8a4 4 0 0 0 0 8" />
                  <path
                    fill="#0383ba"
                    fill-rule="evenodd"
                    d="M16.5 15.75a2.75 2.75 0 0 0-2.383 4.123l3.756-3.756a2.735 2.735 0 0 0-1.373-.367m2.42 1.442l-3.728 3.728a2.75 2.75 0 0 0 3.728-3.728M12.25 18.5a4.25 4.25 0 1 1 8.5 0a4.25 4.25 0 0 1-8.5 0"
                    clip-rule="evenodd"
                  />
                  <path
                    fill="#0383ba"
                    d="M17.996 14.521a4.25 4.25 0 0 0-3.979 7.429c-.608.033-1.278.05-2.017.05c-8 0-8-2.015-8-4.5S7.582 13 12 13c2.387 0 4.53.588 5.996 1.521"
                    opacity="0.6"
                  />
                </svg>
              </span>
              <span>Archived Students</span>
            </Link>
          </li>
          <li
            className={
              location.pathname === "/admin-dashboard/admin/InActiveUsers"
                ? "active"
                : ""
            }
          >
            <Link
              to="/admin-dashboard/admin/InActiveUsers"
              onClick={() => handleItemClick("InActiveUsers")}
            >
              <span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#0383ba"
                    d="M17.327 16.87q0-.166-.115-.278q-.116-.111-.27-.111q-.153 0-.269.115q-.115.116-.115.27v2.495q0 .166.115.278q.116.111.27.111q.153 0 .268-.115q.116-.116.116-.27zm1.346-.005v2.495q0 .167.116.278q.115.112.269.112t.269-.116t.115-.269V16.87q0-.166-.115-.278q-.115-.111-.27-.111q-.153 0-.268.115q-.116.116-.116.27M16.731 8.73q.212 0 .356-.144t.144-.357t-.144-.356t-.356-.143H7.269q-.212 0-.356.144t-.144.356t.144.356t.356.144zM18 22.116q-1.671 0-2.835-1.165Q14 19.787 14 18.116t1.165-2.836T18 14.116t2.836 1.164T22 18.116q0 1.67-1.164 2.835Q19.67 22.116 18 22.116M4 20.72V5.616q0-.667.475-1.141T5.615 4h12.77q.666 0 1.14.475T20 5.615v5.116q0 .313-.254.506q-.254.194-.563.127q-.777-.137-1.55-.106q-.774.03-1.537.242H7.27q-.212 0-.356.144t-.144.357t.144.356t.356.143h6.812q-.752.521-1.326 1.223t-.946 1.546H7.27q-.212 0-.356.144t-.144.357t.144.356t.356.143h4.21q-.108.404-.168.815t-.061.858q0 .477.077.955q.077.477.2.955q.067.179-.09.293q-.158.114-.3.01l-.249-.17q-.111-.074-.234-.074t-.235.073l-.877.608q-.111.073-.234.073t-.235-.073l-.877-.608q-.112-.073-.235-.073t-.234.073l-.877.608q-.111.074-.234.074t-.235-.074l-.877-.607q-.112-.073-.235-.073t-.234.073l-.781.607q-.058.039-.254.13"
                  />
                </svg>
              </span>
              <span>Inactive Users</span>
            </Link>
          </li>
        </ul>
      )}
    </>
  );
};

export default AdminSidebar;
