import AxioInstance, { fileAxios } from "../../api/AxiosInstance";
const AllBatches = async (Batchdata, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.get(
    `admin/batchList?page=${Batchdata}`,
    config,
    Batchdata
  );
  // //(data)
  return data;
};
//upadte Batch
const UpdateBatch = async (updatedData, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await fileAxios.patch(
    "admin/updatebatch",
    updatedData,
    config
  );

  return data;
};

/// update Group
const UpdateGroup = async (updatedgroupData, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await fileAxios.patch(
    "admin/updategroup",
    updatedgroupData,
    config
  );
  return data;
};

const AllGroups = async (Groupdata, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.get(
    `admin/groupList?page=${Groupdata}`,
    config,
    Groupdata
  );
  return data;
};

const CreateBatch = async (batchData, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await fileAxios.post("admin/createbatch", batchData, config);
  return data;
};
const CreateGroup = async (groupData, token) => {
  //(token)
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await fileAxios.post("admin/creategroup", groupData, config);
  return data;
};

//get all users
const Allroles = async (token, allrole) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await fileAxios.get(
    `admin/getusers?page=${allrole}`,
    config,
    allrole
  );

  return data;
};
// get all students
const Allstudents = async (token, allstudents) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await fileAxios.get(
    `admin/getstudents?page=${allstudents}`,
    config,
    allstudents
  );
  return data;
};
// Reopen BatchList
const ReopenBatch = async (ReopenData, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "admin/batchList/openbatchId",
    ReopenData,
    config
  );
  //("heloooooooo")

  return data;
};

//  CloseBatchLis
const CloseBatch = async (CloseData, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "admin/batchList/closebatchId",
    CloseData,
    config
  );
  //("colse batch")
  //(data)
  //("heloooooooo")
  return data;
};

// Reopen GroupLis
const ReopenGroup = async (ReopengroupData, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "admin/groupList/opengroupId",
    ReopengroupData,
    config
  );
  return data;
};

//  Close GroupList
const CloseGroup = async (ClosegroupData, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "admin/groupList/closegroupId",
    ClosegroupData,
    config
  );
  return data;
};

//  Create Branch
const CreateBranch = async (CreateBranchdata, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "auth/insertdata",
    CreateBranchdata,
    config
  );
  return data;
};
//  Create Subject
const CreateSubject = async (CreateSubjectdata, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "auth/insertdata",
    CreateSubjectdata,
    config
  );
  return data;
};

//  Create Course
const CreateCourse = async (CreateCoursedata, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "auth/insertdata",
    CreateCoursedata,
    config
  );
  return data;
};

//  Create Purpose
const CreatePurpose = async (CreatePurposedata, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "auth/insertdata",
    CreatePurposedata,
    config
  );
  return data;
};

//  Create Reasons to Block
const CreateReasons = async (CreateReasonsdata, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.post(
    "auth/insertdata",
    CreateReasonsdata,
    config
  );
  return data;
};

//Add Student
const AddStudents = async (studentsAdd, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await fileAxios.post("auth/addStudent", studentsAdd, config);
  //(data)
  return data;
};

// Archive Batch
const ArchiveBatches = async (Batchdata, token) => {
  let config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const { data } = await AxioInstance.get(
    `/archive/getArchivebatches?page=${Batchdata}`,
    config,
    Batchdata
  );
  // //(data)
  return data;
};

const batchService = {
  AllBatches,
  AllGroups,
  CreateBatch,
  CreateGroup,
  Allroles,
  ReopenBatch,
  CloseBatch,
  ReopenGroup,
  CloseGroup,
  Allstudents,
  CreateBranch,
  CreateSubject,
  CreateCourse,
  CreatePurpose,
  UpdateBatch,
  UpdateGroup,
  AddStudents,
  CreateReasons,
  ArchiveBatches,
};

export default batchService;
