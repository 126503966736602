import React from "react";

import Styles from "./_chat.module.css";

const UserNameData = ({ name, online }) => {
  return (
    <main>
      <div>
        {/* <h2 id={online == "online"? Styles.online: Styles.offline}>{name.studentName}</h2> */}
        <h2>
          {online == "online" ? (
            <>
              {" "}
              <p className={Styles.Username}>{name.studentName}</p>
              <p id={online === "online" ? Styles.online : Styles.offline}>
                Online
              </p>{" "}
            </>
          ) : (
            <>
              <p className={Styles.Username}>{name.studentName}</p>
              <p id={online === "online" ? Styles.online : Styles.offline}>
                Offline
              </p>{" "}
            </>
          )}
        </h2>
        <span style={{ float: "right" }}>
          These chats are monitored by admin
        </span>{" "}
      </div>
      {/* <span className={online == "online"? Styles.online: Styles.offline}>
        {online}
      </span> */}
    </main>
  );
};

export default UserNameData;
