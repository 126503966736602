import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { VscOutput } from "react-icons/vsc";
import { FcGraduationCap } from "react-icons/fc";
import { BsChatDotsFill } from "react-icons/bs";
import { AiOutlineQrcode } from "react-icons/ai";
import Styles from "./_user.module.css";
import { SocketContext } from "../../ioContext/socketIo";
import { toast } from "react-toastify";
import { FaBell } from "react-icons/fa";
import QRCode from "qrcode.react";
import AxiosInstance from "../../api/AxiosInstance";

const UserGroupListDetails = ({
  purpose,
  course,
  groupCode,
  notification,
  isOpen,
  index,
  Handlepage,
  currentPage,
}) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const socket = useContext(SocketContext);

  let [valueid, setsValueId] = useState("");
  let [code, setCode] = useState(false);
  let [loading, setLoading] = useState(false);
  let navigate = useNavigate();

  let [permenentBlock, setPermanentBlock] = useState(false);

  useEffect(() => {
    setsValueId("");
    setCode(false);
  }, [currentPage]);
  useEffect(() => {
    socket.on("blocked", data => {
      setPermanentBlock(true);
    });
  }, []);
  if (permenentBlock === true) {
    toast.error("Contact Administrator", { position: "top-right" });
    function greeting() {
      socket.disconnect();
      navigate("/");
    }
    setTimeout(greeting, 3000);
  }

  const handleGenerateQRCode = async () => {
    setLoading(true); // Show loading state
    let payload = { userId: user._id, groupCode: groupCode };
    try {
      const response = await AxiosInstance.post(
        "users/groups/groupCode",
        payload,
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      //(response);
      let data = response.data.groupdata;
      let value = {
        Group_Id: data?._id,
        Group_Name: data?.groupCode,
        User_Id: user?._id,
        User_Name: user?.username,
        Role: user?.role1,
        Branch: "",
        Purpose: "",
      };
      //(value);
      setsValueId(JSON.stringify(value));
      setCode(true);
    } catch (error) {
      let data = error?.response?.data?.message;
      toast.error(data, { position: "top-right" });
    } finally {
    }
  };
  //(valueid);
  let handleDownloadQRCode = () => {
    setCode(false);

    const canvas = document.getElementById(`canvas-${index}`);
    const dataURL = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    // //(dataURL);
    const a = document.createElement("a");
    a.href = dataURL;
    a.download = `${valueid}.png`;
    //( a.download )
    //(valueid)
    document.body.appendChild(a);

    setTimeout(() => {
      a.click();
      document.body.removeChild(a);
    }, 0);
    setsValueId("");
  };

  return (
    <div
      className={Styles.container}
      style={{
        backgroundImage: `url('https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRGzAJznGUJCegtKaMXwIvYMmcuBt14EwrlXXjudhc3DIfPnFFa')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        border: "0.3px ridge #999",
        height: "auto",
        maxHeight: "50%",
      }}
    >
      <div className={Styles.headingcard}>
        <h1
          className={isOpen === false ? `${Styles.ClosedbatchH1}` : ""}
          style={{ width: "100%", textAlign: "center" }}
        >
          {groupCode}
        </h1>

        <h2
          className={
            notification == true
              ? `${Styles.notification}`
              : `${Styles.NoNotification}`
          }
        >
          {notification == true ? <FaBell /> : ""}
        </h2>
      </div>

      <p>
        <span>
          <strong>
            <VscOutput />
          </strong>
          <strong>Purpose </strong>
        </span>
        <span> {purpose}</span>
      </p>
      <p>
        <span>
          <strong>
            <FcGraduationCap />
          </strong>
          <strong>Course </strong>
        </span>
        <span> {course}</span>
      </p>
      <main className={Styles.chatAndQr}>
        {" "}
        <span onClick={Handlepage}>
          <Link to={`${encodeURIComponent(groupCode)}`}>
            <span>
              <BsChatDotsFill />
            </span>
            chat with students
          </Link>
        </span>
        {/* {purpose !== "requirement" && ( */}
        <div>
          <QRCode
            value={valueid}
            // id="qr-gen"
            id={`canvas-${index}`}
            includeMargin={true}
            style={{ display: "none" }}
          />
        </div>
        {/* )} */}
        {code === true ? (
          ""
        ) : (
          <button onClick={handleGenerateQRCode} className={Styles.QRCODE}>
            {" "}
            <AiOutlineQrcode />{" "}
          </button>
        )}
        &nbsp;
        {valueid ? (
          <button
            style={{ border: "0.3px ridge gray", borderRadius: "5px" }}
            onClick={handleDownloadQRCode}
          >
            Download QR Code
          </button>
        ) : (
          ""
        )}
      </main>
    </div>
  );
};

export default UserGroupListDetails;

// export default UserGroupListDetails
