import React, { useEffect, useRef, useState } from "react";
import { ErrorMessage, Field, Formik, FieldArray } from "formik";
import * as yup from "yup";
import { Button, Input } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { register, reset } from "../../redux/auth/AuthSlice";
import Styles from "../auth/_auth.module.css";
import { IoMdAddCircle } from "react-icons/io";
import { AiOutlineClose } from "react-icons/ai";
import AxiosInstance from "../../api/AxiosInstance";
import { TiDelete } from "react-icons/ti";
const AddRolesCreateTrainer = ({ closeModal }) => {
  let dispatch = useDispatch();

  const renders = useRef(0);
  let { isSuccess, isError, message, Success } = useSelector(
    (state) => state.auth
  );

  // //(Success)
  let [username, setUsername] = useState("");
  let [email, setEmail] = useState("");
  let [number, setNumber] = useState("");
  let [password, setPassword] = useState("");
  let [role, setRole] = useState("trainer");
  let [branch, setBranch] = useState("");
  let [branches, setBranches] = useState([]);
  let [subject, setSubject] = useState("");
  let [subjects, setSubjects] = useState("");

  let { user , userAdmin} = useSelector((state) => state.auth);
  useEffect(() => {
    if (isError) {
      toast.error(message?.message, { position: "top-right" });
    }
    if (isSuccess) {
      toast.success("User is added successfully", { position: "top-right" });
      setUsername("");
      setEmail("");
      setNumber("");
      setRole("");
      setBranch("");
      // closeModal()
      // //("registered");
      dispatch(reset());
    }
    dispatch(reset());
  }, [isError, isSuccess]);

  useEffect(() => {
    async function fetchData() {
      // get Subjects
      let Subjectdata = await AxiosInstance.get(`auth/getdata?data=subject`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      });
// //(Subjectdata)
      let Subjectdataaa = Subjectdata?.data[0]?.subject;
    // //(Subjectdataaa)
      // let ddd = Subjectdataaa.map(function (ele) {
      //   return { value: ele, label: ele };
      // });
      setSubjects(Subjectdataaa);

    }
    fetchData();
  }, [userAdmin._id]);
  // //(subjects)

  useEffect(() => {
    async function fetchData() {
      let Branchdata = await AxiosInstance.get(`auth/getdata?data=branch`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      });
      let Branchdataaa = Branchdata?.data[0]?.branch;
      setBranches(Branchdataaa);
    }
    fetchData();
  }, [userAdmin._id]);

  let handelClose = (e) => {
    closeModal();

  };

  const schema = yup.object().shape({
    username: yup.string().required("username is required"),
    email: yup
    .array()
    .of(
      yup.string().email("invalid email format").required("email is required")
    ),
    number: yup
    .array().of(
      yup
        .string()
        .matches(/^[0-9]{10}$/, "Number should contain only digits")
        .required("Number is required")
    ),

    branch: yup.string().required("branch is required"),
  });

  let AddInputEmail = [""];
  let AddNumber = [""];

  return (
    <section id={Styles.authBlock1}>
      <article id={Styles.authartical}>
        <div id={Styles.RegisterBlock1}>
          <div className={Styles.CloseModal1}>
            <button onClick={handelClose}>
              <AiOutlineClose />
            </button>
          </div>
          <h1>Add Trainer</h1>

          <Formik
            initialValues={{
              username: "",
              email: [""],
              email: [""],
              number: [""],
              password: "",
              role: "trainer",
              branch: "",
              subject: "",
            }}
            validationSchema={schema}
            onSubmit={(values) => {
              let payload = { ...values };
// //(payload)
              dispatch(register(payload));
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isValid,
            }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <div className={Styles.pass}>
                    <label htmlFor="number">Username</label>
                    <aside>
                      <Input
                        size="lg"
                        type="text"
                        name="username"
                        placeholder="Enter username"
                        style={{
                          borderColor:
                            errors.username && touched.username
                              ? "red"
                              : "inherit",
                          marginBottom: 5,
                        }}
                        onChange={(val, event) => {
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        value={values.username}
                      />
                    </aside>
                    <p className={Styles.errors}>
                      <ErrorMessage name="username" />
                    </p>
                  </div>

                  <FieldArray name="email">
                    {({ push, remove }) => (
                      <div className={Styles.pass}>
                        <label htmlFor="email">email</label>
                        {values.email.slice(0, 2).map((_, index) => (
                          <>
                            <aside>
                              <Input
                                size="lg"
                                type="text"
                                name={`email.${index}`}
                                placeholder="Enter email"
                                style={{
                                  borderColor:
                                    errors.password && touched.password
                                      ? "red"
                                      : "inherit",
                                  marginBottom: 5,
                                }}
                                onChange={(val, event) => {
                                  // //(val)
                                  handleChange(event);
                                  // updateValFromStore('password', val)
                                }}
                                onBlur={handleBlur}
                                value={values.event}
                              />
                            </aside>
                          </>
                        ))}{" "}
                           <div
                          className={
                            values.email.length === 2
                              ? `${Styles.removeemail}`
                              : `${Styles.addEmail}`
                          }
                        >
                          <span>
                            <IoMdAddCircle
                              onClick={() => push(AddInputEmail)}
                            ></IoMdAddCircle>
                          </span>
                        </div>
                        <div
                          div
                          className={
                            values.email.length === 2
                              ? `${Styles.removeemail1}`
                              : `${Styles.addEmail1}`
                          }
                        >
                          <span>
                            <TiDelete
                              onClick={() => remove(AddInputEmail)}
                            ></TiDelete>
                          </span>
                        </div>
                        <p className={Styles.errors}>
                          <ErrorMessage name="email" />
                        </p>
                      </div>
                    )}
                  </FieldArray>
                  <FieldArray name="number">
                    {({ push, remove }) => (
                      <div className={Styles.pass}>
                        <label htmlFor="number">Number</label>
                        {values.number.slice(0, 2).map((_, index) => (
                          <>
                            <aside>
                              <Input
                                maxLength={10}
                                size="lg"
                                type="text"
                                name={`number.${index}`}
                                placeholder="Number"
                                style={{
                                  borderColor:
                                    errors.number && touched.number
                                      ? "red"
                                      : "inherit",
                                  marginBottom: 5,
                                }}
                                onChange={(val, event) => {
                                  // //(val)
                                  handleChange(event);
                                  // updateValFromStore ('username', val)
                                }}
                                onBlur={handleBlur}
                                value={values.event}
                              />
                            </aside>
                          </>
                        ))}{" "}
                        <div
                          className={
                            values.number.length === 2
                              ? `${Styles.removeemail}`
                              : `${Styles.addEmail}`
                          }
                        >
                          <span>
                            <IoMdAddCircle
                              // onClick={AddInputEmail}
                              onClick={() => push(AddNumber)}
                            ></IoMdAddCircle>
                          </span>
                        </div>
                        <div
                          div
                          className={
                            values.number.length === 2
                              ? `${Styles.removeemail1}`
                              : `${Styles.addEmail1}`
                          }
                        >
                          <span>
                            <TiDelete
                              onClick={() => remove(AddInputEmail)}
                            ></TiDelete>
                          </span>
                        </div>
                        <p className={Styles.errors}>
                          <ErrorMessage name="number" />
                        </p>
                      </div>
                    )}
                  </FieldArray>
                  {/*----------- role -------------- */}

                  <div className={Styles.pass}>
                    <label htmlFor="role">Role</label>

                    <Input type="text" value="trainer" name="role" />
                    <p className={Styles.errors}>
                      <ErrorMessage name="role" />
                    </p>
                  </div>

                  {/*----------- Subject -------------- */}
                  <div className={Styles.pass}>
           
                    <aside>
                      <Field
                        className={Styles.branch}
                        size="lg"
                        as="select"
                        name="subject"
                        id={subject}
                        placeholder="Subject"
                        style={{
                          borderColor:
                            errors.role && touched.role ? "red" : "inherit",
                          marginBottom: 5,
                        }}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        value={values?.subject}
                      >
                        <option value="" disabled="disabled">
                          Subject
                        </option>
                        {subjects ? subjects?.map((ele, ind) => {
                          return <option key={ind}>{ele}</option>;
                        }):""}
                      </Field>
                    </aside>
                    <p className={Styles.errors}>
                      <ErrorMessage name="subject" />
                    </p>
                  </div>

                  {/*--------------- Branch -------------- */}
                  <div className={Styles.pass}>
                    <label htmlFor="branch">Branch</label>
                    <aside>
                      <Field
                        className={Styles.branch}
                        size="lg"
                        as="select"
                        name="branch"
                        id={branch}
                        placeholder="Branch"
                        style={{
                          borderColor:
                            errors.role && touched.role ? "red" : "inherit",
                          marginBottom: 5,
                        }}
                        onChange={(event) => {
                          handleChange(event);
                        }}
                        onBlur={handleBlur}
                        value={values.branch}
                      >
                        <option value="" disabled="disabled">
                          Branch
                        </option>
                        {branches?.map((ele, ind) => {
                          // //(ele);
                          return <option key={ind}>{ele}</option>;
                        })}
                      </Field>
                    </aside>
                    <p className={Styles.errors}>
                      <ErrorMessage name="branch" />
                    </p>
                  </div>
                  <Button type="submit" disabled={!isValid}>
                    Submit
                  </Button>
                </form>
              );
            }}
          </Formik>
        </div>
      </article>
    </section>
  );
};

export default AddRolesCreateTrainer;
