import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { CloseBatch, ReopenBatch } from "../../redux/Batch/BatchSlice";
import { tableCellClasses } from "@mui/material/TableCell";
import { HiChat } from "react-icons/hi";
import styles from "./table.module.css";
import { FaUserEdit } from "react-icons/fa";
import { toast } from "react-toastify";
import AxiosInstance from "../../api/AxiosInstance";
import { Tooltip, Whisper } from "rsuite";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  Button as MuiButton,
  styled,
  Modal,
  Box,
  Pagination,
} from "@mui/material";
import { RotatingLines } from "react-loader-spinner";
import { Fragment } from "react";

const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#D35400",
    fontWeight: "bold",
    fontSize: window.innerWidth > 1600 ? 16 : 14,
    backgroundColor: "#bbbaba",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: window.innerWidth > 1600 ? 14 : 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
}));

const BatchList = () => {
  let dispatch = useDispatch();
  const [page, setPage] = useState(1);
  let [status, setStatus] = useState([]);
  let [msg, setMsg] = useState("");
  let [itmes, setItems] = useState([]);
  const [defaultData, setDefaultData] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 20,
    page: 1,
    load: false,
    length: 1,
  });
  // let [filter, setFilter] = useState([]);

  let [pag, setPag] = useState("");
  let [username, setUsername] = useState("");
  let [userName, setUserName] = useState("");

  let [branchname, setBranchname] = useState("");
  let [branchName, setBranchName] = useState("");

  let [searchTrue, setSearchTrue] = useState(false);
  let [searchTruebranch, setSearchTruebranch] = useState(false);

  let [loading, setLoading] = useState(false);
  let { userAdmin } = useSelector(state => state.auth);

  useEffect(() => {
    async function fetchlist() {
      setLoading(true);
      await AxiosInstance.get(`admin/batchList`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      })
        .then(data => {
          setLoading(false);
          let dataList = data?.data?.result.results;
          let error = data.response?.status;
          setStatus(error);
          setItems(dataList);
          setDefaultData(data.data.result);
          setPagination({
            ...pagination,
            length: data?.data.result.total,
            load: data?.data.result.total > 20 ? false : null,
            page: 1,
            limit: data.data.result.per_page,
          });
          // setFilter(dataList);
        })
        .catch(x => {
          setLoading(null);
          let error = x?.response?.status;
          let message = x?.response?.data?.result?.results;
          setStatus(error);
          setMsg(message);
        });
    }
    fetchlist();
  }, []);

  const handleChangePage = async (_, value) => {
    const BranchName = branchName ? `&branch_batch=${branchName}` : "";
    const UserName = userName ? `&trainer_tracker=${userName}` : "";
    const newPage = value;
    setPagination({ ...pagination, load: true });
    try {
      const { data } = await AxiosInstance.get(
        `admin/batchList?page=${newPage}${BranchName}${UserName}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );
      setItems(data.result.results);
      setPagination({
        ...pagination,
        length: data.result.total,
        page: value,
        load: false,
      });
    } catch (error) {
      toast.error(error?.response?.status || "something went wrong");
      setPagination({ ...pagination, load: false });
    }
  };

  const handleSearchFunction = async () => {
    const BranchName = branchName ? `branch_batch=${branchName}` : "";
    const UserName = userName ? `&trainer_tracker=${userName}` : "";
    setPagination({ ...pagination, load: true });
    try {
      let { data } = await AxiosInstance.get(
        `admin/batchList?${BranchName}${UserName}`,
        {
          headers: {
            Authorization: `Bearer ${userAdmin.TOKEN}`,
          },
        }
      );

      setItems(data.result.results);
      setPagination({
        ...pagination,
        length: data.result.total,
        page: 1,
        load: data.result.total > 20 ? false : null,
      });
    } catch (error) {
      toast.error(error?.response?.status || "something went wrong");
      setPagination({ ...pagination, load: false });
    }
  };

  const handlePageClick = event => {
    setPage(Number(event.selected + 1));
  };

  let HandleClickSearch = () => {
    if (userName.length > 0) {
      setSearchTrue(true);
      setUsername(userName);
    }
  };

  // modal for buttons
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [action, setAction] = useState("");
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedBatch(null);
    setOpen(false);
  };
  // modal dor buttons
  const handleSubmitOpenClose = () => {
    if (action === "open") {
      dispatch(ReopenBatch({ _id: selectedBatch._id }));
      toast.success("Batch Reopened", {
        position: "top-right",
      });
      setTimeout(() => {
        window.location.assign("/admin-dashboard");
      }, 1000);
    } else {
      dispatch(CloseBatch({ _id: selectedBatch._id }));

      toast.success("Batch Closed", {
        position: "top-right",
      });
      setTimeout(() => {
        window.location.assign("/admin-dashboard");
      }, 1000);
    }
  };

  let HandleClearSearch = () => {
    setSearchTrue(false);
    setUserName("");
    setBranchName("");
    setItems(defaultData.results);
    setPagination({
      ...pagination,
      length: defaultData.total,
      load: defaultData.total > 20 ? false : null,
      page: 1,
    });
  };
  let HandleClickbranchSearch = () => {
    setSearchTruebranch(true);
    setBranchname(branchName);
  };
  let HandleClearbranchSearch = () => {
    setSearchTruebranch(false);
    setBranchname("");
    setBranchName("");
  };
  const handleKeyPress = e => {
    if (e.key === "Enter") {
      if (branchName.trim() !== "") {
        HandleClickbranchSearch();
      }
    }
  };
  const handleKeyPressUser = e => {
    if (e.key === "Enter") {
      if (userName.trim() !== "") {
        HandleClickSearch();
      }
    }
  };
  const handleBackspace = event => {
    if (
      (event.key === "Backspace" && branchName.length === 1) ||
      branchName.length === 0
    ) {
      setSearchTruebranch(false);
      setBranchname("");
      setBranchName("");
    }
  };
  const handleBackspaceUser = event => {
    if (
      (event.key === "Backspace" && userName.length === 1) ||
      userName.length === 0
    ) {
      setSearchTrue(false);
      setUsername("");
      setUserName("");
    }
  };

  return (
    <section>
      <Modal open={open} onClose={handleClose}>
        <Box>
          {selectedBatch ? (
            <aside className={styles.logoutAlertBlock}>
              <h4>
                Are you Sure you want to{" "}
                <b
                  style={{
                    color: action !== "open" ? "crimson" : "green",
                    textTransform: "uppercase",
                  }}
                >
                  "{action}"
                </b>{" "}
                <b style={{ color: "#f16f4d" }}>"{selectedBatch.batchCode}"</b>{" "}
                batch?
              </h4>
              <br />
              <div>
                <button type="button" onClick={handleClose}>
                  No
                </button>
                <button type="button" onClick={handleSubmitOpenClose}>
                  Yes
                </button>
              </div>
            </aside>
          ) : (
            "Loading..."
          )}
        </Box>
      </Modal>
      {loading === null ? (
        <h3 style={{ width: "100%", textAlign: "center" }}>
          Something went wrong
        </h3>
      ) : loading ? (
        <div className={styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        <Paper
          elevation={12}
          sx={{
            width: "98%",
            overflow: "hidden",
            margin: "0 auto",
            position: "relative",
            marginTop: "20px",
          }}
          className={styles.studentListTable}
        >
          <Grid
            container
            spacing={1}
            className={styles.filterBlock}
            alignItems="center"
          >
            <Grid item md={6}>
              <h4 style={{ color: "#fff", textAlign: "center" }}>Batch List</h4>
            </Grid>
            <Grid item md={2} position="relative">
              <span className={styles.searchIcon}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  stroke="#F8931F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </span>
              <input
                type="text"
                name="search"
                id="inputName"
                className={styles.studentNameList}
                value={branchName}
                placeholder="Search Branch/BatchCode"
                onChange={e => setBranchName(e.target.value)}
                // onKeyPress={handleKeyPress}
                // onKeyDown={handleBackspace}
                style={{ fontWeight: "bold" }}
              />
            </Grid>

            <Grid item md={2} position="relative">
              <span className={styles.searchIcon}>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 24 24"
                  fill="#fff"
                  stroke="#F8931F"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <circle cx="11" cy="11" r="8"></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                </svg>
              </span>
              <input
                name="searchuser"
                style={{ fontWeight: "bold" }}
                id="inputNameReq"
                value={userName}
                className={styles.studentNameList}
                type="text"
                placeholder="Search Users"
                onChange={e => setUserName(e.target.value)}
                // onKeyPress={handleKeyPressUser}
                // onKeyDown={handleBackspaceUser}
              />
            </Grid>
            <Grid item md={1}>
              {searchTrue === false ? (
                <MuiButton
                  size="small"
                  type="button"
                  color="warning"
                  sx={{
                    background: "#F87000",
                    color: "#111",
                    fontWeight: "bold",
                    "&:hover": {
                      background: "#F87000",
                      color: "#444",
                    },
                  }}
                  variant="contained"
                  onClick={() => {
                    if (branchName === "" && userName === "") {
                      toast.warn(
                        "Please Enter Branch/Batch Code/User Name to search"
                      );
                    } else {
                      setSearchTrue(true);
                      handleSearchFunction();
                    }
                  }}
                >
                  Search
                </MuiButton>
              ) : (
                <MuiButton
                  sx={{ fontWeight: "bold" }}
                  size="small"
                  type="button"
                  color="error"
                  variant="contained"
                  onClick={HandleClearSearch}
                >
                  Reset
                </MuiButton>
              )}
            </Grid>
          </Grid>

          <TableContainer
            component={Paper}
            sx={{ height: "75vh", borderRadius: "6px" }}
          >
            <Table
              stickyHeader
              aria-label="sticky table"
              style={{ borderCollapse: "collapse" }}
            >
              <TableHead>
                <TableRow>
                  <StyledTableCell size="small">Id</StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    BatchCode
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Branch
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Subject
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Course
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Trainer
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    Students
                  </StyledTableCell>
                  <StyledTableCell align="center" size="small">
                    View Chat
                  </StyledTableCell>
                  {userAdmin.role.map(x => x === "corporate") ? (
                    <StyledTableCell align="center" size="small">
                      Update
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                  {userAdmin.role.map(x => x === "corporate") ? (
                    <StyledTableCell align="center" size="small">
                      Open/Close
                    </StyledTableCell>
                  ) : (
                    ""
                  )}
                </TableRow>
              </TableHead>

              <TableBody>
                {pagination.load ? (
                  <StyledTableRow>
                    <TableCell align="center" colSpan={11}>
                      Loading...
                    </TableCell>
                  </StyledTableRow>
                ) : itmes?.length < 1 ? (
                  <StyledTableRow>
                    <TableCell align="center" colSpan={11}>
                      No Data Found
                    </TableCell>
                  </StyledTableRow>
                ) : (
                  <>
                    {itmes?.map((ele, ind) => {
                      return (
                        <Fragment key={ind}>
                          <StyledTableRow>
                            <StyledTableCell align="center">
                              {ele.dbbatchcodeid}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.batchCode}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.branch}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.subject}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.course}
                            </StyledTableCell>

                            {ele?.juniorTrainer?.length > 0 ? (
                              <Whisper
                                followCursor
                                speaker={
                                  <Tooltip>
                                    {ele?.juniorTrainer.map(x => x.username)}
                                  </Tooltip>
                                }
                              >
                                <StyledTableCell className={styles.Tablenumber}>
                                  {ele?.trainer}
                                </StyledTableCell>
                              </Whisper>
                            ) : (
                              <StyledTableCell className={styles.Tablenumber}>
                                {ele?.trainer}
                              </StyledTableCell>
                            )}

                            <StyledTableCell align="center">
                              {" "}
                              {ele.No_ofStudents}
                            </StyledTableCell>
                            <StyledTableCell align="center">
                              {ele.No_ofStudents == 0 ? (
                                <span
                                  title="No students"
                                  className="color_chat"
                                  style={{
                                    fontSize: "x-large",
                                    cursor: "not-allowed",
                                  }}
                                >
                                  <HiChat />
                                </span>
                              ) : (
                                <Link to={`${ele.batchCode}`}>
                                  <span
                                    className="color_chat"
                                    style={{
                                      fontSize: "x-large",
                                    }}
                                  >
                                    <HiChat />
                                  </span>
                                </Link>
                              )}
                            </StyledTableCell>
                            {userAdmin.role.map(x => x === "corporate") ? (
                              <StyledTableCell
                                className={styles.Tablebatches}
                                align="center"
                              >
                                <Link to={`admin/${ele.batchCode}`}>
                                  <span>
                                    <FaUserEdit></FaUserEdit>
                                  </span>
                                </Link>
                              </StyledTableCell>
                            ) : (
                              ""
                            )}
                            {userAdmin.role.map(x => x === "corporate") ? (
                              <StyledTableCell
                                className={styles.Tablebatches}
                                align="center"
                              >
                                {ele.isOpen === true ? (
                                  <button
                                    className={styles.closeborder}
                                    onClick={() => {
                                      setSelectedBatch(ele);
                                      setAction("close");
                                      handleOpen();
                                    }}
                                  >
                                    Close
                                  </button>
                                ) : (
                                  <button
                                    className={styles.reopen}
                                    onClick={() => {
                                      setSelectedBatch(ele);
                                      setAction("open");
                                      handleOpen();
                                    }}
                                  >
                                    Open
                                  </button>
                                )}
                              </StyledTableCell>
                            ) : (
                              ""
                            )}
                          </StyledTableRow>
                        </Fragment>
                      );
                    })}
                  </>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          {pagination.load !== null && (
            <Grid
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "1%",
                marginBottom: "1%",
              }}
            >
              <Pagination
                count={Math.ceil(pagination.length / pagination.limit)}
                color="warning"
                defaultPage={1}
                disabled={pagination.load}
                rowsperpage={pagination.limit}
                siblingCount={1}
                showLastButton={
                  Math.ceil(pagination.length / pagination.limit) > 5
                }
                showFirstButton={
                  Math.ceil(pagination.length / pagination.limit) > 5
                }
                page={pagination.page}
                onChange={handleChangePage}
              />
            </Grid>
          )}
        </Paper>
      )}
    </section>
  );
};

export default BatchList;
