import React from "react";
import Styles from "./_user.module.css";
import StudentMainBar from "./StudentMainBar";


const StudentDashboard = () => {

  return (
    <section id={Styles.adminDashboardBlock}>
      <article>
    
        <aside
          className={Styles.mainadminBlock}
          style={{ background: "#E5E7E9" }}
        >
          <StudentMainBar />
        </aside>
      </article>
    </section>
  );
};

export default StudentDashboard;
