import React, { Fragment, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
import Styles from "./_chat.module.css";
import Conversation from "./Conversation";
import Message from "./Message";
import UserNameData from "./UserNameData";

const AdminUserBroadGroup = () => {
  let { groupCode } = useParams();
  let { userAdmin } = useSelector(state => state?.auth);
  let scrollRef = useRef();

  // slected batch data
  let [selctBatchStud, setSelctBatchStud] = useState([]);
  let [batchStdentData, setBatchStdentData] = useState([]);
  let [particularStudent, setParticularStudent] = useState("");
  let [batchTrainerData, setBatchTrainerData] = useState(null);
  let [allUsers, setAllUsers] = useState([]);

  // messages
  let [messages, setMessages] = useState([]);

  let [batchname, setBatchName] = useState("");

  useEffect(() => {
    async function fetchlist() {
      AxiosInstance.get(`admin/groupList/${encodeURIComponent(groupCode)}`, {
        headers: {
          Authorization: `Bearer ${userAdmin.TOKEN}`,
        },
      }).then(data => {
        //(data)
        let payload = data?.data?.groupData;
        //(data)
        let studentData = data?.data?.studentData;
        let trainer = data?.data?.trainertrackerData;
        //(trainer)
        let trainersData = data?.data?.trainertrackerData?.filter(x => {
          if (x.role === "trainer") {
            return x;
          } else if (x.role === "counselor") {
            return x;
          } else if (x.role === "hr" || x.role == "human resource") {
            return x;
          }
        });
        let trainerId = trainersData[0]?._id;
        let groupname = data?.data?.groupData?.groupCode;
        setBatchTrainerData(trainerId);
        setBatchName(groupname);
        setSelctBatchStud(payload);
        setBatchStdentData(studentData);
        // setBatchTrainerData(trainerId);
        setAllUsers(trainer);
        setBatchTrainerData(trainer);
      });
    }
    fetchlist();
  }, [userAdmin, groupCode]);
  useEffect(() => {
    async function demo() {
      try {
        if (particularStudent !== "") {
          const res = await AxiosInstance.post(
            "/chat/admin/msg/new/allbrcamsg",
            {
              userId: particularStudent,
              receiversId: selctBatchStud?._id,
            },
            {
              headers: {
                Authorization: `Bearer ${userAdmin.TOKEN}`,
              },
            }
          );
          let mess = res?.data?.reverse();
          setMessages(mess);
        }
      } catch (error) {
        console.log(error);
      }
    }
    demo();
  }, [batchStdentData, selctBatchStud?._id, userAdmin]);

  // scroll for msg
  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth", block: "end" });
  }, [messages]);

  return (
    <>
      <section id={Styles.chatBoxSection}>
        <article>
          <aside className={Styles.chatMenu}>
            <Conversation
              batchname={batchname}
              allUsers={allUsers}
              batchStdent={batchStdentData}
              setParticularStudent={setParticularStudent}
              trainername={selctBatchStud}
              setBatchTrainerData={setBatchTrainerData}
            />
          </aside>
          <aside className={Styles.chatBox}>
            <div className={Styles.chatBoxWrapper}>
              {userAdmin._id ? (
                <>
                  <div className={Styles.chatBoxTop}>
                    <UserNameData name={batchStdentData} />
                    <div className={Styles.chatBoxStore}>
                      {messages.length > 0 ? (
                        messages.map((m, ind) => (
                          <Fragment key={ind}>
                            <Message
                              message={m}
                              own={m.sender === batchTrainerData}
                            />
                          </Fragment>
                        ))
                      ) : (
                        <span className={Styles.noConversation}>
                          <p>No Messages</p>
                        </span>
                      )}
                    </div>
                  </div>
                  <div ref={scrollRef} style={{ visibility: "hidden" }}>
                    top
                  </div>
                </>
              ) : (
                <span className={Styles.noConversationText}>
                  <p>Open a conversation to start a chat.</p>
                </span>
              )}
            </div>
          </aside>
        </article>
      </section>
    </>
  );
};

export default AdminUserBroadGroup;
