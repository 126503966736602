import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import authService from "../../services/auth/AuthService";

let user1 = JSON.parse(sessionStorage.getItem("user"));
// let data = Cookies?.get("name", { domain: ".qspiders.com" }); // => 'value'
let data = sessionStorage.getItem("user");

let initialState = {
  user: data ? JSON.parse(data) : null,
  userAdmin: user1 ? user1 : null,
  isError: false,
  isErrorLogout: false,
  isSuccess: false,
  isLogoutSuccess: false,
  isLogoutAdminSuccess: false,
  isSuccessqandq: false,
  isErrorqandq: false,
  Success: false,
  isLoading: false,
  isLoadingLogout: false,
  message: [],
  role: null,
  isLogout: false,
  user1: [],
  deviceId: "",
  status: null,
};
//register
export const register = createAsyncThunk(
  "auth/register",
  async (user, thunkAPI) => {
    let token = thunkAPI.getState().auth.userAdmin.TOKEN;

    try {
      return await authService.register(user, token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Login
export const AdminWithLogin = createAsyncThunk(
  "auth/admin/login",
  async (user, thunkAPI) => {
    //(user, "ttttttttttttttt");
    try {
      return await authService.adminLogin(user);
    } catch (error) {
      const message = error.response;
      // (error.response && error.response.data ) ||
      // error.message ||
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//student login
export const stdlogin = createAsyncThunk(
  "users/student/login",
  async (user, thunkAPI) => {
    try {
      return await authService.stdlogin(user);
    } catch (error) {
    
      const message = error.response;

      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//student login with Q and Q
export const stdloginQandQ = createAsyncThunk(
  "user/student/login",
  async (user, thunkAPI) => {
    try {
      return await authService.stdloginQandQ(user);
    } catch (error) {
     
      const message = error.response;

      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//User login
export const Userlogin = createAsyncThunk(
  "users/student/login",
  async (user, thunkAPI) => {
    try {
      return await authService.Userlogin(user);
    } catch (error) {
      //(error.response)
      const message = error.response;
      // (error.response && error.response.data ) ||
      // error.message ||error.response.status||
      error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);
//Logout
export const logOut = createAsyncThunk(
  "auth/logoutuser",
  async (_, thunkAPI) => {
    try {
      let token = thunkAPI.getState().auth.userAdmin.TOKEN;
      return await authService.logout(token);
    } catch (error) {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Logout
export const StudentlogOut = createAsyncThunk(
  "users/logoutuser",
  async (logoutdata, thunkAPI) => {
    // //(logoutdata)
    try {
      let token = thunkAPI.getState().auth.user.TOKEN;
      let res = await authService.StudentLogout(logoutdata, token);
      //(res)
      return res;
    } catch (error) {
      //(error)
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

//Slice starts here
export let authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: state => {
      state.isLoading = false;
      state.isError = false;
      state.isErrorqandq = false;
      state.isSuccess = false;
      state.isSuccessqandq = false;
      state.message = "";
      state.status = null;
      state.Success = false;
      state.isLogoutSuccess = false;
      state.isLogoutAdminSuccess = false;
    },
  },
  //register
  extraReducers: builder => {
    builder.addCase(register.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(register.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      state.Success = true;
      state.message = payload;
    });
    builder.addCase(register.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      // state.user = null;
    });
    //signin admin in case
    builder.addCase(AdminWithLogin.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(AdminWithLogin.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccess = true;
      // state.user = payload
      state.userAdmin = payload;
      state.role = payload.role;
    });
    builder.addCase(AdminWithLogin.rejected, (state, { payload }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      //(payload)
      // state.user = null;
      state.isLogout = true;
    });
    //Login with Q and Q
    builder.addCase(stdloginQandQ.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(stdloginQandQ.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isSuccessqandq = true;
      if (payload?.role?.includes("student")) {
        let data1 = {
          ...payload,
          role1: "student",
        };
        state.user = data1;
        state.role = payload.role;
      } else if (payload?.role?.includes("trainer")) {
        let data1 = {
          ...payload,
          role1: "trainer",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (
        payload?.role?.includes("hr") ||
        payload.role?.includes("human resource")
      ) {
        let data1 = {
          ...payload,
          role1: "human resource",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("counselor")) {
        let data1 = {
          ...payload,
          role1: "counselor",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("tracker")) {
        let data1 = {
          ...payload,
          role1: "tracker",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("feetracker")) {
        let data1 = {
          ...payload,
          role1: "feetracker",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("branding")) {
        let data1 = {
          ...payload,
          role1: "branding",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("user")) {
        let data1 = {
          ...payload,
          role1: "user",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("qtalkHelp")) {
        let data1 = {
          ...payload,
          role1: "qtalkHelp",
        };

        state.user = data1;

        state.role = payload.role;
      }
    });
    builder.addCase(stdloginQandQ.rejected, (state, { payload, status }) => {
      state.isLoading = false;
      state.isErrorqandq = true;
      state.message = payload;
      state.isLogout = true;
    });
    // Student login
    builder.addCase(stdlogin.pending, state => {
      state.isLoading = true;
    });
    builder.addCase(stdlogin.fulfilled, (state, { payload, status }) => {
      if (payload?.role?.includes("student")) {
        let data1 = {
          ...payload,
          role1: "student",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("trainer")) {
        let data1 = {
          ...payload,
          role1: "trainer",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (
        payload?.role?.includes("hr") ||
        payload.role?.includes("human resource")
      ) {
        let data1 = {
          ...payload,
          role1: "human resource",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("counselor")) {
        let data1 = {
          ...payload,
          role1: "counselor",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("tracker")) {
        let data1 = {
          ...payload,
          role1: "tracker",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("feetracker")) {
        let data1 = {
          ...payload,
          role1: "feetracker",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("branding")) {
        let data1 = {
          ...payload,
          role1: "branding",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("user")) {
        let data1 = {
          ...payload,
          role1: "user",
        };

        state.user = data1;

        state.role = payload.role;
      } else if (payload?.role?.includes("qtalkHelp")) {
        let data1 = {
          ...payload,
          role1: "qtalkHelp",
        };

        state.user = data1;

        state.role = payload.role;
      }
      state.isLoading = false;
      state.isSuccess = true;
    });
    builder.addCase(stdlogin.rejected, (state, { payload, status }) => {
      state.isLoading = false;
      state.isError = true;
      state.message = payload;
      // state.user = null;
      state.isLogout = true;
      //(payload)
    });
    //Admin Logout
    builder.addCase(logOut.pending, state => {
      state.isLoadingLogout = true;
    });
    builder.addCase(logOut.fulfilled, (state, { payload }) => {
      //(payload, "slice");
      state.isLoadingLogout = false;
      state.isLogoutAdminSuccess = true;

      state.userAdmin = null;
      state.message = payload;
    });
    builder.addCase(logOut.rejected, (state, { payload, status }) => {
      // state.isLoadingLogout = false;
      state.isLogoutAdminSuccess = false;
      state.message = payload;
      state.isErrorLogout = true;
    });
    //Logout
    builder.addCase(StudentlogOut.pending, state => {
      state.isLoadingLogout = true;
    });
    builder.addCase(StudentlogOut.fulfilled, (state, { payload }) => {
      state.isLogoutSuccess = true;
      state.isLoadingLogout = false;
      state.deviceId = payload;
      state.message = payload;
      //(state.isLogoutSuccess);

      state.user = null;
    });
    builder.addCase(StudentlogOut.rejected, (state, { payload, status }) => {
      state.isLoadingLogout = false;
      state.message = payload;
      state.isErrorLogout = true;
    });
  },
});

export let { reset } = authSlice.actions;
export default authSlice.reducer;
