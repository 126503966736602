import React, { useEffect, useState } from "react";
import Styles from "./brand.module.css";
import { Link, Outlet, useLocation } from "react-router-dom";

const BrandDashboard = () => {
  const location = useLocation();

  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location]);

  const [selectedMenu, setSelectedMenu] = useState("/spideyhome");
  return (
    <section id={Styles.brandLandingPage}>
      <article className={Styles.sideBarMenus}>
        <ul>
          <li
            className={selectedMenu === "/spideyhome" ? Styles.selectedMenu : ""}
            onClick={() => {
              setSelectedMenu("/spideyhome");
            }}
            title="View Brand Broadcast Chat"
          >
            <Link to="/spideyhome">View Chat</Link>
          </li>
          <li
            className={
              selectedMenu === "/spideyhome/create-post"
                ? Styles.selectedMenu
                : ""
            }
            onClick={() => {
              setSelectedMenu("/spideyhome/create-post");
            }}
            title="Create Brand Broadcast Post"
          >
            <Link to="/spideyhome/create-post">Create Post</Link>
          </li>
        </ul>
      </article>
      <main>
        <Outlet />
      </main>
    </section>
  );
};

export default BrandDashboard;
