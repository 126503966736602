import React, { useContext, useEffect, useState } from "react";
import Styles from "../Users/_Status.module.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {
  IoMdArrowDropleftCircle,
  IoMdArrowDroprightCircle,
} from "react-icons/io";
import AxiosInstance from "../../api/AxiosInstance";
import { SocketContext } from "../../ioContext/socketIo";
import { RotatingLines, ThreeDots } from "react-loader-spinner";
import Linkify from "react-linkify";
import img1 from "../../assets/noQuiz.webp";
import leftArrow from "../../assets/left arrow.webp";
import { IoSend } from "react-icons/io5";
import { toast } from "react-toastify";
import { Box, Divider } from "@mui/material";
import { Modal, Backdrop, Fade } from "@mui/material";
import handleError from "../Users/ErrorHandler";
import { useNavigate } from "react-router-dom";
import fetchImageUrl from "../functions/FetchFileWithToken";

const styleModal2 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxHeight: "90vh",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "2px solid #f57c00",
};

const StudentStatus = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const navigate = useNavigate();

  const userToken = sessionStorage.getItem("token");

  let [userData, setUserData] = useState([]);
  let [userListStatus, setUserListStatus] = useState([]);
  let [statusClick, setStatusClick] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [newMessage, setNewMessage] = useState("");
  let [loadingList, setLoadingList] = useState(false);
  const [nameError, setNameError] = useState("");
  const socket = useContext(SocketContext);
  let [messages, setMessages] = useState({});
  const [inputValues, setInputValues] = useState({});
  const [selectedStatusIndex, setSelectedStatusIndex] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [carouselType, setCarouselType] = useState("");
  const [selectedText, setSelectedText] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);

  // mobile view media query
  const [isBackActive, setIsBackActive] = useState(false);
  const handleBackClick = () => {
    setIsBackActive(true);
  };
  const handleBackClickBack = () => {
    setIsBackActive(false);
  };

  useEffect(() => {
    const handleNotification = data => {
      new Notification(data?.name, {
        body: `${data?.msg?.messages?.text}\n${data?.msg?.messages?.code}`,
      });
    };

    socket.on("notification", handleNotification);

    return () => {
      socket.off("notification", handleNotification);
    };
  }, []);

  const renderCustomPrevArrow = (onClickHandler, hasPrev) => {
    return (
      hasPrev && (
        <button onClick={onClickHandler} className={Styles.customPrevArrow}>
          <IoMdArrowDropleftCircle />
        </button>
      )
    );
  };

  function openInNewTab(url) {
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {url}
      </a>
    );
  }

  const renderCustomNextArrow = (onClickHandler, hasNext) => {
    return (
      hasNext && (
        <button onClick={onClickHandler} className={Styles.customNextArrow}>
          <IoMdArrowDroprightCircle />
        </button>
      )
    );
  };

  useEffect(() => {
    setLoadingList(true);
    let payload = { studentid: user._id };

    async function students() {
      await AxiosInstance.post(`/employee/Stud_statusUsersList`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(x => {
          setLoadingList(false);

          let data = x.data.findStatus;
          setUserData(data);
        })
        .catch(x => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
          setLoadingList(false);
        })
        .finally(() => {
          setLoadingList(false);
        });
    }

    students();
  }, []);

  let handleStatus = e => {
    let payload = { userId: e.userId };

    async function fetchUserStatus() {
      await AxiosInstance.post(`/employee/viewStatus`, payload, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      })
        .then(x => {
          let data = x.data.findStatus;
          setUserListStatus(data);
        })
        .catch(x => {
          if (x?.response?.status === 403) {
            handleError(navigate, socket);
          }
        });
    }

    fetchUserStatus();
  };



  const [imageUrls, setImageUrls] = useState({});

  useEffect(() => {
    const fetchUrls = async () => {
      const newFileUrls = {};

      await Promise.all(
        userListStatus.map(async ele => {
          if (ele?.status?.message?.files) {
            const fileData = await fetchImageUrl(
              ele?.status?.message?.files[0]?.path,
              user?.TOKEN
            );
            if (fileData) {
              newFileUrls[ele?.status?.message?.files[0]?.path] = fileData;
            }
          }
        })
      );

      setImageUrls(prevUrls => ({ ...prevUrls, ...newFileUrls }));
    };

    fetchUrls();
  }, [userListStatus]);

  let handelSubmit = async e => {
    e?.preventDefault();

    if (newMessage.trim() !== "") {
      // setLoading(true);
    } else {
    }

    try {
      setNewMessage("");
    } catch (error) {}
  };

  const handleImageClick = imageUrl => {
    setSelectedImage(imageUrl);
    setOpenModal(true);
  };

  const handleTextClick = (text, color) => {
    setSelectedText({ text, color });
    setOpenModal(true);
  };

  let handelSubmitButton = async statusIndex => {
    if (inputValues[statusIndex]?.trim() !== "") {
      setNameError("");

      const currentUserListStatus = userListStatus[statusIndex]?.status;

      if (currentUserListStatus) {
        socket?.emit(
          "sendMessage",
          {
            senderId: user._id,
            receiverId: currentUserListStatus?.userid,
            text: inputValues[statusIndex],
            role: user?.role1,
            status: currentUserListStatus,
            device: "web",
            code: "",
          },
          function callback(msg) {
            if (msg.read === false) {
              msg.tick = false;
              setMessages({ ...messages, [statusIndex]: msg });
              toast.success("Reply sent successfully!");
              setInputValues({ ...inputValues, [statusIndex]: "" });
            } else {
              setMessages({ ...messages, [statusIndex]: msg });
            }
          }
        );
      } else {
        toast.warning("User status is empty. Cannot send the message.");
      }
    } else {
      setNameError("Type something");
      // Add this line to ensure the warning toast is displayed before the success toast
      await new Promise(resolve => setTimeout(resolve, 0));
      toast.warning("Type something before submitting!");
    }
  };

  const placeholderText = nameError ? nameError : "Reply";

  const handleKeyPress = (e, statusIndex) => {
    setSelectedStatusIndex(statusIndex);
    if (e.key === "Enter") {
      handelSubmitButton(statusIndex);
    }
  };

  return (
    <>
      {loadingList ? (
        <div
          className={Styles.loader}
          style={{
            height: "100vh",
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <RotatingLines strokeColor="#f16f1d" />
        </div>
      ) : (
        <section className={Styles.StatusBlock}>
          <article
            className={`${Styles.parent} ${
              isBackActive ? Styles.backActive : ""
            }`}
            style={{ position: "relative" }}
          >
            {loadingList ? (
              <div className={Styles.loader}>
                <h6>Loading List</h6>
                <ThreeDots color="#00BFFF" height={80} width={80} />
              </div>
            ) : (
              <aside
                className={Styles.UserStatusBlock}
                style={{ width: userData.length === 0 ? "0" : "" }}
              >
                <h3 style={{ color: "#f16f1d", textAlign: "center" }}>
                  Status
                </h3>
                <Divider />
                {userData.length > 0 ? (
                  userData?.map((ele, ind) => {
                    return (
                      <section key={ind}>
                        <div
                          className={Styles.ListUser}
                          onClick={() => {
                            handleStatus(ele);
                            setStatusClick(true);
                            setNameError("");
                            handleBackClick();
                          }}
                          style={{
                            textTransform: "capitalize",
                            backgroundImage: `url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRlXLm169P0IIPVSlSTI2gNkqihXI2jNcHMbfaJ_xlkm6L1leFed3LhgkUYfAw0PCrSDRY&usqp=CAU')`,
                            backgroundSize: "cover",
                            backgroundRepeat: "no-repeat",
                            border: "0.3px ridge #999",
                            marginBottom: "4px",
                            borderRadius: "6px",
                          }}
                        >
                          {ele?.username}
                        </div>
                      </section>
                    );
                  })
                ) : (
                  <div className={Styles.NoStatus}>
                    <h6>No status updated</h6>
                  </div>
                )}
              </aside>
            )}

            {isBackActive && (
              <div className={Styles.BackBtn} onClick={handleBackClickBack}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="30"
                  viewBox="0 0 24 24"
                >
                  <g fill="none">
                    <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035q-.016-.005-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427q-.004-.016-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093q.019.005.029-.008l.004-.014l-.034-.614q-.005-.019-.02-.022m-.715.002a.02.02 0 0 0-.027.006l-.006.014l-.034.614q.001.018.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                    <path
                      fill="#555"
                      d="M3.283 10.94a1.5 1.5 0 0 0 0 2.12l5.656 5.658a1.5 1.5 0 1 0 2.122-2.122L7.965 13.5H19.5a1.5 1.5 0 0 0 0-3H7.965l3.096-3.096a1.5 1.5 0 1 0-2.122-2.121z"
                    />
                  </g>
                </svg>
              </div>
            )}
            {!loadingList && (
              <aside
                className={Styles.MyStatusBlock}
                style={{ width: userData.length === 0 ? "100%" : "" }}
              >
                {userData.length === 0 ? (
                  <div className={Styles.noStatusView}>
                    <img src={img1} alt="" />
                    <h3>No status updated</h3>
                  </div>
                ) : statusClick === true ? (
                  <div className={Styles.MyStatus}>
                    <Carousel
                      showArrows={true}
                      showThumbs={false}
                      showStatus={false}
                      infiniteLoop={true}
                      selectedItem={currentSlide}
                      renderArrowPrev={renderCustomPrevArrow}
                      renderArrowNext={renderCustomNextArrow}
                      autoPlay
                      interval={30000}
                      onClickItem={index => setCurrentSlide(index)}
                    >
                      {userListStatus.length > 0 &&
                        userListStatus.map((ele, ind) => {
                          const colorObj =
                            ele?.status?.colour !== null
                              ? JSON.parse(ele?.status?.colour)
                              : "";
                          const colorString = `rgba(${Math.min(
                            colorObj.Red + 100,
                            255
                          )}, ${Math.min(
                            colorObj.Green + 100,
                            255
                          )}, ${Math.min(colorObj.Blue + 100, 255)}, ${
                            colorObj.Alpha / 255
                          })`;

                          return (
                            <div className={Styles.content} key={ind}>
                              {ele?.status?.message?.text ? (
                                <>
                                  <div
                                    className={Styles.heading}
                                    style={{ backgroundColor: colorString }}
                                    onClick={() => {
                                      setCarouselType("text");
                                      handleTextClick(
                                        ele?.status?.message?.text,
                                        colorString
                                      );
                                    }}
                                  >
                                    <Linkify componentDecorator={openInNewTab}>
                                      <div
                                        className={Styles.TextBox1}
                                        disabled
                                        style={{
                                          // wordBreak: "break-all",
                                          whiteSpace: "pre-wrap",
                                          padding: "0.5%",
                                        }}
                                      >
                                        {/* {//(ele?.status?.message?.text)} */}
                                        {ele?.status?.message?.text}
                                      </div>
                                    </Linkify>
                                  </div>
                                  <span className={Styles.SendBlock}>
                                    {inputValues[ind] && (
                                      <button
                                        type="button"
                                        className={Styles.chatSubmitButton}
                                        onClick={() => handelSubmitButton(ind)}
                                      >
                                        <IoSend />
                                      </button>
                                    )}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <div
                                    className={Styles.heading}
                                    onClick={() => {
                                      setCarouselType("image");
                                      handleImageClick(
                                        imageUrls[
                                          ele?.status?.message?.files[0]?.path
                                        ]?.url
                                      );
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <img
                                      style={{
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "80%",
                                        cursor: "pointer",
                                      }}
                                      src={
                                        imageUrls[
                                          ele?.status?.message?.files[0]?.path
                                        ]?.url
                                      }
                                      alt=""
                                    />
                                    <div
                                      className={Styles.StatusMsg}
                                      style={{
                                        wordBreak: "break-all",
                                        fontSize: "medium",
                                      }}
                                    >
                                      {ele?.status?.message?.files_text}
                                    </div>
                                  </div>
                                  <span className={Styles.SendBlock}>
                                    {inputValues[ind] && (
                                      <button
                                        type="button"
                                        className={Styles.chatSubmitButton}
                                        onClick={() => handelSubmitButton(ind)}
                                      >
                                        <IoSend />
                                      </button>
                                    )}
                                  </span>
                                </>
                              )}
                              <div className={Styles.MessageBlock}>
                                <input
                                  id={`inputField-${ind}`}
                                  style={{
                                    border: "1px solid #f16f1d",
                                    outline: "none",
                                    fontWeight: "bold",
                                  }}
                                  required
                                  autoFocus={selectedStatusIndex === ind}
                                  placeholder={placeholderText}
                                  className={`${Styles.chatMessageInput} ${Styles.chatMessageInput}`}
                                  onChange={e => {
                                    setInputValues({
                                      ...inputValues,
                                      [ind]: e.target.value,
                                    });
                                  }}
                                  value={inputValues[ind] || ""}
                                  onKeyPress={e => handleKeyPress(e, ind)}
                                />
                              </div>
                            </div>
                          );
                        })}
                    </Carousel>
                  </div>
                ) : (
                  <div className={Styles.StatusViewClick}>
                    <img src={leftArrow} alt="" />
                    <h3>Select User to view status</h3>
                  </div>
                )}
              </aside>
            )}

            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              closeAfterTransition
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openModal}>
                <Box sx={styleModal2}>
                  <div style={{ textAlign: "center", position: "relative" }}>
                    <button
                      style={{
                        position: "absolute",
                        right: "-10px",
                        background: "transparent",
                        top: "-10px",
                      }}
                      onClick={() => setOpenModal(false)}
                    >
                      <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                        <path
                          fill="crimson"
                          d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                        ></path>
                      </svg>
                    </button>{" "}
                  </div>
                  {carouselType === "text" ? (
                    <div
                      style={{
                        width: "100%",
                        // minWidth: "600px",
                        // maxWidth: "700px",
                        background: selectedText?.color,
                        minHeight: "80vh",
                        maxHeight: "85vh",
                        borderRadius: "6px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        color: "black",
                        overflow: "auto",
                        wordBreak: "break-all",
                        padding: "0.5%",
                      }}
                    >
                      {selectedText?.text}
                    </div>
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={selectedImage}
                        alt="Full Size"
                        style={{
                          minHeight: "60vh",
                          maxWidth: "100%",
                          maxHeight: "80vh",
                        }}
                      />
                    </div>
                  )}
                </Box>
              </Fade>
            </Modal>
          </article>
        </section>
      )}
    </>
  );
};

export default StudentStatus;
