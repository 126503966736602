import React from "react";
import { useEffect } from "react";
import { BddtAxios } from "../../api/AxiosInstance";
import jwt_decode from "jwt-decode";
import Styles from "./_user.module.css";
import { RotatingLines } from "react-loader-spinner";
import { Fragment } from "react";
import Cookies from "js-cookie";
import { useState } from "react";
import Moment from "react-moment";
import groom from "../../assets/groom.webp";
import { useNavigate } from "react-router-dom";
import handleError from "../Users/ErrorHandler";

const Grooming = () => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const userToken = sessionStorage.getItem("token");
  const navigate = useNavigate();

  let [loading, setLoading] = useState(false);
  let [requirement, setRequirement] = useState([]);
  let [req, setReq] = useState([]);

  // fetching direct grooming
  useEffect(() => {
    async function fetchlist() {
      setLoading(true);
      BddtAxios.get(`/hr/directstureqlist/?token=${userToken}`)
        .then(x => {
          setLoading(false);
          let data = x?.data?.results[0].grooming_req;
          setReq(data);
        })
        .catch(y => {
          if (y?.response?.status === 403) {
            handleError(navigate);
          }
          setLoading(false);
        });
    }
    fetchlist();
  }, []);

  // fetching main requirement for flow groming
  useEffect(() => {
    async function fetchlist() {
      setLoading(true);
      var decoded = jwt_decode(userToken);
      if (decoded.aid != null) {
        BddtAxios.get(`/hr/student-main-reqdetails/?token=${userToken}`)
          .then(x => {
            setLoading(false);

            let data = x?.data?.results;
            let data1 = data.filter((ele, ind) => {
              return ele.grooming.grooming_req.length > 0;
            });
            //(data);
            setRequirement(data1);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate);
            }
            setLoading(false);
          });
      } else if (user?.number) {
        BddtAxios.get(`/hr/student-main-reqdetails/?token=${userToken}`, {})
          .then(x => {
            setLoading(false);

            let data = x?.data?.results;
            let data1 = data.filter((ele, ind) => {
              return ele.grooming.grooming_req.length > 0;
            });
            setRequirement(data1);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate);
            }
            setLoading(false);
          });
      } else if (user?.email) {
        BddtAxios.get(`/hr/student-main-reqdetails/?token=${userToken}`, {})
          .then(x => {
            setLoading(false);

            let data = x?.data?.results;
            let data1 = data.filter((ele, ind) => {
              return ele?.grooming?.grooming_req?.length > 0;
            });
            setRequirement(data1);
          })
          .catch(y => {
            if (y?.response?.status === 403) {
              handleError(navigate);
            }
            setLoading(false);
          });
      }
    }
    fetchlist();
  }, []);
  const redirectToAnotherDomain = e => {
    const param1 = e?.split?.id;

    Cookies.set("hrst", userToken, { domain: ".qspiders.com" });

    const url = `https://studentcareer.qspiders.com?splitid=${encodeURIComponent(
      param1
    )}`;
    window.open(url, "_blank");
  };
  useEffect(() => {
    //("hgiii");
    if (requirement?.length > 0) {
      const hobbiesArray = [];
      for (let i = 0; i < requirement.length; i++) {
        const person = requirement[i];
        const hobby = person.grooming;
        const activities = hobby?.grooming_req;
        if (activities) {
          activities.forEach(activity => {
            activity.id = hobby.id;
          });
          hobbiesArray.push(...activities);
        }
      }

      setReq(hobbiesArray);
    }
  }, [requirement]);

  return (
    <section id={Styles.RequirementBlock}>
      {loading === true ? (
        <div className={Styles.loader}>
          <RotatingLines
            strokeColor="#f16f1d"
            strokeWidth="5"
            animationDuration="0.75"
            width="96"
            visible={true}
          />
        </div>
      ) : (
        ""
      )}
      {req?.length <= 0 ? (
        <div className={Styles.MessageError}>
          <img src={groom} alt="" />
          <h3> You are not assigned to any Grooming yet</h3>
        </div>
      ) : (
        <Fragment>
          {req?.map((ele, ind) => {
            return (
              <section key={ind}>
                <div
                  className={Styles.cardgroom}
                  key={ind}
                  style={{
                    border: "0.3px ridge #1A5276",
                    height: "auto",
                  }}
                >
                  <div className={Styles.CardHeading}>
                    {" "}
                    <h4 className={Styles.cardTitle}>Grooming Details</h4>{" "}
                  </div>
                  <div className={Styles.cardContent1}>
                    <h4 className={Styles.cardTitle}>Requirement Name</h4>{" "}
                    <span className={Styles.Colan}>:</span>
                    <p className={Styles.cardDescription}>
                      {ele?.split?.req_code}
                    </p>
                  </div>
                  <div className={Styles.cardContent1}>
                    <h4 className={Styles.cardTitle}>Subject</h4>{" "}
                    <span className={Styles.Colan}>:</span>
                    <p className={Styles.cardDescription}>
                      {ele?.subject?.map((x, ind) => x.name).join(", ")}
                    </p>
                  </div>
                  <div className={Styles.cardContent1}>
                    <h4 className={Styles.cardTitle}>Grooming date</h4>{" "}
                    <span className={Styles.Colan}>:</span>
                    <p className={Styles.cardDescription}>
                      <Moment format="YYYY-MM-DD">{ele?.grooming_date}</Moment>
                    </p>
                  </div>

                  <div className={Styles.cardContent1}>
                    <h4 className={Styles.cardTitle}>Grooming Time</h4>{" "}
                    <span className={Styles.Colan}>:</span>
                    <p className={Styles.cardDescription}>
                      <Moment format="HH:mm">{ele?.grooming_date}</Moment>
                    </p>
                  </div>
                  <div className={Styles.cardContent1}>
                    <h4 className={Styles.cardTitle}>Mode of class</h4>{" "}
                    <span className={Styles.Colan}>:</span>
                    <p className={Styles.cardDescription}>
                      {ele?.mode_of_class?.name}
                    </p>
                  </div>
                  <div className={Styles.buttonContainer}>
                    {/* {ele?.grooming?.grooming_req?.length > 0 ? ( */}
                    <button
                      className={Styles.button1}
                      onClick={e => {
                        redirectToAnotherDomain(ele);
                        // setopenCourse(true);
                      }}
                    >
                      Grooming List
                    </button>
                    {/* ) : (
                      ""
                    )}{" "} */}
                  </div>
                </div>
              </section>
            );
          })}

          {/* <div id={Styles.reactpaginate}>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          pageRangeDisplayed={3}
          previousLabel="< previous"
          pageCount={pag?.pages}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          subContainerClassName={"pages pagination"}
          activeClassName={"active"}
        />
      </div> */}
        </Fragment>
      )}
    </section>
  );
};

export default Grooming;
