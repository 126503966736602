import React, { useContext, useEffect, useState } from "react";
import AxiosInstance from "../../../api/AxiosInstance";
import { SocketContext } from "../../../ioContext/socketIo";
import styles from "../../Student/Chat/Modal.module.css";
import { FiSend } from "react-icons/fi";
import { RotatingLines } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import handleError from "../ErrorHandler";
import { toast } from "react-toastify";
import { Button } from "@mui/material";

const FileUpload = ({
  PStudent,
  setOpen,
  selectedUser,
  setArrivalMessageFile,
  reply,
  selectedMessages,
  setReply,
  setSelectedMessages,
}) => {
  //(PStudent);
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const socket = useContext(SocketContext);

  let navigate = useNavigate();

  let [files, setNewFiles] = useState([]);
  let [loading, setLoading] = useState(false);

  const previewFile = e => {
    const selectedFiles = e.target.files;

    if (selectedFiles.length > 5) {
      toast.error("Please select up to 5 files.");
      return;
    }

    const newFiles = Array.from(selectedFiles);
    setNewFiles(prevFiles => [...prevFiles, ...newFiles]);
  };

  const handleDelete = index => {
    setNewFiles(prevFiles => prevFiles.filter((file, i) => i !== index));
  };

  let handleSubmit = async e => {
    e.preventDefault();
    if (files !== "") {
      setLoading(true);

      let abc = files?.map(x => x);

      try {
        let payload = {
          files: abc,
          sender: user._id,
        };
        //(payload);
        const options = {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
            "Content-Type": "multipart/form-data",
          },
        };
        const res = await AxiosInstance.post(
          "/chat/newmsgfiles",
          payload,
          options
        );

        let filesData = res.data.files;
        //(filesData, "file");
        let aaa = filesData.map(x => {
          //(x);
          if (selectedUser.group === false) {
            return reply
              ? new Promise((resolve, reject) => {
                  socket.emit(
                    "users_user_chat",
                    {
                      files: [x],
                      senderId: user._id,
                      receiverId: PStudent?.studentid,
                      role: user?.role1,
                      device: "web",
                      replayback: {
                        message: selectedMessages[0]?.messages?.text
                          ? selectedMessages[0]?.messages?.text
                          : "",
                        file: selectedMessages[0]?.messages?.files
                          ? selectedMessages[0]?.messages?.files
                          : "",
                      },
                    },
                    function callback(msg) {
                      // Do any processing you need with msg here
                      resolve(msg);
                      setReply(false);
                      setSelectedMessages([]);
                    }
                  );
                })
              : new Promise((resolve, reject) => {
                  socket.emit(
                    "users_user_chat",
                    {
                      files: [x],
                      senderId: user._id,
                      receiverId: PStudent?.studentid,
                      role: user?.role1,
                      device: "web",
                    },
                    function callback(msg) {
                      // Do any processing you need with msg here
                      resolve(msg);
                    }
                  );
                });
          } else if (selectedUser.group === true) {
            return reply
              ? new Promise((resolve, reject) => {
                  socket.emit(
                    "users_group",
                    {
                      files: [x],
                      senderId: user._id,
                      sendername: user.username,
                      groupId: selectedUser.id,
                      groupname: selectedUser.username,
                      role: user?.role1,
                      index: 0,
                      device: "web",
                      status: null,
                      replayback: {
                        message: selectedMessages[0]?.messages?.text
                          ? selectedMessages[0]?.messages?.text
                          : "",
                        file: selectedMessages[0]?.messages?.files
                          ? selectedMessages[0]?.messages?.files
                          : "",
                      },
                    },
                    function callback(msg) {
                      // Do any processing you need with msg here
                      resolve(msg);
                      setReply(false);
                      setSelectedMessages([]);
                    }
                  );
                })
              : new Promise((resolve, reject) => {
                  socket.emit(
                    "users_group",
                    {
                      files: [x],
                      senderId: user._id,
                      sendername: user.username,
                      groupId: selectedUser.id,
                      groupname: selectedUser.username,
                      role: user?.role1,
                      index: 0,
                      device: "web",
                      status: null,
                    },
                    function callback(msg) {
                      // Do any processing you need with msg here
                      resolve(msg);
                    }
                  );
                });
          }
        });
        Promise.all(aaa)
          .then(messages => {
            // Update the state with the received messages
            messages.forEach(msg => {
              if (selectedUser.group === false) {
                setArrivalMessageFile(prevMessages => [...prevMessages, msg]);
                setOpen(false);
              } else if (selectedUser.group === true) {
                setArrivalMessageFile(prevMessages => [...prevMessages, msg]);
                setOpen(false);
              }
            });

            // Set loading to false when all messages are received
            setLoading(false);
          })
          .catch(error => {
            console.error("Error:", error);
            // Handle any errors that may occur during the socket.emit calls
          });
      } catch (error) {
        if (error?.response?.status === 403) {
          handleError(navigate, socket);
        }
      }
    } else {
    }
  };

  //(loading)
  return (
    <div>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className={styles.fileupload}>
          <Button
            size="small"
            variant="contained"
            component="label"
            className={styles.fileUpload}
            title="Add File"
            color="success"
          >
            <input
              type="file"
              name="image"
              onChange={e => previewFile(e)}
              hidden
              multiple
              style={{ display: "none" }}
            />
            Choose File
          </Button>
          <span style={{ fontWeight: "bold" }}>{files[0]?.name}</span>
        </div>
        <div className={styles.previewContainer}>
          {files.length > 0 &&
            files.map((file, index) => (
              <div className={styles.imagePreviewContainer} key={index}>
                {file.type && file.type.includes("image") ? (
                  <React.Fragment>
                    <img
                      src={URL.createObjectURL(file)}
                      alt=""
                      width={100}
                      height={100}
                      className={styles.imagePreview}
                    />
                    <span
                      className={styles.deleteButton}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <svg width={26} height={26} viewBox="0 0 12 12">
                        <path
                          fill="crimson"
                          d="M5 3h2a1 1 0 0 0-2 0M4 3a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 7.623 11H4.377a2 2 0 0 1-1.993-1.83L1.941 4H1.5a.5.5 0 0 1 0-1zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0zM5 5.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M3.38 9.085a1 1 0 0 0 .997.915h3.246a1 1 0 0 0 .996-.915L9.055 4h-6.11z"
                        ></path>
                      </svg>
                    </span>
                  </React.Fragment>
                ) : (
                  <div className={styles.nonImagePreview}>
                    <div className={styles.fileIcon}>
                      <span role="img" aria-label="File Icon">
                        📄
                      </span>
                    </div>
                    <div className={styles.fileName}>{file.name}</div>
                    <span
                      className={styles.deleteButton}
                      onClick={() => {
                        handleDelete(index);
                      }}
                    >
                      <svg width={26} height={26} viewBox="0 0 12 12">
                        <path
                          fill="crimson"
                          d="M5 3h2a1 1 0 0 0-2 0M4 3a2 2 0 1 1 4 0h2.5a.5.5 0 0 1 0 1h-.441l-.443 5.17A2 2 0 0 1 7.623 11H4.377a2 2 0 0 1-1.993-1.83L1.941 4H1.5a.5.5 0 0 1 0-1zm3.5 3a.5.5 0 0 0-1 0v2a.5.5 0 0 0 1 0zM5 5.5a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5M3.38 9.085a1 1 0 0 0 .997.915h3.246a1 1 0 0 0 .996-.915L9.055 4h-6.11z"
                        ></path>
                      </svg>
                    </span>
                  </div>
                )}
              </div>
            ))}
        </div>
        <div className={styles.sendbtnfile}>
          {loading ? (
            <button disabled id={styles.filesubmit}>
              <div className={styles.loader}>
                <RotatingLines
                  strokeColor="#f16f1d"
                  strokeWidth="5"
                  animationDuration="0.75"
                  width="20"
                  visible={true}
                />
              </div>
            </button>
          ) : (
            <button
              id={styles.filesubmit}
              disabled={files.length === 0}
              title={files.length === 0 ? "Select file" : "Send"}
            >
              <FiSend color="#fff" />
            </button>
          )}
        </div>
      </form>
    </div>
  );
};

export default FileUpload;
