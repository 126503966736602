import React, { useEffect, useState } from "react";
import { logOut } from "../../redux/auth/AuthSlice";
import { Modal,  Whisper, Tooltip } from "rsuite";
import { FaHandshake } from "react-icons/fa";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button as MUIButton } from "@mui/material";
import Styles from "./_navbar.module.css";

const menuStyle = {
  borderBottom: "2px solid #f16f1d",
  color: "#f16f1d",
  fontWeight: "bold",
};

const AdminMenu = () => {
  const location = useLocation();
  let dispatch = useDispatch();
  let navigate = useNavigate();

  let { userAdmin, isLogoutAdminSuccess } = useSelector(state => state.auth);
  useEffect(() => {
    setSelectedMenu(location.pathname);
  }, [location]);

  const [selectedMenu, setSelectedMenu] = useState("/student-dashboard");

  //logout
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const IsLogout = async () => {
    dispatch(logOut());
    navigate("auth/admin/login");
    sessionStorage.removeItem("user");
    sessionStorage.removeItem("token");
  };
  let handleLogout = () => {
    if (userAdmin?.role[0] === "admin" || userAdmin?.role[0] === "corporate") {
      IsLogout();
    }
  };
  useEffect(() => {
    if (isLogoutAdminSuccess) {
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("device");
    }
  }, [isLogoutAdminSuccess]);
  return (
    <section>
      {/* Logout modal */}
      <Modal
        backdrop="static"
        role="alertdialog"
        open={open}
        onClose={handleClose}
        size="xs"
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Modal.Body style={{ fontSize: "16px" }}>
          Are You Sure You Want To Logout?
        </Modal.Body>
        <Modal.Footer>
          <MUIButton
            size="small"
            color="warning"
            variant="contained"
            onClick={handleLogout}
          >
            OK
          </MUIButton>{" "}
          &nbsp;
          <MUIButton size="small" variant="outlined" onClick={handleClose}>
            Cancel
          </MUIButton>
        </Modal.Footer>
      </Modal>
      <aside className={Styles.StudentNavBlock}>
        <div className={Styles.HomeBlock}>
          {" "}
          <Link
            to={"/admin-dashboard"}
            className={Styles.StudHome}
            style={selectedMenu === "/admin-dashboard" ? menuStyle : {}}
            state={{ model: true, chat: false }}
          >
            <Whisper
              followCursor
              placement="bottom"
              speaker={<Tooltip>Go to home</Tooltip>}
            >
              <div style={{ fontSize: "medium" }} className={Styles.Help}>
                Home
              </div>
            </Whisper>
          </Link>
        </div>

        <div className={Styles.HomeBlock}>
          <Link
            className={Styles.HelpIcon}
            to="/admin-dashboard/admin/chatuser"
            state={{ model: true, chat: false }}
          >
            <Whisper
              followCursor
              placement="bottom"
              speaker={<Tooltip>User broadcast</Tooltip>}
            >
              <div className={Styles.Help}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  viewBox="0 0 24 24"
                >
                  <path
                    fill="#f16f1d"
                    d="M10.997 17.499c0-1.29.375-2.49 1.022-3.501H6.25A2.25 2.25 0 0 0 4 16.248v.92c0 .572.178 1.13.51 1.596c1.403 1.968 3.617 3.034 6.58 3.21l.522-1.713a6.5 6.5 0 0 1-.615-2.762M11.998 2a5.001 5.001 0 1 1 0 10.002a5.001 5.001 0 0 1 0-10.002M23 17.499a5.5 5.5 0 0 1-8.168 4.813l-2.187.665a.5.5 0 0 1-.624-.624l.666-2.187A5.501 5.501 0 1 1 23 17.498m-7.501-1.5a.5.5 0 1 0 0 1h4a.5.5 0 1 0 0-1zm-.5 2.5a.5.5 0 0 0 .5.5h2a.5.5 0 1 0 0-1h-2a.5.5 0 0 0-.5.5"
                  />
                </svg>
              </div>
            </Whisper>
          </Link>
        </div>

        <div className={Styles.HomeBlock}>
          <Link
            to={"admin/helpchat"}
            className={Styles.HelpIcon}
            state={{ modal: true, chat: false }}
          >
            <Whisper
              followCursor
              placement="bottom"
              speaker={<Tooltip>View Help Chats</Tooltip>}
            >
              <div className={Styles.Help}>
                <FaHandshake />
              </div>
            </Whisper>
          </Link>
        </div>
        <div className={Styles.HomeBlock}>
          <Link
            to={"admin/branding"}
            className={Styles.HelpIcon}
            state={{ modal: true, chat: false }}
          >
            <Whisper
              followCursor
              placement="bottom"
              speaker={<Tooltip>View Spidey Aura Messages</Tooltip>}
            >
              <div className={Styles.Help}>
                <svg width={20} height={20} viewBox="0 0 48 48">
                  <circle
                    cx={24}
                    cy={24}
                    r={21.5}
                    fill="none"
                    strokeWidth={3}
                    stroke="#f16f1d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  ></circle>
                  <path
                    strokeWidth={3}
                    fill="none"
                    stroke="#f16f1d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.41 27.5C10.99 34.09 16.93 39 24 39s13.01-4.91 14.59-11.5m-29.18-7C10.99 13.91 16.93 9 24 9s13.01 4.91 14.59 11.5"
                  ></path>
                  <path
                    strokeWidth={3}
                    fill="none"
                    stroke="#f16f1d"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M22 32.26c-3.73-.9-6.5-4.26-6.5-8.26s2.77-7.36 6.5-8.26m4 0c3.73.9 6.5 4.26 6.5 8.26s-2.77 7.36-6.5 8.26"
                  ></path>
                  <circle cx={24} cy={24} r={0.75} fill="#f16f1d"></circle>
                </svg>
              </div>
            </Whisper>
          </Link>
        </div>
        <div className={Styles.HomeBlock}></div>
        <div
          className={Styles.HomeBlock}

          // title="Add tnterview question"
        >
          {" "}
          <button
            onClick={e => {
              setOpen(true);
            }}
            className={Styles.Logoutbtn}
          >
            Logout
          </button>
        </div>
      </aside>
    </section>
  );
};

export default AdminMenu;
