import React from "react";
import Styles from "./_UserToUser.module.css";
const AllStudentMsg = () => {
  return (
    <section>
      <div className={Styles.chatarea}>
        <div className={Styles.NameBlock}>
          <aside className={Styles.HeaderHelp}>
            <div className={Styles.HEadBlockName}>
              <span className={Styles.HeadName}>Help</span>
              <span className={Styles.HeadDeatils}>
                {" "}
                <h6> Chats Are Monitored By Admin</h6>
              </span>
            </div>
          </aside>
        </div>
      </div>
    </section>
  );
};

export default AllStudentMsg;
