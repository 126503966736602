import React from "react";

import { useSelector } from "react-redux";

import { useLocation } from "react-router-dom";



import StudentMenu from "./StudentMenu";
import UserMenu from "./UserMenu";
import AdminMenu from "./AdminMenu";

const MenuBar = () => {
  let {userAdmin } = useSelector(
    state => state.auth
  );
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const location = useLocation();
  const isHomePage =
    location.pathname === "/" || location.pathname === "/auth/login";


  const isHomePageAdmin = location.pathname === "/auth/admin/login";

  return (
    <div>
      {user && user?.role1 === "student" && !isHomePage ? (
        <StudentMenu />
      ) : (userAdmin && userAdmin?.role[0] === "admin") ||
        (userAdmin?.role[0] === "corporate" && !isHomePage) ? (
        <AdminMenu />
      ) : !isHomePage && !isHomePageAdmin ? (
        <UserMenu />
      ) : (
        ""
      )}
    </div>
  );
};

export default MenuBar;
