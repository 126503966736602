import { useContext } from "react";
import { useSelector } from "react-redux";
import { globalContext } from "../Users/UsersContext";
import AdminUserBroadcastComp from "./broadcast/AdminUserBroadcastComp";
import AdminUserChatComp from "./Chat/AdminUserChatComp";

const AdminSelectedBatchList = () => {
  let {switchComp}= useContext(globalContext)
  let { user , userAdmin } = useSelector(state => state?.auth);
  return(
    <div>
    {userAdmin?.TOKEN ? (
     switchComp? (
        <AdminUserChatComp  />
      ) : (
        <AdminUserBroadcastComp  />
      )
    ) : (
      "loading111..."
    )}
 </div>
  )
};

export default AdminSelectedBatchList;
