import React from "react";
import Styles from "./_navbar.module.css";
const Logo = () => {
  return (
    <div className={Styles.logoBlock}>
      <h1 style={{ fontWeight: "bolder" }}>
        Q<span>Talk</span>
      </h1>
      {/* <button>Login</button> */}
    </div>
  );
};

export default Logo;
