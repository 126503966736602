import React, { createContext, useState } from 'react'

export let globalContext=createContext()

const UsersContext = ({children}) => {



  
    let [switchComp, setSwitchComp]= useState(true)
    // //(switchComp)
  return (
    <globalContext.Provider value={{switchComp,setSwitchComp}}>{children}</globalContext.Provider>
  )
}

export default UsersContext