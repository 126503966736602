import { Badge, Box, Divider, Modal } from "@mui/material";
import React, { Suspense, lazy, useEffect } from "react";
import { useState } from "react";
import Styles from "./help.module.css";
import styles from "../../Student/_UserToUser.module.css";
import { useLocation, useNavigate } from "react-router-dom";
import AxiosInstance from "../../../api/AxiosInstance";
// import ChatComp from "./ChatComp";
const ChatComp = lazy(() => import("./ChatComp"));

const styleModal1 = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  height: "auto",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 2,
  borderRadius: "10px",
  outline: "1px solid #f57c00",
  outlineOffset: "3px",
};

const HelpMainPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);
  const [openModal1, setOpenModal1] = useState(location.state.model);
  const [loadExisting, setLoadExisting] = useState(false);
  const [existingTitleList, setExistingTitleList] = useState([]);
  const [defaultExistingTitleList, setDefaultExistingTitleList] = useState([]);
  const [viewChatComp, setViewChatComp] = useState(location.state.chat);
  const [selectedTitle, setSelectedTitle] = useState(null);
  const [groupMembers, setGroupMembers] = useState(null);

  useEffect(() => {
    setOpenModal1(true);
    setViewChatComp(false);
    setGroupMembers(null);
    setSelectedTitle(null);
  }, [location.state]);

  useEffect(() => {
    const fetchExistingList = async () => {
      try {
        setLoadExisting(true);
        const { data } = await AxiosInstance.post(
          `/help/getTitleUserStu`,
          {
            studentId: null,
          },
          {
            headers: {
              Authorization: `Bearer ${user.TOKEN}`,
            },
          }
        );
        setLoadExisting(false);
        if (data.message) {
          setExistingTitleList(null);
          // toast.warn(data.message);
        } else {
          setExistingTitleList(data);
          setDefaultExistingTitleList(data);
        }
      } catch (error) {
        setLoadExisting(null);
        console.log(error);
      }
    };
    fetchExistingList();
  }, [location.state]);

  const fetchExistingList = async () => {
    try {
      setLoadExisting(true);
      const { data } = await AxiosInstance.post(
        `/help/getTitleUserStu`,
        {
          studentId: null,
        },
        {
          headers: {
            Authorization: `Bearer ${user.TOKEN}`,
          },
        }
      );
      setLoadExisting(false);
      if (data.message) {
        setExistingTitleList(null);
        // toast.warn(data.message);
      } else {
        setExistingTitleList(data);
        setDefaultExistingTitleList(data);
      }
    } catch (error) {
      setLoadExisting(null);
      console.log(error);
    }
  };

  const fetchUsers = async item => {
    try {
      let { data } = await AxiosInstance.get(
        `/help/getGroupMembers/${item.requestId}`,
        {
          headers: { Authorization: `Bearer ${user.TOKEN}` },
        }
      );
      const studentMembers = data.members.filter(member =>
        member.role.includes("student")
      );

      setGroupMembers(studentMembers[0]);
    } catch (error) {}
  };

  return (
    <div className={styles.chatarea}>
      <div className={styles.NameBlock}>
        {/* modal for existing ticket */}
        <Modal open={openModal1}>
          <Box sx={styleModal1} position="relative">
            <h4 style={{ textAlign: "center", color: "#f16f1d" }}>
              Ticket List
            </h4>
            <Divider />
            <br />
            <button
              style={{
                border: "none",
                background: "none",
                position: "absolute",
                right: "5px",
                top: "5px",
              }}
              onClick={() => {
                setOpenModal1(false);
                navigate(-1);
                setGroupMembers(null);
                setSelectedTitle(null);
              }}
            >
              <svg width={18.54} height={20} viewBox="0 0 1216 1312">
                <path
                  fill="crimson"
                  d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                ></path>
              </svg>
            </button>
            <div>
              {loadExisting === null ? (
                <h4 style={{ textAlign: "center" }}>something went wrong</h4>
              ) : loadExisting ? (
                <h5 style={{ textAlign: "center", color: "#f57c00" }}>
                  Loading...
                </h5>
              ) : existingTitleList === null ? (
                <p style={{ textAlign: "center" }}>No Ticket Found</p>
              ) : (
                <div className={Styles.existingListUl}>
                  <ul>
                    {existingTitleList.map((item, ind) => {
                      return (
                        <li
                          key={ind + 1}
                          onClick={() => {
                            setViewChatComp(true);
                            setSelectedTitle(item);
                            setOpenModal1(false);
                            fetchUsers(item);
                          }}
                          style={{ position: "relative" }}
                        >
                          {item.title}{" "}
                          {item.conversation.length > 0 &&
                            item.conversation[0].notification && (
                              <span
                                style={{ position: "absolute", right: "7%" }}
                              >
                                <Badge
                                  style={{ fontSize: "small" }}
                                  badgeContent={item.conversation[0].noticount}
                                  color="success"
                                ></Badge>
                              </span>
                            )}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </Box>
        </Modal>
        {/* chat comp */}
        {viewChatComp ? (
          <aside
            className={styles.HeaderHelp}
            style={{
              borderBottom: "1px solid gray",
              padding: "0px 5px",
            }}
          >
            {" "}
            <div className={styles.HEadBlockName}>
              <span
                className={styles.HeadName}
                style={{ color: "#f16f1d", fontWeight: "bold" }}
              >
                Help &nbsp;
                <span
                  className={styles.ChangeUserIcon}
                  title="Change Help Ticket"
                  onClick={() => {
                    setOpenModal1(true);
                    setViewChatComp(false);
                    setGroupMembers(null);
                    fetchExistingList();
                  }}
                  style={{ cursor: "pointer" }}
                >
                  <svg width={24} height={24} viewBox="0 0 24 24">
                    <g
                      fill="none"
                      stroke="crimson"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1.5}
                    >
                      <path d="M19.75 6.75h-12a4 4 0 0 0-4 4v2m16-1v2a4 4 0 0 1-4 4h-12"></path>
                      <path d="m16.75 9.75l3-3l-3-3m-10 11l-3 3l3 3"></path>
                    </g>
                  </svg>{" "}
                </span>
              </span>
              <span className={styles.ChangeUser}>
                {viewChatComp ? (
                  <p className={styles.General}>
                    {viewChatComp && selectedTitle !== null && (
                      <span style={{ fontSize: "large" }}>
                        {selectedTitle.title}
                        <small style={{ fontSize: "x-small" }}>
                          ({selectedTitle.section})
                        </small>
                      </span>
                    )}
                  </p>
                ) : null}
              </span>
              <span className={styles.HeadDeatils}>
                <h6> Chats Are Monitored By Admin</h6>
              </span>
            </div>
          </aside>
        ) : null}
        {viewChatComp ? (
          <Suspense fallback={<h1>Loading...</h1>}>
            <ChatComp
              selectedTitle={selectedTitle}
              groupMembers={groupMembers}
            />
          </Suspense>
        ) : null}
      </div>
    </div>
  );
};

export default HelpMainPage;
