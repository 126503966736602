import { createContext, useEffect, useState } from "react";
import { BddtAxios } from "../../api/AxiosInstance";

export const PostContext = createContext();

export const PostContextProvider = ({ children }) => {
  const user1 = sessionStorage.getItem("user");
  const user = JSON.parse(user1);

  const ItemHeight = 44;
  const itemPaddingTop = 8;
  const MenuProps = {
    PaperProps: {
      style: { maxHeight: ItemHeight * 4.5 + itemPaddingTop, width: "250" },
    },
  };

  // Generate years from 1980 to current year
  const currentYear = new Date().getFullYear();
  const years = Array.from(
    new Array(currentYear - 1980 + 1),
    (val, index) => 1980 + index
  );

  //? student details filter part starts here
  const [studentFiltersData, setStudentFiltersData] = useState({
    studentName: "",
    studentNumber: "",
    studentEmail: "",
    gender: "",
    batch: "",
    oldBatch: "",
    branch: "",
    degreeCollege: "",
    masterDegreeCollege: "",
    fee: "",
    currentLocation: "",
    preferredLocation: "",
    subject: "",
    technicalRating: "",
    communicationRating: "",
    requirementCount: "",
    requirementCountRange: "",
    readyToRelocate: false,
    preMockStudents: false,
    finalMockStudents: false,
  });

  const handleChangeStudentFilter = e => {
    const { name, value, type, checked } = e.target;
    setStudentFiltersData({
      ...studentFiltersData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const clearStudentFilter = () => {
    setSelectedId4([]);
    setSelectedId5(null);
    setSelectedId6([]);
    setSelectedId7(null);
    setStudentFiltersData({
      studentName: "",
      studentNumber: "",
      studentEmail: "",
      gender: "",
      batch: "",
      oldBatch: "",
      branch: "",
      degreeCollege: "",
      masterDegreeCollege: "",
      fee: "",
      currentLocation: "",
      preferredLocation: "",
      subject: "",
      technicalRating: "",
      communicationRating: "",
      requirementCount: "",
      requirementCountRange: "",
      readyToRelocate: false,
      preMockStudents: false,
      finalMockStudents: false,
    });
  };

  //? student details filter part ends here

  //* education details filter part starts here
  const [educationDetailsFilter, setEducationDetailsFilter] = useState({
    min10thPercentage: "",
    max10thPercentage: "",
    min12thPercentage: "",
    max12thPercentage: "",
    diplomaStream: "",
    diplomaYop: [],
    minDiplomaPercentage: "",
    maxDiplomaPercentage: "",
    degree: "",
    degreeStream: "",
    minDegreePercentage: "",
    maxDegreePercentage: "",
    degreeYop: "",
    degreeUniversity: "",
    masterDegree: "",
    masterStream: "",
    minMasterPercentage: "",
    maxMasterPercentage: "",
    masterYop: "",
    masterUniversity: "",
    course: "",
  });

  const handleChangeEducationDetails = e => {
    const { name, value } = e.target;
    setEducationDetailsFilter({
      ...educationDetailsFilter,
      [name]: value,
    });
  };

  const clearEducationFilter = () => {
    setSelectedId8([]);
    setEducationDetailsFilter({
      min10thPercentage: "",
      max10thPercentage: "",
      min12thPercentage: "",
      max12thPercentage: "",
      diplomaStream: "",
      diplomaYop: [],
      minDiplomaPercentage: "",
      maxDiplomaPercentage: "",
      degree: "",
      degreeStream: "",
      minDegreePercentage: "",
      maxDegreePercentage: "",
      degreeYop: "",
      degreeUniversity: "",
      masterDegree: "",
      masterStream: "",
      minMasterPercentage: "",
      maxMasterPercentage: "",
      masterYop: "",
      masterUniversity: "",
      course: "",
    });
  };
  //* education details filter part starts here

  // !student filter
  // autocomplete for batch in student filter
  const [batchData, setBatchData] = useState([]);

  const [open4, setOpen4] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [inputValue4, setInputValue4] = useState("");
  const [selectedId4, setSelectedId4] = useState([]);

  const fetchOptions4 = async searchTerm => {
    try {
      setLoading4(true);
      const response = await BddtAxios.get(
        `/hr/batchcodemini/?name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setBatchData(data);
      } else {
        setBatchData([]);
      }

      setLoading4(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange4 = (event, newInputValue) => {
    setInputValue4(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions4(newInputValue);
    }
  };

  const handleOptionSelect4 = (_, newValue) => {
    setSelectedId4(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId4.length < 1 && inputValue4 === "") {
        // subject
        let subjectData = await BddtAxios.get(`/hr/batchcodemini/`, {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        });
        let fetchSubject = subjectData.data.results;
        setBatchData(fetchSubject);
      }
    };
    fetchSubject();
  }, [selectedId4, inputValue4]);

  // autocomplete for current location filter
  const [currentLocationData, setCurrentLocationData] = useState([]);

  const [open5, setOpen5] = useState(false);
  const [loading5, setLoading5] = useState(false);
  const [inputValue5, setInputValue5] = useState("");
  const [selectedId5, setSelectedId5] = useState(null);

  const fetchOptions5 = async searchTerm => {
    try {
      setLoading5(true);
      const response = await BddtAxios.get(
        `/commons/district-mini/?per_page=200&name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setCurrentLocationData(data);
      } else {
        setCurrentLocationData([]);
      }

      setLoading5(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange5 = (event, newInputValue) => {
    setInputValue5(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions5(newInputValue);
    }
  };

  const handleOptionSelect5 = (_, newValue) => {
    setSelectedId5(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId5 === null && inputValue5 === "") {
        // subject
        let subjectData = await BddtAxios.get(
          `/commons/district-mini/?per_page=200`,
          {
            headers: {
              Authorization: `Token ${user?.hr_Token}`,
            },
          }
        );
        let fetchSubject = subjectData.data.results;
        setCurrentLocationData(fetchSubject);
      }
    };
    fetchSubject();
  }, [selectedId5, inputValue5]);

  // autocomplete for preferred location student filter
  const [preferredLocationData, setPreferredLocationData] = useState([]);

  const [open6, setOpen6] = useState(false);
  const [loading6, setLoading6] = useState(false);
  const [inputValue6, setInputValue6] = useState("");
  const [selectedId6, setSelectedId6] = useState([]);

  const fetchOptions6 = async searchTerm => {
    try {
      setLoading6(true);
      const response = await BddtAxios.get(
        `/commons/cities/?per_page=200&name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setPreferredLocationData(data);
      } else {
        setPreferredLocationData([]);
      }

      setLoading6(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange6 = (event, newInputValue) => {
    setInputValue6(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions6(newInputValue);
    }
  };

  const handleOptionSelect6 = (_, newValue) => {
    setSelectedId6(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId6.length < 1 && inputValue6 === "") {
        // subject
        let subjectData = await BddtAxios.get(`/commons/cities/?per_page=200`, {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        });
        let fetchSubject = subjectData.data.results;
        setPreferredLocationData(fetchSubject);
      }
    };
    fetchSubject();
  }, [selectedId6, inputValue6]);

  // autocomplete for current location filter
  const [subjectData, setSubjectData] = useState([]);

  const [open7, setOpen7] = useState(false);
  const [loading7, setLoading7] = useState(false);
  const [inputValue7, setInputValue7] = useState("");
  const [selectedId7, setSelectedId7] = useState(null);

  const fetchOptions7 = async searchTerm => {
    try {
      setLoading7(true);
      const response = await BddtAxios.get(
        `/courses/subject/?name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setSubjectData(data);
      } else {
        setSubjectData([]);
      }

      setLoading7(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange7 = (event, newInputValue) => {
    setInputValue7(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions7(newInputValue);
    }
  };

  const handleOptionSelect7 = (_, newValue) => {
    setSelectedId7(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId7 === null && inputValue7 === "") {
        // subject
        let subjectData = await BddtAxios.get(`/courses/subject/`, {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        });
        let fetchSubject = subjectData.data.results;
        setSubjectData(fetchSubject);
      }
    };
    fetchSubject();
  }, [selectedId7, inputValue7]);

  // autocomplete course filter for education filter
  const [courseData, setCourseData] = useState([]);

  const [open8, setOpen8] = useState(false);
  const [loading8, setLoading8] = useState(false);
  const [inputValue8, setInputValue8] = useState("");
  const [selectedId8, setSelectedId8] = useState([]);

  const fetchOptions8 = async searchTerm => {
    try {
      setLoading8(true);
      const response = await BddtAxios.get(
        `/courses/courses/?name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setCourseData(data);
      } else {
        setCourseData([]);
      }

      setLoading8(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange8 = (event, newInputValue) => {
    setInputValue8(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions8(newInputValue);
    }
  };

  const handleOptionSelect8 = (_, newValue) => {
    setSelectedId8(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId8.length < 1 && inputValue8 === "") {
        // subject
        let subjectData = await BddtAxios.get(`/courses/courses/`, {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        });
        let fetchSubject = subjectData.data.results;
        setCourseData(fetchSubject);
      }
    };
    fetchSubject();
  }, [selectedId8, inputValue8]);

  const preventMinus = e => {
    if (/[0-9]/.test(e.key)) {
      if (e.code === "Minus" || e.code === "NumpadSubtract") {
        e.preventDefault();
      } else if (e.target.value.length > 4) {
        e.preventDefault();
      } else if (e.target.value === "" && e.key === "0") {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };
  const preventMinusNumber = e => {
    if (/[0-9]/.test(e.key)) {
      if (e.code === "Minus" || e.code === "NumpadSubtract") {
        e.preventDefault();
      } else if (e.target.value.length > 10) {
        e.preventDefault();
      } else if (e.target.value === "" && e.key === "0") {
        e.preventDefault();
      }
    } else {
      e.preventDefault();
    }
  };

  const preventPasteNegative = e => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));

    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  // autocomplete course filter for education filter
  const [dipStream, setDipStreamData] = useState([]);

  const [open9, setOpen9] = useState(false);
  const [loading9, setLoading9] = useState(false);
  const [inputValue9, setInputValue9] = useState("");
  const [selectedId9, setSelectedId9] = useState([]);

  const fetchOptions9 = async searchTerm => {
    try {
      setLoading9(true);
      const response = await BddtAxios.get(
        `/education/streams/?type=Diploma/?name=${searchTerm}`,
        {
          headers: {
            Authorization: `Token ${user?.hr_Token}`,
          },
        }
      );
      const data = response.data.results;

      // Ensure data is an array
      if (Array.isArray(data)) {
        setDipStreamData(data);
      } else {
        setDipStreamData([]);
      }

      setLoading8(false);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleInputChange9 = (event, newInputValue) => {
    setInputValue9(newInputValue);

    if (newInputValue.length >= 3) {
      fetchOptions9(newInputValue);
    }
  };

  const handleOptionSelect9 = (_, newValue) => {
    setSelectedId9(newValue);
  };

  useEffect(() => {
    let fetchSubject = async () => {
      if (selectedId9.length < 1 && inputValue9 === "") {
        // subject
        let subjectData = await BddtAxios.get(
          `/education/streams/?type=Diploma`,
          {
            headers: {
              Authorization: `Token ${user?.hr_Token}`,
            },
          }
        );
        let fetchSubject = subjectData.data.results;
        setDipStreamData(fetchSubject);
      }
    };
    fetchSubject();
  }, [selectedId9, inputValue9]);

  return (
    <PostContext.Provider
      value={{
        studentFiltersData,
        handleChangeStudentFilter,
        clearStudentFilter,
        educationDetailsFilter,
        handleChangeEducationDetails,
        clearEducationFilter,
        preventMinus,
        preventPasteNegative,
        preventMinusNumber,
        batchData,
        handleOptionSelect4,
        handleInputChange4,
        open4,
        loading4,
        inputValue4,
        selectedId4,
        setOpen4,
        currentLocationData,
        handleOptionSelect5,
        handleInputChange5,
        open5,
        loading5,
        inputValue5,
        selectedId5,
        setOpen5,
        preferredLocationData,
        handleOptionSelect6,
        handleInputChange6,
        open6,
        loading6,
        inputValue6,
        selectedId6,
        setOpen6,
        subjectData,
        handleOptionSelect7,
        handleInputChange7,
        open7,
        loading7,
        inputValue7,
        selectedId7,
        setOpen7,
        courseData,
        handleOptionSelect8,
        handleInputChange8,
        open8,
        loading8,
        inputValue8,
        selectedId8,
        setOpen8,
        years,
        MenuProps,
        dipStream,
        handleOptionSelect9,
        handleInputChange9,
        open9,
        loading9,
        inputValue9,
        selectedId9,
        setOpen9,
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
